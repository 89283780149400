import {
  LegacyCard,
  IndexTable,
  useIndexResourceState,
  Badge,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  Page,
  Button,
  Layout,
  Thumbnail,
  Link,
  Spinner,
  EmptyState,
  VerticalStack,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import NumberFormat from "../../helpers/general";
import LoadingMarkup from "../../components/LoadingMarkup";
import ProductPriceCalculator from "../../helpers/ProductPriceCalculator";
import { fetcher } from "../../helpers/fetcher";
import { useMain } from "src/contexts/Main";

function Products(props) {
  const { rates } = useMain();
  // start: Page Variables
  const [products, setProducts] = useState(false);
  // end: Page Variables

  // start: Tabs Variables
  const [selectedTab, setSelectedTab] = useState(0);
  // end: Tabs Variables

  // start: Index Filters Variables
  const filterSortOptions = [
    {
      label: "Ürün Başlığı",
      value: "product_title asc",
      directionLabel: "A-Z",
    },
    {
      label: "Ürün Başlığı",
      value: "product_title desc",
      directionLabel: "Z-A",
    },
    {
      label: "Oluşturulma",
      value: "date asc",
      directionLabel: "Eskiden Yeniye",
    },
    {
      label: "Oluşturulma",
      value: "date desc",
      directionLabel: "Yeniden Eskiye",
    },
    {
      label: "Envanter durumu",
      value: "product_quantity asc",
      directionLabel: "Artan",
    },
    {
      label: "Envanter durumu",
      value: "product_quantity desc",
      directionLabel: "Azalan",
    },
    { label: "Marka", value: "product_brand asc", directionLabel: "A-Z" },
    { label: "Marka", value: "product_brand desc", directionLabel: "Z-A" },
  ];
  const [filterSortSelectedOption, setFilterSortSelectedOption] = useState([
    "date desc",
  ]);
  const filtersMode = useSetIndexFiltersMode();
  const [filtersQueryValue, setFiltersQueryValue] = useState("");
  const [filtersLoading, setFiltersLoading] = useState(false);
  const checkFilterChanges = () => {
    var defaultFilters = {
      selectedTab: 0,
      query: "",
    };
    return defaultFilters.selectedTab === selectedTab &&
      defaultFilters.query === filtersQueryValue &&
      products.length == 0
      ? false
      : true;
  };
  // end: Index Filters Variables

  // start: Index Table Variables
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(products);
  // end: Index Table Variables

  // start: Page Functions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        handleSelectionChange([]);
        setFiltersLoading(true);
        var productSort = filterSortSelectedOption[0].split(" ");
        const result = await fetcher({
          endpoint:
            "getProducts.php?status=" +
            (selectedTab === 0
              ? "All"
              : selectedTab === 1
              ? "Active"
              : selectedTab === 2
              ? "Draft"
              : selectedTab === 3
              ? "Archived"
              : "All") +
            (filtersQueryValue !== 0 && filtersQueryValue !== ""
              ? "&query=" + filtersQueryValue
              : "") +
            (productSort[1] !== null
              ? "&productSort=" + productSort[1]
              : "&productSort=DESC") +
            (productSort[0] !== null
              ? "&sortType=" + productSort[0]
              : "&sortType=date"),
          method: "GET",
        });
        if (!result.fetchError) {
          setProducts(result);
          setFiltersLoading(false);
        } else {
          setFiltersLoading(false);
        }
      })();
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersQueryValue, selectedTab, filterSortSelectedOption]);
  // end: Page Functions

  const tableRowMarkup =
    products &&
    products.map(
      (
        {
          id,
          product_title,
          product_status,
          product_archived,
          product_brand,
          product_price,
          product_type,
          product_quantity,
          product_image,
        },
        index
      ) => (
        <IndexTable.Row
          id={id.toString()}
          key={id.toString()}
          selected={selectedResources.includes(id.toString())}
          status={product_archived === 1 && "subdued"}
          position={index}
        >
          <IndexTable.Cell>
            <Thumbnail source={product_image} size={"small"} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Link url={"/products/" + id} monochrome removeUnderline>
              <div style={{ width: "300px", wordBreak: "break-all" }}>
                <Text breakWord as="p">
                  <span
                    style={{
                      wordBreak: "normal",
                      overflowWrap: "anywhere",
                      whiteSpace: "normal",
                    }}
                  >
                    {product_title}
                  </span>
                </Text>
              </div>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge
              status={
                product_archived === 1 && product_status === 0
                  ? null
                  : product_archived === 0 && product_status === 1
                  ? "success"
                  : product_archived === 0 && product_status === 0
                  ? "info"
                  : null
              }
            >
              {product_archived === 1 && product_status === 0
                ? "Arşivlenen"
                : product_archived === 0 && product_status === 1
                ? "Aktif"
                : product_archived === 0 && product_status === 0
                ? "Taslak"
                : null}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {NumberFormat(product_price, 2, ",", ".")}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {rates.length > 0 && product_type && product_type.value ? (
              ProductPriceCalculator(product_price, product_type.value, rates)
            ) : (
              <Spinner size="small" />
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" color={product_quantity < 10 ? "negative" : null}>
              Stokta {product_quantity} adet var
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{product_brand}</IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  const pageMarkup = (
    <Page
      fullWidth
      title="Ürünler"
      primaryAction={
        checkFilterChanges() ? (
          <Button url="/products/new/" primary>
            Ürün oluştur
          </Button>
        ) : (
          ""
        )
      }
      secondaryActions={
        checkFilterChanges()
          ? [
              { content: "Dışa aktar", disabled: true },
              { content: "İçe aktar", disabled: true },
            ]
          : [{ content: "İçe aktar", disabled: true }]
      }
    >
      <Layout sectioned>
        {checkFilterChanges() ? (
          <LegacyCard>
            <IndexFilters
              sortOptions={filterSortOptions}
              sortSelected={filterSortSelectedOption}
              queryValue={filtersQueryValue}
              queryPlaceholder="Ürünlerin içerisinde ara"
              onQueryChange={(value) => {
                setFiltersQueryValue(value);
              }}
              onQueryClear={() => {
                setFiltersQueryValue("");
              }}
              onSort={(value) => {
                setFilterSortSelectedOption(value);
              }}
              filters={[]}
              appliedFilters={[]}
              hideFilters
              cancelAction={{
                onAction: () => {
                  setFiltersQueryValue("");
                },
                disabled: false,
                loading: false,
              }}
              tabs={[
                {
                  id: "All",
                  content: "Tümü",
                  panelID: "All",
                },
                {
                  id: "Active",
                  content: "Etkin",
                  panelID: "active",
                },
                {
                  id: "Draft",
                  content: "Taslak",
                  panelID: "draft",
                },
                {
                  id: "Archived",
                  content: "Arşivlenen",
                  panelID: "archived",
                },
              ]}
              selected={selectedTab}
              onSelect={setSelectedTab}
              canCreateNewView={false}
              onClearAll={() => setFiltersQueryValue("")}
              mode={filtersMode.mode}
              setMode={filtersMode.setMode}
              loading={filtersLoading}
            />
            <IndexTable
              resourceName={{
                singular: "Ürün",
                plural: "Ürünler",
              }}
              itemCount={products.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[
                { title: "Fotoğraf", hidden: true },
                { title: "Ürün" },
                { title: "Durum" },
                { title: "Ürün Gramı" },
                { title: "Fiyat" },
                { title: "Envanter" },
                { title: "Marka" },
              ]}
              bulkActions={[
                {
                  items: [
                    {
                      content: "Etkin olarak ayarla",
                      onAction: () =>
                        selectedResources.map((value) =>
                          changeProductDetail(value, {
                            product_status: 1,
                            product_archived: 0,
                          })
                        ),
                    },
                    {
                      content: "Taslak olarak ayarla",
                      onAction: () => {
                        selectedResources.map((value) =>
                          changeProductDetail(value, {
                            product_status: 0,
                            product_archived: 0,
                          })
                        );
                      },
                    },
                    {
                      content: "Ürünleri arşivle",
                      onAction: () => {
                        selectedResources.map((value) =>
                          updateArchiveStatus(value, 1)
                        );
                      },
                    },
                    {
                      content: "Ürünleri sil",
                      onAction: () => {
                        selectedResources.map((value) => deleteProducts(value));
                      },
                    },
                  ],
                },
                {
                  items: [
                    {
                      content: "Kullanılan kanalları ekle",
                      onAction: () =>
                        console.log("Todo: implement payment capture"),
                      disabled: true,
                    },
                    {
                      content: "Kullanılan kanalları kaldır",
                      onAction: () => {
                        selectedResources.map((value) =>
                          updateArchiveStatus(value, 1)
                        );
                      },
                      disabled: true,
                    },
                  ],
                },
                {
                  items: [
                    {
                      content: "Koleksiyonlara ekle",
                      onAction: () =>
                        console.log("Todo: implement payment capture"),
                      disabled: true,
                    },
                    {
                      content: "Koleksiyonlardan kaldır",
                      onAction: () => {
                        selectedResources.map((value) =>
                          updateArchiveStatus(value, 1)
                        );
                      },
                      disabled: true,
                    },
                  ],
                },
              ]}
              promotedBulkActions={[
                {
                  content: "Ürünleri düzenle",
                  disabled: true,
                },
              ]}
            >
              {tableRowMarkup}
            </IndexTable>
          </LegacyCard>
        ) : (
          <LegacyCard sectioned>
            <EmptyState
              action={{
                content: "Ürün oluştur",
                url: "/products/new/",
              }}
              image={
                "https://cdn.shopify.com/shopifycloud/web/assets/v1/67d1bd2ad29c4adc.svg"
              }
            >
              <VerticalStack gap={2}>
                <Text variant="headingLg">Öncelikle: Ne satıyorsunuz?</Text>
                <p>Mağazanızı açmadan önce bazı ürünlere ihtiyacınız var.</p>
              </VerticalStack>
            </EmptyState>
          </LegacyCard>
        )}
      </Layout>
    </Page>
  );

  return products ? pageMarkup : <LoadingMarkup />;

  async function updateArchiveStatus(value, status) {
    await fetcher({
      endpoint: "updateArchiveStatusProduct.php",
      method: "POST",
      body: JSON.stringify({
        secure: value,
        status: status,
      }),
    });
    handleSelectionChange([]);
  }

  async function changeProductDetail(secure, changedArray) {
    var combineArray = {};
    products.forEach((e) => {
      if (e.secure_hash === secure) {
        combineArray = e;
      }
    });
    combineArray = { ...combineArray, ...changedArray };
    const result = await fetcher({
      endpoint: "updateProductDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: secure,
        productDetail: combineArray[0],
      }),
    });
    handleSelectionChange([]);
  }

  async function deleteProducts(value) {
    const result = await fetcher({
      endpoint: "deleteProduct.php",
      method: "POST",
      body: JSON.stringify({
        secure: value,
      }),
    });
    handleSelectionChange([]);
  }
}

export default Products;
