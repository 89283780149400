import {
  Page,
  Layout,
  Button,
  LegacyCard,
  SkeletonBodyText,
  SkeletonPage,
  Loading,
  LegacyStack,
  TextField,
  Link,
  Icon,
  Thumbnail,
  Popover,
  Toast,
  Modal,
  Autocomplete,
  Avatar,
  ButtonGroup,
  Tag,
  ResourceList,
  DropZone,
  PageActions,
  Banner,
  FormLayout,
  DataTable,
  ContextualSaveBar,
  Combobox,
  Listbox,
  ActionList,
  Checkbox,
  Select,
  OptionList,
  Spinner,
  ResourceItem,
  Text,
} from "@shopify/polaris";
import {
  NoteMinor,
  CirclePlusMinor,
  SearchMinor,
  CancelSmallMinor,
  HorizontalDotsMinor,
} from "@shopify/polaris-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMediaPredicate } from "react-media-hook";
import { fetcher } from "src/helpers/fetcher";

function DraftOrderDetail() {
  let location = useLocation();
  let navigate = useNavigate();
  const isDesktop = useMediaPredicate("(min-width: 1045px)");
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [savePaymentButtonLoading, setSavePaymentButtonLoading] =
    useState(false);

  const countryList = useRef([]);

  const [orderAddCustomerLoading, setOrderAddCustomerLoading] = useState(true);

  const [orderDetail, setOrderDetail] = useState({
    original: {
      number: "",
      date: 0,
      lastUpdated: 0,
      customer: "",
      completed: 0,
      waitingPayment: 0,
      items: [{ secure: "", name: "", price: 0, quantity: 0 }],
      payments: [
        { type: "", paid: 0 },
        { type: "", paid: 0 },
      ],
      note: "",
      email: "",
      phone: "",
      shippingAddress: {
        addressLine: "",
        secondAddressLine: "",
        country: "",
        city: "",
        province: "",
        zip: "",
      },
      invoiceAddress: {
        addressLine: "",
        secondAddressLine: "",
        country: "",
        city: "",
        province: "",
        zip: "",
      },
      tags: [],
      timeline: [],
      total: 0,
    },
    editable: {
      number: "",
      date: 0,
      lastUpdated: 0,
      customer: "",
      completed: 0,
      waitingPayment: 0,
      items: [{ secure: "", name: "", price: 0, quantity: 0 }],
      payments: [
        { type: "", paid: 0 },
        { type: "", paid: 0 },
      ],
      note: "",
      email: "",
      phone: "",
      shippingAddress: {
        addressLine: "",
        secondAddressLine: "",
        country: "",
        city: "",
        province: "",
        zip: "",
      },
      invoiceAddress: {
        addressLine: "",
        secondAddressLine: "",
        country: "",
        city: "",
        province: "",
        zip: "",
      },
      tags: [],
      timeline: [],
      total: 0,
    },
  });

  const [orderDetailShippingAddress, setOrderDetailShippingAddress] = useState({
    addressLine: "",
    secondAddressLine: "",
    countryCode: "",
    country: "",
    city: "",
    province: "",
    zip: "",
  });

  const [orderDetailInvoiceAddress, setOrderDetailInvoiceAddress] = useState({
    addressLine: "",
    secondAddressLine: "",
    countryCode: "",
    country: "",
    city: "",
    province: "",
    zip: "",
  });

  const [orderDetailNewCustomerDetails, setOrderDetailNewCustomerDetails] =
    useState({
      email: "",
      language: "",
      marketingPreferences: "",
      address: {
        name: "",
        surname: "",
        addressLine: null,
        secondAddressLine: null,
        countryCode: "TR",
        country: "Türkiye",
        city: null,
        province: null,
        zip: null,
        phonePrefix: "+90",
        phone: null,
      },
    });

  const [orderDetailProductItems, setOrderDetailProductItems] = useState([]);

  const [orderDetailSelectedProductItems, setOrderDetailSelectedProductItems] =
    useState([]);

  const [contextualSaveBarActive, setContextualSaveBarActive] = useState(false);

  const [files, setFiles] = useState([]);

  const [ordersBulkToastActive, setOrdersBulkToastActive] = useState(false);
  const ordersBulkActionToastToggle = useCallback(
    () => setOrdersBulkToastActive((active) => !active),
    []
  );

  const [orderTagsTextfieldValue, setOrderTagsTextfieldValue] = useState("");

  const [orderNoteModalActive, setOrderNoteModalActive] = useState(false);

  const [editOrderNoteValue, setEditOrderNoteValue] = useState(
    orderDetail.note
  );

  const [editOrderNoteSaveButtonLoading, setEditOrderNoteSaveButtonLoading] =
    useState(false);

  const [
    editShippingAddressPhoneCountryPopoverActive,
    setEditShippingAddressPhoneCountryPopoverActive,
  ] = useState(false);

  const [timeLineTextFieldValue, setTimeLineTextFieldValue] = useState("");

  const [staff, setStaff] = useState([]);

  const [customers, setCustomers] = useState([]);

  const [productsQuantityInputValues, setProductsQuantityInputValues] =
    useState({});

  const [editContactDetailModalActive, setEditContactDetailModalActive] =
    useState(false);

  const [
    editContactDetailSaveButtonLoading,
    setEditContactDetailSaveButtonLoading,
  ] = useState(false);

  const [orderDetailContactDetailEmail, setOrderDetailContactDetailEmail] =
    useState(orderDetail.email);

  const [orderDetailContactDetailPhone, setOrderDetailContactDetailPhone] =
    useState(orderDetail.phone);

  const [
    orderDetailContactDetailIsCustomerProfileUpdating,
    setOrderDetailContactDetailIsCustomerProfileUpdating,
  ] = useState(false);

  const [editShippingAddressModalActive, setEditShippingAddressModalActive] =
    useState(false);

  const [editInvoiceAddressModalActive, setEditInvoiceAddressModalActive] =
    useState(false);

  const [
    editShippingAddressSaveButtonLoading,
    setEditShippingAddressSaveButtonLoading,
  ] = useState(false);

  const [completeOrderModalActive, setCompleteOrderModalActive] =
    useState(false);

  const [
    editInvoiceAddressSaveButtonLoading,
    setEditInvoiceAddressSaveButtonLoading,
  ] = useState(false);

  const [newCustomerSaveButtonLoading, setNewCustomerSaveButtonLoading] =
    useState(false);

  const [
    orderDetailCustomerSectionPopoverActive,
    setOrderDetailCustomerSectionPopoverActive,
  ] = useState(false);

  const [
    editInvoiceAddressPhoneCountryPopoverActive,
    setEditInvoiceAddressPhoneCountryPopoverActive,
  ] = useState(false);

  const [
    newCustomerPhoneCountryPopoverActive,
    setNewCustomerPhoneCountryPopoverActive,
  ] = useState(false);

  const [addNewProductsModalActive, setAddNewProductsModalActive] =
    useState(false);

  const [newCustomerModalActive, setNewCustomerModalActive] = useState(false);

  const [addNewProductsSaveButtonLoading, setAddNewProductsSaveButtonLoading] =
    useState(false);

  const [addNewProductModalSearchValue, setAddNewProductModalSearchValue] =
    useState("");

  const [addNewProductsSearchLoading, setAddNewProductsSearchLoading] =
    useState(false);

  const [addNewProductsSearchedProducts, setAddNewProductsSearchedProducts] =
    useState([]);

  useEffect(() => {
    fetcher({
      endpoint: "geo/countries.php",
    }).then((e) => (countryList.current = e.content));
  }, []);

  const loadingMarkup = (
    <SkeletonPage>
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              disabled: true,
            }}
          />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const productsRows =
    orderDetail?.items?.length > 0 &&
    orderDetailProductItems.map(({ secure, name, price, quantity }, index) =>
      orderDetail.completed === 0
        ? [
            <div style={{ width: "100%" }}>
              <LegacyStack alignment="center">
                <Thumbnail
                  source={
                    "//panel.altinoranonline.com.tr/p/" + secure + "_0.png"
                  }
                  size="small"
                />
                <LegacyStack spacing="extraTight" vertical>
                  <Text as="span">{name}</Text>
                  <Text as="span">{price}₺</Text>
                </LegacyStack>
              </LegacyStack>
            </div>,
            <LegacyStack alignment="center">
              <div style={{ width: "90px" }}>
                <TextField
                  id={secure}
                  type="number"
                  maxLength={1}
                  onChange={(val) => {
                    let temp_state = [...orderDetailProductItems];
                    let temp_element = { ...temp_state[index] };
                    temp_element.quantity = val;
                    temp_state[index] = temp_element;
                    setOrderDetailProductItems(temp_state);
                    if (val !== quantity && val !== undefined) {
                      controlChanges();
                    }
                  }}
                  disabled={orderDetail.completed === 1 && true}
                  inputMode="numeric"
                  value={orderDetailProductItems[index]["quantity"]}
                  min={1}
                  step={1}
                />
              </div>
            </LegacyStack>,
            <LegacyStack distribution="fill">
              <p>
                {productsQuantityInputValues[secure] !== quantity &&
                productsQuantityInputValues[secure] !== undefined
                  ? (price * productsQuantityInputValues[secure]).toFixed(2) +
                    "₺"
                  : (price * quantity).toFixed(2) + "₺"}
              </p>
            </LegacyStack>,
            <Button
              plain
              removeUnderline
              onClick={() => deleteProducts(secure)}
            >
              <div style={{ color: "black", fill: "black" }}>
                <Icon source={CancelSmallMinor} />
              </div>
            </Button>,
          ]
        : [
            <div style={{ width: "100%" }}>
              <LegacyStack alignment="center">
                <Thumbnail
                  source={
                    "//panel.altinoranonline.com.tr/p/" +
                    orderDetail.items[0].secure +
                    "_0.png"
                  }
                  size="small"
                />
                <LegacyStack spacing="extraTight" vertical>
                  <Text as="span">{name}</Text>
                  <Text as="span">{price}₺</Text>
                </LegacyStack>
              </LegacyStack>
            </div>,
            <LegacyStack alignment="center">
              <div style={{ width: "90px" }}>
                <TextField
                  id={secure}
                  type="number"
                  maxLength={1}
                  onChange={(val) => {
                    setProductsQuantityInputValues({
                      ...productsQuantityInputValues,
                      [secure]: val,
                    });
                    if (val !== quantity && val !== undefined) {
                      controlChanges();
                    }
                  }}
                  disabled={orderDetail.completed === 1 && true}
                  inputMode="numeric"
                  value={
                    productsQuantityInputValues[secure] !== quantity &&
                    productsQuantityInputValues[secure] !== undefined
                      ? productsQuantityInputValues[secure]
                      : quantity
                  }
                  min={1}
                  step={1}
                />
              </div>
            </LegacyStack>,
            <p>
              {productsQuantityInputValues[secure] !== quantity &&
              productsQuantityInputValues[secure] !== undefined
                ? (price * productsQuantityInputValues[secure]).toFixed(2) + "₺"
                : (price * quantity).toFixed(2) + "₺"}
            </p>,
          ]
    );

  const [inputValue, setInputValue] = useState("");

  const actualPageMarkup = (
    <Page
      title={"#" + orderDetail.number}
      titleMetadata={orderDetail.lastUpdated + " tarihinde güncellendi"}
      backAction={{ url: "/orders/drafts/" }}
    >
      {contextualSaveBarActive && (
        <ContextualSaveBar
          message="Kaydedilmemiş değişiklikler"
          saveAction={{
            content: "Taslak siparişi güncelle",
            onAction: () => updateDraftOrderItems(),
            loading: false,
            disabled: false,
          }}
          discardAction={{
            onAction: () => {
              updateOrder();
            },
          }}
        />
      )}
      <Layout>
        <Layout.Section>
          {orderDetail.completed === 1 && (
            <Banner title="Tamamlandı" status="success">
              <Text as="span" color="subdued">
                Bu sipariş {orderDetail.date} tarihinde tamamlandı
              </Text>
            </Banner>
          )}
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Header
              title={
                <LegacyStack spacing="fill" alignment="center">
                  <LegacyStack spacing="tight" alignment="center">
                    <Text variant="headingMd" as="h2">
                      Ürünler
                    </Text>
                  </LegacyStack>
                </LegacyStack>
              }
            ></LegacyCard.Header>
            {orderDetail.completed === 0 && (
              <LegacyCard.Section>
                <LegacyStack spacing="extraTight">
                  <LegacyStack.Item fill>
                    <TextField
                      type="text"
                      autoComplete="off"
                      placeholder="Ürünleri arayın"
                      prefix={<Icon source={SearchMinor} />}
                      onChange={(val) => {
                        setAddNewProductsModalActive(true);
                        setAddNewProductModalSearchValue(val);
                      }}
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Button
                      onClick={() => {
                        setAddNewProductsModalActive(true);
                        searchProducts("");
                      }}
                    >
                      Gözat
                    </Button>
                  </LegacyStack.Item>
                </LegacyStack>
              </LegacyCard.Section>
            )}
            {orderDetail?.items?.length > 0 ? (
              <DataTable
                columnContentTypes={["text", "text", "numeric"]}
                headings={
                  orderDetail.completed === 0
                    ? [
                        <Text as="span" fontWeight="semibold">
                          Ürün
                        </Text>,
                        <Text as="span" fontWeight="semibold">
                          Adet
                        </Text>,
                        <Text as="span" fontWeight="semibold">
                          Toplam
                        </Text>,
                        <Text as="span" fontWeight="semibold"></Text>,
                      ]
                    : [
                        <Text as="span" fontWeight="semibold">
                          Ürün
                        </Text>,
                        <Text as="span" fontWeight="semibold">
                          Adet
                        </Text>,
                        <Text as="span" fontWeight="semibold">
                          Toplam
                        </Text>,
                      ]
                }
                rows={productsRows}
              />
            ) : (
              <LegacyStack distribution="center" alignment="center" vertical>
                <div style={{ margin: "1.25rem 0px" }}>
                  Hiç ürün eklemediniz
                </div>
              </LegacyStack>
            )}
            <Modal
              open={addNewProductsModalActive}
              onClose={() => {
                setAddNewProductsModalActive(false);
                setAddNewProductModalSearchValue("");
                searchProducts("");
              }}
              title="Tüm ürünler"
              primaryAction={{
                content: "Bitti",
                onAction: addProducts,
                loading: addNewProductsSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setAddNewProductsModalActive(false);
                    setAddNewProductModalSearchValue("");
                    searchProducts("");
                  },
                },
              ]}
            >
              <Modal.Section>
                <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="Ürünleri arayın"
                  prefix={<Icon source={SearchMinor} />}
                  value={addNewProductModalSearchValue}
                  onChange={(val) => {
                    searchProducts(val);
                    setAddNewProductModalSearchValue(val);
                  }}
                  autoFocus
                />
              </Modal.Section>
              {addNewProductsSearchLoading && (
                <LegacyStack distribution="center" alignment="center">
                  <Spinner size="large" />
                </LegacyStack>
              )}
              {addNewProductsSearchedProducts.length === 0 &&
              !addNewProductsSearchLoading ? (
                <Modal.Section>
                  <div>
                    <Text as="span">"{addNewProductModalSearchValue}"</Text>
                    <Text as="span" color="subdued">
                      {" "}
                      için sonuç bulunamadı
                    </Text>
                  </div>
                </Modal.Section>
              ) : (
                !addNewProductsSearchLoading && (
                  <ResourceList
                    items={addNewProductsSearchedProducts}
                    renderItem={({ secure_hash, product_title }) => {
                      return (
                        <div>
                          <ResourceItem
                            id={secure_hash}
                            media={
                              <Thumbnail
                                size="small"
                                source={
                                  "//panel.altinoranonline.com.tr/p/" +
                                  secure_hash +
                                  "_0.png"
                                }
                              />
                            }
                          >
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <Text as="span">{product_title}</Text>
                            </div>
                          </ResourceItem>
                        </div>
                      );
                    }}
                    selectedItems={orderDetailSelectedProductItems}
                    onSelectionChange={setOrderDetailSelectedProductItems}
                    selectable
                  />
                )
              )}
              <Modal.Section></Modal.Section>
              <Modal.Section>
                <Text as="span">
                  Kişiye özel ve varyasyonlu ürün ekleyemezsiniz, kişiye özel ve
                  varyasyonlu ürünler ürün listesinde gözükmez
                </Text>
              </Modal.Section>
            </Modal>
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Header
              title={
                <LegacyStack spacing="fill" alignment="center">
                  <LegacyStack spacing="tight" alignment="center">
                    <Text variant="headingMd" as="h2">
                      Ödeme
                    </Text>
                  </LegacyStack>
                </LegacyStack>
              }
            ></LegacyCard.Header>
            <LegacyCard.Section>
              <div style={{ gridRowGap: "0.5rem", display: "grid" }}>
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <span>Ürünler</span>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <span>{orderDetail.total}₺</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <span>Vergi (18%)</span>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <span>
                            {((orderDetail.total * 18) / 100).toFixed(2)}₺
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <Text as="span" fontWeight="semibold">
                    Toplam
                  </Text>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <Text as="span" fontWeight="semibold">
                            {(
                              orderDetail.total +
                              (orderDetail.total * 18) / 100
                            ).toFixed(2)}
                            ₺
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LegacyCard.Section>
            {orderDetail.completed === 0 && (
              <div>
                <LegacyCard.Section>
                  <LegacyStack distribution="trailing" spacing="tight">
                    <Button>Fatura gönder</Button>
                    <Button
                      primary
                      onClick={() => setCompleteOrderModalActive(true)}
                      loading={savePaymentButtonLoading}
                    >
                      Ödendi olarak işaretle
                    </Button>
                  </LegacyStack>
                </LegacyCard.Section>
                <Modal
                  open={completeOrderModalActive}
                  onClose={() => {
                    setCompleteOrderModalActive(false);
                  }}
                  title="Siparişi tamalayın"
                  primaryAction={{
                    content: "Tamamla",
                    onAction: () => {
                      updatePaymentOrder(1);
                    },
                    loading: savePaymentButtonLoading,
                  }}
                  secondaryActions={[
                    {
                      content: "İptal",
                      onAction: () => {
                        setCompleteOrderModalActive(false);
                      },
                    },
                  ]}
                >
                  <Modal.Section>
                    <Text as="span">
                      Bu siparişi tamamlarsanız bir daha üzerinde düzenleme
                      yapamayacaksınız, devam ederseniz müşteri sipariş
                      detaylarını alacak ve ödeme yapmış olarak kabul edilecek
                      eğer müşteriden ödeme almadıysanız ve kredi kartı ile
                      ödemesini isteyecekseniz Fatura Gönder seçeneğini
                      kullanmanız gerekmektedir, lütfen ne yaptığınızdan ve bir
                      hata olmadığından emin olun.
                    </Text>
                  </Modal.Section>
                </Modal>
              </div>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Header title="Müşteri">
              {orderDetail.completed === 0 && orderDetail.customer !== " " && (
                <Popover
                  active={orderDetailCustomerSectionPopoverActive}
                  activator={
                    <Button
                      icon={HorizontalDotsMinor}
                      plain
                      onClick={() => {
                        setOrderDetailCustomerSectionPopoverActive(
                          !orderDetailCustomerSectionPopoverActive
                        );
                      }}
                    ></Button>
                  }
                  onClose={() => {
                    setOrderDetailCustomerSectionPopoverActive(
                      !orderDetailCustomerSectionPopoverActive
                    );
                  }}
                >
                  <ActionList
                    items={[
                      {
                        content: "İletişim bilgilerini düzenleyin",
                        onAction: () => setEditContactDetailModalActive(true),
                      },
                      {
                        content: "Kargo adresini düzenleyin",
                        onAction: () => setEditShippingAddressModalActive(true),
                      },
                      {
                        content: "Fatura adresini düzenleyin",
                        onAction: () => setEditInvoiceAddressModalActive(true),
                      },
                      {
                        content: "Müşteriyi kaldır",
                        destructive: true,
                        onAction: deleteCustomerFromOrder,
                      },
                    ]}
                  />
                </Popover>
              )}
            </LegacyCard.Header>
            <Modal
              open={editContactDetailModalActive}
              onClose={() => {
                setEditContactDetailModalActive(false);
                setOrderDetailContactDetailEmail(orderDetail.email);
                setOrderDetailContactDetailPhone(orderDetail.phone);
              }}
              title="İletişim bilgilerini düzenle"
              primaryAction={{
                content: "Bitti",
                onAction: () => {
                  saveContactDetail();
                },
                loading: editContactDetailSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditContactDetailModalActive(false);
                    setOrderDetailContactDetailEmail(orderDetail.email);
                    setOrderDetailContactDetailPhone(orderDetail.phone);
                  },
                },
              ]}
            >
              <Modal.Section>
                <LegacyStack vertical>
                  <TextField
                    value={orderDetailContactDetailEmail}
                    onChange={setOrderDetailContactDetailEmail}
                    label="E-posta"
                    type="email"
                    inputMode="email"
                  />
                  <TextField
                    value={orderDetailContactDetailPhone}
                    onChange={setOrderDetailContactDetailPhone}
                    label="Telefon numarası"
                    type="tel"
                    inputMode="tel"
                  />
                  <Checkbox
                    checked={orderDetailContactDetailIsCustomerProfileUpdating}
                    onChange={
                      setOrderDetailContactDetailIsCustomerProfileUpdating
                    }
                    label="Müşteri profilini güncelle"
                  />
                </LegacyStack>
              </Modal.Section>
            </Modal>
            <Modal
              open={editShippingAddressModalActive}
              onClose={() => {
                setEditShippingAddressModalActive(false);
              }}
              title="Kargo adresini düzenle"
              primaryAction={{
                content: "Bitti",
                onAction: () => saveOrderShippingAddress(),
                loading: editShippingAddressSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditShippingAddressModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Ülke/Bölge"
                      options={countryList.current.map(({ iso, name }) => ({
                        value: iso,
                        label: name,
                      }))}
                      onChange={(val) => {
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          countryCode: val,
                          country: getCountryNameByCode(val),
                        });
                      }}
                      value={orderDetailShippingAddress?.countryCode}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Ad"
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          name: val,
                        })
                      }
                      value={orderDetailShippingAddress?.name}
                      label="Ad"
                    />
                    <TextField
                      placeholder="Soyad"
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          surname: val,
                        })
                      }
                      value={orderDetailShippingAddress?.surname}
                      label="Soyad"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Adres"
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          addressLine: val,
                        })
                      }
                      value={orderDetailShippingAddress?.addressLine}
                      label="Adres"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Apartman, daire vb."
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          secondAddressLine: val,
                        })
                      }
                      value={orderDetailShippingAddress?.secondAddressLine}
                      label="Apartman, daire vb."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Posta kodu"
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          zip: val,
                        })
                      }
                      value={orderDetailShippingAddress?.zip}
                      label="Posta kodu"
                    />
                    <TextField
                      placeholder="Şehir"
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          city: val,
                        })
                      }
                      value={orderDetailShippingAddress?.city}
                      label="Şehir"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setOrderDetailShippingAddress({
                          ...orderDetailShippingAddress,
                          phone: val,
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {orderDetailShippingAddress?.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={editShippingAddressPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setEditShippingAddressPhoneCountryPopoverActive(
                                  (
                                    editShippingAddressPhoneCountryPopoverActive
                                  ) =>
                                    !editShippingAddressPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={countryList.current.map(
                              ({ iso, name, phone_prefix }) => ({
                                value: phone_prefix,
                                label: name,
                                phoneCountry: iso,
                              })
                            )}
                            onChange={(va) => {
                              setOrderDetailShippingAddress({
                                ...orderDetailShippingAddress,
                                phonePrefix: va,
                              });
                              setEditShippingAddressPhoneCountryPopoverActive(
                                false
                              );
                            }}
                            selected={orderDetailShippingAddress?.phonePrefix}
                          />
                        </Popover>
                      }
                      value={orderDetailShippingAddress?.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <Modal
              open={editInvoiceAddressModalActive}
              onClose={() => {
                setEditInvoiceAddressModalActive(false);
              }}
              title="Fatura adresini düzenle"
              primaryAction={{
                content: "Bitti",
                onAction: () => saveOrderInvoiceAddress(),
                loading: editInvoiceAddressSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditInvoiceAddressModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Ülke/Bölge"
                      options={countryList.current.map(({ iso, name }) => ({
                        value: iso,
                        label: name,
                      }))}
                      onChange={(val) => {
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          countryCode: val,
                          country: getCountryNameByCode(val),
                        });
                      }}
                      value={orderDetailInvoiceAddress?.countryCode}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Ad"
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          name: val,
                        })
                      }
                      value={orderDetailInvoiceAddress?.name}
                      label="Ad"
                    />
                    <TextField
                      placeholder="Soyad"
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          surname: val,
                        })
                      }
                      value={orderDetailInvoiceAddress?.surname}
                      label="Soyad"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Adres"
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          addressLine: val,
                        })
                      }
                      value={orderDetailInvoiceAddress?.addressLine}
                      label="Adres"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Apartman, daire vb."
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          secondAddressLine: val,
                        })
                      }
                      value={orderDetailInvoiceAddress?.secondAddressLine}
                      label="Apartman, daire vb."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Posta kodu"
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          zip: val,
                        })
                      }
                      value={orderDetailInvoiceAddress?.zip}
                      label="Posta kodu"
                    />
                    <TextField
                      placeholder="Şehir"
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          city: val,
                        })
                      }
                      value={orderDetailInvoiceAddress?.city}
                      label="Şehir"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setOrderDetailInvoiceAddress({
                          ...orderDetailInvoiceAddress,
                          phone: val,
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {orderDetailInvoiceAddress?.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={editInvoiceAddressPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setEditInvoiceAddressPhoneCountryPopoverActive(
                                  (
                                    editInvoiceAddressPhoneCountryPopoverActive
                                  ) =>
                                    !editInvoiceAddressPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={countryList.current.map(
                              ({ iso, name, phone_prefix }) => ({
                                value: phone_prefix,
                                label: name,
                                phoneCountry: iso,
                              })
                            )}
                            onChange={(va) => {
                              setOrderDetailInvoiceAddress({
                                ...orderDetailInvoiceAddress,
                                phonePrefix: va,
                              });
                              setEditInvoiceAddressPhoneCountryPopoverActive(
                                false
                              );
                            }}
                            selected={orderDetailInvoiceAddress?.phonePrefix}
                          />
                        </Popover>
                      }
                      value={orderDetailInvoiceAddress?.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <Modal
              open={newCustomerModalActive}
              onClose={() => {
                setNewCustomerModalActive(false);
              }}
              title="Yeni müşteri oluşturun"
              primaryAction={{
                content: "Bitti",
                onAction: () => addNewCustomer(),
                loading: newCustomerSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setNewCustomerModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Ad"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            name: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.name}
                      label="Ad"
                    />
                    <TextField
                      placeholder="Soyad"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            surname: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.surname}
                      label="Soyad"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      label="Dil"
                      options={[
                        { value: "TR", label: "Türkçe" },
                        { value: "EN", label: "İngilizce" },
                      ]}
                      onChange={(val) => {
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          language: val,
                        });
                      }}
                      value={orderDetailNewCustomerDetails.address.countryCode}
                      helpText="Bu müşteri bildirimleri seçilen dilde alır."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="E-posta"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          email: val,
                        })
                      }
                      value={orderDetailNewCustomerDetails.email}
                      label="E-posta"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Checkbox
                      checked={
                        orderDetailContactDetailIsCustomerProfileUpdating
                      }
                      onChange={
                        setOrderDetailContactDetailIsCustomerProfileUpdating
                      }
                      label="Müşteri, e-posta ile pazarlamayı kabul ediyor"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Ülke/Bölge"
                      options={countryList.current.map(({ iso, name }) => ({
                        value: iso,
                        label: name,
                      }))}
                      onChange={(val) => {
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            countryCode: val,
                            country: getCountryNameByCode(val),
                          },
                        });
                      }}
                      value={orderDetailNewCustomerDetails.address.countryCode}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Adres"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            addressLine: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.addressLine}
                      label="Adres"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Apartman, daire vb."
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            secondAddressLine: val,
                          },
                        })
                      }
                      value={
                        orderDetailNewCustomerDetails.address.secondAddressLine
                      }
                      label="Apartman, daire vb."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Posta kodu"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            zip: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.zip}
                      label="Posta kodu"
                    />
                    <TextField
                      placeholder="Şehir"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            city: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.city}
                      label="Şehir"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            phone: val,
                          },
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {orderDetailNewCustomerDetails.address.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={newCustomerPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setNewCustomerPhoneCountryPopoverActive(
                                  (newCustomerPhoneCountryPopoverActive) =>
                                    !newCustomerPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={countryList.current.map(
                              ({ iso, name, phone_prefix }) => ({
                                value: phone_prefix,
                                label: name,
                                phoneCountry: iso,
                              })
                            )}
                            onChange={(va) => {
                              setOrderDetailNewCustomerDetails({
                                ...orderDetailNewCustomerDetails,
                                address: {
                                  ...orderDetailNewCustomerDetails.address,
                                  phonePrefix: va,
                                },
                              });
                              setNewCustomerPhoneCountryPopoverActive(false);
                            }}
                            selected={
                              orderDetailNewCustomerDetails.address.phonePrefix
                            }
                          />
                        </Popover>
                      }
                      value={orderDetailNewCustomerDetails.address.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <LegacyCard.Section>
              {orderDetail.customer === " " ? (
                orderDetail.completed === 0 ? (
                  <Combobox
                    activator={
                      <Combobox.TextField
                        autoComplete="off"
                        onFocus={getCustomers}
                        prefix={<Icon source={SearchMinor} />}
                        onChange={(value) => {
                          setInputValue(value);
                          if (value === "") {
                            setCustomers(customers);
                            return;
                          }
                          const filterRegex = new RegExp(value, "i");
                          const resultOptions = customers.filter((option) =>
                            option.label.match(filterRegex)
                          );
                          setCustomers(resultOptions);
                        }}
                        value={inputValue}
                        placeholder="Müşteri bulun veya oluşturun"
                      />
                    }
                  >
                    <Listbox
                      onSelect={(selected) => {
                        if (selected === "addNew") {
                          setNewCustomerModalActive(true);
                        } else {
                          assignCustomerToOrder(selected);
                        }
                      }}
                    >
                      <Listbox.Section>
                        <Listbox.Action key={"addNew"} value={"addNew"}>
                          <LegacyStack spacing="tight">
                            <Icon source={CirclePlusMinor} color="base" />
                            <div>Yeni müşteri oluşturun</div>
                          </LegacyStack>
                        </Listbox.Action>
                      </Listbox.Section>
                      {orderAddCustomerLoading && <Listbox.Loading />}
                      {customers.length > 0 &&
                        customers.map((option) => {
                          const { secure_hash, name, surname, email } = option;
                          return (
                            <Listbox.Action
                              value={secure_hash}
                              key={`${secure_hash}`}
                            >
                              <LegacyStack vertical spacing="extraTight">
                                <p>
                                  <Text as="span">{name + " " + surname}</Text>
                                </p>
                                <p>
                                  <Text as="span" color="subdued">
                                    {email}
                                  </Text>
                                </p>
                              </LegacyStack>
                            </Listbox.Action>
                          );
                        })}
                    </Listbox>
                  </Combobox>
                ) : (
                  <Text as="span" color="subdued">
                    Bu siparişte müşteri yok
                  </Text>
                )
              ) : (
                <Link url={"/customers/" + orderDetail.customerHash}>
                  {orderDetail.customer}
                </Link>
              )}
            </LegacyCard.Section>
            {orderDetail.completed === 1 || orderDetail.customer !== " " ? (
              <div>
                <LegacyCard.Section title="İLETİŞİM BİLGİLERİ">
                  <LegacyStack spacing="tight">
                    <LegacyStack vertical spacing="extraTight">
                      {orderDetail.email === "" ||
                      orderDetail.email === null ? (
                        <Text as="span" color="subdued">
                          E-Posta adresi yok
                        </Text>
                      ) : (
                        <Button plain>{orderDetail.email}</Button>
                      )}
                      {orderDetail.phone === "" ||
                      orderDetail.phone === null ? (
                        <Text as="span" color="subdued">
                          Telefon numarası yok
                        </Text>
                      ) : (
                        <Button plain>{orderDetail.phone}</Button>
                      )}
                    </LegacyStack>
                  </LegacyStack>
                </LegacyCard.Section>
                <LegacyCard.Section title="KARGO ADRESİ">
                  {orderDetail?.shippingAddress?.name +
                    " " +
                    orderDetail?.shippingAddress?.surname}
                  <br />
                  {orderDetail?.shippingAddress?.addressLine}
                  <br />
                  {orderDetail?.shippingAddress?.secondAddressLine !== null &&
                    orderDetail?.shippingAddress?.secondAddressLine}
                  {orderDetail?.shippingAddress?.secondAddressLine !== null && (
                    <br />
                  )}
                  {orderDetail?.shippingAddress?.zip},{" "}
                  {orderDetail?.shippingAddress?.province}
                  <br />
                  {orderDetail?.shippingAddress?.city},{" "}
                  {orderDetail?.shippingAddress?.country}
                  <br />
                  {orderDetail?.shippingAddress?.phonePrefix}
                  {orderDetail?.shippingAddress?.phone}
                </LegacyCard.Section>
                <LegacyCard.Section title="FATURA ADRESİ">
                  {orderDetail?.invoiceAddress?.name +
                    " " +
                    orderDetail?.invoiceAddress?.surname}
                  <br />
                  {orderDetail?.invoiceAddress?.addressLine}
                  <br />
                  {orderDetail?.invoiceAddress?.secondAddressLine !== null &&
                    orderDetail?.invoiceAddress?.secondAddressLine}
                  {orderDetail?.invoiceAddress?.secondAddressLine !== null && (
                    <br />
                  )}
                  {orderDetail?.invoiceAddress?.zip},{" "}
                  {orderDetail?.invoiceAddress?.province}
                  <br />
                  {orderDetail?.invoiceAddress?.city},{" "}
                  {orderDetail?.invoiceAddress?.country}
                  <br />
                  {orderDetail?.invoiceAddress?.phonePrefix}
                  {orderDetail?.invoiceAddress?.phone}
                </LegacyCard.Section>
              </div>
            ) : null}
          </LegacyCard>
          {!orderDetail.completed && (
            <LegacyCard
              title="Notlar"
              actions={[
                {
                  content: "Düzenle",
                  onAction: () => setOrderNoteModalActive(true),
                },
              ]}
              sectioned
            >
              <div style={{ wordWrap: "break-word" }}>
                <Text
                  as="span"
                  color={orderDetail.note === "" ? "subdued" : "text-inverse"}
                >
                  {orderDetail.note === ""
                    ? "Bir not ekleyin"
                    : orderDetail.note}
                </Text>
              </div>
              <Modal
                open={orderNoteModalActive}
                onClose={() => {
                  setOrderNoteModalActive(!orderNoteModalActive);
                  setEditOrderNoteValue(orderDetail.note);
                }}
                title="Sipariş notunu düzenle"
                primaryAction={{
                  content: "Kaydet",
                  onAction: () => {
                    saveOrderNote();
                  },
                  loading: editOrderNoteSaveButtonLoading,
                }}
                secondaryActions={[
                  {
                    content: "İptal",
                    onAction: () => {
                      setOrderNoteModalActive(!orderNoteModalActive);
                      setEditOrderNoteValue(orderDetail.note);
                    },
                  },
                ]}
              >
                <Modal.Section>
                  <LegacyStack vertical>
                    <TextField
                      label="Not"
                      value={editOrderNoteValue}
                      onChange={(newValue) => setEditOrderNoteValue(newValue)}
                      multiline={1}
                      autoComplete="off"
                    />
                    <Text as="span">
                      Burda yazılacak notun müşteri tarafından görülebileceğini
                      unutmayın, eğer bir personele veya siparişe özel bir not
                      oluşturmak istiyorsanız zaman akışını kullanın.
                    </Text>
                  </LegacyStack>
                </Modal.Section>
              </Modal>
            </LegacyCard>
          )}
          <LegacyCard title="Etiketler" sectioned subdued>
            <LegacyStack vertical spacing="tight">
              <Autocomplete
                actionBefore={{
                  content: "Ekle",
                  ellipsis: true,
                  icon: CirclePlusMinor,
                  onAction: () => addTagToOrder(),
                }}
                options={[]}
                textField={
                  <Autocomplete.TextField
                    onChange={(val) => {
                      setOrderTagsTextfieldValue(val);
                    }}
                    value={orderTagsTextfieldValue}
                    placeholder="Etiket bulun veya oluşturun"
                    autoComplete="off"
                  />
                }
              />
              <LegacyStack spacing="tight">
                {orderDetail?.tags?.length > 0 &&
                  orderDetail.tags.map((option) => (
                    <Tag key={`${option}`} onRemove={() => removeTag(option)}>
                      {option}
                    </Tag>
                  ))}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>
        {orderDetail.completed === 0 && (
          <Layout.Section>
            <PageActions
              secondaryActions={[
                {
                  content: "Taslak siparişi sil",
                  destructive: true,
                  outline: true,
                },
              ]}
            />
          </Layout.Section>
        )}
      </Layout>
      {ordersBulkToastActive ? (
        <Toast
          onDismiss={ordersBulkActionToastToggle}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  async function searchProducts(q) {
    if (!addNewProductsSearchLoading) {
      setAddNewProductsSearchLoading(true);
    }
    setAddNewProductsSearchedProducts([]);
    const result = await fetcher({
      endpoint: "getQuickProducts.php?status=All&type=nonVariantable&q=" + q,
      method: "GET",
    });
    if (!result.fetchError) {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts(result);
    } else {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts([]);
    }
  }

  async function addProducts() {
    setAddNewProductsSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "addProductsToDraftOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        products: orderDetailSelectedProductItems,
      }),
    });
    if (!result.fetchError) {
      setAddNewProductsSaveButtonLoading(false);
      setOrderDetailSelectedProductItems([]);
      setAddNewProductsModalActive(false);
      updateOrder();
    } else {
      setAddNewProductsSaveButtonLoading(false);
    }
  }

  async function deleteProducts(p) {
    await fetcher({
      endpoint: "deleteProductsToDraftOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        product: p,
      }),
    });
    updateOrder();
  }

  function getCountryNameByCode(code) {
    return countryList.current.find(({ iso }) => iso === code).name;
  }

  function controlChanges() {
    setContextualSaveBarActive(true);
  }

  async function updatePaymentOrder(status) {
    setSavePaymentButtonLoading(true);
    const result = await fetcher({
      endpoint: "updatePaymentOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        status: status,
      }),
    });
    if (!result.fetchError) {
      if (status === 1) {
        navigate("/orders/" + result.createdOrderSecure, { replace: true });
      } else {
        updateOrder();
      }
    } else {
      setSavePaymentButtonLoading(false);
    }
  }

  async function updateOrder() {
    const result = await fetcher({
      endpoint: "getDraftOrderDetail.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
      }),
    });
    if (!result.fetchError) {
      setOrderDetail(result);
      setOrderDetailContactDetailEmail(result.email);
      setOrderDetailContactDetailPhone(result.phone);
      setOrderDetailShippingAddress(result.shippingAddress);
      setOrderDetailInvoiceAddress(result.invoiceAddress);
      setOrderDetailProductItems(result.items);
      setSavePaymentButtonLoading(false);
      setOrdersBulkToastActive(true);
      setContextualSaveBarActive(false);
    } else {
      navigate("/orders/drafts/", { replace: true });
    }
  }

  async function updateDraftOrderItems() {
    const result = await fetcher({
      endpoint: "updateDraftOrderItems.php",
      method: "POST",
      body: JSON.stringify({
        items: orderDetailProductItems,
        secure: location.pathname.replace("/orders/drafts/", ""),
      }),
    });
    updateOrder();
  }

  async function saveOrderNote() {
    setEditOrderNoteSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "updateOrderNote.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        note: editOrderNoteValue,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setEditOrderNoteSaveButtonLoading(false);
      setOrderNoteModalActive(false);
    } else {
      setEditOrderNoteSaveButtonLoading(false);
      setOrderNoteModalActive(false);
    }
  }

  async function removeTag(tag) {
    const result = await fetcher({
      endpoint: "deleteOrderTag.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        tag: JSON.stringify(tag).replace('"', "").replace('"', ""),
      }),
    });
    updateOrder();
  }

  async function addTagToOrder() {
    const result = await fetcher({
      endpoint: "addOrderTag.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        tag: orderTagsTextfieldValue,
      }),
    });
    updateOrder();
    setOrderTagsTextfieldValue("");
  }

  async function saveContactDetail() {
    setEditContactDetailSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "editOrderContactDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        customerSecure: orderDetail.customerHash,
        email: orderDetail.email,
        phone: orderDetail.phone,
        newEmail: orderDetailContactDetailEmail,
        newPhone: orderDetailContactDetailPhone,
        customerProfileUpdate:
          orderDetailContactDetailIsCustomerProfileUpdating,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setEditContactDetailSaveButtonLoading(false);
      setEditContactDetailModalActive(false);
    } else {
      setEditContactDetailSaveButtonLoading(false);
    }
  }

  async function saveOrderShippingAddress() {
    setEditShippingAddressSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "saveOrderShippingAddress.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        address: JSON.stringify(orderDetailShippingAddress),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setEditShippingAddressSaveButtonLoading(false);
      setEditShippingAddressModalActive(false);
    } else {
      setEditShippingAddressSaveButtonLoading(false);
    }
  }

  async function saveOrderInvoiceAddress() {
    setEditInvoiceAddressSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "saveOrderInvoiceAddress.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        address: JSON.stringify(orderDetailInvoiceAddress),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setEditInvoiceAddressSaveButtonLoading(false);
      setEditInvoiceAddressModalActive(false);
    } else {
      setEditInvoiceAddressSaveButtonLoading(false);
    }
  }

  async function addNewCustomer() {
    setNewCustomerSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "addNewCustomer.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        customerDetail: encodeURI(
          JSON.stringify(orderDetailNewCustomerDetails)
        ),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setNewCustomerSaveButtonLoading(false);
      setNewCustomerModalActive(false);
    } else {
      setNewCustomerSaveButtonLoading(false);
    }
  }

  if (isLoading && !isLoaded) {
    (async () => {
      const result = await fetcher({
        endpoint: "getDraftOrderDetail.php",
        method: "POST",
        body: JSON.stringify({
          secure: location.pathname.replace("/orders/drafts/", ""),
        }),
      });
      if (!result.fetchError) {
        setOrderDetail(result.email.phone.shippingAddress.invoiceAddress.items);
        setLoading(false);
        setIsLoaded(true);
      } else {
        navigate("/orders/drafts/", { replace: true });
      }
    })();
  }

  if (staff.length === 0) {
    (async () => {
      const result = await fetcher({
        endpoint: "getStaff.php",
        method: "GET",
      });
      if (!result.fetchError) {
        setStaff(result);
      }
    })();
  }

  async function getCustomers() {
    setOrderAddCustomerLoading(true);
    if (customers.length === 0) {
      const result = await fetcher({
        endpoint: "getCustomers.php",
        method: "GET",
        body: JSON.stringify({
          secure: location.pathname.replace("/customers/", ""),
        }),
      });
      if (!result.fetchError) {
        setCustomers(result);
        setOrderAddCustomerLoading(false);
      }
    } else {
      setOrderAddCustomerLoading(false);
    }
  }

  async function assignCustomerToOrder(secure) {
    const result = await fetcher({
      endpoint: "assignCustomerToOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        customer: secure,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  async function deleteCustomerFromOrder() {
    const result = await fetcher({
      endpoint: "deleteCustomerFromOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default DraftOrderDetail;
