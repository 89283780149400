import {
  IndexFilters,
  IndexTable,
  LegacyCard,
  Loading,
  SkeletonBodyText,
  SkeletonPage,
  useSetIndexFiltersMode,
} from "@shopify/polaris";

function LoadingMarkup() {
  const filtersMode = useSetIndexFiltersMode();
  return (
    <SkeletonPage fullWidth primaryAction>
      <Loading />
      <LegacyCard>
        <IndexFilters
          sortOptions={[]}
          sortSelected={[]}
          queryValue={""}
          queryPlaceholder="Siparişlerin içerisinde ara"
          filters={[]}
          appliedFilters={[]}
          hideFilters
          tabs={[
            {
              id: "loadingTab1",
              content: <SkeletonBodyText lines={1} />,
              disabled: true,
            },
            {
              id: "loadingTab2",
              content: <SkeletonBodyText lines={1} />,
              disabled: true,
            },
            {
              id: "loadingTab3",
              content: <SkeletonBodyText lines={1} />,
              disabled: true,
            },
            {
              id: "loadingTab4",
              content: <SkeletonBodyText lines={1} />,
              disabled: true,
            },
            {
              id: "loadingTab5",
              content: <SkeletonBodyText lines={1} />,
              disabled: true,
            },
          ]}
          selected={null}
          canCreateNewView={false}
          mode={filtersMode.mode}
          setMode={filtersMode.setMode}
          disabled={true}
        />
        <LegacyCard>
          <IndexTable
            itemCount={2}
            selectable={false}
            headings={[
              { title: "loading1", hidden: true, flush: true },
              { title: "loading2", hidden: true, flush: true },
              { title: "loading3", hidden: true, flush: true },
              { title: "loading4", hidden: true, flush: true },
              { title: "loading5", hidden: true, flush: true },
            ]}
          >
            <IndexTable.Row key={0}>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
            </IndexTable.Row>
            <IndexTable.Row key={1}>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div
                  style={{
                    paddingTop: "0.50rem",
                    paddingBottom: "0.50rem",
                  }}
                >
                  <SkeletonBodyText lines={1} />
                </div>
              </IndexTable.Cell>
            </IndexTable.Row>
          </IndexTable>
        </LegacyCard>
      </LegacyCard>
    </SkeletonPage>
  );
}

export default LoadingMarkup;
