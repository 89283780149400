import {
  Page,
  Layout,
  LegacyCard,
  PageActions,
  ContextualSaveBar,
  LegacyStack,
  TextField,
  Select,
  Icon,
  ResourceList,
  ResourceItem,
  Spinner,
  Thumbnail,
  Button,
  Modal,
  Badge,
  DataTable,
  Autocomplete,
  Text,
} from "@shopify/polaris";
import {
  CancelSmallMinor,
  ChevronLeftMinor,
  SearchMinor,
} from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { fetcher } from "src/helpers/fetcher";
import { useMain } from "src/contexts/Main";
import ProductPriceCalculator from "src/helpers/ProductPriceCalculator";

function NewCollection(props) {
  let navigate = useNavigate();
  const { rates } = useMain();

  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const [categoryDetail, setCategoryDetail] = useState({
    category_title: "",
    category_description: "",
    category_sub_hashes: {},
    category_products: [],
    category_status: 1,
    category_seo_title: "",
    category_seo_description: "",
    category_url: "",
  });

  const [
    categoryDetailSelectedProductItems,
    setCategoryDetailSelectedProductItems,
  ] = useState([]);
  const [addNewProductsModalActive, setAddNewProductsModalActive] =
    useState(false);
  const [addNewProductModalSearchValue, setAddNewProductModalSearchValue] =
    useState("");
  const [addNewProductsSaveButtonLoading, setAddNewProductsSaveButtonLoading] =
    useState(false);
  const [addNewProductsSearchLoading, setAddNewProductsSearchLoading] =
    useState(false);
  const [addNewProductsSearchedProducts, setAddNewProductsSearchedProducts] =
    useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, []);

  async function getCategoryList() {
    const result = await fetcher({
      endpoint: "getCategories.php?showSubCategories=false",
      method: "POST",
      body: JSON.stringify({
        secure: window.location.pathname.replace("/customers/", ""),
      }),
    });
    if (!result.fetchError) {
      setCategoryList(
        result.map(({ id, category_title, category_has_sub_item }) => ({
          value: id,
          label: category_title,
          hasSubItem: category_has_sub_item,
        }))
      );
      setSearchedOptions(
        result.map(({ id, category_title, category_has_sub_item }) => ({
          value: id,
          label: category_title,
          hasSubItem: category_has_sub_item,
        }))
      );
    }
  }

  async function updateCategoryListSubItems(secure) {
    const result = await fetcher({
      endpoint: "getSubCategories.php",
      method: "POST",
      body: JSON.stringify({
        categorySubHash: secure,
      }),
    });
    if (!result.fetchError) {
      var categoryArray = result.map(
        ({ secure_hash, category_title, category_has_sub_item }) => ({
          value: secure_hash,
          label: category_title,
          hasSubItem: category_has_sub_item,
        })
      );
      categoryArray = [
        {
          label: "Geri dön",
          value: "return",
          media: <Icon source={ChevronLeftMinor} />,
        },
        ...categoryArray,
      ];
      setCategoryList(categoryArray);
      setSearchedOptions(categoryArray);
    }
  }

  function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return "" + Math.round(n * k) / k;
      };
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  }

  const productsRows =
    categoryDetail.category_products.length > 0 &&
    categoryDetail.category_products.map(({ secure, name, status }, index) => [
      <div style={{ width: "100%" }} key={index}>
        <LegacyStack alignment="center">
          <Thumbnail
            source={"//panel.altinoranonline.com.tr/p/" + secure + "_0.png"}
            size="small"
          />
          <LegacyStack spacing="extraTight" vertical>
            <Text as="span">{name}</Text>
          </LegacyStack>
        </LegacyStack>
      </div>,
      <Badge status={status === 0 ? "info" : "success"} key={index}>
        {status === 0 ? "Taslak" : "Aktif"}
      </Badge>,
      <Button
        plain
        removeUnderline
        onClick={() => deleteProduct(secure)}
        key={index}
      >
        <div style={{ color: "black", fill: "black" }}>
          <Icon source={CancelSmallMinor} />
        </div>
      </Button>,
    ]);

  const actualPageMarkup = (
    <Page
      title="Koleksiyon oluştur"
      backAction={{ content: "Koleksiyon oluştur", url: "/collections/" }}
    >
      <ContextualSaveBar
        message="Kaydedilmemiş koleksiyon"
        saveAction={{
          onAction: createCategory,
          loading: saveButtonLoading,
          disabled: categoryDetail.category_title === "",
        }}
        discardAction={{
          onAction: () => {
            navigate("/collections/", { replace: false });
          },
        }}
      />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="loose">
              <TextField
                label="Başlık"
                value={categoryDetail.category_title}
                onChange={(v) => {
                  setCategoryDetail({ ...categoryDetail, category_title: v });
                }}
                placeholder="Yaz Koleksiyonu"
              />
              <TextField
                label="Açıklama"
                value={categoryDetail.category_description}
                onChange={(v) => {
                  setCategoryDetail({
                    ...categoryDetail,
                    category_description: v,
                  });
                }}
                multiline={5}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard title="Ürünler">
            <LegacyCard.Section>
              <LegacyStack spacing="extraTight">
                <LegacyStack.Item fill>
                  <TextField
                    type="text"
                    autoComplete="off"
                    placeholder="Ürünleri arayın"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(val) => {
                      setAddNewProductsModalActive(true);
                      setAddNewProductModalSearchValue(val);
                    }}
                  />
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Button
                    onClick={() => {
                      setAddNewProductsModalActive(true);
                      setCategoryDetailSelectedProductItems([]);
                      searchProducts("");
                    }}
                  >
                    Gözat
                  </Button>
                </LegacyStack.Item>
              </LegacyStack>
              <Modal
                open={addNewProductsModalActive}
                onClose={() => {
                  setAddNewProductsModalActive(false);
                  setAddNewProductModalSearchValue("");
                  setCategoryDetailSelectedProductItems([]);
                  searchProducts("");
                }}
                title="Tüm ürünler"
                primaryAction={{
                  content: "Bitti",
                  loading: addNewProductsSaveButtonLoading,
                  onAction: addProducts,
                }}
                secondaryActions={[
                  {
                    content: "İptal",
                    onAction: () => {
                      setAddNewProductsModalActive(false);
                      setAddNewProductModalSearchValue("");
                      setCategoryDetailSelectedProductItems([]);
                      searchProducts("");
                    },
                  },
                ]}
              >
                <Modal.Section>
                  <TextField
                    type="text"
                    autoComplete="off"
                    placeholder="Ürünleri arayın"
                    prefix={<Icon source={SearchMinor} />}
                    value={addNewProductModalSearchValue}
                    onChange={(val) => {
                      searchProducts(val);
                      setAddNewProductModalSearchValue(val);
                    }}
                    autoFocus
                  />
                </Modal.Section>
                {addNewProductsSearchLoading && (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner size="large" />
                  </LegacyStack>
                )}
                {addNewProductsSearchedProducts.length === 0 &&
                !addNewProductsSearchLoading ? (
                  <Modal.Section>
                    <div>
                      <Text as="span">"{addNewProductModalSearchValue}"</Text>
                      <Text as="span" color="subdued">
                        {" "}
                        için sonuç bulunamadı
                      </Text>
                    </div>
                  </Modal.Section>
                ) : (
                  !addNewProductsSearchLoading && (
                    <ResourceList
                      items={addNewProductsSearchedProducts}
                      renderItem={({
                        id,
                        product_title,
                        product_price,
                        product_quantity,
                        product_type,
                        product_image,
                      }) => {
                        return (
                          <ResourceItem
                            id={id}
                            media={
                              <Thumbnail size="small" source={product_image} />
                            }
                          >
                            <LegacyStack distribution="equalSpacing">
                              <LegacyStack
                                vertical
                                spacing="extraTight"
                                distribution="center"
                              >
                                <Text as="span">{product_title}</Text>
                                <Text as="span" color="subdued">
                                  {product_quantity} adet kaldı
                                </Text>
                              </LegacyStack>
                              <Text as="span" fontWeight="semibold">
                                {rates && rates.length > 0 && product_type.value
                                  ? ProductPriceCalculator(
                                      product_price,
                                      product_type.value,
                                      rates
                                    )
                                  : "0.00₺"}
                              </Text>
                            </LegacyStack>
                          </ResourceItem>
                        );
                      }}
                      selectedItems={categoryDetailSelectedProductItems}
                      onSelectionChange={setCategoryDetailSelectedProductItems}
                      selectable
                    />
                  )
                )}
              </Modal>
            </LegacyCard.Section>
            {categoryDetail.category_products.length > 0 && (
              <LegacyCard.Section>
                <DataTable
                  columnContentTypes={["text", "text", "numeric"]}
                  headings={[
                    <Text as="span" fontWeight="semibold">
                      Ürün
                    </Text>,
                    <Text as="span" fontWeight="semibold">
                      Durum
                    </Text>,
                    <Text as="span" fontWeight="semibold"></Text>,
                  ]}
                  rows={productsRows}
                />
              </LegacyCard.Section>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Koleksiyon durumu">
            <LegacyCard.Section>
              <Select
                options={[
                  { label: "Etkin", value: "active" },
                  { label: "Devre dışı", value: "deactivated" },
                ]}
                onChange={(v) => {
                  setCategoryDetail({
                    ...categoryDetail,
                    category_status: v === "active" ? 1 : 0,
                  });
                }}
                value={
                  categoryDetail.category_status === 1
                    ? "active"
                    : "deactivated"
                }
              />
              {categoryDetail.category_status === 0 && (
                <Text as="span" color="subdued">
                  Bu ürün tüm satış kanallarından gizlenecektir.
                </Text>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Satış kanalları ve uygulamalar" subdued>
              <hr
                style={{
                  border: "none",
                  borderBottom: "0.0625rem solid #c9cccf",
                  marginBottom: "1rem",
                }}
              />
              <LegacyStack alignment="center" spacing="tight">
                <div
                  style={{
                    border: "0.125rem solid currentcolor",
                    borderRadius: "50%",
                    height: "0.625rem",
                    width: "0.625rem",
                    color:
                      categoryDetail.category_status === 1
                        ? "#108043"
                        : "#919eab",
                    background:
                      categoryDetail.category_status === 1
                        ? "currentColor"
                        : "transparent",
                  }}
                />
                <Text as="span">Online Mağaza</Text>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Alt Koleksiyon">
            <LegacyCard.Section>
              <Text as="span" color="subdued">
                Koleksiyonu başka bir koleksiyonun altına dahil edin
              </Text>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <Autocomplete
                textField={
                  <Autocomplete.TextField
                    onChange={(value) => {
                      setCategoryDetail({
                        ...categoryDetail,
                        category_sub_hashes: {
                          ...categoryDetail.category_sub_hashes,
                          label: value,
                        },
                      });
                      if (value === "") {
                        setSearchedOptions(categoryList);
                        return;
                      }

                      const filterRegex = new RegExp(value, "i");
                      const resultOptions = categoryList.filter((option) =>
                        option.label.match(filterRegex)
                      );
                      setSearchedOptions(resultOptions);
                    }}
                    label="Kategoriler"
                    value={categoryDetail.category_sub_hashes.label}
                    prefix={<Icon source={SearchMinor} color="base" />}
                    placeholder="Ara"
                    autoComplete="off"
                  />
                }
                selected={selectedOptions}
                options={searchedOptions}
                onSelect={(selected) => {
                  const selectedValue = selected.map((selectedItem) => {
                    const matchedOption = categoryList.find((option) => {
                      return option.value.match(selectedItem);
                    });
                    return {
                      label: matchedOption.label,
                      value: matchedOption.value,
                      hasSubItem: matchedOption.hasSubItem,
                    };
                  });
                  if (selectedValue[0].value !== "return") {
                    if (selectedValue[0].hasSubItem) {
                      updateCategoryListSubItems(selectedValue[0].value);
                    }
                    setSelectedOptions(selected);
                    setCategoryDetail({
                      ...categoryDetail,
                      category_sub_hashes: selectedValue[0],
                    });
                  } else {
                    getCategoryList();
                  }
                }}
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              loading: saveButtonLoading,
              disabled: categoryDetail.category_title === "",
              onAction: createCategory,
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );

  async function searchProducts(q) {
    if (!addNewProductsSearchLoading) {
      setAddNewProductsSearchLoading(true);
    }
    setAddNewProductsSearchedProducts([]);
    const result = await fetcher({
      endpoint: "getProducts.php?status=All&query=" + q,
      method: "GET",
    });
    if (!result.fetchError) {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts(result);
    } else {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts([]);
    }
  }

  function addProducts() {
    setAddNewProductsSaveButtonLoading(true);
    var check = false;
    var counter = 0;
    var newArray = [...categoryDetail.category_products];
    categoryDetailSelectedProductItems.forEach(async (e) => {
      categoryDetail.category_products.forEach((v) => {
        if (v.secure === e) {
          check = true;
        }
      });
      if (check === false) {
        const result = await fetcher({
          endpoint: "getProductDetails.php",
          method: "POST",
          body: JSON.stringify({
            secure: e,
          }),
        });
        if (!result.fetchError) {
          newArray.push({
            secure: result.secure_hash,
            name: result.product_title,
            status: parseInt(result.product_status),
          });
        }
      }
      counter = counter + 1;
    });
    setTimeout(() => {
      setCategoryDetail({
        ...categoryDetail,
        category_products: newArray,
      });
      setAddNewProductsSaveButtonLoading(false);
      setAddNewProductsModalActive(false);
      setCategoryDetailSelectedProductItems([]);
    }, 3000);
  }

  function deleteProduct(sec) {
    var categoryDetailArray = { ...categoryDetail };
    var parsedArray = [];
    categoryDetailArray.category_products.forEach((e) => {
      if (e.secure !== sec) {
        parsedArray.push(e);
      }
    });
    setCategoryDetail({ ...categoryDetail, category_products: parsedArray });
  }

  async function createCategory() {
    setSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "createCategory.php",
      method: "POST",
      body: JSON.stringify({
        categoryDetail: categoryDetail,
      }),
    });
    if (!result.fetchError && result.status !== "failure") {
      navigate("/collections/" + result, { replace: true });
    } else {
      navigate("/collections/", { replace: true });
    }
  }

  return actualPageMarkup;
}

export default NewCollection;
