import { RequestResponse } from "./interface";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

const DEFAULT_HEADERS_FOR_STREAM = {};

export type FROM_TYPES = "server" | "client";

export async function fetcher({
  endpoint,
  customEndpoint = false,
  headers = {},
  method = "GET",
  body,
}: {
  endpoint: string;
  customEndpoint?: boolean;
  headers?: HeadersInit;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: BodyInit | null;
}) {
  let customProps = {};

  try {
    const fetchResult = await fetch(
      (customEndpoint ? "" : process.env.REACT_APP_API_URL) + endpoint,
      {
        ...{
          headers: {
            ...(typeof body === "string" && DEFAULT_HEADERS),
            ...headers,
          },
          method: method,
          body: body,
          credentials: "include",
        },
        ...customProps,
      }
    );

    return (await fetchResult.json()) as RequestResponse;
  } catch (error) {
    let data: RequestResponse = {
      status: false,
      message:
        "Eyvah, sistemsel bir hata oluştu, teknik ekibimiz konuyla ilgileniyor, tekrar deneyebilirsiniz. Hata mesajı: " +
        error,
      content: null,
      fetchError: true,
    };
    return data;
  }
}
