import {
  Page,
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonPage,
  PageActions,
  Loading,
  Toast,
  ContextualSaveBar,
  LegacyStack,
  Avatar,
  TextField,
  Thumbnail,
  Button,
  Icon,
  Popover,
  ResourceList,
  DropZone,
  Tag,
  ButtonGroup,
  EmptyState,
  Link,
  Badge,
  Modal,
  FormLayout,
  Select,
  OptionList,
  Combobox,
  Listbox,
  Checkbox,
  Text,
} from "@shopify/polaris";
import {
  CirclePlusMinor,
  NoteMinor,
  SearchMinor,
} from "@shopify/polaris-icons";
import { useCallback, useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMediaPredicate } from "react-media-hook";
import { fetcher } from "src/helpers/fetcher";
import NumberFormat from "src/helpers/general";

function CustomerDetail() {
  let navigate = useNavigate();
  let location = useLocation();
  const isDesktop = useMediaPredicate("(min-width: 1045px)");
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [contextualSaveBarActive, setContextualSaveBarActive] = useState(false);
  const [customerUpdateToastActive, setCustomerUpdateToastActive] =
    useState(false);
  const [editMarketingModalActive, setEditMarketingModalActive] =
    useState(false);
  const [editCustomerDetailModalActive, setEditCustomerDetailModalActive] =
    useState(false);
  const [
    deleteCustomerConfirmationModalActive,
    setDeleteCustomerConfirmationModalActive,
  ] = useState(false);
  const [staff, setStaff] = useState([]);
  const [files, setFiles] = useState([]);
  const countryList = useRef([]);
  const [stateList, setStateList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [tagsAddInputValue, setTagsAddInputValue] = useState("");

  const [editPrimaryAddressModalActive, setEditPrimaryAddressModalActive] =
    useState(false);

  const [
    editPrimaryAddressPhonePopoverActive,
    setEditPrimaryAddressPhonePopoverActive,
  ] = useState(false);

  const [
    editCustomerPhoneCountryPopoverActive,
    setEditCustomerPhoneCountryPopoverActive,
  ] = useState(false);

  const [saveButtonsLoading, setSaveButtonsLoading] = useState(false);

  const customerUpdateActionToastToggle = useCallback(
    () => setCustomerUpdateToastActive((active) => !active),
    []
  );

  useEffect(() => {
    fetcher({
      endpoint: "geo/countries.php",
    }).then((e) => (countryList.current = e.content));
  }, []);

  const [customerDetail, setCustomerDetail] = useState({
    original: {
      id: 0,
      secure_hash: "",
      name: "",
      surname: "",
      email: "",
      phonePrefix: "",
      phone: "",
      password: "",
      language: 0,
      primary_address: {
        person_name: "",
        person_surname: "",
        line: "",
        second_line: "",
        country_iso: "",
        country: "",
        city_iso: "",
        city: "",
        district_iso: "",
        district: "",
        zip: "",
        person_phone_prefix: "",
        person_phone: "",
        person_email: "",
      },
      note: "",
      tags: [],
      timeline: [],
      all_order_count: 0,
      all_paid_total: 0.0,
      last_order: {},
      is_account: 1,
      email_subscription: 1,
      account_approved: 1,
      ip: "",
      user_agent: "",
      registration_periot: {
        year: 0,
        month: 0,
      },
      date: 0,
      last_updated: 0,
      last_login: 0,
    },
    editable: {
      id: 0,
      secure_hash: "",
      name: "",
      surname: "",
      email: "",
      phonePrefix: "",
      phone: "",
      password: "",
      language: 0,
      primary_address: {
        person_name: "",
        person_surname: "",
        line: "",
        second_line: "",
        country_iso: "",
        country: "",
        city_iso: "",
        city: "",
        district_iso: "",
        district: "",
        zip: "",
        person_phone_prefix: "",
        person_phone: "",
        person_email: "",
      },
      note: "",
      tags: [],
      timeline: [],
      all_order_count: 0,
      all_paid_total: 0.0,
      last_order: {},
      is_account: 1,
      email_subscription: 1,
      account_approved: 1,
      ip: "",
      user_agent: "",
      registration_periot: {
        year: 0,
        month: 0,
      },
      date: 0,
      last_updated: 0,
      last_login: 0,
    },
  });

  const loadingMarkup = (
    <SkeletonPage>
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard title="Verilen son sipariş" sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Müşteri">
            <LegacyCard.Section>
              <SkeletonBodyText lines={3} />
            </LegacyCard.Section>
            <LegacyCard.Section title="VARSAYILAN ADRES">
              <SkeletonBodyText lines={3} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Etiketler" subdued sectioned>
            <SkeletonBodyText lines={2} />
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actualPageMarkup = (
    <Page
      title={
        customerDetail.original.name + " " + customerDetail.original.surname
      }
      additionalMetadata={
        (customerDetail.original.primary_address
          ? `${customerDetail.original.primary_address.city}, ${customerDetail.original.primary_address.country} • `
          : "") +
        "Müşteri olduğu süre: yaklaşık " +
        (customerDetail.original.registration_periot.year === 0
          ? customerDetail.editable.registration_periot.month + " ay"
          : customerDetail.editable.registration_periot.year +
            " yıl " +
            customerDetail.editable.registration_periot.month +
            " ay")
      }
      backAction={{ content: "Kerem Kutlu", url: "/customers/" }}
      secondaryActions={
        customerDetail.original.is_account
          ? [{ content: "Hesap daveti gönder" }]
          : [{ content: "Parolayı sıfırla" }]
      }
    >
      {contextualSaveBarActive && (
        <ContextualSaveBar
          message="Kaydedilmemiş değişiklikler"
          saveAction={{
            onAction: () => saveCustomerDetail(),
            loading: saveButtonsLoading,
          }}
          discardAction={{
            onAction: () => {
              setCustomerDetail({
                ...customerDetail,
                editable: customerDetail.original,
              });
              setContextualSaveBarActive(false);
            },
          }}
        />
      )}
      <Layout>
        <Layout.Section>
          {customerDetail.original.last_order !== null ? (
            <>
              <LegacyCard>
                <div
                  style={
                    isDesktop
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "var(--p-space-5)",
                        }
                      : { display: "block", flexWrap: "wrap", padding: "0" }
                  }
                >
                  <div className="Ot7rf">
                    <LegacyStack vertical spacing="extraTight">
                      <Text as="span" color="subdued">
                        Harcanan tutar
                      </Text>
                      <Text as="p" variant="headingXl" fontWeight="semibold">
                        ₺{NumberFormat(customerDetail.original.all_paid_total)}
                      </Text>
                    </LegacyStack>
                  </div>
                  <div className="Ot7rf">
                    <LegacyStack vertical spacing="extraTight">
                      <Text as="span" color="subdued">
                        Sipariş
                      </Text>
                      <Text variant="headingXl" as="p" fontWeight="semibold">
                        {customerDetail.original.all_order_count}
                      </Text>
                    </LegacyStack>
                  </div>
                  <div className="Ot7rf">
                    <LegacyStack vertical spacing="extraTight">
                      <Text as="span" color="subdued">
                        Ortalama sipariş değeri
                      </Text>
                      <Text variant="headingXl" as="p" fontWeight="semibold">
                        ₺{NumberFormat(customerDetail.original.all_paid_total)}
                      </Text>
                    </LegacyStack>
                  </div>
                </div>
              </LegacyCard>
              <LegacyCard title="Verilen son sipariş">
                <LegacyCard.Section>
                  <LegacyStack vertical spacing="tight">
                    <LegacyStack distribution="equalSpacing" spacing="tight">
                      <LegacyStack.Item fill>
                        <LegacyStack
                          spacing="tight"
                          distribution="leading"
                          alignment="center"
                        >
                          <Link
                            url={
                              "/orders/" +
                              customerDetail.original.last_order.secure
                            }
                            removeUnderline
                          >
                            <Text
                              as="h2"
                              variant="headingMd"
                              fontWeight="semibold"
                            >
                              #{customerDetail.original.last_order.number}
                            </Text>
                          </Link>
                          {customerDetail.original.last_order.payment_status ===
                            1 &&
                          customerDetail.original.last_order.refunded === 0 ? (
                            <Badge status="success" progress="complete">
                              Ödendi
                            </Badge>
                          ) : customerDetail.original.last_order.refunded ===
                            1 ? (
                            <Badge status="attention" progress="complete">
                              Ödeme iade edildi
                            </Badge>
                          ) : (
                            <Badge
                              status="warning"
                              progress="partiallyComplete"
                            >
                              Ödeme bekleniyor
                            </Badge>
                          )}
                          {customerDetail.original.last_order
                            .shipping_status === 1 ? (
                            <Badge status="success" progress="complete">
                              Gönderildi
                            </Badge>
                          ) : customerDetail.original.last_order
                              .shipping_status === 2 ? (
                            <Badge status="warning" progress="incomplete">
                              İade bekleniyor
                            </Badge>
                          ) : customerDetail.original.last_order
                              .shipping_status === 3 ? (
                            <Badge status="attention" progress="complete">
                              İade edildi
                            </Badge>
                          ) : (
                            <Badge status="warning" progress="incomplete">
                              Gönderilmedi
                            </Badge>
                          )}
                        </LegacyStack>
                      </LegacyStack.Item>
                      <LegacyStack.Item>
                        <Text variant="headingMd" as="h2">
                          ₺
                          {NumberFormat(
                            customerDetail.original.last_order.total
                          )}
                        </Text>
                      </LegacyStack.Item>
                    </LegacyStack>
                    <Text as="span" color="subdued">
                      {customerDetail.original.last_order.date +
                        " tarihinde online mağazanızdan verildi"}
                    </Text>
                  </LegacyStack>
                </LegacyCard.Section>
                <LegacyCard.Section>
                  <LegacyStack distribution="trailing">
                    <ButtonGroup>
                      <Button plain url="/orders/">
                        Tüm siparişleri görüntüle
                      </Button>
                      <Button primary url="/orders/drafts/new/">
                        Sipariş oluştur
                      </Button>
                    </ButtonGroup>
                  </LegacyStack>
                </LegacyCard.Section>
              </LegacyCard>
            </>
          ) : (
            <LegacyCard title="Verilen son sipariş" sectioned>
              <EmptyState
                heading="Bu müşteri sipariş vermedi."
                action={{
                  content: "Sipariş oluştur",
                  url: "/orders/drafts/new/",
                }}
                image="https://cdn.shopify.com/shopifycloud/web/assets/v1/0ecc04f85cc74cb401e71254d8a8b83dc03bdade26037cdda6a034831d940dcd.svg"
              />
            </LegacyCard>
          )}
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Notlar" sectioned>
            <TextField
              placeholder="Bu müşteriyle ilgili not yok"
              helpText="Bu notu müşteriniz görmez"
              label="Not"
              value={customerDetail.editable.note}
              onChange={(b) => {
                setContextualSaveBarActive(true);
                setCustomerDetail({
                  ...customerDetail,
                  editable: { ...customerDetail.editable, note: b },
                });
              }}
              multiline
            />
          </LegacyCard>
          <LegacyCard
            title="Müşteri"
            actions={[
              {
                content: "Düzenle",
                onAction: () => setEditCustomerDetailModalActive(true),
              },
            ]}
          >
            <Modal
              open={editCustomerDetailModalActive}
              onClose={() => setEditCustomerDetailModalActive(false)}
              title="Müşteriyi düzenleyin"
              primaryAction={{
                content: "Kaydet",
                onAction: () => {
                  saveCustomerDetail();
                },
                loading: saveButtonsLoading,
                disabled: !(
                  customerDetail.editable.name !==
                    customerDetail.original.name ||
                  customerDetail.editable.surname !==
                    customerDetail.original.surname ||
                  customerDetail.editable.language !==
                    customerDetail.original.language ||
                  customerDetail.editable.email !==
                    customerDetail.original.email ||
                  customerDetail.editable.phonePrefix !==
                    customerDetail.original.phonePrefix ||
                  customerDetail.editable.phone !==
                    customerDetail.original.phone
                ),
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setContextualSaveBarActive(false);
                    setCustomerDetail({
                      ...customerDetail,
                      editable: customerDetail.editable,
                    });
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      label="Ad"
                      value={customerDetail.editable.name}
                      onChange={(f) => {
                        setCustomerDetail({
                          ...customerDetail,
                          editable: { ...customerDetail.editable, name: f },
                        });
                      }}
                    />
                    <TextField
                      label="Soyad"
                      value={customerDetail.editable.surname}
                      onChange={(f) => {
                        setCustomerDetail({
                          ...customerDetail,
                          editable: { ...customerDetail.editable, surname: f },
                        });
                      }}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      label="Dil"
                      options={[
                        { value: "0", label: "Türkçe" },
                        { value: "1", label: "İngilizce" },
                      ]}
                      onChange={(v) => {
                        setCustomerDetail({
                          ...customerDetail,
                          editable: {
                            ...customerDetail.editable,
                            language: parseInt(v),
                          },
                        });
                      }}
                      value={JSON.stringify(customerDetail.editable.language)}
                      helpText="Bu müşteri bildirimleri seçilen dilde alır."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      label="E-posta"
                      onChange={(v) => {
                        setCustomerDetail({
                          ...customerDetail,
                          editable: { ...customerDetail.editable, email: v },
                        });
                      }}
                      value={customerDetail.editable.email}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setCustomerDetail({
                          ...customerDetail,
                          editable: {
                            ...customerDetail.editable,
                            phone: val,
                          },
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {customerDetail.editable.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={editCustomerPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setEditCustomerPhoneCountryPopoverActive(
                                  (editCustomerPhoneCountryPopoverActive) =>
                                    !editCustomerPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                          onClose={() =>
                            setEditCustomerPhoneCountryPopoverActive(false)
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={countryList.current.map(
                              ({ iso, name, phone_prefix }) => ({
                                label: name,
                                value: phone_prefix,
                                phoneCountry: iso,
                              })
                            )}
                            onChange={(va) => {
                              setCustomerDetail({
                                ...customerDetail,
                                editable: {
                                  ...customerDetail.editable,
                                  phonePrefix: va[0],
                                },
                              });
                              setEditCustomerPhoneCountryPopoverActive(false);
                            }}
                            selected={customerDetail.editable.phonePrefix}
                          />
                        </Popover>
                      }
                      value={customerDetail.editable.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <LegacyCard.Section>
              <LegacyStack vertical spacing="extraTight">
                <Link removeUnderline>{customerDetail.original.email}</Link>
                <Text as="span" color="subdued">
                  Bildirimler{" "}
                  {customerDetail.original.language === 1
                    ? "İngilizce"
                    : "Türkçe"}{" "}
                  dilinde alınacak
                </Text>
                <Text as="span" color="subdued">
                  <Link removeUnderline>Şirket ekle</Link>
                </Text>
                <Text as="span" color="subdued">
                  {customerDetail.original.is_account === 1
                    ? "Bir hesabı var"
                    : "Hesabı yok"}
                </Text>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section
              title="VARSAYILAN ADRES"
              actions={[
                {
                  content: "Düzenle",
                  onAction: () => setEditPrimaryAddressModalActive(true),
                },
              ]}
            >
              <Modal
                open={editPrimaryAddressModalActive}
                onClose={() => {
                  setEditPrimaryAddressModalActive(false);
                }}
                title="Varsayılan adresi düzenleyin"
                primaryAction={{
                  content: "Bitti",
                  onAction: () => saveCustomerDetail(),
                  loading: saveButtonsLoading,
                }}
                secondaryActions={[
                  {
                    content: "İptal",
                    onAction: () => {
                      setEditPrimaryAddressModalActive(false);
                    },
                  },
                ]}
              >
                <Modal.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <TextField
                        placeholder="Ad"
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                person_name: val,
                              },
                            },
                          })
                        }
                        value={
                          customerDetail.editable.primary_address
                            ?.person_name || ""
                        }
                        label="Ad"
                      />
                      <TextField
                        placeholder="Soyad"
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                person_surname: val,
                              },
                            },
                          })
                        }
                        value={
                          customerDetail.editable.primary_address
                            ?.person_surname || ""
                        }
                        label="Soyad"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Modal.Section>
                <Modal.Section>
                  <FormLayout>
                    <FormLayout.Group></FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        placeholder="Adres"
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                line: val,
                              },
                            },
                          })
                        }
                        value={
                          customerDetail.editable.primary_address?.line || ""
                        }
                        label="Adres"
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        placeholder="Apartman, daire vb."
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                second_line: val,
                              },
                            },
                          })
                        }
                        value={
                          customerDetail.editable.primary_address
                            ?.second_line || ""
                        }
                        label="Apartman, daire vb."
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <Select
                        label="Ülke/Bölge"
                        options={countryList.current.map(({ iso, name }) => ({
                          value: iso,
                          label: name,
                        }))}
                        onChange={(val) => {
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                country_iso: val,
                                country: getCountryNameByCode(val),
                              },
                            },
                          });
                        }}
                        value={
                          customerDetail.editable.primary_address
                            ?.country_iso || ""
                        }
                      />
                      <Select
                        label="Şehir"
                        options={stateList.map(({ iso, name }) => ({
                          value: iso,
                          label: name,
                        }))}
                        onChange={(val) => {
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                city_iso: val,
                                city: stateList.find(({ iso }) => iso === val)
                                  .name,
                              },
                            },
                          });
                        }}
                        value={
                          customerDetail.editable.primary_address?.city_iso
                        }
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        placeholder="Posta kodu"
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                zip: val,
                              },
                            },
                          })
                        }
                        value={
                          customerDetail.editable.primary_address?.zip || ""
                        }
                        label="Posta kodu"
                      />
                      <Select
                        label="İlçe"
                        options={provinceList.map(({ iso, name }) => ({
                          value: iso,
                          label: name,
                        }))}
                        onChange={(val) => {
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                district_iso: val,
                                district: provinceList.find(
                                  ({ iso }) => iso === val
                                ).name,
                              },
                            },
                          });
                        }}
                        value={
                          customerDetail.editable.primary_address?.district_iso
                        }
                        disabled={
                          !customerDetail.editable.primary_address ||
                          stateList.length === 0
                        }
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        type="tel"
                        inputMode="tel"
                        placeholder="Telefon"
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                person_phone: val,
                              },
                            },
                          })
                        }
                        prefix={
                          <Text as="span" fontWeight="semibold">
                            {customerDetail.editable.primary_address
                              ?.person_phone_prefix || ""}
                          </Text>
                        }
                        connectedRight={
                          <Popover
                            active={editPrimaryAddressPhonePopoverActive}
                            activator={
                              <Button
                                onClick={() =>
                                  setEditPrimaryAddressPhonePopoverActive(
                                    (editPrimaryAddressPhonePopoverActive) =>
                                      !editPrimaryAddressPhonePopoverActive
                                  )
                                }
                                disclosure
                              >
                                Ülke
                              </Button>
                            }
                            onClose={() =>
                              setEditPrimaryAddressPhonePopoverActive(false)
                            }
                          >
                            <OptionList
                              title="Ülke"
                              options={countryList.current.map(
                                ({ iso, name, phone_prefix }) => ({
                                  label: name,
                                  value: phone_prefix,
                                  phoneCountry: iso,
                                })
                              )}
                              onChange={(va) => {
                                setCustomerDetail({
                                  ...customerDetail,
                                  editable: {
                                    ...customerDetail.editable,
                                    primary_address: {
                                      ...customerDetail.editable
                                        .primary_address,
                                      person_phone_prefix: va,
                                    },
                                  },
                                });
                                setEditPrimaryAddressPhonePopoverActive(false);
                              }}
                              selected={
                                customerDetail.editable.primary_address
                                  ?.person_phone_prefix
                              }
                            />
                          </Popover>
                        }
                        value={
                          customerDetail.editable.primary_address?.person_phone
                        }
                        label="Telefon"
                      />
                      <TextField
                        placeholder="musteri@altinoranonline.com.tr"
                        onChange={(val) =>
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              primary_address: {
                                ...customerDetail.editable.primary_address,
                                person_email: val,
                              },
                            },
                          })
                        }
                        value={
                          customerDetail.editable.primary_address
                            ?.person_email || ""
                        }
                        label="E-Posta"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Modal.Section>
              </Modal>
              <LegacyStack vertical spacing="extraTight">
                {customerDetail.original.primary_address ? (
                  <>
                    <Text as="p" fontWeight="semibold">
                      {[
                        customerDetail.original.primary_address.person_name,
                        customerDetail.original.primary_address.person_surname,
                      ].join(" ")}
                    </Text>
                    <Text as="p">
                      {customerDetail.original.primary_address.line}
                    </Text>
                    <Text as="p">
                      {customerDetail.original.primary_address.second_line}
                    </Text>
                    <Text as="p">
                      {[
                        customerDetail.original.primary_address.zip,
                        customerDetail.original.primary_address.district,
                      ].join(", ")}
                    </Text>
                    <Text as="p">
                      {[
                        customerDetail.original.primary_address.city,
                        customerDetail.original.primary_address.country,
                      ].join(", ")}
                    </Text>
                  </>
                ) : (
                  "—"
                )}
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard
            title="Pazarlama durumu"
            actions={[
              {
                content: "Düzenle",
                onAction: () => setEditMarketingModalActive(true),
              },
            ]}
            sectioned
          >
            <Modal
              open={editMarketingModalActive}
              onClose={() => setEditMarketingModalActive(false)}
              title="Pazarlama durumunu düzenleyin"
              primaryAction={{
                content: "Bitti",
                onAction: saveCustomerDetail,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => setEditMarketingModalActive(false),
                },
              ]}
            >
              <Modal.Section>
                <LegacyStack vertical spacing="base">
                  <Checkbox
                    checked={Boolean(
                      customerDetail.editable.email_subscription
                    )}
                    onChange={(val) =>
                      setCustomerDetail({
                        ...customerDetail,
                        editable: {
                          ...customerDetail.editable,
                          email_subscription: +val,
                        },
                      })
                    }
                    label="Müşteri, pazarlama e-postaları almayı kabul etti."
                  />
                  <Checkbox
                    label="Müşteri, SMS ile pazarlama kısa mesajları almayı kabul etti."
                    disabled
                  />
                  <Text as="span" color="subdued">
                    Müşterileri pazarlama e-postalarınıza veya SMS'lerinize
                    abone yapmadan önce izinlerini istemelisiniz.
                  </Text>
                </LegacyStack>
              </Modal.Section>
            </Modal>
            <LegacyStack vertical>
              <Badge
                status={
                  customerDetail.original.email_subscription === 1
                    ? "success"
                    : "warning"
                }
              >
                {customerDetail.original.email_subscription === 1
                  ? "E-posta abonesi olundu"
                  : "E-posta abonelikten çıkarıldı"}
              </Badge>
              <Badge>SMS abonesi olunmadı</Badge>
            </LegacyStack>
          </LegacyCard>
          <LegacyCard title="Etiketler" sectioned subdued>
            <LegacyStack vertical spacing="tight">
              <Combobox
                activator={
                  <Combobox.TextField
                    autoComplete="off"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(value) => {
                      setTagsAddInputValue(value);
                    }}
                    value={tagsAddInputValue}
                    placeholder="Etiket bulun veya oluşturun"
                  />
                }
              >
                <Listbox
                  onSelect={() => {
                    if (
                      !customerDetail.editable.tags.includes(
                        tagsAddInputValue
                      ) &&
                      tagsAddInputValue !== null &&
                      tagsAddInputValue !== ""
                    ) {
                      setContextualSaveBarActive(true);
                      setCustomerDetail({
                        ...customerDetail,
                        editable: {
                          ...customerDetail.editable,
                          tags: [
                            ...customerDetail.editable.tags,
                            tagsAddInputValue,
                          ],
                        },
                      });
                      setTagsAddInputValue("");
                    }
                  }}
                >
                  <Listbox.Action key={"addNew"} value={"addNew"}>
                    <LegacyStack spacing="tight">
                      <Icon source={CirclePlusMinor} color="base" />
                      <div>Etiketi ekle</div>
                    </LegacyStack>
                  </Listbox.Action>
                </Listbox>
              </Combobox>
              <LegacyStack spacing="tight">
                {customerDetail.editable.tags.length > 0 &&
                  customerDetail.editable.tags.map((option) => (
                    <Tag
                      key={`${option}`}
                      onRemove={() => {
                        var array = [...customerDetail.editable.tags];
                        var index = array.indexOf(option);
                        if (index !== -1) {
                          array.splice(index, 1);
                          setContextualSaveBarActive(true);
                          setCustomerDetail({
                            ...customerDetail,
                            editable: {
                              ...customerDetail.editable,
                              tags: array,
                            },
                          });
                        }
                      }}
                    >
                      {option}
                    </Tag>
                  ))}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            secondaryActions={[
              {
                content: "Müşteriyi sil",
                destructive: true,
                outline: true,
                onAction: () => setDeleteCustomerConfirmationModalActive(true),
              },
            ]}
          />
          <Modal
            open={deleteCustomerConfirmationModalActive}
            onClose={() => setDeleteCustomerConfirmationModalActive(false)}
            title={
              customerDetail.original.name +
              " " +
              customerDetail.original.surname +
              " silinsin mi?"
            }
            primaryAction={{
              content: "Sil",
              onAction: deleteCustomer,
              destructive: true,
              loading: saveButtonsLoading,
              disabled: saveButtonsLoading,
            }}
            secondaryActions={[
              {
                content: "İptal",
                onAction: () => setDeleteCustomerConfirmationModalActive(false),
              },
            ]}
          >
            <Modal.Section>
              <Text as="span" fontWeight="semibold">
                {customerDetail.original.name +
                  " " +
                  customerDetail.original.surname}
              </Text>{" "}
              adlı müşteriyi silmek istediğinizden emin misiniz? Bu işlem geri
              alınamaz.
            </Modal.Section>
          </Modal>
        </Layout.Section>
      </Layout>
      {customerUpdateToastActive ? (
        <Toast
          onDismiss={customerUpdateActionToastToggle}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  useEffect(() => {
    if (!customerDetail.editable.primary_address?.country_iso) return;
    setStateList([]);
    setProvinceList([]);
    setCustomerDetail({
      ...customerDetail,
      editable: {
        ...customerDetail.editable,
        primary_address: {
          ...customerDetail.editable.primary_address,
          city: null,
          city_iso: null,
          district: null,
          district_iso: null,
        },
      },
    });
    const result = fetcher({
      endpoint:
        "geo/states.php?country=" +
        customerDetail.editable.primary_address?.country_iso,
    }).then((e) => {
      if (e.status) setStateList(e.content);
    });
  }, [customerDetail.editable.primary_address?.country_iso]);

  useEffect(() => {
    if (!customerDetail.editable.primary_address?.city_iso) return;
    setProvinceList([]);
    setCustomerDetail({
      ...customerDetail,
      editable: {
        ...customerDetail.editable,
        primary_address: {
          ...customerDetail.editable.primary_address,
          district: null,
          district_iso: null,
        },
      },
    });
    const result = fetcher({
      endpoint:
        "geo/provinces.php?country=" +
        customerDetail.editable.primary_address?.country_iso +
        "&state=" +
        customerDetail.editable.primary_address?.city_iso,
    }).then((e) => {
      if (e.status) setProvinceList(e.content);
    });
  }, [customerDetail.editable.primary_address?.city_iso]);

  function getCountryNameByCode(code) {
    return countryList.current.find(({ iso }) => iso === code)?.name;
  }

  async function updateCustomerDetail() {
    const result = await fetcher({
      endpoint: "getCustomerDetail.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/customers/", ""),
      }),
    });
    if (!result.fetchError) {
      setCustomerDetail(result);
      setCustomerUpdateToastActive(true);
      setContextualSaveBarActive(false);
    } else {
      navigate("/customers/", { replace: true });
    }
  }

  async function saveCustomerDetail() {
    setSaveButtonsLoading(true);
    const result = await fetcher({
      endpoint: "updateCustomerDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/customers/", ""),
        customerDetails: customerDetail.editable,
      }),
    });
    if (!result.fetchError) {
      setCustomerDetail({
        ...customerDetail,
        original: customerDetail.editable,
      });
      setContextualSaveBarActive(false);
      setSaveButtonsLoading(false);
      setEditCustomerDetailModalActive(false);
      setEditPrimaryAddressModalActive(false);
      setEditMarketingModalActive(false);
    } else {
      setSaveButtonsLoading(false);
    }
  }

  async function deleteCustomer() {
    setSaveButtonsLoading(true);
    const result = await fetcher({
      endpoint: "deleteCustomer.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/customers/", ""),
      }),
    });
    if (!result.fetchError) {
      navigate("/customers/", { replace: false });
    } else {
      setSaveButtonsLoading(false);
    }
  }

  if (isLoading && !isLoaded) {
    (async () => {
      const result = await fetcher({
        endpoint: "getCustomerDetail.php",
        method: "POST",
        body: JSON.stringify({
          secure: location.pathname.replace("/customers/", ""),
        }),
      });
      if (!result.fetchError) {
        if (result.primary_address && result.primary_address.country_iso) {
          const states = fetcher({
            endpoint:
              "geo/states.php?country=" + result.primary_address.country_iso,
          }).then((e) => {
            if (!e.fetchError) setStateList(e.content);
          });
          const provinces = fetcher({
            endpoint:
              "geo/provinces.php?country=" +
              result.primary_address.country_iso +
              "&state=" +
              result.primary_address.city_iso,
          }).then((e) => {
            if (!e.fetchError) setProvinceList(e.content);
          });
        }
        setCustomerDetail({
          original: result,
          editable: result,
        });
        setLoading(false);
        setIsLoaded(true);
      } else {
        navigate("/customers/", { replace: true });
      }
    })();
  }

  if (staff.length === 0) {
    (async () => {
      const result = await fetcher({
        endpoint: "getStaff.php",
        method: "GET",
      });
      if (!result.fetchError) {
        setStaff(result);
      }
    })();
  }

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default CustomerDetail;
