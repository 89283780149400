import {
  Page,
  Layout,
  Button,
  LegacyCard,
  SkeletonBodyText,
  SkeletonPage,
  Loading,
  SkeletonThumbnail,
  LegacyStack,
  SkeletonDisplayText,
  TextField,
  Badge,
  Link,
  Icon,
  Thumbnail,
  Popover,
  ActionList,
  Toast,
  Modal,
  FormLayout,
  Autocomplete,
  Tag,
  Text,
} from "@shopify/polaris";
import {
  DuplicateMinor,
  CancelSmallMinor,
  ArchiveMinor,
  PrintMinor,
  EditMinor,
  RefundMinor,
  HorizontalDotsMinor,
  CirclePlusMinor,
} from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { fetcher } from "src/helpers/fetcher";

function OrderDetail() {
  let location = useLocation();
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [savePaymentButtonLoading, setSavePaymentButtonLoading] =
    useState(false);
  const [shippingCardActionsActive, setShippingCardActionsActive] =
    useState(false);
  const [orderDetail, setOrderDetail] = useState({
    number: "",
    date: "",
    customer: "",
    paymentStatus: 0,
    shippingStatus: 0,
    refunded: 0,
    archived: 0,
    items: [],
    payments: [
      { type: "", paid: 0 },
      { type: "", paid: 0 },
    ],
    note: "",
    email: "",
    phone: "",
    shippingAddress: {
      addressLine: "",
      secondAddressLine: "",
      country: "",
      city: "",
      province: "",
      zip: "",
    },
    invoiceAddress: {
      addressLine: "",
      secondAddressLine: "",
      country: "",
      city: "",
      province: "",
      zip: "",
    },
    shippingCarrier: "",
    trackingCode: "",
    conversation: [
      { name: "", status: 0 },
      { name: "", status: 0 },
    ],
    fraud: [
      { name: "", status: 0 },
      { name: "", status: 0 },
    ],
    tags: [],
  });

  const [editTrackingSaveButtonLoading, setEditTrackingSaveButtonLoading] =
    useState(false);

  const [editTrackingCodeValue, setEditTrackingCodeValue] = useState("");
  const [editShippingCarrierOptions, setEditShippingCarrierOptions] = useState(
    []
  );
  const [
    editShippingCarrierSelectedOptions,
    setEditShippingCarrierSelectedOptions,
  ] = useState([]);
  const [editShippingCarrierValue, setEditShippingCarrierValue] = useState("");
  const [
    isEditShippingCarrierListLoading,
    setIsEditShippingCarrierListLoading,
  ] = useState(true);

  const [ordersBulkToastActive, setOrdersBulkToastActive] = useState(false);
  const ordersBulkActionToastToggle = useCallback(
    () => setOrdersBulkToastActive((active) => !active),
    []
  );

  const [orderTagsTextfieldValue, setOrderTagsTextfieldValue] = useState("");

  const [trackingCodeEditModalActive, setTrackingCodeEditModalActive] =
    useState(false);

  const [orderNoteModalActive, setOrderNoteModalActive] = useState(false);
  const [fraudDetailModalActive, setFraudDetailModalActive] = useState(false);

  const [editOrderNoteValue, setEditOrderNoteValue] = useState(
    orderDetail.note
  );

  const [editOrderNoteSaveButtonLoading, setEditOrderNoteSaveButtonLoading] =
    useState(false);

  const options = [];

  const successIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        style={{ position: "absolute", zIndex: "9" }}
      >
        <circle cx="8.5" cy="8.59" r="7.52" fill="currentColor"></circle>
        <path d="M8.5 17a8.36 8.36 0 1 1 8.35-8.36A8.35 8.35 0 0 1 8.5 17zm0-15a6.69 6.69 0 1 0 6.68 6.68A6.68 6.68 0 0 0 8.5 1.91z"></path>
        <path
          className="cls-2"
          d="M7.66 11.09a.82.82 0 0 1-.59-.24L5.4 9.18A.84.84 0 0 1 5.45 8a.82.82 0 0 1 1.13 0l1.08 1.08 2.75-2.75a.83.83 0 0 1 1.18 1.18l-3.34 3.34a.82.82 0 0 1-.59.24z"
        ></path>
      </svg>
    );
  };

  const inProgressIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        style={{ position: "absolute", zIndex: "9" }}
      >
        <circle cx="8.5" cy="8.59" r="7.2" fill="currentColor"></circle>
        <path d="M10.09 16.41a.8.8 0 0 1-.18-1.58 6.46 6.46 0 0 0 2-.81L12 14a.79.79 0 0 1 1.07.35.8.8 0 0 1-.3 1.05 7.89 7.89 0 0 1-2.46 1 .55.55 0 0 1-.22.01zm-3.2 0h-.18a7.89 7.89 0 0 1-2.47-1A.8.8 0 0 1 5.09 14a6.49 6.49 0 0 0 2 .82.8.8 0 0 1 .6 1 .81.81 0 0 1-.78.62zm7.7-3.18a.8.8 0 0 1-.8-.8.79.79 0 0 1 .12-.42 6.27 6.27 0 0 0 .83-2 .8.8 0 0 1 1.56.36 7.89 7.89 0 0 1-1 2.47.77.77 0 0 1-.71.39zm-12.19 0a.78.78 0 0 1-.67-.37 8.14 8.14 0 0 1-1-2.46.8.8 0 0 1 1.53-.4 6.19 6.19 0 0 0 .82 2 .8.8 0 0 1-.68 1.23zm13.12-5.4a.81.81 0 0 1-.78-.63 6.46 6.46 0 0 0-.81-2 .81.81 0 0 1 .24-1.11.79.79 0 0 1 1.1.24 8 8 0 0 1 1 2.47.8.8 0 0 1-.6 1h-.18zm-14 0a.58.58 0 0 1-.19 0 .79.79 0 0 1-.6-1 8.22 8.22 0 0 1 1-2.47.82.82 0 0 1 1.11-.26.8.8 0 0 1 .25 1.11 6.49 6.49 0 0 0-.82 2 .78.78 0 0 1-.77.62zM12.33 3.3a.83.83 0 0 1-.43-.13 6.49 6.49 0 0 0-2-.82.79.79 0 0 1-.63-.93.8.8 0 0 1 .94-.64 8.15 8.15 0 0 1 2.48 1A.8.8 0 0 1 13 2.92a.78.78 0 0 1-.68.37zm-7.65 0A.82.82 0 0 1 4 2.93a.8.8 0 0 1 .22-1.1l.1-.06a7.93 7.93 0 0 1 2.39-1 .8.8 0 0 1 1 .61.79.79 0 0 1-.6 1 6.43 6.43 0 0 0-2 .82.82.82 0 0 1-.43.12z"></path>
      </svg>
    );
  };

  const refundedIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        style={{ position: "absolute", zIndex: "9" }}
      >
        <circle cx="8.5" cy="8.5" fill="currentColor" r="7.52"></circle>
        <path d="M14.514 2.485a8.508 8.508 0 0 0-12.028.01 8.497 8.497 0 0 0 .01 12.02 8.506 8.506 0 0 0 12.015 0 8.514 8.514 0 0 0 0-12.03zm-1.201 10.831a6.812 6.812 0 0 1-9.631-.005 6.804 6.804 0 0 1 .006-9.626 6.812 6.812 0 0 1 9.625 0 6.703 6.703 0 0 1 1.932 3.964h-6.4L9.95 6.552a.854.854 0 1 0-1.209-1.209L6.19 7.896a.857.857 0 0 0 0 1.207l2.553 2.552a.864.864 0 0 0 1.209 0 .857.857 0 0 0 0-1.207L8.844 9.35h6.4a6.71 6.71 0 0 1-1.931 3.966z"></path>
      </svg>
    );
  };

  const loadingMarkup = (
    <SkeletonPage primaryAction>
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard
            title={
              <LegacyStack spacing="tight" alignment="center">
                <SkeletonThumbnail size="small" />
                <div style={{ width: "10rem" }}>
                  <Text variant="headingMd" as="h2">
                    <SkeletonDisplayText size="large" />
                  </Text>
                </div>
              </LegacyStack>
            }
          >
            <LegacyCard.Section>
              <SkeletonBodyText lines={4} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <LegacyStack distribution="trailing">
                <Button size="large" disabled />
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard
            title={
              <LegacyStack spacing="tight" alignment="center">
                <SkeletonThumbnail size="small" />
                <div style={{ width: "10rem" }}>
                  <Text variant="headingMd" as="h2">
                    {" "}
                    <SkeletonDisplayText size="large" />
                  </Text>
                </div>
              </LegacyStack>
            }
          >
            <LegacyCard.Section>
              <SkeletonBodyText lines={4} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={1} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Notlar" sectioned>
            <SkeletonBodyText lines={2} />
          </LegacyCard>
          <LegacyCard title="Müşteri">
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section title="İLETİŞİM BİLGİLERİ">
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section title="KARGO ADRESİ">
              <SkeletonBodyText lines={5} />
            </LegacyCard.Section>
            <LegacyCard.Section title="FATURA ADRESİ">
              <SkeletonBodyText lines={1} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Etiketler" sectioned subdued>
            <SkeletonBodyText lines={2} />
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actualPageMarkup = (
    <Page
      title={"#" + orderDetail.number}
      titleMetadata={
        <LegacyStack spacing="extraTight">
          {orderDetail.paymentStatus === 1 && orderDetail.refunded === 0 ? (
            <Badge status="success" progress="complete">
              Ödendi
            </Badge>
          ) : orderDetail.refunded === 1 ? (
            <Badge status="attention" progress="complete">
              Ödeme iade edildi
            </Badge>
          ) : (
            <Badge status="warning" progress="partiallyComplete">
              Ödeme bekleniyor
            </Badge>
          )}
          {orderDetail.shippingStatus === 1 ? (
            <Badge status="success" progress="complete">
              Gönderildi
            </Badge>
          ) : orderDetail.shippingStatus === 2 ? (
            <Badge status="warning" progress="incomplete">
              İade bekleniyor
            </Badge>
          ) : orderDetail.shippingStatus === 3 ? (
            <Badge status="attention" progress="complete">
              İade edildi
            </Badge>
          ) : (
            <Badge status="warning" progress="incomplete">
              Gönderilmedi
            </Badge>
          )}
          {orderDetail.archived === 1 && (
            <Badge status="info" progress="incomplete">
              Arşivlendi
            </Badge>
          )}
        </LegacyStack>
      }
      subtitle={orderDetail.date + " tarihinde online mağazanızdan verildi"}
      backAction={{ url: "/orders/" }}
      secondaryActions={
        orderDetail.shippingStatus === 2 || orderDetail.shippingStatus === 3
          ? [
              { content: "Düzenle", icon: EditMinor },
              { content: "Çoğalt", icon: DuplicateMinor },
              orderDetail.refunded === 0 && {
                content: "Para iadesi",
                icon: RefundMinor,
                onAction: () => makeRefund(),
              },
              {
                content:
                  orderDetail.archived === 1 ? "Arşivden çıkar" : "Arşivle",
                onAction:
                  orderDetail.archived === 1
                    ? () => updateArchiveStatusOrder(0)
                    : () => updateArchiveStatusOrder(1),
                icon: ArchiveMinor,
              },
              { content: "Sevk irsaliyesini yazdır", icon: PrintMinor },
            ]
          : [
              { content: "Düzenle", icon: EditMinor },
              { content: "Çoğalt", icon: DuplicateMinor },
              orderDetail.refunded === 0 && {
                content: "Para iadesi",
                icon: RefundMinor,
                onAction: () => makeRefund(),
              },
              {
                content:
                  orderDetail.archived === 1 ? "Arşivden çıkar" : "Arşivle",
                onAction:
                  orderDetail.archived === 1
                    ? () => updateArchiveStatusOrder(0)
                    : () => updateArchiveStatusOrder(1),
                icon: ArchiveMinor,
              },
              { content: "Sevk irsaliyesini yazdır", icon: PrintMinor },
              {
                content: "Siparişi iptal et",
                icon: CancelSmallMinor,
                destructive: true,
                onAction: () => cancelOrder(),
              },
            ]
      }
    >
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Header
              title={
                <LegacyStack spacing="fill" alignment="center">
                  <LegacyStack spacing="tight" alignment="center">
                    <Icon
                      source={
                        orderDetail.shippingStatus === 1
                          ? successIcon
                          : orderDetail.shippingStatus === 3
                          ? successIcon
                          : inProgressIcon
                      }
                      color={
                        orderDetail.shippingStatus === 1
                          ? "success"
                          : orderDetail.shippingStatus === 2
                          ? "highlight"
                          : orderDetail.shippingStatus === 3
                          ? "warning"
                          : "warning"
                      }
                      backdrop
                    ></Icon>
                    <Text variant="headingMd" as="h2">
                      {orderDetail.shippingStatus === 1
                        ? "Gönderildi"
                        : orderDetail.shippingStatus === 2
                        ? "İade bekleniyor"
                        : orderDetail.shippingStatus === 3
                        ? "İade edildi"
                        : "Gönderilmedi"}
                      {" (" + orderDetail.items.length + ")"}
                    </Text>
                  </LegacyStack>
                </LegacyStack>
              }
            >
              {orderDetail.shippingStatus === 1 && (
                <Popover
                  active={shippingCardActionsActive}
                  activator={
                    <Button
                      icon={HorizontalDotsMinor}
                      plain
                      onClick={() =>
                        setShippingCardActionsActive((active) => !active)
                      }
                    ></Button>
                  }
                  autofocusTarget="first-node"
                  onClose={() =>
                    setShippingCardActionsActive((active) => !active)
                  }
                >
                  <ActionList
                    actionRole="menuitem"
                    items={
                      orderDetail.trackingCode !== ""
                        ? [
                            {
                              content: "Takip kodunu düzenle",
                              onAction: () =>
                                setTrackingCodeEditModalActive(
                                  !trackingCodeEditModalActive
                                ),
                            },
                            {
                              content: "Sevk irsaliyesi yazdır",
                            },
                          ]
                        : [
                            {
                              content: "Sevk irsaliyesi yazdır",
                            },
                          ]
                    }
                  />
                </Popover>
              )}
            </LegacyCard.Header>
            {orderDetail.trackingCode !== "" && (
              <div
                style={{ padding: "var(--p-space-5)", paddingBottom: "0px" }}
              >
                <Text as="span" color="subdued">
                  Takip kodu ({orderDetail.shippingCarrier})
                </Text>
                <br />
                <Text as="span">{orderDetail.trackingCode}</Text>
              </div>
            )}
            <LegacyCard.Section>
              <ul style={{ padding: "0", margin: "0" }}>
                {orderDetail.items.map(
                  (
                    {
                      secure,
                      name,
                      price,
                      quantity,
                      productImage,
                      selectedVariants,
                      personalizedAreas,
                    },
                    index
                  ) => (
                    <li
                      style={{ listStyle: "none", paddingBottom: "0.25rem" }}
                      key={index}
                    >
                      <div
                        style={{
                          marginTop: "0.25rem",
                          display: "grid",
                          gridTemplateColumns: "auto 5fr",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ gridRow: "1/span 2" }}>
                          <div
                            style={{
                              marginRight: "0.5rem",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                right: "0",
                                top: "0",
                                transform: "translate3d(0.75rem,-50%,0)",
                                zIndex: "99",
                              }}
                            >
                              <Badge>{quantity}</Badge>
                            </div>
                            <Thumbnail size="small" source={productImage} />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateAreas: '"row-1" "row-2"',
                            marginLeft: "1rem",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateAreas: '"col-2 col-3 col-4"',
                              gridTemplateColumns:
                                "6fr minmax(5.625rem,auto) minmax(5rem,auto)",
                            }}
                          >
                            <div
                              style={{ alignSelf: "center", gridArea: "col-2" }}
                            >
                              <LegacyStack vertical spacing="extraTight">
                                <Link url={"/products/" + secure}>{name}</Link>
                                {selectedVariants.length > 0 ? (
                                  <LegacyStack spacing="extraTight">
                                    {selectedVariants.map(
                                      ({ label, value }, index) => (
                                        <Tag key={index}>
                                          {label}: {value}
                                        </Tag>
                                      )
                                    )}
                                  </LegacyStack>
                                ) : null}
                                {personalizedAreas.length > 0 ? (
                                  <LegacyStack spacing="extraTight">
                                    {personalizedAreas.map(
                                      ({ label, value }, index) => (
                                        <Tag key={index}>
                                          {label}: {value}
                                        </Tag>
                                      )
                                    )}
                                  </LegacyStack>
                                ) : null}
                              </LegacyStack>
                            </div>
                            <div
                              style={{
                                gridArea: "col-3",
                                wordWrap: "break-word",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                overflowWrap: "break-word",
                                textAlign: "right",
                                wordBreak: "break-word",
                              }}
                            >
                              {price}₺ x {quantity}
                            </div>
                            <div
                              style={{
                                gridArea: "col-4",
                                wordWrap: "break-word",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                overflowWrap: "break-word",
                                textAlign: "right",
                                wordBreak: "break-word",
                              }}
                            >
                              {price * quantity}₺
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </LegacyCard.Section>
            {orderDetail.shippingStatus === 0 ||
            (orderDetail.shippingStatus === 1 &&
              orderDetail.trackingCode === "") ? (
              <LegacyCard.Section>
                <LegacyStack distribution="trailing">
                  {orderDetail.shippingStatus === 0 && (
                    <Button primary url="fulfill/">
                      {orderDetail.items.length > 1 ? "Ürünleri" : "Ürünü"}{" "}
                      gönder
                    </Button>
                  )}
                  {orderDetail.shippingStatus === 1 &&
                    orderDetail.trackingCode === "" && (
                      <Button
                        onClick={() =>
                          setTrackingCodeEditModalActive(
                            !trackingCodeEditModalActive
                          )
                        }
                        primary
                      >
                        Takip kodu ekle
                      </Button>
                    )}
                </LegacyStack>
              </LegacyCard.Section>
            ) : null}
            {orderDetail.shippingStatus === 2 && (
              <LegacyCard.Section>
                <LegacyStack distribution="trailing">
                  <Button primary onClick={() => acceptRefund()}>
                    İadeyi onayla
                  </Button>
                </LegacyStack>
              </LegacyCard.Section>
            )}
            <Modal
              open={trackingCodeEditModalActive}
              onClose={() =>
                setTrackingCodeEditModalActive(!trackingCodeEditModalActive)
              }
              title="Takip kodunu düzenleyin"
              primaryAction={{
                content: "Kaydet",
                onAction: () => shipOrder(),
                loading: editTrackingSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditTrackingCodeValue("");
                    setEditShippingCarrierSelectedOptions([]);
                    setTrackingCodeEditModalActive(
                      !trackingCodeEditModalActive
                    );
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      type="text"
                      label="Takip numarası"
                      onChange={setEditTrackingCodeValue}
                      value={editTrackingCodeValue}
                      autoComplete={"off"}
                    />
                    <Autocomplete
                      options={editShippingCarrierOptions}
                      selected={editShippingCarrierSelectedOptions}
                      onSelect={(selected) => {
                        const selectedValue = selected.map((selectedItem) => {
                          const matchedOption = editShippingCarrierOptions.find(
                            (option) => {
                              return option.value.match(selectedItem);
                            }
                          );
                          return matchedOption && matchedOption.label;
                        });
                        setEditShippingCarrierSelectedOptions(selected);
                        setEditShippingCarrierValue(selectedValue[0]);
                      }}
                      textField={
                        <Autocomplete.TextField
                          onChange={(value) => {
                            setEditShippingCarrierValue(value);

                            if (value === "") {
                              setEditShippingCarrierOptions(
                                editShippingCarrierOptions
                              );
                              return;
                            }

                            const filterRegex = new RegExp(value, "i");
                            const resultOptions =
                              editShippingCarrierOptions.filter((option) =>
                                option.label.match(filterRegex)
                              );
                            setEditShippingCarrierOptions(resultOptions);
                          }}
                          label="Kargo şirketi"
                          value={editShippingCarrierValue}
                          autoComplete={"off"}
                        />
                      }
                      loading={isEditShippingCarrierListLoading}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Header
              title={
                <LegacyStack spacing="fill" alignment="center">
                  <LegacyStack spacing="tight" alignment="center">
                    <Icon
                      source={
                        orderDetail.paymentStatus === 1
                          ? successIcon
                          : orderDetail.paymentStatus === 0 &&
                            orderDetail.refunded === 1
                          ? refundedIcon
                          : inProgressIcon
                      }
                      color={
                        orderDetail.paymentStatus === 1
                          ? "success"
                          : orderDetail.paymentStatus === 0 &&
                            orderDetail.refunded === 1
                          ? "critical"
                          : "warning"
                      }
                      backdrop
                    ></Icon>
                    <Text variant="headingMd" as="h2">
                      {orderDetail.paymentStatus === 1
                        ? "Ödendi"
                        : orderDetail.paymentStatus === 0 &&
                          orderDetail.refunded === 1
                        ? "İade edildi"
                        : "Beklemede"}
                    </Text>
                  </LegacyStack>
                </LegacyStack>
              }
            ></LegacyCard.Header>
            <LegacyCard.Section>
              <div style={{ gridRowGap: "0.5rem", display: "grid" }}>
                {orderDetail.payments.map(({ type, paid }, index) => (
                  <div
                    style={{
                      gridGap: "0.5rem",
                      display: "grid",
                      gridTemplateColumns: "8.75rem auto",
                    }}
                    key={index}
                  >
                    <span>
                      <span className="">{type}</span>
                    </span>
                    <div
                      style={{
                        gridGap: "1rem",
                        gridRowGap: "1rem",
                        display: "grid",
                        wordBreak: "break-word",
                      }}
                    >
                      <div
                        style={{
                          gridGowGap: "0.25rem",
                          display: "grid",
                          gridTemplateColumns: "auto",
                        }}
                      >
                        <div
                          style={{
                            gridColumnGap: "0.5rem",
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                          }}
                        >
                          <div style={{ justifySelf: "end" }}>
                            <span className="">{paid}₺</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <Text as="span" fontWeight="semibold">
                    Toplam
                  </Text>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <Text as="span" fontWeight="semibold">
                            {orderDetail.payments[0]["paid"] +
                              orderDetail.payments[1]["paid"]}
                            ₺
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LegacyCard.Section>
            <LegacyCard.Section>
              {orderDetail.paymentStatus === 1 && orderDetail.refunded === 0 ? (
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <Text as="span">Müşteri tarafından ödendi</Text>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <Text as="span">
                            {orderDetail.payments[0]["paid"] +
                              orderDetail.payments[1]["paid"]}
                            ₺
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : orderDetail.paymentStatus === 0 &&
                orderDetail.refunded === 0 ? (
                <LegacyStack distribution="trailing" spacing="tight">
                  <Button>Fatura gönder</Button>
                  <Button
                    primary
                    onClick={() => updatePaymentOrder(1)}
                    loading={savePaymentButtonLoading}
                  >
                    Ödendi olarak işaretle
                  </Button>
                </LegacyStack>
              ) : (
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <Text as="span">Müşteriye iade edildi</Text>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <Text as="span">
                            -
                            {orderDetail.payments[0]["paid"] +
                              orderDetail.payments[1]["paid"]}
                            ₺
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard
            title="Notlar"
            actions={[
              {
                content: "Düzenle",
                onAction: () => setOrderNoteModalActive(true),
              },
            ]}
            sectioned
          >
            <div style={{ wordWrap: "break-word" }}>
              <Text
                as="span"
                color={orderDetail.note === "" ? "subdued" : null}
              >
                {orderDetail.note === ""
                  ? "Müşteriden not yok"
                  : orderDetail.note}
              </Text>
            </div>
            <Modal
              open={orderNoteModalActive}
              onClose={() => {
                setOrderNoteModalActive(!orderNoteModalActive);
                setEditOrderNoteValue(orderDetail.note);
              }}
              title="Sipariş notunu düzenle"
              primaryAction={{
                content: "Kaydet",
                onAction: () => {
                  saveOrderNote();
                },
                loading: editOrderNoteSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setOrderNoteModalActive(!orderNoteModalActive);
                    setEditOrderNoteValue(orderDetail.note);
                  },
                },
              ]}
            >
              <Modal.Section>
                <LegacyStack vertical>
                  <TextField
                    label="Not"
                    value={editOrderNoteValue}
                    onChange={(newValue) => setEditOrderNoteValue(newValue)}
                    multiline={1}
                    autoComplete="off"
                  />
                  <Text as="span">
                    Burda yazılacak notun müşteri tarafından görülebileceğini
                    unutmayın, eğer bir personele veya siparişe özel bir not
                    oluşturmak istiyorsanız zaman akışını kullanın.
                  </Text>
                </LegacyStack>
              </Modal.Section>
            </Modal>
          </LegacyCard>
          <LegacyCard title="Müşteri">
            <LegacyCard.Section>
              <Link url={"/customers/" + orderDetail.customer}>
                {orderDetail.customer === "" ? (
                  <Text as="span" color="subdued">
                    Bu siparişte müşteri yok
                  </Text>
                ) : (
                  orderDetail.customer
                )}
              </Link>
            </LegacyCard.Section>
            <LegacyCard.Section title="İLETİŞİM BİLGİLERİ">
              <LegacyStack spacing="tight">
                {orderDetail.email === "" ? (
                  <Text as="span" color="subdued">
                    E-Posta adresi yok
                  </Text>
                ) : (
                  <Button plain>{orderDetail.email}</Button>
                )}
                {orderDetail.phone === "" ? (
                  <Text as="span" color="subdued">
                    Telefon numarası yok
                  </Text>
                ) : (
                  <Button plain>{orderDetail.phone}</Button>
                )}
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section title="KARGO ADRESİ">
              {orderDetail.customer}
              <br />
              {orderDetail.shippingAddress["addressLine"]}
              <br />
              {orderDetail.shippingAddress["secondAddressLine"] !== "" &&
                orderDetail.shippingAddress["secondAddressLine"]}
              {orderDetail.shippingAddress["secondAddressLine"] !== "" && (
                <br />
              )}
              {orderDetail.shippingAddress["zip"]},{" "}
              {orderDetail.shippingAddress["province"]}
              <br />
              {orderDetail.shippingAddress["city"]},{" "}
              {orderDetail.shippingAddress["country"]}
            </LegacyCard.Section>
            <LegacyCard.Section title="FATURA ADRESİ">
              {orderDetail.customer}
              <br />
              {orderDetail.invoiceAddress["addressLine"]}
              <br />
              {orderDetail.invoiceAddress["secondAddressLine"] !== "" &&
                orderDetail.invoiceAddress["secondAddressLine"]}
              {orderDetail.invoiceAddress["secondAddressLine"] !== "" && <br />}
              {orderDetail.invoiceAddress["zip"]},{" "}
              {orderDetail.invoiceAddress["province"]}
              <br />
              {orderDetail.invoiceAddress["city"]},{" "}
              {orderDetail.invoiceAddress["country"]}
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Sahtekarlık analizi">
            <LegacyCard.Section>
              <ol style={{ listStyle: "none", margin: "0", padding: "0" }}>
                <li style={{ display: "flex", padding: "0.5rem" }}>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      marginRight: "1rem",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "var(--p-icon-subdued,#919eab)",
                        borderRadius: "0.5rem",
                        height: "0.5rem",
                        width: "0.5rem",
                      }}
                    ></div>
                  </div>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    {orderDetail.fraud[0].name}
                  </div>
                </li>
                <li style={{ display: "flex", padding: "0.5rem" }}>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      marginRight: "1rem",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "var(--p-icon-subdued,#919eab)",
                        borderRadius: "0.5rem",
                        height: "0.5rem",
                        width: "0.5rem",
                      }}
                    ></div>
                  </div>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    {orderDetail.fraud[1].name}
                  </div>
                </li>
              </ol>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <LegacyStack distribution="center">
                <Link onClick={() => setFraudDetailModalActive(true)}>
                  Tüm analizi görüntüle
                </Link>
              </LegacyStack>
            </LegacyCard.Section>
            <Modal
              open={fraudDetailModalActive}
              onClose={() => {
                setFraudDetailModalActive(!fraudDetailModalActive);
              }}
              title="Sahtekarlık analizi"
            >
              <Modal.Section>
                <ol style={{ listStyle: "none", margin: "0", padding: "0" }}>
                  {orderDetail.fraud.map(({ name, status }, index) => (
                    <li
                      style={{ display: "flex", padding: "0.5rem" }}
                      key={index}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          marginRight: "1rem",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              status === 1
                                ? "var(--p-icon-success)"
                                : "var(--p-icon-warning)",
                            borderRadius: "0.5rem",
                            height: "0.5rem",
                            width: "0.5rem",
                          }}
                        />
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        {name}
                      </div>
                    </li>
                  ))}
                </ol>
              </Modal.Section>
              <Modal.Section>
                <Text as="span">
                  Bu sonuçlar bilgisayar ölçümlerine dayalıdır, kendi
                  insiyatifinizi kullanmanız gerekmektedir.
                </Text>
              </Modal.Section>
            </Modal>
          </LegacyCard>
          <LegacyCard title="Etiketler" sectioned subdued>
            <LegacyStack vertical spacing="tight">
              <Autocomplete
                actionBefore={{
                  content: "Ekle",
                  ellipsis: true,
                  icon: CirclePlusMinor,
                  onAction: () => addTagToOrder(),
                }}
                options={options}
                textField={
                  <Autocomplete.TextField
                    onChange={(val) => {
                      setOrderTagsTextfieldValue(val);
                    }}
                    value={orderTagsTextfieldValue}
                    placeholder="Etiket bulun veya oluşturun"
                    autoComplete="off"
                  />
                }
              />
              <LegacyStack spacing="tight">
                {orderDetail.tags.length > 0 &&
                  orderDetail.tags.map((option, index) => (
                    <Tag key={index} onRemove={() => removeTag(option)}>
                      {option}
                    </Tag>
                  ))}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      {ordersBulkToastActive ? (
        <Toast
          onDismiss={ordersBulkActionToastToggle}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  async function updatePaymentOrder(status) {
    setSavePaymentButtonLoading(true);
    const result = await fetcher({
      endpoint: "updatePaymentOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
        status: status,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  async function updateOrder() {
    const result = await fetcher({
      endpoint: "getOrderDetail.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
      }),
    });
    if (!result.fetchError) {
      setOrderDetail(result);
      setSavePaymentButtonLoading(false);
      setOrdersBulkToastActive(true);
    } else {
      navigate("/orders/", { replace: true });
    }
  }

  async function shipOrder() {
    setEditTrackingSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "shipOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
        shippingCarrier:
          editShippingCarrierSelectedOptions.length === 1
            ? editShippingCarrierSelectedOptions[0]
            : "",
        trackingCode: editTrackingCodeValue,
        customerContact: 0,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setEditTrackingSaveButtonLoading(false);
      setTrackingCodeEditModalActive(!trackingCodeEditModalActive);
    } else {
      updateOrder();
      setEditTrackingSaveButtonLoading(false);
      setTrackingCodeEditModalActive(!trackingCodeEditModalActive);
    }
  }

  async function saveOrderNote() {
    setEditOrderNoteSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "updateOrderNote.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
        note: editOrderNoteValue,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setEditOrderNoteSaveButtonLoading(false);
      setOrderNoteModalActive(false);
    } else {
      updateOrder();
      setEditOrderNoteSaveButtonLoading(false);
      setOrderNoteModalActive(false);
    }
  }

  async function removeTag(tag) {
    const result = await fetcher({
      endpoint: "deleteOrderTag.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
        tag: JSON.stringify(tag).replace('"', "").replace('"', ""),
      }),
    });
    updateOrder();
  }

  async function updateArchiveStatusOrder(status) {
    const result = await fetcher({
      endpoint: "updateArchiveStatusOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
        status: status,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  async function makeRefund() {
    const result = await fetcher({
      endpoint: "makeRefund.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  async function cancelOrder() {
    const result = await fetcher({
      endpoint: "cancelOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  async function acceptRefund() {
    const result = await fetcher({
      endpoint: "acceptRefund.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
      }),
    });
    if (!result.fetchError) {
      updateOrder();
    }
  }

  async function addTagToOrder() {
    const result = await fetcher({
      endpoint: "addOrderTag.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/", ""),
        tag: orderTagsTextfieldValue,
      }),
    });
    if (!result.fetchError) {
      updateOrder();
      setOrderTagsTextfieldValue("");
    }
  }

  if (isLoading && !isLoaded) {
    (async () => {
      const result = await fetcher({
        endpoint: "getOrderDetail.php",
        method: "POST",
        body: JSON.stringify({
          secure: location.pathname.replace("/orders/", ""),
        }),
      });
      if (!result.fetchError) {
        setOrderDetail(result);
        setLoading(false);
        setIsLoaded(true);
      } else {
        navigate("/orders/", { replace: true });
      }
    })();
  }

  if (isEditShippingCarrierListLoading) {
    (async () => {
      const result = await fetcher({
        endpoint: "getShippingCarriers.php",
        method: "GET",
      });
      if (!result.fetchError) {
        setEditShippingCarrierOptions(result.carriers);
        setIsEditShippingCarrierListLoading(false);
      }
    })();
  }

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default OrderDetail;
