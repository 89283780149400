import {
  Page,
  Layout,
  LegacyCard,
  Loading,
  Grid,
  ActionList,
  Icon,
  CalloutCard,
  SkeletonBodyText,
  LegacyStack,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import {
  ChevronRightMinor,
  AddProductMajor,
  PaymentsMajor,
  OrdersMajor,
  StoreStatusMajor,
} from "@shopify/polaris-icons";

import { fetcher } from "src/helpers/fetcher";

function Dashboard() {
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [analytics, setAnalytics] = useState({
    sessionCount: 0,
    liveVisitors: 0,
    orderCount: 0,
    orderTotal: 0,
  });

  const [newsPosts, setNewsPosts] = useState([]);

  const loadingMarkup = (
    <Page>
      {isLoading && !isLoaded ? <Loading /> : null}
      <LegacyStack vertical>
        <div style={{ maxWidth: "18.75rem" }}>
          <SkeletonBodyText lines={1} />
        </div>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <LegacyCard subdued>
              <LegacyCard.Section
                title={
                  <div style={{ width: "6.25rem" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                }
              >
                <div style={{ margin: "0.75rem 0", width: "6.25rem" }}>
                  <SkeletonDisplayText size="medium" />
                </div>
                <hr
                  style={{
                    border: "none",
                    borderTop: "0.0625rem solid var(--p-divider)",
                    margin: "1rem 0",
                  }}
                ></hr>
                <div>
                  <SkeletonBodyText lines={1} />
                </div>
              </LegacyCard.Section>
            </LegacyCard>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <LegacyCard subdued>
              <LegacyCard.Section
                title={
                  <div style={{ width: "6.25rem" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                }
              >
                <div style={{ margin: "0.75rem 0", width: "6.25rem" }}>
                  <SkeletonDisplayText size="medium" />
                </div>
                <hr
                  style={{
                    border: "none",
                    borderTop: "0.0625rem solid var(--p-divider)",
                    margin: "1rem 0",
                  }}
                ></hr>
                <div>
                  <SkeletonBodyText lines={1} />
                </div>
              </LegacyCard.Section>
            </LegacyCard>
          </Grid.Cell>
        </Grid>
        <LegacyCard
          title={
            <div style={{ maxWidth: "23rem" }}>
              <SkeletonBodyText lines={1} />
            </div>
          }
          sectioned
        >
          <SkeletonBodyText lines={3} />
        </LegacyCard>
        <LegacyCard
          title={
            <div style={{ maxWidth: "23rem" }}>
              <SkeletonBodyText lines={1} />
            </div>
          }
          sectioned
        >
          <SkeletonBodyText lines={3} />
        </LegacyCard>
        <LegacyCard
          title={
            <div style={{ maxWidth: "23rem" }}>
              <SkeletonBodyText lines={1} />
            </div>
          }
          sectioned
        >
          <SkeletonBodyText lines={3} />
        </LegacyCard>
        <LegacyCard
          title={
            <div style={{ maxWidth: "23rem" }}>
              <SkeletonBodyText lines={1} />
            </div>
          }
          sectioned
        >
          <SkeletonBodyText lines={3} />
        </LegacyCard>
        <LegacyCard
          title={
            <div style={{ maxWidth: "23rem" }}>
              <SkeletonBodyText lines={1} />
            </div>
          }
          sectioned
        >
          <SkeletonBodyText lines={3} />
        </LegacyCard>
        <LegacyCard
          title={
            <div style={{ maxWidth: "23rem" }}>
              <SkeletonBodyText lines={1} />
            </div>
          }
          sectioned
        >
          <SkeletonBodyText lines={3} />
        </LegacyCard>
      </LegacyStack>
    </Page>
  );

  const actualPageMarkup = (
    <Page>
      <Layout sectioned>
        <p style={{ marginBottom: "20px" }}>
          İşte bugün mağazanız olup bitenler:
        </p>
        {analytics["sessionCount"] === 0 ? (
          <LegacyCard sectioned subdued>
            <LegacyStack alignment="center">
              <Icon source={StoreStatusMajor} color="base" />
              <LegacyStack vertical spacing="extraTight" distribution="center">
                <Text variant="headingMd" as="h2" color="subdued">
                  Mağaza faaliyeti yok
                </Text>
                <Text as="span" color="subdued">
                  Satışlarınız, siparişleriniz ve oturumlarınız burada görünür.
                </Text>
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        ) : (
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <LegacyCard>
                <LegacyCard.Section title="TOPLAM OTURUM SAYISI">
                  {analytics["sessionCount"] === 0 ? (
                    <p>Henüz ziyaret yok</p>
                  ) : (
                    <Text variant="heading2xl" as="p">
                      {analytics["sessionCount"]}
                    </Text>
                  )}
                  <hr
                    style={{
                      border: "none",
                      borderTop: "0.0625rem solid var(--p-divider)",
                      margin: "1rem 0",
                    }}
                  ></hr>
                  {analytics["liveVisitors"] !== 0 ? (
                    <LegacyStack spacing="none">
                      <div
                        className="liveBefore"
                        style={{
                          background: "rgb(255, 244, 244)",
                          borderRadius: "3px",
                          color: "#202223",
                          fontSize: ".6875rem",
                          fontWeight: "600",
                          marginRight: "0.5rem",
                          padding: "0 0.5rem 0 0.875rem",
                          position: "relative",
                          textTransform: "uppercase",
                        }}
                      >
                        Canlı
                      </div>
                      <Text as="span" color="subdued">
                        {analytics["liveVisitors"] + " ziyaretçi"}
                      </Text>
                    </LegacyStack>
                  ) : (
                    <p>Şu anda ziyaretçi yok</p>
                  )}
                </LegacyCard.Section>
              </LegacyCard>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <Link
                to="/orders/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <LegacyCard>
                  <LegacyCard.Section title="TOPLAM SATIŞ">
                    {analytics["orderTotal"] === 0 ? (
                      <p style={{ marginTop: "0.75rem" }}>Henüz satış yok</p>
                    ) : (
                      <Text variant="heading2xl" as="p">
                        {analytics["orderTotal"]}₺
                      </Text>
                    )}
                    <hr
                      style={{
                        border: "none",
                        borderTop: "0.0625rem solid var(--p-divider)",
                        margin: "1rem 0",
                      }}
                    ></hr>
                    {analytics["orderCount"] > 0 ? (
                      analytics["orderCount"]
                    ) : (
                      <p>Henüz sipariş yok</p>
                    )}
                  </LegacyCard.Section>
                </LegacyCard>
              </Link>
            </Grid.Cell>
          </Grid>
        )}
        <div style={{ margin: "1.75rem 0" }}>
          <LegacyCard>
            <div style={{ margin: "5px" }}>
              <ActionList
                items={[
                  {
                    content: (
                      <Text variant="headingSm" as="h2">
                        Yeni ürün
                      </Text>
                    ),
                    icon: AddProductMajor,
                    suffix: <Icon source={ChevronRightMinor} />,
                    url: "/products/new/",
                  },
                  {
                    content: (
                      <Text variant="headingSm" as="h2">
                        Bekleyen siparişler
                      </Text>
                    ),
                    icon: OrdersMajor,
                    suffix: <Icon source={ChevronRightMinor} />,
                    url: "/orders/",
                  },
                  {
                    content: (
                      <Text variant="headingSm" as="h2">
                        Ödemeler
                      </Text>
                    ),
                    icon: PaymentsMajor,
                    suffix: <Icon source={ChevronRightMinor} />,
                    url: "finance",
                  },
                ]}
              />
            </div>
          </LegacyCard>
        </div>
        {newsPosts.map(
          ({ title, description, illustration, linkText, link }, index) => (
            <CalloutCard
              key={index}
              title={title}
              primaryAction={{
                content: linkText,
                url: link,
              }}
              illustration={illustration}
            >
              <p>{description}</p>
            </CalloutCard>
          )
        )}
      </Layout>
    </Page>
  );

  if (isLoading && !isLoaded) {
    (async () => {
      fetcher({
        endpoint: "miniAnalytics.php",
        method: "GET",
      }).then((e) => {
        if (e.status !== "failure") {
          setAnalytics(e);
          setLoading(false);
          setIsLoaded(true);
        } else {
          window.location.href =
            process.env.REACT_APP_API_URL + "../account/logout";
        }
      });
      fetcher({
        endpoint: "getHomeNews.php",
        method: "GET",
      }).then((e) => {
        if (e.status !== "failure") {
          setNewsPosts(e);
        }
      });
    })();
  }

  useEffect(() => {
    const timer = setInterval(() => {
      fetcher({
        endpoint: "miniAnalytics.php",
        method: "GET",
      }).then((data) => {
        if (analytics !== data && data.status !== "failure") {
          setAnalytics(data);
        }
        setLoading(false);
        setIsLoaded(true);
      });
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default Dashboard;
