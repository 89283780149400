import {
  Page,
  Layout,
  SkeletonPage,
  Loading,
  LegacyCard,
  SkeletonBodyText,
  PageActions,
  Badge,
  LegacyStack,
  Button,
  Link,
  Thumbnail,
  FormLayout,
  TextField,
  Autocomplete,
  Checkbox,
  Toast,
  VerticalStack,
  Text,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { fetcher } from "src/helpers/fetcher";

function FulfillOrder() {
  let location = useLocation();
  let navigate = useNavigate();
  const [storeAddress, setStoreAddress] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderDetail, setOrderDetail] = useState({
    number: "",
    date: "",
    customer: "",
    paymentStatus: 0,
    shippingStatus: 0,
    refunded: 0,
    archived: 0,
    items: [],
    payments: [
      { type: "", paid: 0 },
      { type: "", paid: 0 },
    ],
    note: "",
    email: "",
    phone: "",
    shippingAddress: {
      addressLine: "",
      secondAddressLine: "",
      country: "",
      city: "",
      province: "",
      zip: "",
    },
    invoiceAddress: {
      addressLine: "",
      secondAddressLine: "",
      country: "",
      city: "",
      province: "",
      zip: "",
    },
    shippingCarrier: "",
    conversation: "",
    fraud: [],
    tags: [],
    timeline: [],
  });

  const [trackingCodeVale, setTrackingCodeValue] = useState("");

  const [isDataSending, setIsDataSending] = useState(false);

  const [isCustomerContactSelected, setIsCustomerContactSelected] =
    useState(true);

  const [isShippingCarrierListLoading, setIsShippingCarrierListLoading] =
    useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [shippingCarrierValue, setShippingCarrierValue] = useState("");
  const [options, setOptions] = useState([]);

  const toastMarkup = useCallback((toastValue) => {
    return <Toast content={toastValue} />;
  }, []);

  const loadingMarkup = (
    <SkeletonPage
      title={
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <div style={{ padding: "40px" }}></div>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      }
    >
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              disabled: true,
            }}
          />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actualPageMarkup = (
    <Page
      title={"Ürünü gönder"}
      backAction={{ url: location.pathname.replace("/fulfill/", "") }}
      secondaryActions={[{ content: "Sevk irsaliyesi yazdır" }]}
    >
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <LegacyStack alignment="baseline">
                <LegacyStack.Item fill>
                  <LegacyStack alignment="baseline">
                    <Text variant="headingMd" as="h2">
                      #1003
                    </Text>
                    <Badge
                      progress="incomplete"
                      status="attention"
                      size="small"
                    >
                      Gönderilmedi
                    </Badge>
                  </LegacyStack>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Text as="span">{storeAddress}</Text>
                </LegacyStack.Item>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section title="ÜRÜNLER">
              <ul style={{ padding: "0", margin: "0" }}>
                {orderDetail.items.map(
                  ({ secure, name, price, quantity, productImage }, index) => (
                    <li
                      style={{ listStyle: "none", paddingBottom: "0.25rem" }}
                      key={index}
                    >
                      <div
                        style={{
                          marginTop: "0.25rem",
                          display: "grid",
                          gridTemplateColumns: "auto 5fr",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ gridRow: "1/span 2" }}>
                          <div
                            style={{
                              marginRight: "0.5rem",
                              position: "relative",
                            }}
                          >
                            <Thumbnail size="small" source={productImage} />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateAreas: '"row-1" "row-2"',
                            marginLeft: "0.25rem",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateAreas: '"col-2 col-3 col-4"',
                              gridTemplateColumns:
                                "6fr minmax(5.625rem,auto) minmax(5rem,auto)",
                            }}
                          >
                            <div
                              style={{ alignSelf: "center", gridArea: "col-2" }}
                            >
                              <div>
                                <Link url={"/products/" + secure}>{name}</Link>
                              </div>
                            </div>
                            <div
                              style={{
                                gridArea: "col-3",
                                wordWrap: "break-word",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                overflowWrap: "break-word",
                                textAlign: "right",
                                wordBreak: "break-word",
                              }}
                            >
                              {price}₺ x {quantity}
                            </div>
                            <div
                              style={{
                                gridArea: "col-4",
                                wordWrap: "break-word",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                overflowWrap: "break-word",
                                textAlign: "right",
                                wordBreak: "break-word",
                              }}
                            >
                              {price * quantity}₺
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </LegacyCard.Section>
            <LegacyCard.Section title="TAKİP BİLGİLERİ (İSTEĞE BAĞLI)">
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    type="text"
                    label="Takip numarası"
                    onChange={setTrackingCodeValue}
                    value={trackingCodeVale}
                    autoComplete={"off"}
                  />
                  <Autocomplete
                    options={options}
                    selected={selectedOptions}
                    onSelect={(selected) => {
                      const selectedValue = selected.map((selectedItem) => {
                        const matchedOption = options.find((option) => {
                          return option.value.match(selectedItem);
                        });
                        return matchedOption && matchedOption.label;
                      });

                      setSelectedOptions(selected);
                      setShippingCarrierValue(selectedValue[0]);
                    }}
                    textField={
                      <Autocomplete.TextField
                        onChange={(value) => {
                          setShippingCarrierValue(value);

                          if (value === "") {
                            setOptions(options);
                            return;
                          }

                          const filterRegex = new RegExp(value, "i");
                          const resultOptions = options.filter((option) =>
                            option.label.match(filterRegex)
                          );
                          setOptions(resultOptions);
                        }}
                        label="Kargo şirketi"
                        value={shippingCarrierValue}
                        autoComplete={"off"}
                      />
                    }
                    loading={isShippingCarrierListLoading}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>
            <LegacyCard.Section title="MÜŞTERİYE KARGO HAKKINDA BİLGİ VERİN">
              <Checkbox
                label="Kargo ayrıntılarını hemen müşterinize gönderin"
                checked={isCustomerContactSelected}
                onChange={(newChecked) =>
                  setIsCustomerContactSelected(newChecked)
                }
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard
            title="Kargo adresi"
            actions={[{ content: "Düzenle" }]}
            sectioned
          >
            {orderDetail.customer}
            <br />
            {orderDetail.shippingAddress["addressLine"]}
            <br />
            {orderDetail.shippingAddress["secondAddressLine"] !== null &&
              orderDetail.shippingAddress["secondAddressLine"]}
            {orderDetail.shippingAddress["secondAddressLine"] !== null && (
              <br />
            )}
            {orderDetail.shippingAddress["zip"]},{" "}
            {orderDetail.shippingAddress["province"]}
            <br />
            {orderDetail.shippingAddress["city"]},{" "}
            {orderDetail.shippingAddress["country"]}
          </LegacyCard>
          <LegacyCard title="Özet">
            <LegacyCard.Section>
              <VerticalStack gap={2}>
                <p>{storeAddress} konumundan gönderiliyor</p>
                <p>{orderDetail.items.length} ürün</p>
              </VerticalStack>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <Button
                primary
                fullWidth
                loading={isDataSending}
                disabled={isDataSending}
                onClick={shipProducts}
              >
                {orderDetail.items.length > 1 ? "Ürünleri" : "Ürünü"} gönder
              </Button>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      {toastMarkup}
    </Page>
  );

  if (isLoading && !isLoaded) {
    (async () => {
      const result = await fetcher({
        endpoint: "getOrderDetail.php",
        method: "POST",
        body: JSON.stringify({
          secure: location.pathname
            .replace("/orders/", "")
            .replace("/fulfill/", ""),
        }),
      });
      if (!result.fetchError) {
        setOrderDetail(result);
        setLoading(false);
        setIsLoaded(true);
      } else {
        navigate(location.pathname.replace("/fulfill/", ""), { replace: true });
      }
    })();
  }

  if (isShippingCarrierListLoading) {
    (async () => {
      const result = await fetcher({
        endpoint: "getShippingCarriers.php",
        method: "GET",
      });
      if (!result.fetchError) {
        setStoreAddress(result.storeAddress["addressLine"]);
        setOptions(result.carriers);
        setIsShippingCarrierListLoading(false);
      }
    })();
  }

  async function shipProducts() {
    setIsDataSending(true);
    const result = await fetcher({
      endpoint: "shipOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname
          .replace("/orders/", "")
          .replace("/fulfill/", ""),
        shippingCarrier: selectedOptions.length === 1 ? selectedOptions[0] : "",
        trackingCode: trackingCodeVale,
        customerContact: isCustomerContactSelected,
      }),
    });
    if (!result.fetchError) {
      setIsDataSending(false);
      navigate(location.pathname.replace("/fulfill/", ""), { replace: true });
    } else {
      setIsDataSending(false);
    }
  }

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default FulfillOrder;
