import {
  Page,
  Layout,
  LegacyCard,
  PageActions,
  ContextualSaveBar,
  LegacyStack,
  TextField,
  Select,
  Text,
  FormLayout,
  SkeletonPage,
  SkeletonBodyText,
  Loading,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import _ from "lodash";
import { fetcher } from "src/helpers/fetcher";

function FeatureDetail() {
  const { id } = useParams();
  let navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [contextualSaveBarActive, setContextualSaveBarActive] = useState(false);

  const [featureDetail, setFeatureDetail] = useState({
    original: {
      id: "",
      secure_hash: "",
      identifier: "",
      name: "",
      description: "",
      features: [{ name: "" }],
      date: "",
      status: 1,
    },
    editable: {
      id: "",
      secure_hash: "",
      identifier: "",
      name: "",
      description: "",
      features: [{ name: "" }],
      date: "",
      status: 1,
    },
  });

  useEffect(() => {
    if (!_.isEqual(featureDetail.original, featureDetail.editable)) {
      setContextualSaveBarActive(true);
    } else {
      setContextualSaveBarActive(false);
    }
  }, [featureDetail]);

  const loadingMarkup = (
    <SkeletonPage>
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              disabled: true,
            }}
          />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actualPageMarkup = (
    <Page
      title="Özelliği düzenle"
      backAction={{ content: "Özellikler", url: "/features/" }}
    >
      {contextualSaveBarActive && (
        <ContextualSaveBar
          message="Kaydedilmemiş değişiklikler"
          saveAction={{
            onAction: () => updateFeature(),
            loading: saveButtonLoading,
          }}
          discardAction={{
            onAction: () => {
              setFeatureDetail({
                ...featureDetail,
                editable: featureDetail.original,
              });
            },
          }}
        />
      )}
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="loose">
              <TextField
                label="Başlık"
                value={featureDetail.editable.name}
                onChange={(v) => {
                  setFeatureDetail({
                    ...featureDetail,
                    editable: { ...featureDetail.editable, name: v },
                  });
                }}
                placeholder="Pırlanta Taşı"
              />
              <TextField
                label="Açıklama"
                value={featureDetail.editable.description}
                onChange={(v) => {
                  setFeatureDetail({
                    ...featureDetail,
                    editable: { ...featureDetail.editable, description: v },
                  });
                }}
                multiline={3}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard title="Özellik Listesi">
            <LegacyCard.Section>
              <LegacyStack spacing="baseTight" vertical>
                {featureDetail.editable.features.map(({ name }, index) => (
                  <FormLayout key={index}>
                    <TextField
                      placeholder="İsim"
                      autoComplete="off"
                      label="Özellik adı"
                      value={name}
                      onChange={(v) => {
                        var lastItem =
                          featureDetail.editable.features[
                            featureDetail.editable.features.length - 1
                          ];
                        var currentList = featureDetail.editable.features;
                        currentList[index].name = v;
                        if (v !== "" && lastItem.name !== "") {
                          currentList.push({ name: "" });
                        } else if (v === "" && lastItem.name === "") {
                          currentList.splice(index, 1);
                        }
                        setFeatureDetail({
                          ...featureDetail,
                          editable: {
                            ...featureDetail.editable,
                            features: currentList,
                          },
                        });
                      }}
                    />
                  </FormLayout>
                ))}
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Özellik durumu">
            <LegacyCard.Section>
              <Select
                options={[
                  { label: "Etkin", value: "1" },
                  { label: "Devre dışı", value: "0" },
                ]}
                onChange={(v) => {
                  setFeatureDetail({
                    ...featureDetail,
                    editable: {
                      ...featureDetail.editable,
                      status: parseInt(v),
                    },
                  });
                }}
                value={featureDetail.editable.status.toString()}
              />
              {!featureDetail.editable.status && (
                <Text as="span" color="subdued">
                  Bu ürün tüm satış kanallarından gizlenecektir.
                </Text>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Satış kanalları ve uygulamalar" subdued>
              <hr
                style={{
                  border: "none",
                  borderBottom: "0.0625rem solid #c9cccf",
                  marginBottom: "1rem",
                }}
              />
              <LegacyStack alignment="center" spacing="tight">
                <div
                  style={{
                    border: "0.125rem solid currentcolor",
                    borderRadius: "50%",
                    height: "0.625rem",
                    width: "0.625rem",
                    color: featureDetail.editable.status
                      ? "#108043"
                      : "#919eab",
                    background: featureDetail.editable.status
                      ? "currentColor"
                      : "transparent",
                  }}
                />
                <Text as="span">Online Mağaza</Text>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            secondaryActions={[
              {
                content: "Ürünü sil",
                onAction: deleteFeature,
                destructive: true,
                outline: true,
              },
            ]}
            primaryAction={{
              content: "Kaydet",
              loading: saveButtonLoading,
              onAction: updateFeature,
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );

  useEffect(() => {
    (async () => {
      const result = await fetcher({
        endpoint: "getFeatureDetail.php",
        method: "POST",
        body: JSON.stringify({
          secure: id,
        }),
      });
      if (!result.fetchError) {
        setFeatureDetail({
          original: result,
          editable: result,
        });
        setLoading(false);
        setIsLoaded(true);
      } else {
        navigate("/features/", { replace: true });
      }
    })();
  }, []);

  async function updateFeature() {
    setSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "updateFeature.php",
      method: "POST",
      body: JSON.stringify({
        secure: id,
        featureDetails: featureDetail.editable,
      }),
    });
    if (!result.fetchError) {
      if (result.status == "success") {
        setFeatureDetail({
          editable: featureDetail.editable,
          original: featureDetail.editable,
        });
        setContextualSaveBarActive(false);
        setSaveButtonLoading(false);
      }
    } else {
      setSaveButtonLoading(false);
    }
  }

  async function deleteFeature() {
    const result = await fetcher({
      endpoint: "deleteFeature.php",
      method: "POST",
      body: JSON.stringify({
        secure: id,
      }),
    });
    if (!result.fetchError) {
      navigate("/features/", { replace: true });
    }
  }

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default FeatureDetail;
