import {
  AppProvider,
  TopBar,
  Navigation,
  Frame,
  SkeletonBodyText,
  Grid,
  SkeletonDisplayText,
  LegacyCard,
  Loading,
  Page,
  Spinner,
  VerticalStack,
  HorizontalStack,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import tr from "@shopify/polaris/locales/tr.json";
import {
  HomeMinor,
  OrdersMinor,
  MarketingMinor,
  ProductsMinor,
  CustomersMinor,
  FinancesMinor,
  AnalyticsMinor,
  DiscountsMinor,
  ProfileMinor,
  LogOutMinor,
  QuestionMarkMinor,
  SettingsMinor,
  MergeMinor,
} from "@shopify/polaris-icons";
import { useMediaPredicate } from "react-media-hook";
import LivePricingTable from "./components/LivePricingTable";
import Clock from "./components/Clock";
import Logo from "./sources/svg/logo.svg";
import LightLogo from "./sources/svg/light_logo.svg";
import { fetcher } from "src/helpers/fetcher";
import Dashboard from "./pages/dashboard/Dashboard";
import Orders from "./pages/orders/Orders";
import OrderDetail from "./pages/orders/OrderDetail";
import FulfillOrder from "./pages/orders/FullfillOrder";
import DraftOrders from "./pages/orders/DraftOrders";
import DraftOrderDetail from "./pages/orders/DraftOrderDetail";
import NewDraftOrder from "./pages/orders/NewDraftOrder";
import Products from "./pages/products/Products";
import ProductDetail from "./pages/products/ProductDetail";
import NewProduct from "./pages/products/NewProduct";
import Collections from "./pages/products/Collections";
import CollectionDetail from "./pages/products/CollectionDetail";
import NewCollection from "./pages/products/NewCollection";
import Features from "./pages/products/Features";
import FeatureDetail from "./pages/products/FeatureDetail";
import NewFeature from "./pages/products/NewFeature";
import Customers from "./pages/customers/Customers";
import CustomerDetail from "./pages/customers/CustomerDetail";
import NewCustomer from "./pages/customers/NewCustomer";
import { MainProvider } from "./contexts/Main";

function App() {
  const [loading, setLoading] = useState(true);
  const isDesktop = useMediaPredicate("(min-width: 1045px)");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [userData, setUserData] = useState({
    name: null,
    surname: null,
    email: null,
    storeName: null,
  });
  let location = useLocation();
  let navigate = useNavigate();

  const [puriteTable, setPuriteTable] = useState({
    status: false,
    items: [],
  });

  useEffect(() => {
    fetcher({
      endpoint: "getGeneralInformations.php",
    })
      .then(() => {
        fetcher({
          endpoint: "getPuriteTable.php",
        }).then((e) => {
          setPuriteTable({ status: true, items: e });
          setLoading(false);
        });
      })
      .catch(() =>
        navigate(process.env.REACT_APP_APP_URL + "account/logout/", {
          replace: false,
        })
      );
  }, []);

  return (
    <AppProvider
      i18n={tr}
      linkComponent={({ children, url, ...rest }) => {
        return (
          <Link to={url} {...rest}>
            {children}
          </Link>
        );
      }}
    >
      <MainProvider>
        <Frame
          logo={{
            width: 124,
            topBarSource: Logo,
            contextualSaveBarSource: LightLogo,
            url: process.env.REACT_APP_APP_URL,
          }}
          topBar={
            <>
              <TopBar
                showNavigationToggle
                userMenu={
                  <HorizontalStack blockAlign="center" gap={4}>
                    <div
                      style={{
                        "--p-surface": "var(--p-surface-dark)!important",
                      }}
                    >
                      {loading ? (
                        <Spinner size="small" />
                      ) : (
                        <TopBar.UserMenu
                          actions={[
                            {
                              items: [
                                {
                                  icon: ProfileMinor,
                                  content: "Hesabı yönet",
                                  disabled: true,
                                  onAction: () => alert("FUCK"),
                                },
                                {
                                  icon: LogOutMinor,
                                  content: "Oturumu kapat",
                                  onAction: () =>
                                    (window.location.href =
                                      process.env.REACT_APP_API_URL +
                                      "account/logout/"),
                                },
                              ],
                            },
                            {
                              items: [
                                {
                                  icon: QuestionMarkMinor,
                                  content: "Yardım merkezi",
                                  disabled: true,
                                },
                              ],
                            },
                          ]}
                          name={userData.name + " " + userData.surname}
                          detail={userData.storeName}
                          initials={(userData.name + " " + userData.surname)
                            .split(" ")
                            .map((word) => word[0])
                            .join("")}
                          open={userMenuActive}
                          onToggle={() =>
                            setUserMenuActive(
                              (userMenuActive) => !userMenuActive
                            )
                          }
                        />
                      )}
                    </div>
                  </HorizontalStack>
                }
                secondaryMenu={
                  isDesktop ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        minWidth: "max-content",
                        gap: "24px",
                        marginInlineEnd: "12px",
                      }}
                    >
                      <LivePricingTable />
                      <Clock />
                    </div>
                  ) : null
                }
                onNavigationToggle={() =>
                  setMobileNavigationActive(
                    (mobileNavigationActive) => !mobileNavigationActive
                  )
                }
              />
            </>
          }
          navigation={
            <Navigation location={location.pathname}>
              <Navigation.Section
                fill
                items={[
                  {
                    url: "/",
                    label: "Ana sayfa",
                    icon: HomeMinor,
                    selected: location.pathname === "/" ? true : false,
                    disabled: loading,
                  },
                  {
                    url: "/orders/",
                    label: "Siparişler",
                    icon: OrdersMinor,
                    selected:
                      location.pathname === "/orders/" ||
                      location.pathname === "/orders" ||
                      location.pathname === "/orders/drafts/" ||
                      location.pathname === "/orders/checkouts/"
                        ? true
                        : false,
                    disabled: loading,
                    subNavigationItems: [
                      {
                        url: "/orders/drafts/",
                        disabled: loading,
                        selected:
                          location.pathname === "/orders/drafts/"
                            ? true
                            : false,
                        label: "Taslaklar",
                      },
                      {
                        url: "/orders/checkouts/",
                        disabled: true,
                        selected:
                          location.pathname === "/orders/checkouts/"
                            ? true
                            : false,
                        label: "Yarım bırakılmış ödemeler",
                      },
                    ],
                  },
                  {
                    url: "/products/",
                    label: "Ürünler",
                    icon: ProductsMinor,
                    disabled: loading,
                    selected:
                      location.pathname === "/products/" ||
                      location.pathname.includes("/products")
                        ? true
                        : false,
                    subNavigationItems: [
                      {
                        url: "/collections/",
                        disabled: loading,
                        selected:
                          location.pathname === "/collections" ||
                          location.pathname.includes("/collections")
                            ? true
                            : false,
                        label: "Koleksiyonlar",
                      },
                      {
                        url: "/features/",
                        disabled: loading,
                        selected:
                          location.pathname === "/features" ||
                          location.pathname.includes("/features")
                            ? true
                            : false,
                        label: "Özellik Listesi",
                      },
                      {
                        url: "/products/gift-cards/",
                        disabled: true,
                        selected:
                          location.pathname === "/products/gift-cards/"
                            ? true
                            : false,
                        label: "Hediye kartları",
                      },
                      {
                        url: "/products/product-prices/",
                        disabled: true,
                        selected:
                          location.pathname === "/products/product-prices/"
                            ? true
                            : false,
                        label: "Fiyat listeleri",
                      },
                    ],
                  },
                  {
                    url: "/customers/",
                    label: "Müşteriler",
                    icon: CustomersMinor,
                    selected:
                      (location.pathname === "/customers/" ||
                        location.pathname === "/customers") &&
                      location.pathname.includes("/customers") &&
                      !location.pathname.includes("businesses")
                        ? true
                        : false,
                    disabled: loading,
                    subNavigationItems: [
                      {
                        url: "/customers/businesses/",
                        disabled: true,
                        selected:
                          location.pathname === "/customers/businesses/"
                            ? true
                            : false,
                        label: "Şirketler",
                      },
                    ],
                  },
                  {
                    url: "/entegration/",
                    label: "Entegrasyon",
                    disabled: true,
                    icon: MergeMinor,
                    selected:
                      location.pathname === "/entegration/" ||
                      location.pathname === "/entegration/orders/" ||
                      location.pathname === "/entegration/products/"
                        ? true
                        : false,
                    subNavigationItems: [
                      {
                        url: "/entegration/drafts/",
                        disabled: true,
                        selected:
                          location.pathname === "/entegration/drafts/"
                            ? true
                            : false,
                        label: "Taslaklar",
                      },
                      {
                        url: "/entegration/checkouts/",
                        disabled: true,
                        selected:
                          location.pathname === "/entegration/checkouts/"
                            ? true
                            : false,
                        label: "Yarım bırakılmış ödemeler",
                      },
                    ],
                  },
                  {
                    url: "/finance/",
                    label: "Finans",
                    disabled: true,
                    selected: location.pathname === "/finances/" ? true : false,
                    icon: FinancesMinor,
                    subNavigationItems: [
                      {
                        url: "/finance/invoice/",
                        disabled: true,
                        selected:
                          location.pathname === "/finance/invoice/"
                            ? true
                            : false,
                        label: "Faturalandırma",
                      },
                    ],
                  },
                  {
                    url: "/reports/dashboard/",
                    label: "Analizler",
                    disabled: true,
                    selected:
                      location.pathname === "/reports/dashboard/"
                        ? true
                        : false,
                    icon: AnalyticsMinor,
                    subNavigationItems: [
                      {
                        url: "/reports/",
                        disabled: true,
                        selected:
                          location.pathname === "/reports/" ? true : false,
                        label: "Raporlar",
                      },
                      {
                        url: "/reports/live/",
                        disabled: true,
                        selected:
                          location.pathname === "/reports/live/" ? true : false,
                        label: "Canlı görünüm",
                      },
                    ],
                  },
                  {
                    url: "/marketing/",
                    label: "Pazarlama",
                    disabled: true,
                    selected:
                      location.pathname === "/marketing/" ? true : false,
                    icon: MarketingMinor,
                    subNavigationItems: [
                      {
                        url: "/marketing/campaigns/",
                        disabled: true,
                        selected:
                          location.pathname === "/marketing/campaigns/"
                            ? true
                            : false,
                        label: "Kampanyalar",
                      },
                      {
                        url: "/marketing/automations/",
                        disabled: true,
                        selected:
                          location.pathname === "/marketing/automations/"
                            ? true
                            : false,
                        label: "Otomasyonlar",
                      },
                    ],
                  },
                  {
                    url: "/discounts/",
                    label: "İndirimler",
                    icon: DiscountsMinor,
                    selected:
                      location.pathname === "/discounts/" ? true : false,
                    disabled: true,
                  },
                ]}
              />
              <Navigation.Section
                items={[
                  {
                    url: "/settings/",
                    label: "Ayarlar",
                    icon: SettingsMinor,
                    selected: location.pathname === "/settings/" ? true : false,
                    disabled: true,
                  },
                ]}
              ></Navigation.Section>
            </Navigation>
          }
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={() => setMobileNavigationActive(false)}
        >
          {loading ? (
            <Page>
              <Loading />
              <VerticalStack gap={4}>
                <div style={{ maxWidth: "18.75rem" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <Grid>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <LegacyCard subdued>
                      <LegacyCard.Section
                        title={
                          <div style={{ width: "6.25rem" }}>
                            <SkeletonBodyText lines={1} />
                          </div>
                        }
                      >
                        <div style={{ margin: "0.75rem 0", width: "6.25rem" }}>
                          <SkeletonDisplayText size="medium" />
                        </div>
                        <hr
                          style={{
                            border: "none",
                            borderTop: "0.0625rem solid var(--p-divider)",
                            margin: "1rem 0",
                          }}
                        ></hr>
                        <div>
                          <SkeletonBodyText lines={1} />
                        </div>
                      </LegacyCard.Section>
                    </LegacyCard>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <LegacyCard subdued>
                      <LegacyCard.Section
                        title={
                          <div style={{ width: "6.25rem" }}>
                            <SkeletonBodyText lines={1} />
                          </div>
                        }
                      >
                        <div style={{ margin: "0.75rem 0", width: "6.25rem" }}>
                          <SkeletonDisplayText size="medium" />
                        </div>
                        <hr
                          style={{
                            border: "none",
                            borderTop: "0.0625rem solid var(--p-divider)",
                            margin: "1rem 0",
                          }}
                        ></hr>
                        <div>
                          <SkeletonBodyText lines={1} />
                        </div>
                      </LegacyCard.Section>
                    </LegacyCard>
                  </Grid.Cell>
                </Grid>
                <LegacyCard
                  title={
                    <div style={{ maxWidth: "23rem" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  }
                  sectioned
                >
                  <SkeletonBodyText lines={3} />
                </LegacyCard>
                <LegacyCard
                  title={
                    <div style={{ maxWidth: "23rem" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  }
                  sectioned
                >
                  <SkeletonBodyText lines={3} />
                </LegacyCard>
                <LegacyCard
                  title={
                    <div style={{ maxWidth: "23rem" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  }
                  sectioned
                >
                  <SkeletonBodyText lines={3} />
                </LegacyCard>
                <LegacyCard
                  title={
                    <div style={{ maxWidth: "23rem" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  }
                  sectioned
                >
                  <SkeletonBodyText lines={3} />
                </LegacyCard>
                <LegacyCard
                  title={
                    <div style={{ maxWidth: "23rem" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  }
                  sectioned
                >
                  <SkeletonBodyText lines={3} />
                </LegacyCard>
                <LegacyCard
                  title={
                    <div style={{ maxWidth: "23rem" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  }
                  sectioned
                >
                  <SkeletonBodyText lines={3} />
                </LegacyCard>
              </VerticalStack>
            </Page>
          ) : (
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/orders/" element={<Orders />} />
              <Route path="/orders/:id" element={<OrderDetail />} />
              <Route path="/orders/:id/fulfill/" element={<FulfillOrder />} />
              <Route path="/orders/drafts/" element={<DraftOrders />} />
              <Route path="/orders/drafts/:id" element={<DraftOrderDetail />} />
              <Route path="/orders/drafts/new/" element={<NewDraftOrder />} />
              <Route path="/products/" element={<Products />} />
              <Route
                path="/products/:id"
                element={<ProductDetail puriteTable={puriteTable.items} />}
              />
              <Route
                path="/products/new/"
                element={<NewProduct puriteTable={puriteTable.items} />}
              />
              <Route path="/collections/" element={<Collections />} />
              <Route
                path="/collections/:id"
                element={<CollectionDetail puriteTable={puriteTable.items} />}
              />
              <Route
                path="/collections/new/"
                element={<NewCollection puriteTable={puriteTable.items} />}
              />
              <Route path="/features/" element={<Features />} />
              <Route path="/features/:id" element={<FeatureDetail />} />
              <Route path="/features/new/" element={<NewFeature />} />
              <Route path="/customers/" element={<Customers />} />
              <Route path="/customers/:id" element={<CustomerDetail />} />
              <Route path="/customers/new/" element={<NewCustomer />} />
            </Routes>
          )}
        </Frame>
      </MainProvider>
    </AppProvider>
  );
}

export default App;
