import {
  Page,
  Layout,
  Button,
  LegacyCard,
  LegacyStack,
  TextField,
  Icon,
  Thumbnail,
  Popover,
  Toast,
  Modal,
  Tag,
  ResourceList,
  Banner,
  FormLayout,
  DataTable,
  ContextualSaveBar,
  Combobox,
  Listbox,
  ActionList,
  Checkbox,
  Select,
  OptionList,
  Spinner,
  ResourceItem,
  Text,
} from "@shopify/polaris";
import {
  CirclePlusMinor,
  SearchMinor,
  CancelSmallMinor,
  HorizontalDotsMinor,
} from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { fetcher } from "src/helpers/fetcher";

function NewDraftOrder() {
  let location = useLocation();
  let navigate = useNavigate();
  const [savePaymentButtonLoading, setSavePaymentButtonLoading] =
    useState(false);

  const [orderAddCustomerLoading, setOrderAddCustomerLoading] = useState(true);

  const [orderDetail, setOrderDetail] = useState({
    customer: "",
    customerHash: "",
    waitingPayment: 0,
    items: [],
    payments: [{ paid: 0 }, { paid: 0 }],
    note: "",
    email: "",
    phone: "",
    shippingAddress: {
      name: "",
      surname: "",
      addressLine: "",
      secondAddressLine: "",
      country: "",
      city: "",
      province: "",
      zip: "",
      phonePrefix: "+90",
      phone: "",
    },
    invoiceAddress: {
      name: "",
      surname: "",
      addressLine: "",
      secondAddressLine: "",
      country: "",
      city: "",
      province: "",
      zip: "",
      phonePrefix: "+90",
      phone: "",
    },
    tags: [],
    total: 0,
  });

  const [orderDetailNewCustomerDetails, setOrderDetailNewCustomerDetails] =
    useState({
      email: "",
      language: "",
      marketingPreferences: "",
      address: {
        name: "",
        surname: "",
        addressLine: null,
        secondAddressLine: null,
        countryCode: "TR",
        country: "Türkiye",
        city: null,
        province: null,
        zip: null,
        phonePrefix: "+90",
        phone: null,
      },
    });

  const [orderDetailSelectedProductItems, setOrderDetailSelectedProductItems] =
    useState([]);

  const [ordersBulkToastActive, setOrdersBulkToastActive] = useState(false);
  const ordersBulkActionToastToggle = useCallback(
    () => setOrdersBulkToastActive((active) => !active),
    []
  );

  const [
    editShippingAddressPhoneCountryPopoverActive,
    setEditShippingAddressPhoneCountryPopoverActive,
  ] = useState(false);

  const [staff, setStaff] = useState([]);

  const [customers, setCustomers] = useState([]);

  const [editContactDetailModalActive, setEditContactDetailModalActive] =
    useState(false);

  const [
    editContactDetailSaveButtonLoading,
    setEditContactDetailSaveButtonLoading,
  ] = useState(false);

  const [
    orderDetailContactDetailIsCustomerProfileUpdating,
    setOrderDetailContactDetailIsCustomerProfileUpdating,
  ] = useState(false);

  const [editShippingAddressModalActive, setEditShippingAddressModalActive] =
    useState(false);

  const [editInvoiceAddressModalActive, setEditInvoiceAddressModalActive] =
    useState(false);

  const [completeOrderModalActive, setCompleteOrderModalActive] =
    useState(false);

  const [tagsAddInputValue, setTagsAddInputValue] = useState("");

  const [newCustomerSaveButtonLoading, setNewCustomerSaveButtonLoading] =
    useState(false);

  const [
    orderDetailCustomerSectionPopoverActive,
    setOrderDetailCustomerSectionPopoverActive,
  ] = useState(false);

  const [
    editInvoiceAddressPhoneCountryPopoverActive,
    setEditInvoiceAddressPhoneCountryPopoverActive,
  ] = useState(false);

  const [
    newCustomerPhoneCountryPopoverActive,
    setNewCustomerPhoneCountryPopoverActive,
  ] = useState(false);

  const [addNewProductsModalActive, setAddNewProductsModalActive] =
    useState(false);

  const [newCustomerModalActive, setNewCustomerModalActive] = useState(false);

  const [addNewProductsSaveButtonLoading, setAddNewProductsSaveButtonLoading] =
    useState(false);

  const [addNewProductModalSearchValue, setAddNewProductModalSearchValue] =
    useState("");

  const [addNewProductsSearchLoading, setAddNewProductsSearchLoading] =
    useState(false);

  const [addNewProductsSearchedProducts, setAddNewProductsSearchedProducts] =
    useState([]);

  const productsRows =
    orderDetail.items.length > 0 &&
    orderDetail.items.map(({ secure, name, price, quantity }, index) => [
      <div style={{ width: "100%" }}>
        <LegacyStack alignment="center">
          <Thumbnail
            source={"//panel.altinoranonline.com.tr/p/" + secure + "_0.png"}
            size="small"
          />
          <LegacyStack spacing="extraTight" vertical>
            <Text as="span">{name}</Text>
            <Text as="span">{price}₺</Text>
          </LegacyStack>
        </LegacyStack>
      </div>,
      <LegacyStack alignment="center">
        <div style={{ width: "90px" }}>
          <TextField
            id={secure}
            type="number"
            maxLength={1}
            onChange={(val) => {
              let temp_state = [...orderDetail.items];
              let temp_element = { ...temp_state[index] };
              if (val > 0 || val === "") {
                temp_element.quantity = val;
              } else {
                temp_element.quantity = 1;
              }
              temp_state[index] = temp_element;
              if (val !== undefined) {
                var total = 0;
                temp_state.forEach((e) => {
                  total = total + e.price * e.quantity;
                });
                setOrderDetail({
                  ...orderDetail,
                  items: temp_state,
                  total: total,
                });
              } else {
                //setOrderDetail({ ...orderDetail, items: temp_state });
              }
            }}
            inputMode="numeric"
            value={orderDetail.items[index]["quantity"]}
            min={1}
            step={1}
          />
        </div>
      </LegacyStack>,
      <LegacyStack distribution="fill">
        <p>{(price * quantity).toFixed(2) + "₺"}</p>
      </LegacyStack>,
      <Button plain removeUnderline onClick={() => deleteProducts(secure)}>
        <div style={{ color: "black", fill: "black" }}>
          <Icon source={CancelSmallMinor} />
        </div>
      </Button>,
    ]);

  const [inputValue, setInputValue] = useState("");

  const actualPageMarkup = (
    <Page title={"Sipariş oluştur"} backAction={{ url: "/orders/drafts/" }}>
      <ContextualSaveBar
        message="Kaydedilmemiş taslak sipariş"
        saveAction={{
          onAction: () => {
            createDraftOrder(0);
          },
          loading: savePaymentButtonLoading,
          disabled: savePaymentButtonLoading,
        }}
        discardAction={{
          onAction: () => {
            navigate("/orders/drafts/", { replace: true });
          },
        }}
      />
      <Layout>
        <Layout.Section>
          {orderDetail.completed === 1 && (
            <Banner title="Tamamlandı" status="success">
              <Text as="span" color="subdued">
                Bu sipariş {orderDetail.date} tarihinde tamamlandı
              </Text>
            </Banner>
          )}
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Header
              title={
                <LegacyStack spacing="fill" alignment="center">
                  <LegacyStack spacing="tight" alignment="center">
                    <Text variant="headingMd" as="h2">
                      Ürünler
                    </Text>
                  </LegacyStack>
                </LegacyStack>
              }
            ></LegacyCard.Header>
            <LegacyCard.Section>
              <LegacyStack spacing="extraTight">
                <LegacyStack.Item fill>
                  <TextField
                    type="text"
                    autoComplete="off"
                    placeholder="Ürünleri arayın"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(val) => {
                      setAddNewProductsModalActive(true);
                      setAddNewProductModalSearchValue(val);
                    }}
                  />
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Button
                    onClick={() => {
                      setAddNewProductsModalActive(true);
                      searchProducts("");
                    }}
                  >
                    Gözat
                  </Button>
                </LegacyStack.Item>
              </LegacyStack>
            </LegacyCard.Section>
            {orderDetail.items.length > 0 && (
              <DataTable
                columnContentTypes={["text", "text", "numeric"]}
                headings={[
                  <Text as="span" fontWeight="semibold">
                    Ürün
                  </Text>,
                  <Text as="span" fontWeight="semibold">
                    Adet
                  </Text>,
                  <Text as="span" fontWeight="semibold">
                    Toplam
                  </Text>,
                  <Text as="span" fontWeight="semibold"></Text>,
                ]}
                rows={productsRows}
              />
            )}
            <Modal
              open={addNewProductsModalActive}
              onClose={() => {
                setAddNewProductsModalActive(false);
                setAddNewProductModalSearchValue("");
                searchProducts("");
              }}
              title="Tüm ürünler"
              primaryAction={{
                content: "Bitti",
                onAction: addProducts,
                loading: addNewProductsSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setAddNewProductsModalActive(false);
                    setAddNewProductModalSearchValue("");
                    searchProducts("");
                  },
                },
              ]}
            >
              <Modal.Section>
                <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="Ürünleri arayın"
                  prefix={<Icon source={SearchMinor} />}
                  value={addNewProductModalSearchValue}
                  onChange={(val) => {
                    searchProducts(val);
                    setAddNewProductModalSearchValue(val);
                  }}
                  autoFocus
                />
              </Modal.Section>
              {addNewProductsSearchLoading && (
                <LegacyStack distribution="center" alignment="center">
                  <Spinner size="large" />
                </LegacyStack>
              )}
              {addNewProductsSearchedProducts.length === 0 &&
              !addNewProductsSearchLoading ? (
                <Modal.Section>
                  <div>
                    <Text as="span">"{addNewProductModalSearchValue}"</Text>
                    <Text as="span" color="subdued">
                      {" "}
                      için sonuç bulunamadı
                    </Text>
                  </div>
                </Modal.Section>
              ) : (
                !addNewProductsSearchLoading && (
                  <ResourceList
                    items={addNewProductsSearchedProducts}
                    renderItem={({
                      secure_hash,
                      product_title,
                      product_discount_price,
                      product_quantity,
                    }) => {
                      return (
                        <div>
                          <ResourceItem
                            id={secure_hash}
                            media={
                              <Thumbnail
                                size="small"
                                source={
                                  "//panel.altinoranonline.com.tr/p/" +
                                  secure_hash +
                                  "_0.png"
                                }
                              />
                            }
                          >
                            <LegacyStack distribution="equalSpacing">
                              <LegacyStack
                                vertical
                                spacing="extraTight"
                                distribution="center"
                              >
                                <Text as="span">{product_title}</Text>
                                <Text as="span" color="subdued">
                                  {product_quantity} adet kaldı
                                </Text>
                              </LegacyStack>
                              <Text as="span" fontWeight="semibold">
                                {product_discount_price}₺
                              </Text>
                            </LegacyStack>
                          </ResourceItem>
                        </div>
                      );
                    }}
                    selectedItems={orderDetailSelectedProductItems}
                    onSelectionChange={setOrderDetailSelectedProductItems}
                    selectable
                  />
                )
              )}
              <Modal.Section></Modal.Section>
              <Modal.Section>
                <Text as="span">
                  Kişiye özel veya varyasyonlu ürün ekleyemezsiniz, kişiye özel
                  veya varyasyonlu ürünler ürün listesinde gözükmez
                </Text>
              </Modal.Section>
            </Modal>
          </LegacyCard>
          <LegacyCard>
            <LegacyCard.Header
              title={
                <LegacyStack spacing="fill" alignment="center">
                  <LegacyStack spacing="tight" alignment="center">
                    <Text variant="headingMd" as="h2">
                      Ödeme
                    </Text>
                  </LegacyStack>
                </LegacyStack>
              }
            ></LegacyCard.Header>
            <LegacyCard.Section>
              <div style={{ gridRowGap: "0.5rem", display: "grid" }}>
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <span>Ürünler</span>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <span>{orderDetail.total.toFixed(2)}₺</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <span>Vergi (18%)</span>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <span>
                            {((orderDetail.total * 18) / 100).toFixed(2)}₺
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    gridGap: "0.5rem",
                    display: "grid",
                    gridTemplateColumns: "8.75rem auto",
                  }}
                >
                  <Text as="span" fontWeight="semibold">
                    Toplam
                  </Text>
                  <div
                    style={{
                      gridGap: "1rem",
                      gridRowGap: "1rem",
                      display: "grid",
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      style={{
                        gridGowGap: "0.25rem",
                        display: "grid",
                        gridTemplateColumns: "auto",
                      }}
                    >
                      <div
                        style={{
                          gridColumnGap: "0.5rem",
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <div style={{ justifySelf: "end" }}>
                          <Text as="span" fontWeight="semibold">
                            {(
                              orderDetail.total +
                              (orderDetail.total * 18) / 100
                            ).toFixed(2)}
                            ₺
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LegacyCard.Section>
            {orderDetail.items.length > 0 ? (
              <div>
                <LegacyCard.Section>
                  <LegacyStack distribution="trailing" spacing="tight">
                    <Button>Fatura gönder</Button>
                    <Button
                      primary
                      onClick={() => setCompleteOrderModalActive(true)}
                      loading={savePaymentButtonLoading}
                    >
                      Ödendi olarak işaretle
                    </Button>
                  </LegacyStack>
                </LegacyCard.Section>
                <Modal
                  open={completeOrderModalActive}
                  onClose={() => {
                    setCompleteOrderModalActive(false);
                  }}
                  title="Siparişi tamalayın"
                  primaryAction={{
                    content: "Tamamla",
                    onAction: () => {
                      createDraftOrder(1);
                    },
                    loading: savePaymentButtonLoading,
                  }}
                  secondaryActions={[
                    {
                      content: "İptal",
                      onAction: () => {
                        setCompleteOrderModalActive(false);
                      },
                    },
                  ]}
                >
                  <Modal.Section>
                    <Text as="span">
                      Bu siparişi tamamlarsanız bir daha üzerinde düzenleme
                      yapamayacaksınız, devam ederseniz müşteri sipariş
                      detaylarını alacak ve ödeme yapmış olarak kabul edilecek
                      eğer müşteriden ödeme almadıysanız ve kredi kartı ile
                      ödemesini isteyecekseniz Fatura Gönder seçeneğini
                      kullanmanız gerekmektedir, lütfen ne yaptığınızdan ve bir
                      hata olmadığından emin olun.
                    </Text>
                  </Modal.Section>
                </Modal>
              </div>
            ) : (
              <LegacyCard.Section subdued>
                Toplam tutarı hesaplamak ve ödeme seçeneklerini görüntülemek
                için bir ürün ekleyin.
              </LegacyCard.Section>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Header title="Müşteri">
              {orderDetail.customerHash !== "" && (
                <Popover
                  active={orderDetailCustomerSectionPopoverActive}
                  activator={
                    <Button
                      icon={HorizontalDotsMinor}
                      plain
                      onClick={() => {
                        setOrderDetailCustomerSectionPopoverActive(
                          !orderDetailCustomerSectionPopoverActive
                        );
                      }}
                    ></Button>
                  }
                  onClose={() => {
                    setOrderDetailCustomerSectionPopoverActive(
                      !orderDetailCustomerSectionPopoverActive
                    );
                  }}
                >
                  <ActionList
                    items={[
                      {
                        content: "İletişim bilgilerini düzenleyin",
                        onAction: () => setEditContactDetailModalActive(true),
                      },
                      {
                        content: "Kargo adresini düzenleyin",
                        onAction: () => setEditShippingAddressModalActive(true),
                      },
                      {
                        content: "Fatura adresini düzenleyin",
                        onAction: () => setEditInvoiceAddressModalActive(true),
                      },
                      {
                        content: "Müşteriyi kaldır",
                        destructive: true,
                        onAction: deleteCustomerFromOrder,
                      },
                    ]}
                  />
                </Popover>
              )}
            </LegacyCard.Header>
            <Modal
              open={editContactDetailModalActive}
              onClose={() => {
                setEditContactDetailModalActive(false);
              }}
              title="İletişim bilgilerini düzenle"
              primaryAction={{
                content: "Bitti",
                onAction: () => {
                  saveContactDetail();
                },
                loading: editContactDetailSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditContactDetailModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <LegacyStack vertical>
                  <TextField
                    value={orderDetail.email}
                    onChange={(v) =>
                      setOrderDetail({ ...orderDetail, email: v })
                    }
                    label="E-posta"
                    type="email"
                    inputMode="email"
                  />
                  <TextField
                    value={orderDetail.phone}
                    onChange={(v) =>
                      setOrderDetail({ ...orderDetail, phone: v })
                    }
                    label="Telefon numarası"
                    type="tel"
                    inputMode="tel"
                  />
                  <Checkbox
                    checked={orderDetailContactDetailIsCustomerProfileUpdating}
                    onChange={
                      setOrderDetailContactDetailIsCustomerProfileUpdating
                    }
                    label="Müşteri profilini güncelle"
                  />
                </LegacyStack>
              </Modal.Section>
            </Modal>
            <Modal
              open={editShippingAddressModalActive}
              onClose={() => {
                setEditShippingAddressModalActive(false);
              }}
              title="Kargo adresini düzenle"
              primaryAction={{
                content: "Bitti",
                onAction: () => setEditShippingAddressModalActive(false),
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditShippingAddressModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Ülke/Bölge"
                      options={[
                        { label: "Afghanistan", value: "AF" },
                        { label: "Åland Islands", value: "AX" },
                        { label: "Albania", value: "AL" },
                        { label: "Algeria", value: "DZ" },
                        { label: "American Samoa", value: "AS" },
                        { label: "AndorrA", value: "AD" },
                        { label: "Angola", value: "AO" },
                        { label: "Anguilla", value: "AI" },
                        { label: "Antarctica", value: "AQ" },
                        { label: "Antigua and Barbuda", value: "AG" },
                        { label: "Argentina", value: "AR" },
                        { label: "Armenia", value: "AM" },
                        { label: "Aruba", value: "AW" },
                        { label: "Australia", value: "AU" },
                        { label: "Austria", value: "AT" },
                        { label: "Azerbaijan", value: "AZ" },
                        { label: "Bahamas", value: "BS" },
                        { label: "Bahrain", value: "BH" },
                        { label: "Bangladesh", value: "BD" },
                        { label: "Barbados", value: "BB" },
                        { label: "Belarus", value: "BY" },
                        { label: "Belgium", value: "BE" },
                        { label: "Belize", value: "BZ" },
                        { label: "Benin", value: "BJ" },
                        { label: "Bermuda", value: "BM" },
                        { label: "Bhutan", value: "BT" },
                        { label: "Bolivia", value: "BO" },
                        { label: "Bosnia and Herzegovina", value: "BA" },
                        { label: "Botswana", value: "BW" },
                        { label: "Bouvet Island", value: "BV" },
                        { label: "Brazil", value: "BR" },
                        {
                          label: "British Indian Ocean Territory",
                          value: "IO",
                        },
                        { label: "Brunei Darussalam", value: "BN" },
                        { label: "Bulgaria", value: "BG" },
                        { label: "Burkina Faso", value: "BF" },
                        { label: "Burundi", value: "BI" },
                        { label: "Cambodia", value: "KH" },
                        { label: "Cameroon", value: "CM" },
                        { label: "Canada", value: "CA" },
                        { label: "Cape Verde", value: "CV" },
                        { label: "Cayman Islands", value: "KY" },
                        { label: "Central African Republic", value: "CF" },
                        { label: "Chad", value: "TD" },
                        { label: "Chile", value: "CL" },
                        { label: "China", value: "CN" },
                        { label: "Christmas Island", value: "CX" },
                        { label: "Cocos (Keeling) Islands", value: "CC" },
                        { label: "Colombia", value: "CO" },
                        { label: "Comoros", value: "KM" },
                        { label: "Congo", value: "CG" },
                        {
                          label: "Congo, The Democratic Republic of the",
                          value: "CD",
                        },
                        { label: "Cook Islands", value: "CK" },
                        { label: "Costa Rica", value: "CR" },
                        { label: 'Cote D"Ivoire', value: "CI" },
                        { label: "Croatia", value: "HR" },
                        { label: "Cuba", value: "CU" },
                        { label: "Cyprus", value: "CY" },
                        { label: "Czech Republic", value: "CZ" },
                        { label: "Denmark", value: "DK" },
                        { label: "Djibouti", value: "DJ" },
                        { label: "Dominica", value: "DM" },
                        { label: "Dominican Republic", value: "DO" },
                        { label: "Ecuador", value: "EC" },
                        { label: "Egypt", value: "EG" },
                        { label: "El Salvador", value: "SV" },
                        { label: "Equatorial Guinea", value: "GQ" },
                        { label: "Eritrea", value: "ER" },
                        { label: "Estonia", value: "EE" },
                        { label: "Ethiopia", value: "ET" },
                        { label: "Falkland Islands (Malvinas)", value: "FK" },
                        { label: "Faroe Islands", value: "FO" },
                        { label: "Fiji", value: "FJ" },
                        { label: "Finland", value: "FI" },
                        { label: "France", value: "FR" },
                        { label: "French Guiana", value: "GF" },
                        { label: "French Polynesia", value: "PF" },
                        { label: "French Southern Territories", value: "TF" },
                        { label: "Gabon", value: "GA" },
                        { label: "Gambia", value: "GM" },
                        { label: "Georgia", value: "GE" },
                        { label: "Germany", value: "DE" },
                        { label: "Ghana", value: "GH" },
                        { label: "Gibraltar", value: "GI" },
                        { label: "Greece", value: "GR" },
                        { label: "Greenland", value: "GL" },
                        { label: "Grenada", value: "GD" },
                        { label: "Guadeloupe", value: "GP" },
                        { label: "Guam", value: "GU" },
                        { label: "Guatemala", value: "GT" },
                        { label: "Guernsey", value: "GG" },
                        { label: "Guinea", value: "GN" },
                        { label: "Guinea-Bissau", value: "GW" },
                        { label: "Guyana", value: "GY" },
                        { label: "Haiti", value: "HT" },
                        {
                          label: "Heard Island and Mcdonald Islands",
                          value: "HM",
                        },
                        { label: "Holy See (Vatican City State)", value: "VA" },
                        { label: "Honduras", value: "HN" },
                        { label: "Hong Kong", value: "HK" },
                        { label: "Hungary", value: "HU" },
                        { label: "Iceland", value: "IS" },
                        { label: "India", value: "IN" },
                        { label: "Indonesia", value: "ID" },
                        { label: "Iran, Islamic Republic Of", value: "IR" },
                        { label: "Iraq", value: "IQ" },
                        { label: "Ireland", value: "IE" },
                        { label: "Isle of Man", value: "IM" },
                        { label: "Israel", value: "IL" },
                        { label: "Italy", value: "IT" },
                        { label: "Jamaica", value: "JM" },
                        { label: "Japan", value: "JP" },
                        { label: "Jersey", value: "JE" },
                        { label: "Jordan", value: "JO" },
                        { label: "Kazakhstan", value: "KZ" },
                        { label: "Kenya", value: "KE" },
                        { label: "Kiribati", value: "KI" },
                        {
                          label: 'Korea, Democratic People"S Republic of',
                          value: "KP",
                        },
                        { label: "Korea, Republic of", value: "KR" },
                        { label: "Kuwait", value: "KW" },
                        { label: "Kyrgyzstan", value: "KG" },
                        {
                          label: 'Lao People"S Democratic Republic',
                          value: "LA",
                        },
                        { label: "Latvia", value: "LV" },
                        { label: "Lebanon", value: "LB" },
                        { label: "Lesotho", value: "LS" },
                        { label: "Liberia", value: "LR" },
                        { label: "Libyan Arab Jamahiriya", value: "LY" },
                        { label: "Liechtenstein", value: "LI" },
                        { label: "Lithuania", value: "LT" },
                        { label: "Luxembourg", value: "LU" },
                        { label: "Macao", value: "MO" },
                        {
                          label: "Macedonia, The Former Yugoslav Republic of",
                          value: "MK",
                        },
                        { label: "Madagascar", value: "MG" },
                        { label: "Malawi", value: "MW" },
                        { label: "Malaysia", value: "MY" },
                        { label: "Maldives", value: "MV" },
                        { label: "Mali", value: "ML" },
                        { label: "Malta", value: "MT" },
                        { label: "Marshall Islands", value: "MH" },
                        { label: "Martinique", value: "MQ" },
                        { label: "Mauritania", value: "MR" },
                        { label: "Mauritius", value: "MU" },
                        { label: "Mayotte", value: "YT" },
                        { label: "Mexico", value: "MX" },
                        {
                          label: "Micronesia, Federated States of",
                          value: "FM",
                        },
                        { label: "Moldova, Republic of", value: "MD" },
                        { label: "Monaco", value: "MC" },
                        { label: "Mongolia", value: "MN" },
                        { label: "Montserrat", value: "MS" },
                        { label: "Morocco", value: "MA" },
                        { label: "Mozambique", value: "MZ" },
                        { label: "Myanmar", value: "MM" },
                        { label: "Namibia", value: "NA" },
                        { label: "Nauru", value: "NR" },
                        { label: "Nepal", value: "NP" },
                        { label: "Netherlands", value: "NL" },
                        { label: "Netherlands Antilles", value: "AN" },
                        { label: "New Caledonia", value: "NC" },
                        { label: "New Zealand", value: "NZ" },
                        { label: "Nicaragua", value: "NI" },
                        { label: "Niger", value: "NE" },
                        { label: "Nigeria", value: "NG" },
                        { label: "Niue", value: "NU" },
                        { label: "Norfolk Island", value: "NF" },
                        { label: "Northern Mariana Islands", value: "MP" },
                        { label: "Norway", value: "NO" },
                        { label: "Oman", value: "OM" },
                        { label: "Pakistan", value: "PK" },
                        { label: "Palau", value: "PW" },
                        {
                          label: "Palestinian Territory, Occupied",
                          value: "PS",
                        },
                        { label: "Panama", value: "PA" },
                        { label: "Papua New Guinea", value: "PG" },
                        { label: "Paraguay", value: "PY" },
                        { label: "Peru", value: "PE" },
                        { label: "Philippines", value: "PH" },
                        { label: "Pitcairn", value: "PN" },
                        { label: "Poland", value: "PL" },
                        { label: "Portugal", value: "PT" },
                        { label: "Puerto Rico", value: "PR" },
                        { label: "Qatar", value: "QA" },
                        { label: "Reunion", value: "RE" },
                        { label: "Romania", value: "RO" },
                        { label: "Russian Federation", value: "RU" },
                        { label: "RWANDA", value: "RW" },
                        { label: "Saint Helena", value: "SH" },
                        { label: "Saint Kitts and Nevis", value: "KN" },
                        { label: "Saint Lucia", value: "LC" },
                        { label: "Saint Pierre and Miquelon", value: "PM" },
                        {
                          label: "Saint Vincent and the Grenadines",
                          value: "VC",
                        },
                        { label: "Samoa", value: "WS" },
                        { label: "San Marino", value: "SM" },
                        { label: "Sao Tome and Principe", value: "ST" },
                        { label: "Saudi Arabia", value: "SA" },
                        { label: "Senegal", value: "SN" },
                        { label: "Serbia and Montenegro", value: "CS" },
                        { label: "Seychelles", value: "SC" },
                        { label: "Sierra Leone", value: "SL" },
                        { label: "Singapore", value: "SG" },
                        { label: "Slovakia", value: "SK" },
                        { label: "Slovenia", value: "SI" },
                        { label: "Solomon Islands", value: "SB" },
                        { label: "Somalia", value: "SO" },
                        { label: "South Africa", value: "ZA" },
                        {
                          label: "South Georgia and the South Sandwich Islands",
                          value: "GS",
                        },
                        { label: "Spain", value: "ES" },
                        { label: "Sri Lanka", value: "LK" },
                        { label: "Sudan", value: "SD" },
                        { label: "Suriname", value: "SR" },
                        { label: "Svalbard and Jan Mayen", value: "SJ" },
                        { label: "Swaziland", value: "SZ" },
                        { label: "Sweden", value: "SE" },
                        { label: "Switzerland", value: "CH" },
                        { label: "Syrian Arab Republic", value: "SY" },
                        { label: "Taiwan, Province of China", value: "TW" },
                        { label: "Tajikistan", value: "TJ" },
                        { label: "Tanzania, United Republic of", value: "TZ" },
                        { label: "Thailand", value: "TH" },
                        { label: "Timor-Leste", value: "TL" },
                        { label: "Togo", value: "TG" },
                        { label: "Tokelau", value: "TK" },
                        { label: "Tonga", value: "TO" },
                        { label: "Trinidad and Tobago", value: "TT" },
                        { label: "Tunisia", value: "TN" },
                        { label: "Türkiye", value: "TR" },
                        { label: "Turkmenistan", value: "TM" },
                        { label: "Turks and Caicos Islands", value: "TC" },
                        { label: "Tuvalu", value: "TV" },
                        { label: "Uganda", value: "UG" },
                        { label: "Ukraine", value: "UA" },
                        { label: "United Arab Emirates", value: "AE" },
                        { label: "United Kingdom", value: "GB" },
                        { label: "United States", value: "US" },
                        {
                          label: "United States Minor Outlying Islands",
                          value: "UM",
                        },
                        { label: "Uruguay", value: "UY" },
                        { label: "Uzbekistan", value: "UZ" },
                        { label: "Vanuatu", value: "VU" },
                        { label: "Venezuela", value: "VE" },
                        { label: "Viet Nam", value: "VN" },
                        { label: "Virgin Islands, British", value: "VG" },
                        { label: "Virgin Islands, U.S.", value: "VI" },
                        { label: "Wallis and Futuna", value: "WF" },
                        { label: "Western Sahara", value: "EH" },
                        { label: "Yemen", value: "YE" },
                        { label: "Zambia", value: "ZM" },
                        { label: "Zimbabwe", value: "ZW" },
                      ]}
                      onChange={(val) => {
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            countryCode: val,
                            country: getCountryNameByCode(val),
                          },
                        });
                      }}
                      value={orderDetail.shippingAddress.countryCode}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Ad"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            name: val,
                          },
                        })
                      }
                      value={orderDetail.shippingAddress.name}
                      label="Ad"
                    />
                    <TextField
                      placeholder="Soyad"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            surname: val,
                          },
                        })
                      }
                      value={orderDetail.shippingAddress.surname}
                      label="Soyad"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Adres"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            addressLine: val,
                          },
                        })
                      }
                      value={orderDetail.shippingAddress.addressLine}
                      label="Adres"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Apartman, daire vb."
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            secondAddressLine: val,
                          },
                        })
                      }
                      value={orderDetail.shippingAddress.secondAddressLine}
                      label="Apartman, daire vb."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Posta kodu"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            zip: val,
                          },
                        })
                      }
                      value={orderDetail.shippingAddress.zip}
                      label="Posta kodu"
                    />
                    <TextField
                      placeholder="Şehir"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            city: val,
                          },
                        })
                      }
                      value={orderDetail.shippingAddress.city}
                      label="Şehir"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          shippingAddress: {
                            ...orderDetail.shippingAddress,
                            phone: val,
                          },
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {orderDetail.shippingAddress.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={editShippingAddressPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setEditShippingAddressPhoneCountryPopoverActive(
                                  (
                                    editShippingAddressPhoneCountryPopoverActive
                                  ) =>
                                    !editShippingAddressPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={[
                              {
                                label: "Afghanistan",
                                value: "+93",
                                phoneCountry: "AF",
                              },
                              {
                                label: "Aland Islands",
                                value: "+358",
                                phoneCountry: "AX",
                              },
                              {
                                label: "Albania",
                                value: "+355",
                                phoneCountry: "AL",
                              },
                              {
                                label: "Algeria",
                                value: "+213",
                                phoneCountry: "DZ",
                              },
                              {
                                label: "AmericanSamoa",
                                value: "+1684",
                                phoneCountry: "AS",
                              },
                              {
                                label: "Andorra",
                                value: "+376",
                                phoneCountry: "AD",
                              },
                              {
                                label: "Angola",
                                value: "+244",
                                phoneCountry: "AO",
                              },
                              {
                                label: "Anguilla",
                                value: "+1264",
                                phoneCountry: "AI",
                              },
                              {
                                label: "Antarctica",
                                value: "+672",
                                phoneCountry: "AQ",
                              },
                              {
                                label: "Antigua and Barbuda",
                                value: "+1268",
                                phoneCountry: "AG",
                              },
                              {
                                label: "Argentina",
                                value: "+54",
                                phoneCountry: "AR",
                              },
                              {
                                label: "Armenia",
                                value: "+374",
                                phoneCountry: "AM",
                              },
                              {
                                label: "Aruba",
                                value: "+297",
                                phoneCountry: "AW",
                              },
                              {
                                label: "Australia",
                                value: "+61",
                                phoneCountry: "AU",
                              },
                              {
                                label: "Austria",
                                value: "+43",
                                phoneCountry: "AT",
                              },
                              {
                                label: "Azerbaijan",
                                value: "+994",
                                phoneCountry: "AZ",
                              },
                              {
                                label: "Bahamas",
                                value: "+1242",
                                phoneCountry: "BS",
                              },
                              {
                                label: "Bahrain",
                                value: "+973",
                                phoneCountry: "BH",
                              },
                              {
                                label: "Bangladesh",
                                value: "+880",
                                phoneCountry: "BD",
                              },
                              {
                                label: "Barbados",
                                value: "+1246",
                                phoneCountry: "BB",
                              },
                              {
                                label: "Belarus",
                                value: "+375",
                                phoneCountry: "BY",
                              },
                              {
                                label: "Belgium",
                                value: "+32",
                                phoneCountry: "BE",
                              },
                              {
                                label: "Belize",
                                value: "+501",
                                phoneCountry: "BZ",
                              },
                              {
                                label: "Benin",
                                value: "+229",
                                phoneCountry: "BJ",
                              },
                              {
                                label: "Bermuda",
                                value: "+1441",
                                phoneCountry: "BM",
                              },
                              {
                                label: "Bhutan",
                                value: "+975",
                                phoneCountry: "BT",
                              },
                              {
                                label: "Bolivia, Plurinational State of",
                                value: "+591",
                                phoneCountry: "BO",
                              },
                              {
                                label: "Bosnia and Herzegovina",
                                value: "+387",
                                phoneCountry: "BA",
                              },
                              {
                                label: "Botswana",
                                value: "+267",
                                phoneCountry: "BW",
                              },
                              {
                                label: "Brazil",
                                value: "+55",
                                phoneCountry: "BR",
                              },
                              {
                                label: "British Indian Ocean Territory",
                                value: "+246",
                                phoneCountry: "IO",
                              },
                              {
                                label: "Brunei Darussalam",
                                value: "+673",
                                phoneCountry: "BN",
                              },
                              {
                                label: "Bulgaria",
                                value: "+359",
                                phoneCountry: "BG",
                              },
                              {
                                label: "Burkina Faso",
                                value: "+226",
                                phoneCountry: "BF",
                              },
                              {
                                label: "Burundi",
                                value: "+257",
                                phoneCountry: "BI",
                              },
                              {
                                label: "Cambodia",
                                value: "+855",
                                phoneCountry: "KH",
                              },
                              {
                                label: "Cameroon",
                                value: "+237",
                                phoneCountry: "CM",
                              },
                              {
                                label: "Canada",
                                value: "+1",
                                phoneCountry: "CA",
                              },
                              {
                                label: "Cape Verde",
                                value: "+238",
                                phoneCountry: "CV",
                              },
                              {
                                label: "Cayman Islands",
                                value: "+ 345",
                                phoneCountry: "KY",
                              },
                              {
                                label: "Central African Republic",
                                value: "+236",
                                phoneCountry: "CF",
                              },
                              {
                                label: "Chad",
                                value: "+235",
                                phoneCountry: "TD",
                              },
                              {
                                label: "Chile",
                                value: "+56",
                                phoneCountry: "CL",
                              },
                              {
                                label: "China",
                                value: "+86",
                                phoneCountry: "CN",
                              },
                              {
                                label: "Christmas Island",
                                value: "+61",
                                phoneCountry: "CX",
                              },
                              {
                                label: "Cocos (Keeling) Islands",
                                value: "+61",
                                phoneCountry: "CC",
                              },
                              {
                                label: "Colombia",
                                value: "+57",
                                phoneCountry: "CO",
                              },
                              {
                                label: "Comoros",
                                value: "+269",
                                phoneCountry: "KM",
                              },
                              {
                                label: "Congo",
                                value: "+242",
                                phoneCountry: "CG",
                              },
                              {
                                label:
                                  "Congo, The Democratic Republic of the Congo",
                                value: "+243",
                                phoneCountry: "CD",
                              },
                              {
                                label: "Cook Islands",
                                value: "+682",
                                phoneCountry: "CK",
                              },
                              {
                                label: "Costa Rica",
                                value: "+506",
                                phoneCountry: "CR",
                              },
                              {
                                label: "Cote d'Ivoire",
                                value: "+225",
                                phoneCountry: "CI",
                              },
                              {
                                label: "Croatia",
                                value: "+385",
                                phoneCountry: "HR",
                              },
                              {
                                label: "Cuba",
                                value: "+53",
                                phoneCountry: "CU",
                              },
                              {
                                label: "Cyprus",
                                value: "+357",
                                phoneCountry: "CY",
                              },
                              {
                                label: "Czech Republic",
                                value: "+420",
                                phoneCountry: "CZ",
                              },
                              {
                                label: "Denmark",
                                value: "+45",
                                phoneCountry: "DK",
                              },
                              {
                                label: "Djibouti",
                                value: "+253",
                                phoneCountry: "DJ",
                              },
                              {
                                label: "Dominica",
                                value: "+1767",
                                phoneCountry: "DM",
                              },
                              {
                                label: "Dominican Republic",
                                value: "+1849",
                                phoneCountry: "DO",
                              },
                              {
                                label: "Ecuador",
                                value: "+593",
                                phoneCountry: "EC",
                              },
                              {
                                label: "Egypt",
                                value: "+20",
                                phoneCountry: "EG",
                              },
                              {
                                label: "El Salvador",
                                value: "+503",
                                phoneCountry: "SV",
                              },
                              {
                                label: "Equatorial Guinea",
                                value: "+240",
                                phoneCountry: "GQ",
                              },
                              {
                                label: "Eritrea",
                                value: "+291",
                                phoneCountry: "ER",
                              },
                              {
                                label: "Estonia",
                                value: "+372",
                                phoneCountry: "EE",
                              },
                              {
                                label: "Ethiopia",
                                value: "+251",
                                phoneCountry: "ET",
                              },
                              {
                                label: "Falkland Islands (Malvinas)",
                                value: "+500",
                                phoneCountry: "FK",
                              },
                              {
                                label: "Faroe Islands",
                                value: "+298",
                                phoneCountry: "FO",
                              },
                              {
                                label: "Fiji",
                                value: "+679",
                                phoneCountry: "FJ",
                              },
                              {
                                label: "Finland",
                                value: "+358",
                                phoneCountry: "FI",
                              },
                              {
                                label: "France",
                                value: "+33",
                                phoneCountry: "FR",
                              },
                              {
                                label: "French Guiana",
                                value: "+594",
                                phoneCountry: "GF",
                              },
                              {
                                label: "French Polynesia",
                                value: "+689",
                                phoneCountry: "PF",
                              },
                              {
                                label: "Gabon",
                                value: "+241",
                                phoneCountry: "GA",
                              },
                              {
                                label: "Gambia",
                                value: "+220",
                                phoneCountry: "GM",
                              },
                              {
                                label: "Georgia",
                                value: "+995",
                                phoneCountry: "GE",
                              },
                              {
                                label: "Germany",
                                value: "+49",
                                phoneCountry: "DE",
                              },
                              {
                                label: "Ghana",
                                value: "+233",
                                phoneCountry: "GH",
                              },
                              {
                                label: "Gibraltar",
                                value: "+350",
                                phoneCountry: "GI",
                              },
                              {
                                label: "Greece",
                                value: "+30",
                                phoneCountry: "GR",
                              },
                              {
                                label: "Greenland",
                                value: "+299",
                                phoneCountry: "GL",
                              },
                              {
                                label: "Grenada",
                                value: "+1473",
                                phoneCountry: "GD",
                              },
                              {
                                label: "Guadeloupe",
                                value: "+590",
                                phoneCountry: "GP",
                              },
                              {
                                label: "Guam",
                                value: "+1671",
                                phoneCountry: "GU",
                              },
                              {
                                label: "Guatemala",
                                value: "+502",
                                phoneCountry: "GT",
                              },
                              {
                                label: "Guernsey",
                                value: "+44",
                                phoneCountry: "GG",
                              },
                              {
                                label: "Guinea",
                                value: "+224",
                                phoneCountry: "GN",
                              },
                              {
                                label: "Guinea-Bissau",
                                value: "+245",
                                phoneCountry: "GW",
                              },
                              {
                                label: "Guyana",
                                value: "+595",
                                phoneCountry: "GY",
                              },
                              {
                                label: "Haiti",
                                value: "+509",
                                phoneCountry: "HT",
                              },
                              {
                                label: "Holy See (Vatican City State)",
                                value: "+379",
                                phoneCountry: "VA",
                              },
                              {
                                label: "Honduras",
                                value: "+504",
                                phoneCountry: "HN",
                              },
                              {
                                label: "Hong Kong",
                                value: "+852",
                                phoneCountry: "HK",
                              },
                              {
                                label: "Hungary",
                                value: "+36",
                                phoneCountry: "HU",
                              },
                              {
                                label: "Iceland",
                                value: "+354",
                                phoneCountry: "IS",
                              },
                              {
                                label: "India",
                                value: "+91",
                                phoneCountry: "IN",
                              },
                              {
                                label: "Indonesia",
                                value: "+62",
                                phoneCountry: "ID",
                              },
                              {
                                label: "Iran, Islamic Republic of Persian Gulf",
                                value: "+98",
                                phoneCountry: "IR",
                              },
                              {
                                label: "Iraq",
                                value: "+964",
                                phoneCountry: "IQ",
                              },
                              {
                                label: "Ireland",
                                value: "+353",
                                phoneCountry: "IE",
                              },
                              {
                                label: "Isle of Man",
                                value: "+44",
                                phoneCountry: "IM",
                              },
                              {
                                label: "Israel",
                                value: "+972",
                                phoneCountry: "IL",
                              },
                              {
                                label: "Italy",
                                value: "+39",
                                phoneCountry: "IT",
                              },
                              {
                                label: "Jamaica",
                                value: "+1876",
                                phoneCountry: "JM",
                              },
                              {
                                label: "Japan",
                                value: "+81",
                                phoneCountry: "JP",
                              },
                              {
                                label: "Jersey",
                                value: "+44",
                                phoneCountry: "JE",
                              },
                              {
                                label: "Jordan",
                                value: "+962",
                                phoneCountry: "JO",
                              },
                              {
                                label: "Kazakhstan",
                                value: "+77",
                                phoneCountry: "KZ",
                              },
                              {
                                label: "Kenya",
                                value: "+254",
                                phoneCountry: "KE",
                              },
                              {
                                label: "Kiribati",
                                value: "+686",
                                phoneCountry: "KI",
                              },
                              {
                                label:
                                  "Korea, Democratic People's Republic of Korea",
                                value: "+850",
                                phoneCountry: "KP",
                              },
                              {
                                label: "Korea, Republic of South Korea",
                                value: "+82",
                                phoneCountry: "KR",
                              },
                              {
                                label: "Kuwait",
                                value: "+965",
                                phoneCountry: "KW",
                              },
                              {
                                label: "Kyrgyzstan",
                                value: "+996",
                                phoneCountry: "KG",
                              },
                              {
                                label: "Laos",
                                value: "+856",
                                phoneCountry: "LA",
                              },
                              {
                                label: "Latvia",
                                value: "+371",
                                phoneCountry: "LV",
                              },
                              {
                                label: "Lebanon",
                                value: "+961",
                                phoneCountry: "LB",
                              },
                              {
                                label: "Lesotho",
                                value: "+266",
                                phoneCountry: "LS",
                              },
                              {
                                label: "Liberia",
                                value: "+231",
                                phoneCountry: "LR",
                              },
                              {
                                label: "Libyan Arab Jamahiriya",
                                value: "+218",
                                phoneCountry: "LY",
                              },
                              {
                                label: "Liechtenstein",
                                value: "+423",
                                phoneCountry: "LI",
                              },
                              {
                                label: "Lithuania",
                                value: "+370",
                                phoneCountry: "LT",
                              },
                              {
                                label: "Luxembourg",
                                value: "+352",
                                phoneCountry: "LU",
                              },
                              {
                                label: "Macao",
                                value: "+853",
                                phoneCountry: "MO",
                              },
                              {
                                label: "Macedonia",
                                value: "+389",
                                phoneCountry: "MK",
                              },
                              {
                                label: "Madagascar",
                                value: "+261",
                                phoneCountry: "MG",
                              },
                              {
                                label: "Malawi",
                                value: "+265",
                                phoneCountry: "MW",
                              },
                              {
                                label: "Malaysia",
                                value: "+60",
                                phoneCountry: "MY",
                              },
                              {
                                label: "Maldives",
                                value: "+960",
                                phoneCountry: "MV",
                              },
                              {
                                label: "Mali",
                                value: "+223",
                                phoneCountry: "ML",
                              },
                              {
                                label: "Malta",
                                value: "+356",
                                phoneCountry: "MT",
                              },
                              {
                                label: "Marshall Islands",
                                value: "+692",
                                phoneCountry: "MH",
                              },
                              {
                                label: "Martinique",
                                value: "+596",
                                phoneCountry: "MQ",
                              },
                              {
                                label: "Mauritania",
                                value: "+222",
                                phoneCountry: "MR",
                              },
                              {
                                label: "Mauritius",
                                value: "+230",
                                phoneCountry: "MU",
                              },
                              {
                                label: "Mayotte",
                                value: "+262",
                                phoneCountry: "YT",
                              },
                              {
                                label: "Mexico",
                                value: "+52",
                                phoneCountry: "MX",
                              },
                              {
                                label:
                                  "Micronesia, Federated States of Micronesia",
                                value: "+691",
                                phoneCountry: "FM",
                              },
                              {
                                label: "Moldova",
                                value: "+373",
                                phoneCountry: "MD",
                              },
                              {
                                label: "Monaco",
                                value: "+377",
                                phoneCountry: "MC",
                              },
                              {
                                label: "Mongolia",
                                value: "+976",
                                phoneCountry: "MN",
                              },
                              {
                                label: "Montenegro",
                                value: "+382",
                                phoneCountry: "ME",
                              },
                              {
                                label: "Montserrat",
                                value: "+1664",
                                phoneCountry: "MS",
                              },
                              {
                                label: "Morocco",
                                value: "+212",
                                phoneCountry: "MA",
                              },
                              {
                                label: "Mozambique",
                                value: "+258",
                                phoneCountry: "MZ",
                              },
                              {
                                label: "Myanmar",
                                value: "+95",
                                phoneCountry: "MM",
                              },
                              {
                                label: "Namibia",
                                value: "+264",
                                phoneCountry: "NA",
                              },
                              {
                                label: "Nauru",
                                value: "+674",
                                phoneCountry: "NR",
                              },
                              {
                                label: "Nepal",
                                value: "+977",
                                phoneCountry: "NP",
                              },
                              {
                                label: "Netherlands",
                                value: "+31",
                                phoneCountry: "NL",
                              },
                              {
                                label: "Netherlands Antilles",
                                value: "+599",
                                phoneCountry: "AN",
                              },
                              {
                                label: "New Caledonia",
                                value: "+687",
                                phoneCountry: "NC",
                              },
                              {
                                label: "New Zealand",
                                value: "+64",
                                phoneCountry: "NZ",
                              },
                              {
                                label: "Nicaragua",
                                value: "+505",
                                phoneCountry: "NI",
                              },
                              {
                                label: "Niger",
                                value: "+227",
                                phoneCountry: "NE",
                              },
                              {
                                label: "Nigeria",
                                value: "+234",
                                phoneCountry: "NG",
                              },
                              {
                                label: "Niue",
                                value: "+683",
                                phoneCountry: "NU",
                              },
                              {
                                label: "Norfolk Island",
                                value: "+672",
                                phoneCountry: "NF",
                              },
                              {
                                label: "Northern Mariana Islands",
                                value: "+1670",
                                phoneCountry: "MP",
                              },
                              {
                                label: "Norway",
                                value: "+47",
                                phoneCountry: "NO",
                              },
                              {
                                label: "Oman",
                                value: "+968",
                                phoneCountry: "OM",
                              },
                              {
                                label: "Pakistan",
                                value: "+92",
                                phoneCountry: "PK",
                              },
                              {
                                label: "Palau",
                                value: "+680",
                                phoneCountry: "PW",
                              },
                              {
                                label: "Palestinian Territory, Occupied",
                                value: "+970",
                                phoneCountry: "PS",
                              },
                              {
                                label: "Panama",
                                value: "+507",
                                phoneCountry: "PA",
                              },
                              {
                                label: "Papua New Guinea",
                                value: "+675",
                                phoneCountry: "PG",
                              },
                              {
                                label: "Paraguay",
                                value: "+595",
                                phoneCountry: "PY",
                              },
                              {
                                label: "Peru",
                                value: "+51",
                                phoneCountry: "PE",
                              },
                              {
                                label: "Philippines",
                                value: "+63",
                                phoneCountry: "PH",
                              },
                              {
                                label: "Pitcairn",
                                value: "+872",
                                phoneCountry: "PN",
                              },
                              {
                                label: "Poland",
                                value: "+48",
                                phoneCountry: "PL",
                              },
                              {
                                label: "Portugal",
                                value: "+351",
                                phoneCountry: "PT",
                              },
                              {
                                label: "Puerto Rico",
                                value: "+1939",
                                phoneCountry: "PR",
                              },
                              {
                                label: "Qatar",
                                value: "+974",
                                phoneCountry: "QA",
                              },
                              {
                                label: "Romania",
                                value: "+40",
                                phoneCountry: "RO",
                              },
                              {
                                label: "Russia",
                                value: "+7",
                                phoneCountry: "RU",
                              },
                              {
                                label: "Rwanda",
                                value: "+250",
                                phoneCountry: "RW",
                              },
                              {
                                label: "Reunion",
                                value: "+262",
                                phoneCountry: "RE",
                              },
                              {
                                label: "Saint Barthelemy",
                                value: "+590",
                                phoneCountry: "BL",
                              },
                              {
                                label:
                                  "Saint Helena, Ascension and Tristan Da Cunha",
                                value: "+290",
                                phoneCountry: "SH",
                              },
                              {
                                label: "Saint Kitts and Nevis",
                                value: "+1869",
                                phoneCountry: "KN",
                              },
                              {
                                label: "Saint Lucia",
                                value: "+1758",
                                phoneCountry: "LC",
                              },
                              {
                                label: "Saint Martin",
                                value: "+590",
                                phoneCountry: "MF",
                              },
                              {
                                label: "Saint Pierre and Miquelon",
                                value: "+508",
                                phoneCountry: "PM",
                              },
                              {
                                label: "Saint Vincent and the Grenadines",
                                value: "+1784",
                                phoneCountry: "VC",
                              },
                              {
                                label: "Samoa",
                                value: "+685",
                                phoneCountry: "WS",
                              },
                              {
                                label: "San Marino",
                                value: "+378",
                                phoneCountry: "SM",
                              },
                              {
                                label: "Sao Tome and Principe",
                                value: "+239",
                                phoneCountry: "ST",
                              },
                              {
                                label: "Saudi Arabia",
                                value: "+966",
                                phoneCountry: "SA",
                              },
                              {
                                label: "Senegal",
                                value: "+221",
                                phoneCountry: "SN",
                              },
                              {
                                label: "Serbia",
                                value: "+381",
                                phoneCountry: "RS",
                              },
                              {
                                label: "Seychelles",
                                value: "+248",
                                phoneCountry: "SC",
                              },
                              {
                                label: "Sierra Leone",
                                value: "+232",
                                phoneCountry: "SL",
                              },
                              {
                                label: "Singapore",
                                value: "+65",
                                phoneCountry: "SG",
                              },
                              {
                                label: "Slovakia",
                                value: "+421",
                                phoneCountry: "SK",
                              },
                              {
                                label: "Slovenia",
                                value: "+386",
                                phoneCountry: "SI",
                              },
                              {
                                label: "Solomon Islands",
                                value: "+677",
                                phoneCountry: "SB",
                              },
                              {
                                label: "Somalia",
                                value: "+252",
                                phoneCountry: "SO",
                              },
                              {
                                label: "South Africa",
                                value: "+27",
                                phoneCountry: "ZA",
                              },
                              {
                                label: "South Sudan",
                                value: "+211",
                                phoneCountry: "SS",
                              },
                              {
                                label:
                                  "South Georgia and the South Sandwich Islands",
                                value: "+500",
                                phoneCountry: "GS",
                              },
                              {
                                label: "Spain",
                                value: "+34",
                                phoneCountry: "ES",
                              },
                              {
                                label: "Sri Lanka",
                                value: "+94",
                                phoneCountry: "LK",
                              },
                              {
                                label: "Sudan",
                                value: "+249",
                                phoneCountry: "SD",
                              },
                              {
                                label: "Suriname",
                                value: "+597",
                                phoneCountry: "SR",
                              },
                              {
                                label: "Svalbard and Jan Mayen",
                                value: "+47",
                                phoneCountry: "SJ",
                              },
                              {
                                label: "Swaziland",
                                value: "+268",
                                phoneCountry: "SZ",
                              },
                              {
                                label: "Sweden",
                                value: "+46",
                                phoneCountry: "SE",
                              },
                              {
                                label: "Switzerland",
                                value: "+41",
                                phoneCountry: "CH",
                              },
                              {
                                label: "Syrian Arab Republic",
                                value: "+963",
                                phoneCountry: "SY",
                              },
                              {
                                label: "Taiwan",
                                value: "+886",
                                phoneCountry: "TW",
                              },
                              {
                                label: "Tajikistan",
                                value: "+992",
                                phoneCountry: "TJ",
                              },
                              {
                                label: "Tanzania, United Republic of Tanzania",
                                value: "+255",
                                phoneCountry: "TZ",
                              },
                              {
                                label: "Thailand",
                                value: "+66",
                                phoneCountry: "TH",
                              },
                              {
                                label: "Timor-Leste",
                                value: "+670",
                                phoneCountry: "TL",
                              },
                              {
                                label: "Togo",
                                value: "+228",
                                phoneCountry: "TG",
                              },
                              {
                                label: "Tokelau",
                                value: "+690",
                                phoneCountry: "TK",
                              },
                              {
                                label: "Tonga",
                                value: "+676",
                                phoneCountry: "TO",
                              },
                              {
                                label: "Trinidad and Tobago",
                                value: "+1868",
                                phoneCountry: "TT",
                              },
                              {
                                label: "Tunisia",
                                value: "+216",
                                phoneCountry: "TN",
                              },
                              {
                                label: "Turkey",
                                value: "+90",
                                phoneCountry: "TR",
                              },
                              {
                                label: "Turkmenistan",
                                value: "+993",
                                phoneCountry: "TM",
                              },
                              {
                                label: "Turks and Caicos Islands",
                                value: "+1649",
                                phoneCountry: "TC",
                              },
                              {
                                label: "Tuvalu",
                                value: "+688",
                                phoneCountry: "TV",
                              },
                              {
                                label: "Uganda",
                                value: "+256",
                                phoneCountry: "UG",
                              },
                              {
                                label: "Ukraine",
                                value: "+380",
                                phoneCountry: "UA",
                              },
                              {
                                label: "United Arab Emirates",
                                value: "+971",
                                phoneCountry: "AE",
                              },
                              {
                                label: "United Kingdom",
                                value: "+44",
                                phoneCountry: "GB",
                              },
                              {
                                label: "United States",
                                value: "+1",
                                phoneCountry: "US",
                              },
                              {
                                label: "Uruguay",
                                value: "+598",
                                phoneCountry: "UY",
                              },
                              {
                                label: "Uzbekistan",
                                value: "+998",
                                phoneCountry: "UZ",
                              },
                              {
                                label: "Vanuatu",
                                value: "+678",
                                phoneCountry: "VU",
                              },
                              {
                                label:
                                  "Venezuela, Bolivarian Republic of Venezuela",
                                value: "+58",
                                phoneCountry: "VE",
                              },
                              {
                                label: "Vietnam",
                                value: "+84",
                                phoneCountry: "VN",
                              },
                              {
                                label: "Virgin Islands, British",
                                value: "+1284",
                                phoneCountry: "VG",
                              },
                              {
                                label: "Virgin Islands, U.S.",
                                value: "+1340",
                                phoneCountry: "VI",
                              },
                              {
                                label: "Wallis and Futuna",
                                value: "+681",
                                phoneCountry: "WF",
                              },
                              {
                                label: "Yemen",
                                value: "+967",
                                phoneCountry: "YE",
                              },
                              {
                                label: "Zambia",
                                value: "+260",
                                phoneCountry: "ZM",
                              },
                              {
                                label: "Zimbabwe",
                                value: "+263",
                                phoneCountry: "ZW",
                              },
                            ]}
                            onChange={(va) => {
                              setOrderDetail({
                                ...orderDetail,
                                shippingAddress: {
                                  ...orderDetail.shippingAddress,
                                  phonePrefix: va,
                                },
                              });
                              setEditShippingAddressPhoneCountryPopoverActive(
                                false
                              );
                            }}
                            selected={orderDetail.shippingAddress.phonePrefix}
                          />
                        </Popover>
                      }
                      value={orderDetail.shippingAddress.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <Modal
              open={editInvoiceAddressModalActive}
              onClose={() => {
                setEditInvoiceAddressModalActive(false);
              }}
              title="Fatura adresini düzenle"
              primaryAction={{
                content: "Bitti",
                onAction: () => setEditInvoiceAddressModalActive(false),
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setEditInvoiceAddressModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Ülke/Bölge"
                      options={[
                        { label: "Afghanistan", value: "AF" },
                        { label: "Åland Islands", value: "AX" },
                        { label: "Albania", value: "AL" },
                        { label: "Algeria", value: "DZ" },
                        { label: "American Samoa", value: "AS" },
                        { label: "AndorrA", value: "AD" },
                        { label: "Angola", value: "AO" },
                        { label: "Anguilla", value: "AI" },
                        { label: "Antarctica", value: "AQ" },
                        { label: "Antigua and Barbuda", value: "AG" },
                        { label: "Argentina", value: "AR" },
                        { label: "Armenia", value: "AM" },
                        { label: "Aruba", value: "AW" },
                        { label: "Australia", value: "AU" },
                        { label: "Austria", value: "AT" },
                        { label: "Azerbaijan", value: "AZ" },
                        { label: "Bahamas", value: "BS" },
                        { label: "Bahrain", value: "BH" },
                        { label: "Bangladesh", value: "BD" },
                        { label: "Barbados", value: "BB" },
                        { label: "Belarus", value: "BY" },
                        { label: "Belgium", value: "BE" },
                        { label: "Belize", value: "BZ" },
                        { label: "Benin", value: "BJ" },
                        { label: "Bermuda", value: "BM" },
                        { label: "Bhutan", value: "BT" },
                        { label: "Bolivia", value: "BO" },
                        { label: "Bosnia and Herzegovina", value: "BA" },
                        { label: "Botswana", value: "BW" },
                        { label: "Bouvet Island", value: "BV" },
                        { label: "Brazil", value: "BR" },
                        {
                          label: "British Indian Ocean Territory",
                          value: "IO",
                        },
                        { label: "Brunei Darussalam", value: "BN" },
                        { label: "Bulgaria", value: "BG" },
                        { label: "Burkina Faso", value: "BF" },
                        { label: "Burundi", value: "BI" },
                        { label: "Cambodia", value: "KH" },
                        { label: "Cameroon", value: "CM" },
                        { label: "Canada", value: "CA" },
                        { label: "Cape Verde", value: "CV" },
                        { label: "Cayman Islands", value: "KY" },
                        { label: "Central African Republic", value: "CF" },
                        { label: "Chad", value: "TD" },
                        { label: "Chile", value: "CL" },
                        { label: "China", value: "CN" },
                        { label: "Christmas Island", value: "CX" },
                        { label: "Cocos (Keeling) Islands", value: "CC" },
                        { label: "Colombia", value: "CO" },
                        { label: "Comoros", value: "KM" },
                        { label: "Congo", value: "CG" },
                        {
                          label: "Congo, The Democratic Republic of the",
                          value: "CD",
                        },
                        { label: "Cook Islands", value: "CK" },
                        { label: "Costa Rica", value: "CR" },
                        { label: 'Cote D"Ivoire', value: "CI" },
                        { label: "Croatia", value: "HR" },
                        { label: "Cuba", value: "CU" },
                        { label: "Cyprus", value: "CY" },
                        { label: "Czech Republic", value: "CZ" },
                        { label: "Denmark", value: "DK" },
                        { label: "Djibouti", value: "DJ" },
                        { label: "Dominica", value: "DM" },
                        { label: "Dominican Republic", value: "DO" },
                        { label: "Ecuador", value: "EC" },
                        { label: "Egypt", value: "EG" },
                        { label: "El Salvador", value: "SV" },
                        { label: "Equatorial Guinea", value: "GQ" },
                        { label: "Eritrea", value: "ER" },
                        { label: "Estonia", value: "EE" },
                        { label: "Ethiopia", value: "ET" },
                        { label: "Falkland Islands (Malvinas)", value: "FK" },
                        { label: "Faroe Islands", value: "FO" },
                        { label: "Fiji", value: "FJ" },
                        { label: "Finland", value: "FI" },
                        { label: "France", value: "FR" },
                        { label: "French Guiana", value: "GF" },
                        { label: "French Polynesia", value: "PF" },
                        { label: "French Southern Territories", value: "TF" },
                        { label: "Gabon", value: "GA" },
                        { label: "Gambia", value: "GM" },
                        { label: "Georgia", value: "GE" },
                        { label: "Germany", value: "DE" },
                        { label: "Ghana", value: "GH" },
                        { label: "Gibraltar", value: "GI" },
                        { label: "Greece", value: "GR" },
                        { label: "Greenland", value: "GL" },
                        { label: "Grenada", value: "GD" },
                        { label: "Guadeloupe", value: "GP" },
                        { label: "Guam", value: "GU" },
                        { label: "Guatemala", value: "GT" },
                        { label: "Guernsey", value: "GG" },
                        { label: "Guinea", value: "GN" },
                        { label: "Guinea-Bissau", value: "GW" },
                        { label: "Guyana", value: "GY" },
                        { label: "Haiti", value: "HT" },
                        {
                          label: "Heard Island and Mcdonald Islands",
                          value: "HM",
                        },
                        { label: "Holy See (Vatican City State)", value: "VA" },
                        { label: "Honduras", value: "HN" },
                        { label: "Hong Kong", value: "HK" },
                        { label: "Hungary", value: "HU" },
                        { label: "Iceland", value: "IS" },
                        { label: "India", value: "IN" },
                        { label: "Indonesia", value: "ID" },
                        { label: "Iran, Islamic Republic Of", value: "IR" },
                        { label: "Iraq", value: "IQ" },
                        { label: "Ireland", value: "IE" },
                        { label: "Isle of Man", value: "IM" },
                        { label: "Israel", value: "IL" },
                        { label: "Italy", value: "IT" },
                        { label: "Jamaica", value: "JM" },
                        { label: "Japan", value: "JP" },
                        { label: "Jersey", value: "JE" },
                        { label: "Jordan", value: "JO" },
                        { label: "Kazakhstan", value: "KZ" },
                        { label: "Kenya", value: "KE" },
                        { label: "Kiribati", value: "KI" },
                        {
                          label: 'Korea, Democratic People"S Republic of',
                          value: "KP",
                        },
                        { label: "Korea, Republic of", value: "KR" },
                        { label: "Kuwait", value: "KW" },
                        { label: "Kyrgyzstan", value: "KG" },
                        {
                          label: 'Lao People"S Democratic Republic',
                          value: "LA",
                        },
                        { label: "Latvia", value: "LV" },
                        { label: "Lebanon", value: "LB" },
                        { label: "Lesotho", value: "LS" },
                        { label: "Liberia", value: "LR" },
                        { label: "Libyan Arab Jamahiriya", value: "LY" },
                        { label: "Liechtenstein", value: "LI" },
                        { label: "Lithuania", value: "LT" },
                        { label: "Luxembourg", value: "LU" },
                        { label: "Macao", value: "MO" },
                        {
                          label: "Macedonia, The Former Yugoslav Republic of",
                          value: "MK",
                        },
                        { label: "Madagascar", value: "MG" },
                        { label: "Malawi", value: "MW" },
                        { label: "Malaysia", value: "MY" },
                        { label: "Maldives", value: "MV" },
                        { label: "Mali", value: "ML" },
                        { label: "Malta", value: "MT" },
                        { label: "Marshall Islands", value: "MH" },
                        { label: "Martinique", value: "MQ" },
                        { label: "Mauritania", value: "MR" },
                        { label: "Mauritius", value: "MU" },
                        { label: "Mayotte", value: "YT" },
                        { label: "Mexico", value: "MX" },
                        {
                          label: "Micronesia, Federated States of",
                          value: "FM",
                        },
                        { label: "Moldova, Republic of", value: "MD" },
                        { label: "Monaco", value: "MC" },
                        { label: "Mongolia", value: "MN" },
                        { label: "Montserrat", value: "MS" },
                        { label: "Morocco", value: "MA" },
                        { label: "Mozambique", value: "MZ" },
                        { label: "Myanmar", value: "MM" },
                        { label: "Namibia", value: "NA" },
                        { label: "Nauru", value: "NR" },
                        { label: "Nepal", value: "NP" },
                        { label: "Netherlands", value: "NL" },
                        { label: "Netherlands Antilles", value: "AN" },
                        { label: "New Caledonia", value: "NC" },
                        { label: "New Zealand", value: "NZ" },
                        { label: "Nicaragua", value: "NI" },
                        { label: "Niger", value: "NE" },
                        { label: "Nigeria", value: "NG" },
                        { label: "Niue", value: "NU" },
                        { label: "Norfolk Island", value: "NF" },
                        { label: "Northern Mariana Islands", value: "MP" },
                        { label: "Norway", value: "NO" },
                        { label: "Oman", value: "OM" },
                        { label: "Pakistan", value: "PK" },
                        { label: "Palau", value: "PW" },
                        {
                          label: "Palestinian Territory, Occupied",
                          value: "PS",
                        },
                        { label: "Panama", value: "PA" },
                        { label: "Papua New Guinea", value: "PG" },
                        { label: "Paraguay", value: "PY" },
                        { label: "Peru", value: "PE" },
                        { label: "Philippines", value: "PH" },
                        { label: "Pitcairn", value: "PN" },
                        { label: "Poland", value: "PL" },
                        { label: "Portugal", value: "PT" },
                        { label: "Puerto Rico", value: "PR" },
                        { label: "Qatar", value: "QA" },
                        { label: "Reunion", value: "RE" },
                        { label: "Romania", value: "RO" },
                        { label: "Russian Federation", value: "RU" },
                        { label: "RWANDA", value: "RW" },
                        { label: "Saint Helena", value: "SH" },
                        { label: "Saint Kitts and Nevis", value: "KN" },
                        { label: "Saint Lucia", value: "LC" },
                        { label: "Saint Pierre and Miquelon", value: "PM" },
                        {
                          label: "Saint Vincent and the Grenadines",
                          value: "VC",
                        },
                        { label: "Samoa", value: "WS" },
                        { label: "San Marino", value: "SM" },
                        { label: "Sao Tome and Principe", value: "ST" },
                        { label: "Saudi Arabia", value: "SA" },
                        { label: "Senegal", value: "SN" },
                        { label: "Serbia and Montenegro", value: "CS" },
                        { label: "Seychelles", value: "SC" },
                        { label: "Sierra Leone", value: "SL" },
                        { label: "Singapore", value: "SG" },
                        { label: "Slovakia", value: "SK" },
                        { label: "Slovenia", value: "SI" },
                        { label: "Solomon Islands", value: "SB" },
                        { label: "Somalia", value: "SO" },
                        { label: "South Africa", value: "ZA" },
                        {
                          label: "South Georgia and the South Sandwich Islands",
                          value: "GS",
                        },
                        { label: "Spain", value: "ES" },
                        { label: "Sri Lanka", value: "LK" },
                        { label: "Sudan", value: "SD" },
                        { label: "Suriname", value: "SR" },
                        { label: "Svalbard and Jan Mayen", value: "SJ" },
                        { label: "Swaziland", value: "SZ" },
                        { label: "Sweden", value: "SE" },
                        { label: "Switzerland", value: "CH" },
                        { label: "Syrian Arab Republic", value: "SY" },
                        { label: "Taiwan, Province of China", value: "TW" },
                        { label: "Tajikistan", value: "TJ" },
                        { label: "Tanzania, United Republic of", value: "TZ" },
                        { label: "Thailand", value: "TH" },
                        { label: "Timor-Leste", value: "TL" },
                        { label: "Togo", value: "TG" },
                        { label: "Tokelau", value: "TK" },
                        { label: "Tonga", value: "TO" },
                        { label: "Trinidad and Tobago", value: "TT" },
                        { label: "Tunisia", value: "TN" },
                        { label: "Türkiye", value: "TR" },
                        { label: "Turkmenistan", value: "TM" },
                        { label: "Turks and Caicos Islands", value: "TC" },
                        { label: "Tuvalu", value: "TV" },
                        { label: "Uganda", value: "UG" },
                        { label: "Ukraine", value: "UA" },
                        { label: "United Arab Emirates", value: "AE" },
                        { label: "United Kingdom", value: "GB" },
                        { label: "United States", value: "US" },
                        {
                          label: "United States Minor Outlying Islands",
                          value: "UM",
                        },
                        { label: "Uruguay", value: "UY" },
                        { label: "Uzbekistan", value: "UZ" },
                        { label: "Vanuatu", value: "VU" },
                        { label: "Venezuela", value: "VE" },
                        { label: "Viet Nam", value: "VN" },
                        { label: "Virgin Islands, British", value: "VG" },
                        { label: "Virgin Islands, U.S.", value: "VI" },
                        { label: "Wallis and Futuna", value: "WF" },
                        { label: "Western Sahara", value: "EH" },
                        { label: "Yemen", value: "YE" },
                        { label: "Zambia", value: "ZM" },
                        { label: "Zimbabwe", value: "ZW" },
                      ]}
                      onChange={(val) => {
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            countryCode: val,
                            country: getCountryNameByCode(val),
                          },
                        });
                      }}
                      value={orderDetail.invoiceAddress.countryCode}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Ad"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            name: val,
                          },
                        })
                      }
                      value={orderDetail.invoiceAddress.name}
                      label="Ad"
                    />
                    <TextField
                      placeholder="Soyad"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            surname: val,
                          },
                        })
                      }
                      value={orderDetail.invoiceAddress.surname}
                      label="Soyad"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Adres"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            addressLine: val,
                          },
                        })
                      }
                      value={orderDetail.invoiceAddress.addressLine}
                      label="Adres"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Apartman, daire vb."
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            secondAddressLine: val,
                          },
                        })
                      }
                      value={orderDetail.invoiceAddress.secondAddressLine}
                      label="Apartman, daire vb."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Posta kodu"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            zip: val,
                          },
                        })
                      }
                      value={orderDetail.invoiceAddress.zip}
                      label="Posta kodu"
                    />
                    <TextField
                      placeholder="Şehir"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            city: val,
                          },
                        })
                      }
                      value={orderDetail.invoiceAddress.city}
                      label="Şehir"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setOrderDetail({
                          ...orderDetail,
                          invoiceAddress: {
                            ...orderDetail.invoiceAddress,
                            phone: val,
                          },
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {orderDetail.invoiceAddress.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={editInvoiceAddressPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setEditInvoiceAddressPhoneCountryPopoverActive(
                                  (
                                    editInvoiceAddressPhoneCountryPopoverActive
                                  ) =>
                                    !editInvoiceAddressPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={[
                              {
                                label: "Afghanistan",
                                value: "+93",
                                phoneCountry: "AF",
                              },
                              {
                                label: "Aland Islands",
                                value: "+358",
                                phoneCountry: "AX",
                              },
                              {
                                label: "Albania",
                                value: "+355",
                                phoneCountry: "AL",
                              },
                              {
                                label: "Algeria",
                                value: "+213",
                                phoneCountry: "DZ",
                              },
                              {
                                label: "AmericanSamoa",
                                value: "+1684",
                                phoneCountry: "AS",
                              },
                              {
                                label: "Andorra",
                                value: "+376",
                                phoneCountry: "AD",
                              },
                              {
                                label: "Angola",
                                value: "+244",
                                phoneCountry: "AO",
                              },
                              {
                                label: "Anguilla",
                                value: "+1264",
                                phoneCountry: "AI",
                              },
                              {
                                label: "Antarctica",
                                value: "+672",
                                phoneCountry: "AQ",
                              },
                              {
                                label: "Antigua and Barbuda",
                                value: "+1268",
                                phoneCountry: "AG",
                              },
                              {
                                label: "Argentina",
                                value: "+54",
                                phoneCountry: "AR",
                              },
                              {
                                label: "Armenia",
                                value: "+374",
                                phoneCountry: "AM",
                              },
                              {
                                label: "Aruba",
                                value: "+297",
                                phoneCountry: "AW",
                              },
                              {
                                label: "Australia",
                                value: "+61",
                                phoneCountry: "AU",
                              },
                              {
                                label: "Austria",
                                value: "+43",
                                phoneCountry: "AT",
                              },
                              {
                                label: "Azerbaijan",
                                value: "+994",
                                phoneCountry: "AZ",
                              },
                              {
                                label: "Bahamas",
                                value: "+1242",
                                phoneCountry: "BS",
                              },
                              {
                                label: "Bahrain",
                                value: "+973",
                                phoneCountry: "BH",
                              },
                              {
                                label: "Bangladesh",
                                value: "+880",
                                phoneCountry: "BD",
                              },
                              {
                                label: "Barbados",
                                value: "+1246",
                                phoneCountry: "BB",
                              },
                              {
                                label: "Belarus",
                                value: "+375",
                                phoneCountry: "BY",
                              },
                              {
                                label: "Belgium",
                                value: "+32",
                                phoneCountry: "BE",
                              },
                              {
                                label: "Belize",
                                value: "+501",
                                phoneCountry: "BZ",
                              },
                              {
                                label: "Benin",
                                value: "+229",
                                phoneCountry: "BJ",
                              },
                              {
                                label: "Bermuda",
                                value: "+1441",
                                phoneCountry: "BM",
                              },
                              {
                                label: "Bhutan",
                                value: "+975",
                                phoneCountry: "BT",
                              },
                              {
                                label: "Bolivia, Plurinational State of",
                                value: "+591",
                                phoneCountry: "BO",
                              },
                              {
                                label: "Bosnia and Herzegovina",
                                value: "+387",
                                phoneCountry: "BA",
                              },
                              {
                                label: "Botswana",
                                value: "+267",
                                phoneCountry: "BW",
                              },
                              {
                                label: "Brazil",
                                value: "+55",
                                phoneCountry: "BR",
                              },
                              {
                                label: "British Indian Ocean Territory",
                                value: "+246",
                                phoneCountry: "IO",
                              },
                              {
                                label: "Brunei Darussalam",
                                value: "+673",
                                phoneCountry: "BN",
                              },
                              {
                                label: "Bulgaria",
                                value: "+359",
                                phoneCountry: "BG",
                              },
                              {
                                label: "Burkina Faso",
                                value: "+226",
                                phoneCountry: "BF",
                              },
                              {
                                label: "Burundi",
                                value: "+257",
                                phoneCountry: "BI",
                              },
                              {
                                label: "Cambodia",
                                value: "+855",
                                phoneCountry: "KH",
                              },
                              {
                                label: "Cameroon",
                                value: "+237",
                                phoneCountry: "CM",
                              },
                              {
                                label: "Canada",
                                value: "+1",
                                phoneCountry: "CA",
                              },
                              {
                                label: "Cape Verde",
                                value: "+238",
                                phoneCountry: "CV",
                              },
                              {
                                label: "Cayman Islands",
                                value: "+ 345",
                                phoneCountry: "KY",
                              },
                              {
                                label: "Central African Republic",
                                value: "+236",
                                phoneCountry: "CF",
                              },
                              {
                                label: "Chad",
                                value: "+235",
                                phoneCountry: "TD",
                              },
                              {
                                label: "Chile",
                                value: "+56",
                                phoneCountry: "CL",
                              },
                              {
                                label: "China",
                                value: "+86",
                                phoneCountry: "CN",
                              },
                              {
                                label: "Christmas Island",
                                value: "+61",
                                phoneCountry: "CX",
                              },
                              {
                                label: "Cocos (Keeling) Islands",
                                value: "+61",
                                phoneCountry: "CC",
                              },
                              {
                                label: "Colombia",
                                value: "+57",
                                phoneCountry: "CO",
                              },
                              {
                                label: "Comoros",
                                value: "+269",
                                phoneCountry: "KM",
                              },
                              {
                                label: "Congo",
                                value: "+242",
                                phoneCountry: "CG",
                              },
                              {
                                label:
                                  "Congo, The Democratic Republic of the Congo",
                                value: "+243",
                                phoneCountry: "CD",
                              },
                              {
                                label: "Cook Islands",
                                value: "+682",
                                phoneCountry: "CK",
                              },
                              {
                                label: "Costa Rica",
                                value: "+506",
                                phoneCountry: "CR",
                              },
                              {
                                label: "Cote d'Ivoire",
                                value: "+225",
                                phoneCountry: "CI",
                              },
                              {
                                label: "Croatia",
                                value: "+385",
                                phoneCountry: "HR",
                              },
                              {
                                label: "Cuba",
                                value: "+53",
                                phoneCountry: "CU",
                              },
                              {
                                label: "Cyprus",
                                value: "+357",
                                phoneCountry: "CY",
                              },
                              {
                                label: "Czech Republic",
                                value: "+420",
                                phoneCountry: "CZ",
                              },
                              {
                                label: "Denmark",
                                value: "+45",
                                phoneCountry: "DK",
                              },
                              {
                                label: "Djibouti",
                                value: "+253",
                                phoneCountry: "DJ",
                              },
                              {
                                label: "Dominica",
                                value: "+1767",
                                phoneCountry: "DM",
                              },
                              {
                                label: "Dominican Republic",
                                value: "+1849",
                                phoneCountry: "DO",
                              },
                              {
                                label: "Ecuador",
                                value: "+593",
                                phoneCountry: "EC",
                              },
                              {
                                label: "Egypt",
                                value: "+20",
                                phoneCountry: "EG",
                              },
                              {
                                label: "El Salvador",
                                value: "+503",
                                phoneCountry: "SV",
                              },
                              {
                                label: "Equatorial Guinea",
                                value: "+240",
                                phoneCountry: "GQ",
                              },
                              {
                                label: "Eritrea",
                                value: "+291",
                                phoneCountry: "ER",
                              },
                              {
                                label: "Estonia",
                                value: "+372",
                                phoneCountry: "EE",
                              },
                              {
                                label: "Ethiopia",
                                value: "+251",
                                phoneCountry: "ET",
                              },
                              {
                                label: "Falkland Islands (Malvinas)",
                                value: "+500",
                                phoneCountry: "FK",
                              },
                              {
                                label: "Faroe Islands",
                                value: "+298",
                                phoneCountry: "FO",
                              },
                              {
                                label: "Fiji",
                                value: "+679",
                                phoneCountry: "FJ",
                              },
                              {
                                label: "Finland",
                                value: "+358",
                                phoneCountry: "FI",
                              },
                              {
                                label: "France",
                                value: "+33",
                                phoneCountry: "FR",
                              },
                              {
                                label: "French Guiana",
                                value: "+594",
                                phoneCountry: "GF",
                              },
                              {
                                label: "French Polynesia",
                                value: "+689",
                                phoneCountry: "PF",
                              },
                              {
                                label: "Gabon",
                                value: "+241",
                                phoneCountry: "GA",
                              },
                              {
                                label: "Gambia",
                                value: "+220",
                                phoneCountry: "GM",
                              },
                              {
                                label: "Georgia",
                                value: "+995",
                                phoneCountry: "GE",
                              },
                              {
                                label: "Germany",
                                value: "+49",
                                phoneCountry: "DE",
                              },
                              {
                                label: "Ghana",
                                value: "+233",
                                phoneCountry: "GH",
                              },
                              {
                                label: "Gibraltar",
                                value: "+350",
                                phoneCountry: "GI",
                              },
                              {
                                label: "Greece",
                                value: "+30",
                                phoneCountry: "GR",
                              },
                              {
                                label: "Greenland",
                                value: "+299",
                                phoneCountry: "GL",
                              },
                              {
                                label: "Grenada",
                                value: "+1473",
                                phoneCountry: "GD",
                              },
                              {
                                label: "Guadeloupe",
                                value: "+590",
                                phoneCountry: "GP",
                              },
                              {
                                label: "Guam",
                                value: "+1671",
                                phoneCountry: "GU",
                              },
                              {
                                label: "Guatemala",
                                value: "+502",
                                phoneCountry: "GT",
                              },
                              {
                                label: "Guernsey",
                                value: "+44",
                                phoneCountry: "GG",
                              },
                              {
                                label: "Guinea",
                                value: "+224",
                                phoneCountry: "GN",
                              },
                              {
                                label: "Guinea-Bissau",
                                value: "+245",
                                phoneCountry: "GW",
                              },
                              {
                                label: "Guyana",
                                value: "+595",
                                phoneCountry: "GY",
                              },
                              {
                                label: "Haiti",
                                value: "+509",
                                phoneCountry: "HT",
                              },
                              {
                                label: "Holy See (Vatican City State)",
                                value: "+379",
                                phoneCountry: "VA",
                              },
                              {
                                label: "Honduras",
                                value: "+504",
                                phoneCountry: "HN",
                              },
                              {
                                label: "Hong Kong",
                                value: "+852",
                                phoneCountry: "HK",
                              },
                              {
                                label: "Hungary",
                                value: "+36",
                                phoneCountry: "HU",
                              },
                              {
                                label: "Iceland",
                                value: "+354",
                                phoneCountry: "IS",
                              },
                              {
                                label: "India",
                                value: "+91",
                                phoneCountry: "IN",
                              },
                              {
                                label: "Indonesia",
                                value: "+62",
                                phoneCountry: "ID",
                              },
                              {
                                label: "Iran, Islamic Republic of Persian Gulf",
                                value: "+98",
                                phoneCountry: "IR",
                              },
                              {
                                label: "Iraq",
                                value: "+964",
                                phoneCountry: "IQ",
                              },
                              {
                                label: "Ireland",
                                value: "+353",
                                phoneCountry: "IE",
                              },
                              {
                                label: "Isle of Man",
                                value: "+44",
                                phoneCountry: "IM",
                              },
                              {
                                label: "Israel",
                                value: "+972",
                                phoneCountry: "IL",
                              },
                              {
                                label: "Italy",
                                value: "+39",
                                phoneCountry: "IT",
                              },
                              {
                                label: "Jamaica",
                                value: "+1876",
                                phoneCountry: "JM",
                              },
                              {
                                label: "Japan",
                                value: "+81",
                                phoneCountry: "JP",
                              },
                              {
                                label: "Jersey",
                                value: "+44",
                                phoneCountry: "JE",
                              },
                              {
                                label: "Jordan",
                                value: "+962",
                                phoneCountry: "JO",
                              },
                              {
                                label: "Kazakhstan",
                                value: "+77",
                                phoneCountry: "KZ",
                              },
                              {
                                label: "Kenya",
                                value: "+254",
                                phoneCountry: "KE",
                              },
                              {
                                label: "Kiribati",
                                value: "+686",
                                phoneCountry: "KI",
                              },
                              {
                                label:
                                  "Korea, Democratic People's Republic of Korea",
                                value: "+850",
                                phoneCountry: "KP",
                              },
                              {
                                label: "Korea, Republic of South Korea",
                                value: "+82",
                                phoneCountry: "KR",
                              },
                              {
                                label: "Kuwait",
                                value: "+965",
                                phoneCountry: "KW",
                              },
                              {
                                label: "Kyrgyzstan",
                                value: "+996",
                                phoneCountry: "KG",
                              },
                              {
                                label: "Laos",
                                value: "+856",
                                phoneCountry: "LA",
                              },
                              {
                                label: "Latvia",
                                value: "+371",
                                phoneCountry: "LV",
                              },
                              {
                                label: "Lebanon",
                                value: "+961",
                                phoneCountry: "LB",
                              },
                              {
                                label: "Lesotho",
                                value: "+266",
                                phoneCountry: "LS",
                              },
                              {
                                label: "Liberia",
                                value: "+231",
                                phoneCountry: "LR",
                              },
                              {
                                label: "Libyan Arab Jamahiriya",
                                value: "+218",
                                phoneCountry: "LY",
                              },
                              {
                                label: "Liechtenstein",
                                value: "+423",
                                phoneCountry: "LI",
                              },
                              {
                                label: "Lithuania",
                                value: "+370",
                                phoneCountry: "LT",
                              },
                              {
                                label: "Luxembourg",
                                value: "+352",
                                phoneCountry: "LU",
                              },
                              {
                                label: "Macao",
                                value: "+853",
                                phoneCountry: "MO",
                              },
                              {
                                label: "Macedonia",
                                value: "+389",
                                phoneCountry: "MK",
                              },
                              {
                                label: "Madagascar",
                                value: "+261",
                                phoneCountry: "MG",
                              },
                              {
                                label: "Malawi",
                                value: "+265",
                                phoneCountry: "MW",
                              },
                              {
                                label: "Malaysia",
                                value: "+60",
                                phoneCountry: "MY",
                              },
                              {
                                label: "Maldives",
                                value: "+960",
                                phoneCountry: "MV",
                              },
                              {
                                label: "Mali",
                                value: "+223",
                                phoneCountry: "ML",
                              },
                              {
                                label: "Malta",
                                value: "+356",
                                phoneCountry: "MT",
                              },
                              {
                                label: "Marshall Islands",
                                value: "+692",
                                phoneCountry: "MH",
                              },
                              {
                                label: "Martinique",
                                value: "+596",
                                phoneCountry: "MQ",
                              },
                              {
                                label: "Mauritania",
                                value: "+222",
                                phoneCountry: "MR",
                              },
                              {
                                label: "Mauritius",
                                value: "+230",
                                phoneCountry: "MU",
                              },
                              {
                                label: "Mayotte",
                                value: "+262",
                                phoneCountry: "YT",
                              },
                              {
                                label: "Mexico",
                                value: "+52",
                                phoneCountry: "MX",
                              },
                              {
                                label:
                                  "Micronesia, Federated States of Micronesia",
                                value: "+691",
                                phoneCountry: "FM",
                              },
                              {
                                label: "Moldova",
                                value: "+373",
                                phoneCountry: "MD",
                              },
                              {
                                label: "Monaco",
                                value: "+377",
                                phoneCountry: "MC",
                              },
                              {
                                label: "Mongolia",
                                value: "+976",
                                phoneCountry: "MN",
                              },
                              {
                                label: "Montenegro",
                                value: "+382",
                                phoneCountry: "ME",
                              },
                              {
                                label: "Montserrat",
                                value: "+1664",
                                phoneCountry: "MS",
                              },
                              {
                                label: "Morocco",
                                value: "+212",
                                phoneCountry: "MA",
                              },
                              {
                                label: "Mozambique",
                                value: "+258",
                                phoneCountry: "MZ",
                              },
                              {
                                label: "Myanmar",
                                value: "+95",
                                phoneCountry: "MM",
                              },
                              {
                                label: "Namibia",
                                value: "+264",
                                phoneCountry: "NA",
                              },
                              {
                                label: "Nauru",
                                value: "+674",
                                phoneCountry: "NR",
                              },
                              {
                                label: "Nepal",
                                value: "+977",
                                phoneCountry: "NP",
                              },
                              {
                                label: "Netherlands",
                                value: "+31",
                                phoneCountry: "NL",
                              },
                              {
                                label: "Netherlands Antilles",
                                value: "+599",
                                phoneCountry: "AN",
                              },
                              {
                                label: "New Caledonia",
                                value: "+687",
                                phoneCountry: "NC",
                              },
                              {
                                label: "New Zealand",
                                value: "+64",
                                phoneCountry: "NZ",
                              },
                              {
                                label: "Nicaragua",
                                value: "+505",
                                phoneCountry: "NI",
                              },
                              {
                                label: "Niger",
                                value: "+227",
                                phoneCountry: "NE",
                              },
                              {
                                label: "Nigeria",
                                value: "+234",
                                phoneCountry: "NG",
                              },
                              {
                                label: "Niue",
                                value: "+683",
                                phoneCountry: "NU",
                              },
                              {
                                label: "Norfolk Island",
                                value: "+672",
                                phoneCountry: "NF",
                              },
                              {
                                label: "Northern Mariana Islands",
                                value: "+1670",
                                phoneCountry: "MP",
                              },
                              {
                                label: "Norway",
                                value: "+47",
                                phoneCountry: "NO",
                              },
                              {
                                label: "Oman",
                                value: "+968",
                                phoneCountry: "OM",
                              },
                              {
                                label: "Pakistan",
                                value: "+92",
                                phoneCountry: "PK",
                              },
                              {
                                label: "Palau",
                                value: "+680",
                                phoneCountry: "PW",
                              },
                              {
                                label: "Palestinian Territory, Occupied",
                                value: "+970",
                                phoneCountry: "PS",
                              },
                              {
                                label: "Panama",
                                value: "+507",
                                phoneCountry: "PA",
                              },
                              {
                                label: "Papua New Guinea",
                                value: "+675",
                                phoneCountry: "PG",
                              },
                              {
                                label: "Paraguay",
                                value: "+595",
                                phoneCountry: "PY",
                              },
                              {
                                label: "Peru",
                                value: "+51",
                                phoneCountry: "PE",
                              },
                              {
                                label: "Philippines",
                                value: "+63",
                                phoneCountry: "PH",
                              },
                              {
                                label: "Pitcairn",
                                value: "+872",
                                phoneCountry: "PN",
                              },
                              {
                                label: "Poland",
                                value: "+48",
                                phoneCountry: "PL",
                              },
                              {
                                label: "Portugal",
                                value: "+351",
                                phoneCountry: "PT",
                              },
                              {
                                label: "Puerto Rico",
                                value: "+1939",
                                phoneCountry: "PR",
                              },
                              {
                                label: "Qatar",
                                value: "+974",
                                phoneCountry: "QA",
                              },
                              {
                                label: "Romania",
                                value: "+40",
                                phoneCountry: "RO",
                              },
                              {
                                label: "Russia",
                                value: "+7",
                                phoneCountry: "RU",
                              },
                              {
                                label: "Rwanda",
                                value: "+250",
                                phoneCountry: "RW",
                              },
                              {
                                label: "Reunion",
                                value: "+262",
                                phoneCountry: "RE",
                              },
                              {
                                label: "Saint Barthelemy",
                                value: "+590",
                                phoneCountry: "BL",
                              },
                              {
                                label:
                                  "Saint Helena, Ascension and Tristan Da Cunha",
                                value: "+290",
                                phoneCountry: "SH",
                              },
                              {
                                label: "Saint Kitts and Nevis",
                                value: "+1869",
                                phoneCountry: "KN",
                              },
                              {
                                label: "Saint Lucia",
                                value: "+1758",
                                phoneCountry: "LC",
                              },
                              {
                                label: "Saint Martin",
                                value: "+590",
                                phoneCountry: "MF",
                              },
                              {
                                label: "Saint Pierre and Miquelon",
                                value: "+508",
                                phoneCountry: "PM",
                              },
                              {
                                label: "Saint Vincent and the Grenadines",
                                value: "+1784",
                                phoneCountry: "VC",
                              },
                              {
                                label: "Samoa",
                                value: "+685",
                                phoneCountry: "WS",
                              },
                              {
                                label: "San Marino",
                                value: "+378",
                                phoneCountry: "SM",
                              },
                              {
                                label: "Sao Tome and Principe",
                                value: "+239",
                                phoneCountry: "ST",
                              },
                              {
                                label: "Saudi Arabia",
                                value: "+966",
                                phoneCountry: "SA",
                              },
                              {
                                label: "Senegal",
                                value: "+221",
                                phoneCountry: "SN",
                              },
                              {
                                label: "Serbia",
                                value: "+381",
                                phoneCountry: "RS",
                              },
                              {
                                label: "Seychelles",
                                value: "+248",
                                phoneCountry: "SC",
                              },
                              {
                                label: "Sierra Leone",
                                value: "+232",
                                phoneCountry: "SL",
                              },
                              {
                                label: "Singapore",
                                value: "+65",
                                phoneCountry: "SG",
                              },
                              {
                                label: "Slovakia",
                                value: "+421",
                                phoneCountry: "SK",
                              },
                              {
                                label: "Slovenia",
                                value: "+386",
                                phoneCountry: "SI",
                              },
                              {
                                label: "Solomon Islands",
                                value: "+677",
                                phoneCountry: "SB",
                              },
                              {
                                label: "Somalia",
                                value: "+252",
                                phoneCountry: "SO",
                              },
                              {
                                label: "South Africa",
                                value: "+27",
                                phoneCountry: "ZA",
                              },
                              {
                                label: "South Sudan",
                                value: "+211",
                                phoneCountry: "SS",
                              },
                              {
                                label:
                                  "South Georgia and the South Sandwich Islands",
                                value: "+500",
                                phoneCountry: "GS",
                              },
                              {
                                label: "Spain",
                                value: "+34",
                                phoneCountry: "ES",
                              },
                              {
                                label: "Sri Lanka",
                                value: "+94",
                                phoneCountry: "LK",
                              },
                              {
                                label: "Sudan",
                                value: "+249",
                                phoneCountry: "SD",
                              },
                              {
                                label: "Suriname",
                                value: "+597",
                                phoneCountry: "SR",
                              },
                              {
                                label: "Svalbard and Jan Mayen",
                                value: "+47",
                                phoneCountry: "SJ",
                              },
                              {
                                label: "Swaziland",
                                value: "+268",
                                phoneCountry: "SZ",
                              },
                              {
                                label: "Sweden",
                                value: "+46",
                                phoneCountry: "SE",
                              },
                              {
                                label: "Switzerland",
                                value: "+41",
                                phoneCountry: "CH",
                              },
                              {
                                label: "Syrian Arab Republic",
                                value: "+963",
                                phoneCountry: "SY",
                              },
                              {
                                label: "Taiwan",
                                value: "+886",
                                phoneCountry: "TW",
                              },
                              {
                                label: "Tajikistan",
                                value: "+992",
                                phoneCountry: "TJ",
                              },
                              {
                                label: "Tanzania, United Republic of Tanzania",
                                value: "+255",
                                phoneCountry: "TZ",
                              },
                              {
                                label: "Thailand",
                                value: "+66",
                                phoneCountry: "TH",
                              },
                              {
                                label: "Timor-Leste",
                                value: "+670",
                                phoneCountry: "TL",
                              },
                              {
                                label: "Togo",
                                value: "+228",
                                phoneCountry: "TG",
                              },
                              {
                                label: "Tokelau",
                                value: "+690",
                                phoneCountry: "TK",
                              },
                              {
                                label: "Tonga",
                                value: "+676",
                                phoneCountry: "TO",
                              },
                              {
                                label: "Trinidad and Tobago",
                                value: "+1868",
                                phoneCountry: "TT",
                              },
                              {
                                label: "Tunisia",
                                value: "+216",
                                phoneCountry: "TN",
                              },
                              {
                                label: "Turkey",
                                value: "+90",
                                phoneCountry: "TR",
                              },
                              {
                                label: "Turkmenistan",
                                value: "+993",
                                phoneCountry: "TM",
                              },
                              {
                                label: "Turks and Caicos Islands",
                                value: "+1649",
                                phoneCountry: "TC",
                              },
                              {
                                label: "Tuvalu",
                                value: "+688",
                                phoneCountry: "TV",
                              },
                              {
                                label: "Uganda",
                                value: "+256",
                                phoneCountry: "UG",
                              },
                              {
                                label: "Ukraine",
                                value: "+380",
                                phoneCountry: "UA",
                              },
                              {
                                label: "United Arab Emirates",
                                value: "+971",
                                phoneCountry: "AE",
                              },
                              {
                                label: "United Kingdom",
                                value: "+44",
                                phoneCountry: "GB",
                              },
                              {
                                label: "United States",
                                value: "+1",
                                phoneCountry: "US",
                              },
                              {
                                label: "Uruguay",
                                value: "+598",
                                phoneCountry: "UY",
                              },
                              {
                                label: "Uzbekistan",
                                value: "+998",
                                phoneCountry: "UZ",
                              },
                              {
                                label: "Vanuatu",
                                value: "+678",
                                phoneCountry: "VU",
                              },
                              {
                                label:
                                  "Venezuela, Bolivarian Republic of Venezuela",
                                value: "+58",
                                phoneCountry: "VE",
                              },
                              {
                                label: "Vietnam",
                                value: "+84",
                                phoneCountry: "VN",
                              },
                              {
                                label: "Virgin Islands, British",
                                value: "+1284",
                                phoneCountry: "VG",
                              },
                              {
                                label: "Virgin Islands, U.S.",
                                value: "+1340",
                                phoneCountry: "VI",
                              },
                              {
                                label: "Wallis and Futuna",
                                value: "+681",
                                phoneCountry: "WF",
                              },
                              {
                                label: "Yemen",
                                value: "+967",
                                phoneCountry: "YE",
                              },
                              {
                                label: "Zambia",
                                value: "+260",
                                phoneCountry: "ZM",
                              },
                              {
                                label: "Zimbabwe",
                                value: "+263",
                                phoneCountry: "ZW",
                              },
                            ]}
                            onChange={(va) => {
                              setOrderDetail({
                                ...orderDetail,
                                invoiceAddress: {
                                  ...orderDetail.invoiceAddress,
                                  phonePrefix: va,
                                },
                              });
                              setEditInvoiceAddressPhoneCountryPopoverActive(
                                false
                              );
                            }}
                            selected={orderDetail.invoiceAddress.phonePrefix}
                          />
                        </Popover>
                      }
                      value={orderDetail.invoiceAddress.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <Modal
              open={newCustomerModalActive}
              onClose={() => {
                setNewCustomerModalActive(false);
              }}
              title="Yeni müşteri oluşturun"
              primaryAction={{
                content: "Bitti",
                onAction: () => addNewCustomer(),
                loading: newCustomerSaveButtonLoading,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setNewCustomerModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Ad"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            name: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.name}
                      label="Ad"
                    />
                    <TextField
                      placeholder="Soyad"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            surname: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.surname}
                      label="Soyad"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      label="Dil"
                      options={[
                        { value: "TR", label: "Türkçe" },
                        { value: "EN", label: "İngilizce" },
                      ]}
                      onChange={(val) => {
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          language: val,
                        });
                      }}
                      value={orderDetailNewCustomerDetails.address.countryCode}
                      helpText="Bu müşteri bildirimleri seçilen dilde alır."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="E-posta"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          email: val,
                        })
                      }
                      value={orderDetailNewCustomerDetails.email}
                      label="E-posta"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Checkbox
                      checked={
                        orderDetailContactDetailIsCustomerProfileUpdating
                      }
                      onChange={
                        setOrderDetailContactDetailIsCustomerProfileUpdating
                      }
                      label="Müşteri, e-posta ile pazarlamayı kabul ediyor"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
              <Modal.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Ülke/Bölge"
                      options={[
                        { label: "Afghanistan", value: "AF" },
                        { label: "Åland Islands", value: "AX" },
                        { label: "Albania", value: "AL" },
                        { label: "Algeria", value: "DZ" },
                        { label: "American Samoa", value: "AS" },
                        { label: "AndorrA", value: "AD" },
                        { label: "Angola", value: "AO" },
                        { label: "Anguilla", value: "AI" },
                        { label: "Antarctica", value: "AQ" },
                        { label: "Antigua and Barbuda", value: "AG" },
                        { label: "Argentina", value: "AR" },
                        { label: "Armenia", value: "AM" },
                        { label: "Aruba", value: "AW" },
                        { label: "Australia", value: "AU" },
                        { label: "Austria", value: "AT" },
                        { label: "Azerbaijan", value: "AZ" },
                        { label: "Bahamas", value: "BS" },
                        { label: "Bahrain", value: "BH" },
                        { label: "Bangladesh", value: "BD" },
                        { label: "Barbados", value: "BB" },
                        { label: "Belarus", value: "BY" },
                        { label: "Belgium", value: "BE" },
                        { label: "Belize", value: "BZ" },
                        { label: "Benin", value: "BJ" },
                        { label: "Bermuda", value: "BM" },
                        { label: "Bhutan", value: "BT" },
                        { label: "Bolivia", value: "BO" },
                        { label: "Bosnia and Herzegovina", value: "BA" },
                        { label: "Botswana", value: "BW" },
                        { label: "Bouvet Island", value: "BV" },
                        { label: "Brazil", value: "BR" },
                        {
                          label: "British Indian Ocean Territory",
                          value: "IO",
                        },
                        { label: "Brunei Darussalam", value: "BN" },
                        { label: "Bulgaria", value: "BG" },
                        { label: "Burkina Faso", value: "BF" },
                        { label: "Burundi", value: "BI" },
                        { label: "Cambodia", value: "KH" },
                        { label: "Cameroon", value: "CM" },
                        { label: "Canada", value: "CA" },
                        { label: "Cape Verde", value: "CV" },
                        { label: "Cayman Islands", value: "KY" },
                        { label: "Central African Republic", value: "CF" },
                        { label: "Chad", value: "TD" },
                        { label: "Chile", value: "CL" },
                        { label: "China", value: "CN" },
                        { label: "Christmas Island", value: "CX" },
                        { label: "Cocos (Keeling) Islands", value: "CC" },
                        { label: "Colombia", value: "CO" },
                        { label: "Comoros", value: "KM" },
                        { label: "Congo", value: "CG" },
                        {
                          label: "Congo, The Democratic Republic of the",
                          value: "CD",
                        },
                        { label: "Cook Islands", value: "CK" },
                        { label: "Costa Rica", value: "CR" },
                        { label: 'Cote D"Ivoire', value: "CI" },
                        { label: "Croatia", value: "HR" },
                        { label: "Cuba", value: "CU" },
                        { label: "Cyprus", value: "CY" },
                        { label: "Czech Republic", value: "CZ" },
                        { label: "Denmark", value: "DK" },
                        { label: "Djibouti", value: "DJ" },
                        { label: "Dominica", value: "DM" },
                        { label: "Dominican Republic", value: "DO" },
                        { label: "Ecuador", value: "EC" },
                        { label: "Egypt", value: "EG" },
                        { label: "El Salvador", value: "SV" },
                        { label: "Equatorial Guinea", value: "GQ" },
                        { label: "Eritrea", value: "ER" },
                        { label: "Estonia", value: "EE" },
                        { label: "Ethiopia", value: "ET" },
                        { label: "Falkland Islands (Malvinas)", value: "FK" },
                        { label: "Faroe Islands", value: "FO" },
                        { label: "Fiji", value: "FJ" },
                        { label: "Finland", value: "FI" },
                        { label: "France", value: "FR" },
                        { label: "French Guiana", value: "GF" },
                        { label: "French Polynesia", value: "PF" },
                        { label: "French Southern Territories", value: "TF" },
                        { label: "Gabon", value: "GA" },
                        { label: "Gambia", value: "GM" },
                        { label: "Georgia", value: "GE" },
                        { label: "Germany", value: "DE" },
                        { label: "Ghana", value: "GH" },
                        { label: "Gibraltar", value: "GI" },
                        { label: "Greece", value: "GR" },
                        { label: "Greenland", value: "GL" },
                        { label: "Grenada", value: "GD" },
                        { label: "Guadeloupe", value: "GP" },
                        { label: "Guam", value: "GU" },
                        { label: "Guatemala", value: "GT" },
                        { label: "Guernsey", value: "GG" },
                        { label: "Guinea", value: "GN" },
                        { label: "Guinea-Bissau", value: "GW" },
                        { label: "Guyana", value: "GY" },
                        { label: "Haiti", value: "HT" },
                        {
                          label: "Heard Island and Mcdonald Islands",
                          value: "HM",
                        },
                        { label: "Holy See (Vatican City State)", value: "VA" },
                        { label: "Honduras", value: "HN" },
                        { label: "Hong Kong", value: "HK" },
                        { label: "Hungary", value: "HU" },
                        { label: "Iceland", value: "IS" },
                        { label: "India", value: "IN" },
                        { label: "Indonesia", value: "ID" },
                        { label: "Iran, Islamic Republic Of", value: "IR" },
                        { label: "Iraq", value: "IQ" },
                        { label: "Ireland", value: "IE" },
                        { label: "Isle of Man", value: "IM" },
                        { label: "Israel", value: "IL" },
                        { label: "Italy", value: "IT" },
                        { label: "Jamaica", value: "JM" },
                        { label: "Japan", value: "JP" },
                        { label: "Jersey", value: "JE" },
                        { label: "Jordan", value: "JO" },
                        { label: "Kazakhstan", value: "KZ" },
                        { label: "Kenya", value: "KE" },
                        { label: "Kiribati", value: "KI" },
                        {
                          label: 'Korea, Democratic People"S Republic of',
                          value: "KP",
                        },
                        { label: "Korea, Republic of", value: "KR" },
                        { label: "Kuwait", value: "KW" },
                        { label: "Kyrgyzstan", value: "KG" },
                        {
                          label: 'Lao People"S Democratic Republic',
                          value: "LA",
                        },
                        { label: "Latvia", value: "LV" },
                        { label: "Lebanon", value: "LB" },
                        { label: "Lesotho", value: "LS" },
                        { label: "Liberia", value: "LR" },
                        { label: "Libyan Arab Jamahiriya", value: "LY" },
                        { label: "Liechtenstein", value: "LI" },
                        { label: "Lithuania", value: "LT" },
                        { label: "Luxembourg", value: "LU" },
                        { label: "Macao", value: "MO" },
                        {
                          label: "Macedonia, The Former Yugoslav Republic of",
                          value: "MK",
                        },
                        { label: "Madagascar", value: "MG" },
                        { label: "Malawi", value: "MW" },
                        { label: "Malaysia", value: "MY" },
                        { label: "Maldives", value: "MV" },
                        { label: "Mali", value: "ML" },
                        { label: "Malta", value: "MT" },
                        { label: "Marshall Islands", value: "MH" },
                        { label: "Martinique", value: "MQ" },
                        { label: "Mauritania", value: "MR" },
                        { label: "Mauritius", value: "MU" },
                        { label: "Mayotte", value: "YT" },
                        { label: "Mexico", value: "MX" },
                        {
                          label: "Micronesia, Federated States of",
                          value: "FM",
                        },
                        { label: "Moldova, Republic of", value: "MD" },
                        { label: "Monaco", value: "MC" },
                        { label: "Mongolia", value: "MN" },
                        { label: "Montserrat", value: "MS" },
                        { label: "Morocco", value: "MA" },
                        { label: "Mozambique", value: "MZ" },
                        { label: "Myanmar", value: "MM" },
                        { label: "Namibia", value: "NA" },
                        { label: "Nauru", value: "NR" },
                        { label: "Nepal", value: "NP" },
                        { label: "Netherlands", value: "NL" },
                        { label: "Netherlands Antilles", value: "AN" },
                        { label: "New Caledonia", value: "NC" },
                        { label: "New Zealand", value: "NZ" },
                        { label: "Nicaragua", value: "NI" },
                        { label: "Niger", value: "NE" },
                        { label: "Nigeria", value: "NG" },
                        { label: "Niue", value: "NU" },
                        { label: "Norfolk Island", value: "NF" },
                        { label: "Northern Mariana Islands", value: "MP" },
                        { label: "Norway", value: "NO" },
                        { label: "Oman", value: "OM" },
                        { label: "Pakistan", value: "PK" },
                        { label: "Palau", value: "PW" },
                        {
                          label: "Palestinian Territory, Occupied",
                          value: "PS",
                        },
                        { label: "Panama", value: "PA" },
                        { label: "Papua New Guinea", value: "PG" },
                        { label: "Paraguay", value: "PY" },
                        { label: "Peru", value: "PE" },
                        { label: "Philippines", value: "PH" },
                        { label: "Pitcairn", value: "PN" },
                        { label: "Poland", value: "PL" },
                        { label: "Portugal", value: "PT" },
                        { label: "Puerto Rico", value: "PR" },
                        { label: "Qatar", value: "QA" },
                        { label: "Reunion", value: "RE" },
                        { label: "Romania", value: "RO" },
                        { label: "Russian Federation", value: "RU" },
                        { label: "RWANDA", value: "RW" },
                        { label: "Saint Helena", value: "SH" },
                        { label: "Saint Kitts and Nevis", value: "KN" },
                        { label: "Saint Lucia", value: "LC" },
                        { label: "Saint Pierre and Miquelon", value: "PM" },
                        {
                          label: "Saint Vincent and the Grenadines",
                          value: "VC",
                        },
                        { label: "Samoa", value: "WS" },
                        { label: "San Marino", value: "SM" },
                        { label: "Sao Tome and Principe", value: "ST" },
                        { label: "Saudi Arabia", value: "SA" },
                        { label: "Senegal", value: "SN" },
                        { label: "Serbia and Montenegro", value: "CS" },
                        { label: "Seychelles", value: "SC" },
                        { label: "Sierra Leone", value: "SL" },
                        { label: "Singapore", value: "SG" },
                        { label: "Slovakia", value: "SK" },
                        { label: "Slovenia", value: "SI" },
                        { label: "Solomon Islands", value: "SB" },
                        { label: "Somalia", value: "SO" },
                        { label: "South Africa", value: "ZA" },
                        {
                          label: "South Georgia and the South Sandwich Islands",
                          value: "GS",
                        },
                        { label: "Spain", value: "ES" },
                        { label: "Sri Lanka", value: "LK" },
                        { label: "Sudan", value: "SD" },
                        { label: "Suriname", value: "SR" },
                        { label: "Svalbard and Jan Mayen", value: "SJ" },
                        { label: "Swaziland", value: "SZ" },
                        { label: "Sweden", value: "SE" },
                        { label: "Switzerland", value: "CH" },
                        { label: "Syrian Arab Republic", value: "SY" },
                        { label: "Taiwan, Province of China", value: "TW" },
                        { label: "Tajikistan", value: "TJ" },
                        { label: "Tanzania, United Republic of", value: "TZ" },
                        { label: "Thailand", value: "TH" },
                        { label: "Timor-Leste", value: "TL" },
                        { label: "Togo", value: "TG" },
                        { label: "Tokelau", value: "TK" },
                        { label: "Tonga", value: "TO" },
                        { label: "Trinidad and Tobago", value: "TT" },
                        { label: "Tunisia", value: "TN" },
                        { label: "Türkiye", value: "TR" },
                        { label: "Turkmenistan", value: "TM" },
                        { label: "Turks and Caicos Islands", value: "TC" },
                        { label: "Tuvalu", value: "TV" },
                        { label: "Uganda", value: "UG" },
                        { label: "Ukraine", value: "UA" },
                        { label: "United Arab Emirates", value: "AE" },
                        { label: "United Kingdom", value: "GB" },
                        { label: "United States", value: "US" },
                        {
                          label: "United States Minor Outlying Islands",
                          value: "UM",
                        },
                        { label: "Uruguay", value: "UY" },
                        { label: "Uzbekistan", value: "UZ" },
                        { label: "Vanuatu", value: "VU" },
                        { label: "Venezuela", value: "VE" },
                        { label: "Viet Nam", value: "VN" },
                        { label: "Virgin Islands, British", value: "VG" },
                        { label: "Virgin Islands, U.S.", value: "VI" },
                        { label: "Wallis and Futuna", value: "WF" },
                        { label: "Western Sahara", value: "EH" },
                        { label: "Yemen", value: "YE" },
                        { label: "Zambia", value: "ZM" },
                        { label: "Zimbabwe", value: "ZW" },
                      ]}
                      onChange={(val) => {
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            countryCode: val,
                            country: getCountryNameByCode(val),
                          },
                        });
                      }}
                      value={orderDetailNewCustomerDetails.address.countryCode}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Adres"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            addressLine: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.addressLine}
                      label="Adres"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Apartman, daire vb."
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            secondAddressLine: val,
                          },
                        })
                      }
                      value={
                        orderDetailNewCustomerDetails.address.secondAddressLine
                      }
                      label="Apartman, daire vb."
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      placeholder="Posta kodu"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            zip: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.zip}
                      label="Posta kodu"
                    />
                    <TextField
                      placeholder="Şehir"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            city: val,
                          },
                        })
                      }
                      value={orderDetailNewCustomerDetails.address.city}
                      label="Şehir"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      type="tel"
                      inputMode="tel"
                      placeholder="Telefon"
                      onChange={(val) =>
                        setOrderDetailNewCustomerDetails({
                          ...orderDetailNewCustomerDetails,
                          address: {
                            ...orderDetailNewCustomerDetails.address,
                            phone: val,
                          },
                        })
                      }
                      prefix={
                        <Text as="span" fontWeight="semibold">
                          {orderDetailNewCustomerDetails.address.phonePrefix}
                        </Text>
                      }
                      connectedRight={
                        <Popover
                          active={newCustomerPhoneCountryPopoverActive}
                          activator={
                            <Button
                              onClick={() =>
                                setNewCustomerPhoneCountryPopoverActive(
                                  (newCustomerPhoneCountryPopoverActive) =>
                                    !newCustomerPhoneCountryPopoverActive
                                )
                              }
                              disclosure
                            >
                              Ülke
                            </Button>
                          }
                        >
                          <OptionList
                            title="Ülke"
                            options={[
                              {
                                label: "Afghanistan",
                                value: "+93",
                                phoneCountry: "AF",
                              },
                              {
                                label: "Aland Islands",
                                value: "+358",
                                phoneCountry: "AX",
                              },
                              {
                                label: "Albania",
                                value: "+355",
                                phoneCountry: "AL",
                              },
                              {
                                label: "Algeria",
                                value: "+213",
                                phoneCountry: "DZ",
                              },
                              {
                                label: "AmericanSamoa",
                                value: "+1684",
                                phoneCountry: "AS",
                              },
                              {
                                label: "Andorra",
                                value: "+376",
                                phoneCountry: "AD",
                              },
                              {
                                label: "Angola",
                                value: "+244",
                                phoneCountry: "AO",
                              },
                              {
                                label: "Anguilla",
                                value: "+1264",
                                phoneCountry: "AI",
                              },
                              {
                                label: "Antarctica",
                                value: "+672",
                                phoneCountry: "AQ",
                              },
                              {
                                label: "Antigua and Barbuda",
                                value: "+1268",
                                phoneCountry: "AG",
                              },
                              {
                                label: "Argentina",
                                value: "+54",
                                phoneCountry: "AR",
                              },
                              {
                                label: "Armenia",
                                value: "+374",
                                phoneCountry: "AM",
                              },
                              {
                                label: "Aruba",
                                value: "+297",
                                phoneCountry: "AW",
                              },
                              {
                                label: "Australia",
                                value: "+61",
                                phoneCountry: "AU",
                              },
                              {
                                label: "Austria",
                                value: "+43",
                                phoneCountry: "AT",
                              },
                              {
                                label: "Azerbaijan",
                                value: "+994",
                                phoneCountry: "AZ",
                              },
                              {
                                label: "Bahamas",
                                value: "+1242",
                                phoneCountry: "BS",
                              },
                              {
                                label: "Bahrain",
                                value: "+973",
                                phoneCountry: "BH",
                              },
                              {
                                label: "Bangladesh",
                                value: "+880",
                                phoneCountry: "BD",
                              },
                              {
                                label: "Barbados",
                                value: "+1246",
                                phoneCountry: "BB",
                              },
                              {
                                label: "Belarus",
                                value: "+375",
                                phoneCountry: "BY",
                              },
                              {
                                label: "Belgium",
                                value: "+32",
                                phoneCountry: "BE",
                              },
                              {
                                label: "Belize",
                                value: "+501",
                                phoneCountry: "BZ",
                              },
                              {
                                label: "Benin",
                                value: "+229",
                                phoneCountry: "BJ",
                              },
                              {
                                label: "Bermuda",
                                value: "+1441",
                                phoneCountry: "BM",
                              },
                              {
                                label: "Bhutan",
                                value: "+975",
                                phoneCountry: "BT",
                              },
                              {
                                label: "Bolivia, Plurinational State of",
                                value: "+591",
                                phoneCountry: "BO",
                              },
                              {
                                label: "Bosnia and Herzegovina",
                                value: "+387",
                                phoneCountry: "BA",
                              },
                              {
                                label: "Botswana",
                                value: "+267",
                                phoneCountry: "BW",
                              },
                              {
                                label: "Brazil",
                                value: "+55",
                                phoneCountry: "BR",
                              },
                              {
                                label: "British Indian Ocean Territory",
                                value: "+246",
                                phoneCountry: "IO",
                              },
                              {
                                label: "Brunei Darussalam",
                                value: "+673",
                                phoneCountry: "BN",
                              },
                              {
                                label: "Bulgaria",
                                value: "+359",
                                phoneCountry: "BG",
                              },
                              {
                                label: "Burkina Faso",
                                value: "+226",
                                phoneCountry: "BF",
                              },
                              {
                                label: "Burundi",
                                value: "+257",
                                phoneCountry: "BI",
                              },
                              {
                                label: "Cambodia",
                                value: "+855",
                                phoneCountry: "KH",
                              },
                              {
                                label: "Cameroon",
                                value: "+237",
                                phoneCountry: "CM",
                              },
                              {
                                label: "Canada",
                                value: "+1",
                                phoneCountry: "CA",
                              },
                              {
                                label: "Cape Verde",
                                value: "+238",
                                phoneCountry: "CV",
                              },
                              {
                                label: "Cayman Islands",
                                value: "+ 345",
                                phoneCountry: "KY",
                              },
                              {
                                label: "Central African Republic",
                                value: "+236",
                                phoneCountry: "CF",
                              },
                              {
                                label: "Chad",
                                value: "+235",
                                phoneCountry: "TD",
                              },
                              {
                                label: "Chile",
                                value: "+56",
                                phoneCountry: "CL",
                              },
                              {
                                label: "China",
                                value: "+86",
                                phoneCountry: "CN",
                              },
                              {
                                label: "Christmas Island",
                                value: "+61",
                                phoneCountry: "CX",
                              },
                              {
                                label: "Cocos (Keeling) Islands",
                                value: "+61",
                                phoneCountry: "CC",
                              },
                              {
                                label: "Colombia",
                                value: "+57",
                                phoneCountry: "CO",
                              },
                              {
                                label: "Comoros",
                                value: "+269",
                                phoneCountry: "KM",
                              },
                              {
                                label: "Congo",
                                value: "+242",
                                phoneCountry: "CG",
                              },
                              {
                                label:
                                  "Congo, The Democratic Republic of the Congo",
                                value: "+243",
                                phoneCountry: "CD",
                              },
                              {
                                label: "Cook Islands",
                                value: "+682",
                                phoneCountry: "CK",
                              },
                              {
                                label: "Costa Rica",
                                value: "+506",
                                phoneCountry: "CR",
                              },
                              {
                                label: "Cote d'Ivoire",
                                value: "+225",
                                phoneCountry: "CI",
                              },
                              {
                                label: "Croatia",
                                value: "+385",
                                phoneCountry: "HR",
                              },
                              {
                                label: "Cuba",
                                value: "+53",
                                phoneCountry: "CU",
                              },
                              {
                                label: "Cyprus",
                                value: "+357",
                                phoneCountry: "CY",
                              },
                              {
                                label: "Czech Republic",
                                value: "+420",
                                phoneCountry: "CZ",
                              },
                              {
                                label: "Denmark",
                                value: "+45",
                                phoneCountry: "DK",
                              },
                              {
                                label: "Djibouti",
                                value: "+253",
                                phoneCountry: "DJ",
                              },
                              {
                                label: "Dominica",
                                value: "+1767",
                                phoneCountry: "DM",
                              },
                              {
                                label: "Dominican Republic",
                                value: "+1849",
                                phoneCountry: "DO",
                              },
                              {
                                label: "Ecuador",
                                value: "+593",
                                phoneCountry: "EC",
                              },
                              {
                                label: "Egypt",
                                value: "+20",
                                phoneCountry: "EG",
                              },
                              {
                                label: "El Salvador",
                                value: "+503",
                                phoneCountry: "SV",
                              },
                              {
                                label: "Equatorial Guinea",
                                value: "+240",
                                phoneCountry: "GQ",
                              },
                              {
                                label: "Eritrea",
                                value: "+291",
                                phoneCountry: "ER",
                              },
                              {
                                label: "Estonia",
                                value: "+372",
                                phoneCountry: "EE",
                              },
                              {
                                label: "Ethiopia",
                                value: "+251",
                                phoneCountry: "ET",
                              },
                              {
                                label: "Falkland Islands (Malvinas)",
                                value: "+500",
                                phoneCountry: "FK",
                              },
                              {
                                label: "Faroe Islands",
                                value: "+298",
                                phoneCountry: "FO",
                              },
                              {
                                label: "Fiji",
                                value: "+679",
                                phoneCountry: "FJ",
                              },
                              {
                                label: "Finland",
                                value: "+358",
                                phoneCountry: "FI",
                              },
                              {
                                label: "France",
                                value: "+33",
                                phoneCountry: "FR",
                              },
                              {
                                label: "French Guiana",
                                value: "+594",
                                phoneCountry: "GF",
                              },
                              {
                                label: "French Polynesia",
                                value: "+689",
                                phoneCountry: "PF",
                              },
                              {
                                label: "Gabon",
                                value: "+241",
                                phoneCountry: "GA",
                              },
                              {
                                label: "Gambia",
                                value: "+220",
                                phoneCountry: "GM",
                              },
                              {
                                label: "Georgia",
                                value: "+995",
                                phoneCountry: "GE",
                              },
                              {
                                label: "Germany",
                                value: "+49",
                                phoneCountry: "DE",
                              },
                              {
                                label: "Ghana",
                                value: "+233",
                                phoneCountry: "GH",
                              },
                              {
                                label: "Gibraltar",
                                value: "+350",
                                phoneCountry: "GI",
                              },
                              {
                                label: "Greece",
                                value: "+30",
                                phoneCountry: "GR",
                              },
                              {
                                label: "Greenland",
                                value: "+299",
                                phoneCountry: "GL",
                              },
                              {
                                label: "Grenada",
                                value: "+1473",
                                phoneCountry: "GD",
                              },
                              {
                                label: "Guadeloupe",
                                value: "+590",
                                phoneCountry: "GP",
                              },
                              {
                                label: "Guam",
                                value: "+1671",
                                phoneCountry: "GU",
                              },
                              {
                                label: "Guatemala",
                                value: "+502",
                                phoneCountry: "GT",
                              },
                              {
                                label: "Guernsey",
                                value: "+44",
                                phoneCountry: "GG",
                              },
                              {
                                label: "Guinea",
                                value: "+224",
                                phoneCountry: "GN",
                              },
                              {
                                label: "Guinea-Bissau",
                                value: "+245",
                                phoneCountry: "GW",
                              },
                              {
                                label: "Guyana",
                                value: "+595",
                                phoneCountry: "GY",
                              },
                              {
                                label: "Haiti",
                                value: "+509",
                                phoneCountry: "HT",
                              },
                              {
                                label: "Holy See (Vatican City State)",
                                value: "+379",
                                phoneCountry: "VA",
                              },
                              {
                                label: "Honduras",
                                value: "+504",
                                phoneCountry: "HN",
                              },
                              {
                                label: "Hong Kong",
                                value: "+852",
                                phoneCountry: "HK",
                              },
                              {
                                label: "Hungary",
                                value: "+36",
                                phoneCountry: "HU",
                              },
                              {
                                label: "Iceland",
                                value: "+354",
                                phoneCountry: "IS",
                              },
                              {
                                label: "India",
                                value: "+91",
                                phoneCountry: "IN",
                              },
                              {
                                label: "Indonesia",
                                value: "+62",
                                phoneCountry: "ID",
                              },
                              {
                                label: "Iran, Islamic Republic of Persian Gulf",
                                value: "+98",
                                phoneCountry: "IR",
                              },
                              {
                                label: "Iraq",
                                value: "+964",
                                phoneCountry: "IQ",
                              },
                              {
                                label: "Ireland",
                                value: "+353",
                                phoneCountry: "IE",
                              },
                              {
                                label: "Isle of Man",
                                value: "+44",
                                phoneCountry: "IM",
                              },
                              {
                                label: "Israel",
                                value: "+972",
                                phoneCountry: "IL",
                              },
                              {
                                label: "Italy",
                                value: "+39",
                                phoneCountry: "IT",
                              },
                              {
                                label: "Jamaica",
                                value: "+1876",
                                phoneCountry: "JM",
                              },
                              {
                                label: "Japan",
                                value: "+81",
                                phoneCountry: "JP",
                              },
                              {
                                label: "Jersey",
                                value: "+44",
                                phoneCountry: "JE",
                              },
                              {
                                label: "Jordan",
                                value: "+962",
                                phoneCountry: "JO",
                              },
                              {
                                label: "Kazakhstan",
                                value: "+77",
                                phoneCountry: "KZ",
                              },
                              {
                                label: "Kenya",
                                value: "+254",
                                phoneCountry: "KE",
                              },
                              {
                                label: "Kiribati",
                                value: "+686",
                                phoneCountry: "KI",
                              },
                              {
                                label:
                                  "Korea, Democratic People's Republic of Korea",
                                value: "+850",
                                phoneCountry: "KP",
                              },
                              {
                                label: "Korea, Republic of South Korea",
                                value: "+82",
                                phoneCountry: "KR",
                              },
                              {
                                label: "Kuwait",
                                value: "+965",
                                phoneCountry: "KW",
                              },
                              {
                                label: "Kyrgyzstan",
                                value: "+996",
                                phoneCountry: "KG",
                              },
                              {
                                label: "Laos",
                                value: "+856",
                                phoneCountry: "LA",
                              },
                              {
                                label: "Latvia",
                                value: "+371",
                                phoneCountry: "LV",
                              },
                              {
                                label: "Lebanon",
                                value: "+961",
                                phoneCountry: "LB",
                              },
                              {
                                label: "Lesotho",
                                value: "+266",
                                phoneCountry: "LS",
                              },
                              {
                                label: "Liberia",
                                value: "+231",
                                phoneCountry: "LR",
                              },
                              {
                                label: "Libyan Arab Jamahiriya",
                                value: "+218",
                                phoneCountry: "LY",
                              },
                              {
                                label: "Liechtenstein",
                                value: "+423",
                                phoneCountry: "LI",
                              },
                              {
                                label: "Lithuania",
                                value: "+370",
                                phoneCountry: "LT",
                              },
                              {
                                label: "Luxembourg",
                                value: "+352",
                                phoneCountry: "LU",
                              },
                              {
                                label: "Macao",
                                value: "+853",
                                phoneCountry: "MO",
                              },
                              {
                                label: "Macedonia",
                                value: "+389",
                                phoneCountry: "MK",
                              },
                              {
                                label: "Madagascar",
                                value: "+261",
                                phoneCountry: "MG",
                              },
                              {
                                label: "Malawi",
                                value: "+265",
                                phoneCountry: "MW",
                              },
                              {
                                label: "Malaysia",
                                value: "+60",
                                phoneCountry: "MY",
                              },
                              {
                                label: "Maldives",
                                value: "+960",
                                phoneCountry: "MV",
                              },
                              {
                                label: "Mali",
                                value: "+223",
                                phoneCountry: "ML",
                              },
                              {
                                label: "Malta",
                                value: "+356",
                                phoneCountry: "MT",
                              },
                              {
                                label: "Marshall Islands",
                                value: "+692",
                                phoneCountry: "MH",
                              },
                              {
                                label: "Martinique",
                                value: "+596",
                                phoneCountry: "MQ",
                              },
                              {
                                label: "Mauritania",
                                value: "+222",
                                phoneCountry: "MR",
                              },
                              {
                                label: "Mauritius",
                                value: "+230",
                                phoneCountry: "MU",
                              },
                              {
                                label: "Mayotte",
                                value: "+262",
                                phoneCountry: "YT",
                              },
                              {
                                label: "Mexico",
                                value: "+52",
                                phoneCountry: "MX",
                              },
                              {
                                label:
                                  "Micronesia, Federated States of Micronesia",
                                value: "+691",
                                phoneCountry: "FM",
                              },
                              {
                                label: "Moldova",
                                value: "+373",
                                phoneCountry: "MD",
                              },
                              {
                                label: "Monaco",
                                value: "+377",
                                phoneCountry: "MC",
                              },
                              {
                                label: "Mongolia",
                                value: "+976",
                                phoneCountry: "MN",
                              },
                              {
                                label: "Montenegro",
                                value: "+382",
                                phoneCountry: "ME",
                              },
                              {
                                label: "Montserrat",
                                value: "+1664",
                                phoneCountry: "MS",
                              },
                              {
                                label: "Morocco",
                                value: "+212",
                                phoneCountry: "MA",
                              },
                              {
                                label: "Mozambique",
                                value: "+258",
                                phoneCountry: "MZ",
                              },
                              {
                                label: "Myanmar",
                                value: "+95",
                                phoneCountry: "MM",
                              },
                              {
                                label: "Namibia",
                                value: "+264",
                                phoneCountry: "NA",
                              },
                              {
                                label: "Nauru",
                                value: "+674",
                                phoneCountry: "NR",
                              },
                              {
                                label: "Nepal",
                                value: "+977",
                                phoneCountry: "NP",
                              },
                              {
                                label: "Netherlands",
                                value: "+31",
                                phoneCountry: "NL",
                              },
                              {
                                label: "Netherlands Antilles",
                                value: "+599",
                                phoneCountry: "AN",
                              },
                              {
                                label: "New Caledonia",
                                value: "+687",
                                phoneCountry: "NC",
                              },
                              {
                                label: "New Zealand",
                                value: "+64",
                                phoneCountry: "NZ",
                              },
                              {
                                label: "Nicaragua",
                                value: "+505",
                                phoneCountry: "NI",
                              },
                              {
                                label: "Niger",
                                value: "+227",
                                phoneCountry: "NE",
                              },
                              {
                                label: "Nigeria",
                                value: "+234",
                                phoneCountry: "NG",
                              },
                              {
                                label: "Niue",
                                value: "+683",
                                phoneCountry: "NU",
                              },
                              {
                                label: "Norfolk Island",
                                value: "+672",
                                phoneCountry: "NF",
                              },
                              {
                                label: "Northern Mariana Islands",
                                value: "+1670",
                                phoneCountry: "MP",
                              },
                              {
                                label: "Norway",
                                value: "+47",
                                phoneCountry: "NO",
                              },
                              {
                                label: "Oman",
                                value: "+968",
                                phoneCountry: "OM",
                              },
                              {
                                label: "Pakistan",
                                value: "+92",
                                phoneCountry: "PK",
                              },
                              {
                                label: "Palau",
                                value: "+680",
                                phoneCountry: "PW",
                              },
                              {
                                label: "Palestinian Territory, Occupied",
                                value: "+970",
                                phoneCountry: "PS",
                              },
                              {
                                label: "Panama",
                                value: "+507",
                                phoneCountry: "PA",
                              },
                              {
                                label: "Papua New Guinea",
                                value: "+675",
                                phoneCountry: "PG",
                              },
                              {
                                label: "Paraguay",
                                value: "+595",
                                phoneCountry: "PY",
                              },
                              {
                                label: "Peru",
                                value: "+51",
                                phoneCountry: "PE",
                              },
                              {
                                label: "Philippines",
                                value: "+63",
                                phoneCountry: "PH",
                              },
                              {
                                label: "Pitcairn",
                                value: "+872",
                                phoneCountry: "PN",
                              },
                              {
                                label: "Poland",
                                value: "+48",
                                phoneCountry: "PL",
                              },
                              {
                                label: "Portugal",
                                value: "+351",
                                phoneCountry: "PT",
                              },
                              {
                                label: "Puerto Rico",
                                value: "+1939",
                                phoneCountry: "PR",
                              },
                              {
                                label: "Qatar",
                                value: "+974",
                                phoneCountry: "QA",
                              },
                              {
                                label: "Romania",
                                value: "+40",
                                phoneCountry: "RO",
                              },
                              {
                                label: "Russia",
                                value: "+7",
                                phoneCountry: "RU",
                              },
                              {
                                label: "Rwanda",
                                value: "+250",
                                phoneCountry: "RW",
                              },
                              {
                                label: "Reunion",
                                value: "+262",
                                phoneCountry: "RE",
                              },
                              {
                                label: "Saint Barthelemy",
                                value: "+590",
                                phoneCountry: "BL",
                              },
                              {
                                label:
                                  "Saint Helena, Ascension and Tristan Da Cunha",
                                value: "+290",
                                phoneCountry: "SH",
                              },
                              {
                                label: "Saint Kitts and Nevis",
                                value: "+1869",
                                phoneCountry: "KN",
                              },
                              {
                                label: "Saint Lucia",
                                value: "+1758",
                                phoneCountry: "LC",
                              },
                              {
                                label: "Saint Martin",
                                value: "+590",
                                phoneCountry: "MF",
                              },
                              {
                                label: "Saint Pierre and Miquelon",
                                value: "+508",
                                phoneCountry: "PM",
                              },
                              {
                                label: "Saint Vincent and the Grenadines",
                                value: "+1784",
                                phoneCountry: "VC",
                              },
                              {
                                label: "Samoa",
                                value: "+685",
                                phoneCountry: "WS",
                              },
                              {
                                label: "San Marino",
                                value: "+378",
                                phoneCountry: "SM",
                              },
                              {
                                label: "Sao Tome and Principe",
                                value: "+239",
                                phoneCountry: "ST",
                              },
                              {
                                label: "Saudi Arabia",
                                value: "+966",
                                phoneCountry: "SA",
                              },
                              {
                                label: "Senegal",
                                value: "+221",
                                phoneCountry: "SN",
                              },
                              {
                                label: "Serbia",
                                value: "+381",
                                phoneCountry: "RS",
                              },
                              {
                                label: "Seychelles",
                                value: "+248",
                                phoneCountry: "SC",
                              },
                              {
                                label: "Sierra Leone",
                                value: "+232",
                                phoneCountry: "SL",
                              },
                              {
                                label: "Singapore",
                                value: "+65",
                                phoneCountry: "SG",
                              },
                              {
                                label: "Slovakia",
                                value: "+421",
                                phoneCountry: "SK",
                              },
                              {
                                label: "Slovenia",
                                value: "+386",
                                phoneCountry: "SI",
                              },
                              {
                                label: "Solomon Islands",
                                value: "+677",
                                phoneCountry: "SB",
                              },
                              {
                                label: "Somalia",
                                value: "+252",
                                phoneCountry: "SO",
                              },
                              {
                                label: "South Africa",
                                value: "+27",
                                phoneCountry: "ZA",
                              },
                              {
                                label: "South Sudan",
                                value: "+211",
                                phoneCountry: "SS",
                              },
                              {
                                label:
                                  "South Georgia and the South Sandwich Islands",
                                value: "+500",
                                phoneCountry: "GS",
                              },
                              {
                                label: "Spain",
                                value: "+34",
                                phoneCountry: "ES",
                              },
                              {
                                label: "Sri Lanka",
                                value: "+94",
                                phoneCountry: "LK",
                              },
                              {
                                label: "Sudan",
                                value: "+249",
                                phoneCountry: "SD",
                              },
                              {
                                label: "Suriname",
                                value: "+597",
                                phoneCountry: "SR",
                              },
                              {
                                label: "Svalbard and Jan Mayen",
                                value: "+47",
                                phoneCountry: "SJ",
                              },
                              {
                                label: "Swaziland",
                                value: "+268",
                                phoneCountry: "SZ",
                              },
                              {
                                label: "Sweden",
                                value: "+46",
                                phoneCountry: "SE",
                              },
                              {
                                label: "Switzerland",
                                value: "+41",
                                phoneCountry: "CH",
                              },
                              {
                                label: "Syrian Arab Republic",
                                value: "+963",
                                phoneCountry: "SY",
                              },
                              {
                                label: "Taiwan",
                                value: "+886",
                                phoneCountry: "TW",
                              },
                              {
                                label: "Tajikistan",
                                value: "+992",
                                phoneCountry: "TJ",
                              },
                              {
                                label: "Tanzania, United Republic of Tanzania",
                                value: "+255",
                                phoneCountry: "TZ",
                              },
                              {
                                label: "Thailand",
                                value: "+66",
                                phoneCountry: "TH",
                              },
                              {
                                label: "Timor-Leste",
                                value: "+670",
                                phoneCountry: "TL",
                              },
                              {
                                label: "Togo",
                                value: "+228",
                                phoneCountry: "TG",
                              },
                              {
                                label: "Tokelau",
                                value: "+690",
                                phoneCountry: "TK",
                              },
                              {
                                label: "Tonga",
                                value: "+676",
                                phoneCountry: "TO",
                              },
                              {
                                label: "Trinidad and Tobago",
                                value: "+1868",
                                phoneCountry: "TT",
                              },
                              {
                                label: "Tunisia",
                                value: "+216",
                                phoneCountry: "TN",
                              },
                              {
                                label: "Turkey",
                                value: "+90",
                                phoneCountry: "TR",
                              },
                              {
                                label: "Turkmenistan",
                                value: "+993",
                                phoneCountry: "TM",
                              },
                              {
                                label: "Turks and Caicos Islands",
                                value: "+1649",
                                phoneCountry: "TC",
                              },
                              {
                                label: "Tuvalu",
                                value: "+688",
                                phoneCountry: "TV",
                              },
                              {
                                label: "Uganda",
                                value: "+256",
                                phoneCountry: "UG",
                              },
                              {
                                label: "Ukraine",
                                value: "+380",
                                phoneCountry: "UA",
                              },
                              {
                                label: "United Arab Emirates",
                                value: "+971",
                                phoneCountry: "AE",
                              },
                              {
                                label: "United Kingdom",
                                value: "+44",
                                phoneCountry: "GB",
                              },
                              {
                                label: "United States",
                                value: "+1",
                                phoneCountry: "US",
                              },
                              {
                                label: "Uruguay",
                                value: "+598",
                                phoneCountry: "UY",
                              },
                              {
                                label: "Uzbekistan",
                                value: "+998",
                                phoneCountry: "UZ",
                              },
                              {
                                label: "Vanuatu",
                                value: "+678",
                                phoneCountry: "VU",
                              },
                              {
                                label:
                                  "Venezuela, Bolivarian Republic of Venezuela",
                                value: "+58",
                                phoneCountry: "VE",
                              },
                              {
                                label: "Vietnam",
                                value: "+84",
                                phoneCountry: "VN",
                              },
                              {
                                label: "Virgin Islands, British",
                                value: "+1284",
                                phoneCountry: "VG",
                              },
                              {
                                label: "Virgin Islands, U.S.",
                                value: "+1340",
                                phoneCountry: "VI",
                              },
                              {
                                label: "Wallis and Futuna",
                                value: "+681",
                                phoneCountry: "WF",
                              },
                              {
                                label: "Yemen",
                                value: "+967",
                                phoneCountry: "YE",
                              },
                              {
                                label: "Zambia",
                                value: "+260",
                                phoneCountry: "ZM",
                              },
                              {
                                label: "Zimbabwe",
                                value: "+263",
                                phoneCountry: "ZW",
                              },
                            ]}
                            onChange={(va) => {
                              setOrderDetailNewCustomerDetails({
                                ...orderDetailNewCustomerDetails,
                                address: {
                                  ...orderDetailNewCustomerDetails.address,
                                  phonePrefix: va,
                                },
                              });
                              setNewCustomerPhoneCountryPopoverActive(false);
                            }}
                            selected={
                              orderDetailNewCustomerDetails.address.phonePrefix
                            }
                          />
                        </Popover>
                      }
                      value={orderDetailNewCustomerDetails.address.phone}
                      label="Telefon"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Section>
            </Modal>
            <LegacyCard.Section>
              {orderDetail.customerHash === "" ? (
                <Combobox
                  activator={
                    <Combobox.TextField
                      autoComplete="off"
                      onFocus={getCustomers}
                      prefix={<Icon source={SearchMinor} />}
                      onChange={(value) => {
                        setInputValue(value);
                        if (value === "") {
                          setCustomers(customers);
                          return;
                        }
                        const filterRegex = new RegExp(value, "i");
                        const resultOptions = customers.filter((option) =>
                          option.label.match(filterRegex)
                        );
                        setCustomers(resultOptions);
                      }}
                      value={inputValue}
                      placeholder="Müşteri bulun veya oluşturun"
                    />
                  }
                >
                  <Listbox
                    onSelect={(selected) => {
                      if (selected === "addNew") {
                        setNewCustomerModalActive(true);
                      } else {
                        getCustomerDetailAndAssignIt(selected);
                      }
                    }}
                  >
                    <Listbox.Section>
                      <Listbox.Action key={"addNew"} value={"addNew"}>
                        <LegacyStack spacing="tight">
                          <Icon source={CirclePlusMinor} color="base" />
                          <div>Yeni müşteri oluşturun</div>
                        </LegacyStack>
                      </Listbox.Action>
                    </Listbox.Section>
                    {orderAddCustomerLoading && <Listbox.Loading />}
                    {customers.length > 0 &&
                      customers.map((option) => {
                        const { secure_hash, name, surname, email } = option;
                        return (
                          <Listbox.Action
                            value={secure_hash}
                            key={`${secure_hash}`}
                          >
                            <LegacyStack vertical spacing="extraTight">
                              <p>
                                <Text as="span">{name + " " + surname}</Text>
                              </p>
                              <p>
                                <Text as="span" color="subdued">
                                  {email}
                                </Text>
                              </p>
                            </LegacyStack>
                          </Listbox.Action>
                        );
                      })}
                  </Listbox>
                </Combobox>
              ) : (
                <Text as="span" color="subdued">
                  {orderDetail.customer}
                </Text>
              )}
            </LegacyCard.Section>
            {orderDetail.customerHash !== "" ? (
              <div>
                <LegacyCard.Section title="İLETİŞİM BİLGİLERİ">
                  <LegacyStack spacing="tight">
                    <LegacyStack vertical spacing="extraTight">
                      {orderDetail.email === "" ||
                      orderDetail.email === null ? (
                        <Text as="span" color="subdued">
                          E-Posta adresi yok
                        </Text>
                      ) : (
                        <Button plain>{orderDetail.email}</Button>
                      )}
                      {orderDetail.phone === "" ||
                      orderDetail.phone === null ? (
                        <Text as="span" color="subdued">
                          Telefon numarası yok
                        </Text>
                      ) : (
                        <Button plain>{orderDetail.phone}</Button>
                      )}
                    </LegacyStack>
                  </LegacyStack>
                </LegacyCard.Section>
                <LegacyCard.Section title="KARGO ADRESİ">
                  {orderDetail.shippingAddress["name"] +
                    " " +
                    orderDetail.shippingAddress["surname"]}
                  <br />
                  {orderDetail.shippingAddress["addressLine"]}
                  <br />
                  {orderDetail.shippingAddress["secondAddressLine"] !== null &&
                  orderDetail.shippingAddress["secondAddressLine"] !== "" ? (
                    <div>
                      {orderDetail.shippingAddress["secondAddressLine"]}
                      <br />
                    </div>
                  ) : null}
                  {orderDetail.shippingAddress["zip"]},{" "}
                  {orderDetail.shippingAddress["province"]}
                  <br />
                  {orderDetail.shippingAddress["city"]},{" "}
                  {orderDetail.shippingAddress["country"]}
                  <br />
                  {orderDetail.shippingAddress["phonePrefix"]}
                  {orderDetail.shippingAddress["phone"]}
                </LegacyCard.Section>
                <LegacyCard.Section title="FATURA ADRESİ">
                  {orderDetail.invoiceAddress["name"] +
                    " " +
                    orderDetail.invoiceAddress["surname"]}
                  <br />
                  {orderDetail.invoiceAddress["addressLine"]}
                  <br />
                  {orderDetail.invoiceAddress["secondAddressLine"] !== null &&
                  orderDetail.invoiceAddress["secondAddressLine"] !== "" ? (
                    <div>
                      {orderDetail.invoiceAddress["secondAddressLine"]}
                      <br />
                    </div>
                  ) : null}
                  {orderDetail.invoiceAddress["zip"]},{" "}
                  {orderDetail.invoiceAddress["province"]}
                  <br />
                  {orderDetail.invoiceAddress["city"]},{" "}
                  {orderDetail.invoiceAddress["country"]}
                  <br />
                  {orderDetail.invoiceAddress["phonePrefix"]}
                  {orderDetail.invoiceAddress["phone"]}
                </LegacyCard.Section>
              </div>
            ) : null}
          </LegacyCard>
          {!orderDetail.completed && (
            <LegacyCard title="Notlar" sectioned>
              <div style={{ wordWrap: "break-word" }}>
                <Text as="span">
                  <TextField
                    multiline
                    value={orderDetail.note}
                    onChange={(val) =>
                      setOrderDetail({ ...orderDetail, note: val })
                    }
                  />
                </Text>
              </div>
            </LegacyCard>
          )}
          <LegacyCard title="Etiketler" sectioned subdued>
            <LegacyStack vertical spacing="tight">
              <Combobox
                activator={
                  <Combobox.TextField
                    autoComplete="off"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(value) => {
                      setTagsAddInputValue(value);
                    }}
                    value={tagsAddInputValue}
                    placeholder="Etiket bulun veya oluşturun"
                  />
                }
              >
                <Listbox
                  onSelect={() => {
                    if (
                      !orderDetail.tags.includes(tagsAddInputValue) &&
                      tagsAddInputValue !== null &&
                      tagsAddInputValue !== ""
                    ) {
                      setOrderDetail({
                        ...orderDetail,
                        tags: [...orderDetail.tags, tagsAddInputValue],
                      });
                      setTagsAddInputValue("");
                    }
                  }}
                >
                  <Listbox.Action key={"addNew"} value={"addNew"}>
                    <LegacyStack spacing="tight">
                      <Icon source={CirclePlusMinor} color="base" />
                      <div>Etiketi ekle</div>
                    </LegacyStack>
                  </Listbox.Action>
                </Listbox>
              </Combobox>
              <LegacyStack spacing="tight">
                {orderDetail.tags.length > 0 &&
                  orderDetail.tags.map((option) => (
                    <Tag
                      key={`${option}`}
                      onRemove={() => {
                        var array = [...orderDetail.tags];
                        var index = array.indexOf(option);
                        if (index !== -1) {
                          array.splice(index, 1);
                          setOrderDetail({ ...orderDetail, tags: array });
                        }
                      }}
                    >
                      {option}
                    </Tag>
                  ))}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      {ordersBulkToastActive ? (
        <Toast
          onDismiss={ordersBulkActionToastToggle}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  async function createDraftOrder(type) {
    setSavePaymentButtonLoading(true);
    const result = await fetcher({
      endpoint: "createDraftOrder.php",
      method: "POST",
      body: JSON.stringify({
        orderDetail: orderDetail,
        status: type,
      }),
    });
    if (!result.fetchError) {
      if (type === 0) {
        navigate("/orders/drafts/" + result.createdOrderSecure, {
          replace: true,
        });
      } else if (1) {
        navigate("/orders/" + result.createdOrderSecure, { replace: true });
      } else {
        navigate("/orders/drafts/" + result.createdOrderSecure, {
          replace: true,
        });
      }
    } else {
      setSavePaymentButtonLoading(false);
    }
  }

  async function searchProducts(q) {
    if (!addNewProductsSearchLoading) {
      setAddNewProductsSearchLoading(true);
    }
    setAddNewProductsSearchedProducts([]);
    const result = await fetcher({
      endpoint: "getQuickProducts.php?status=All&type=nonVariantable&q=" + q,
      method: "GET",
    });
    if (!result.fetchError) {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts(result);
    } else {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts([]);
    }
  }

  function addProducts() {
    setAddNewProductsSaveButtonLoading(true);
    var array = [];
    var alreadyAddedProducts = [];
    orderDetail.items.forEach((e) => {
      alreadyAddedProducts.push(e.secure);
    });
    orderDetailSelectedProductItems.forEach(async (e) => {
      if (!alreadyAddedProducts.includes(e)) {
        const result = await fetcher({
          endpoint: "getProductDetails.php",
          method: "POST",
          body: JSON.stringify({
            secure: e,
          }),
        });
        if (!result.fetchError) {
          array.push({
            secure: result.secure_hash,
            name: result.product_title,
            quantity: 1,
            price: result.product_discount_price,
          });
          var total = 0;
          array.forEach((e) => {
            total = total + e.price * e.quantity;
          });
          setOrderDetail({
            ...orderDetail,
            items: array,
            total: total,
          });
        } else {
          setAddNewProductsSaveButtonLoading(false);
        }
      }
    });
    setOrderDetailSelectedProductItems([]);
    setAddNewProductsModalActive(false);
    setAddNewProductsSaveButtonLoading(false);
  }

  function deleteProducts(p) {
    var parsedArray = [];
    orderDetail.items.forEach((e) => {
      if (e.secure !== p) {
        parsedArray.push(e);
      }
    });
    var total = 0;
    parsedArray.forEach((e) => {
      total = total + e.price * e.quantity;
    });
    setOrderDetail({ ...orderDetail, items: parsedArray, total: total });
  }

  function getCountryNameByCode(code) {
    const countryList = {
      AF: { label: "Afghanistan", value: "AF" },
      AX: { label: "Åland Islands", value: "AX" },
      AL: { label: "Albania", value: "AL" },
      DZ: { label: "Algeria", value: "DZ" },
      AS: { label: "American Samoa", value: "AS" },
      AD: { label: "AndorrA", value: "AD" },
      AO: { label: "Angola", value: "AO" },
      AI: { label: "Anguilla", value: "AI" },
      AQ: { label: "Antarctica", value: "AQ" },
      AG: { label: "Antigua and Barbuda", value: "AG" },
      AR: { label: "Argentina", value: "AR" },
      AM: { label: "Armenia", value: "AM" },
      AW: { label: "Aruba", value: "AW" },
      AU: { label: "Australia", value: "AU" },
      AT: { label: "Austria", value: "AT" },
      AZ: { label: "Azerbaijan", value: "AZ" },
      BS: { label: "Bahamas", value: "BS" },
      BH: { label: "Bahrain", value: "BH" },
      BD: { label: "Bangladesh", value: "BD" },
      BB: { label: "Barbados", value: "BB" },
      BY: { label: "Belarus", value: "BY" },
      BE: { label: "Belgium", value: "BE" },
      BZ: { label: "Belize", value: "BZ" },
      BJ: { label: "Benin", value: "BJ" },
      BM: { label: "Bermuda", value: "BM" },
      BT: { label: "Bhutan", value: "BT" },
      BO: { label: "Bolivia", value: "BO" },
      BA: { label: "Bosnia and Herzegovina", value: "BA" },
      BW: { label: "Botswana", value: "BW" },
      BV: { label: "Bouvet Island", value: "BV" },
      BR: { label: "Brazil", value: "BR" },
      IO: { label: "British Indian Ocean Territory", value: "IO" },
      BN: { label: "Brunei Darussalam", value: "BN" },
      BG: { label: "Bulgaria", value: "BG" },
      BF: { label: "Burkina Faso", value: "BF" },
      BI: { label: "Burundi", value: "BI" },
      KH: { label: "Cambodia", value: "KH" },
      CM: { label: "Cameroon", value: "CM" },
      CA: { label: "Canada", value: "CA" },
      CV: { label: "Cape Verde", value: "CV" },
      KY: { label: "Cayman Islands", value: "KY" },
      CF: { label: "Central African Republic", value: "CF" },
      TD: { label: "Chad", value: "TD" },
      CL: { label: "Chile", value: "CL" },
      CN: { label: "China", value: "CN" },
      CX: { label: "Christmas Island", value: "CX" },
      CC: { label: "Cocos (Keeling) Islands", value: "CC" },
      CO: { label: "Colombia", value: "CO" },
      KM: { label: "Comoros", value: "KM" },
      CG: { label: "Congo", value: "CG" },
      CD: { label: "Congo, The Democratic Republic of the", value: "CD" },
      CK: { label: "Cook Islands", value: "CK" },
      CR: { label: "Costa Rica", value: "CR" },
      CI: { label: 'Cote D"Ivoire', value: "CI" },
      HR: { label: "Croatia", value: "HR" },
      CU: { label: "Cuba", value: "CU" },
      CY: { label: "Cyprus", value: "CY" },
      CZ: { label: "Czech Republic", value: "CZ" },
      DK: { label: "Denmark", value: "DK" },
      DJ: { label: "Djibouti", value: "DJ" },
      DM: { label: "Dominica", value: "DM" },
      DO: { label: "Dominican Republic", value: "DO" },
      EC: { label: "Ecuador", value: "EC" },
      EG: { label: "Egypt", value: "EG" },
      SV: { label: "El Salvador", value: "SV" },
      GQ: { label: "Equatorial Guinea", value: "GQ" },
      ER: { label: "Eritrea", value: "ER" },
      EE: { label: "Estonia", value: "EE" },
      ET: { label: "Ethiopia", value: "ET" },
      FK: { label: "Falkland Islands (Malvinas)", value: "FK" },
      FO: { label: "Faroe Islands", value: "FO" },
      FJ: { label: "Fiji", value: "FJ" },
      FI: { label: "Finland", value: "FI" },
      FR: { label: "France", value: "FR" },
      GF: { label: "French Guiana", value: "GF" },
      PF: { label: "French Polynesia", value: "PF" },
      TF: { label: "French Southern Territories", value: "TF" },
      GA: { label: "Gabon", value: "GA" },
      GM: { label: "Gambia", value: "GM" },
      GE: { label: "Georgia", value: "GE" },
      DE: { label: "Germany", value: "DE" },
      GH: { label: "Ghana", value: "GH" },
      GI: { label: "Gibraltar", value: "GI" },
      GR: { label: "Greece", value: "GR" },
      GL: { label: "Greenland", value: "GL" },
      GD: { label: "Grenada", value: "GD" },
      GP: { label: "Guadeloupe", value: "GP" },
      GU: { label: "Guam", value: "GU" },
      GT: { label: "Guatemala", value: "GT" },
      GG: { label: "Guernsey", value: "GG" },
      GN: { label: "Guinea", value: "GN" },
      GW: { label: "Guinea-Bissau", value: "GW" },
      GY: { label: "Guyana", value: "GY" },
      HT: { label: "Haiti", value: "HT" },
      HM: { label: "Heard Island and Mcdonald Islands", value: "HM" },
      VA: { label: "Holy See (Vatican City State)", value: "VA" },
      HN: { label: "Honduras", value: "HN" },
      HK: { label: "Hong Kong", value: "HK" },
      HU: { label: "Hungary", value: "HU" },
      IS: { label: "Iceland", value: "IS" },
      IN: { label: "India", value: "IN" },
      ID: { label: "Indonesia", value: "ID" },
      IR: { label: "Iran, Islamic Republic Of", value: "IR" },
      IQ: { label: "Iraq", value: "IQ" },
      IE: { label: "Ireland", value: "IE" },
      IM: { label: "Isle of Man", value: "IM" },
      IL: { label: "Israel", value: "IL" },
      IT: { label: "Italy", value: "IT" },
      JM: { label: "Jamaica", value: "JM" },
      JP: { label: "Japan", value: "JP" },
      JE: { label: "Jersey", value: "JE" },
      JO: { label: "Jordan", value: "JO" },
      KZ: { label: "Kazakhstan", value: "KZ" },
      KE: { label: "Kenya", value: "KE" },
      KI: { label: "Kiribati", value: "KI" },
      KP: { label: 'Korea, Democratic People"S Republic of', value: "KP" },
      KR: { label: "Korea, Republic of", value: "KR" },
      KW: { label: "Kuwait", value: "KW" },
      KG: { label: "Kyrgyzstan", value: "KG" },
      LA: { label: 'Lao People"S Democratic Republic', value: "LA" },
      LV: { label: "Latvia", value: "LV" },
      LB: { label: "Lebanon", value: "LB" },
      LS: { label: "Lesotho", value: "LS" },
      LR: [{ label: "Liberia", value: "LR" }],
      LY: { label: "Libyan Arab Jamahiriya", value: "LY" },
      LI: { label: "Liechtenstein", value: "LI" },
      LT: { label: "Lithuania", value: "LT" },
      LU: { label: "Luxembourg", value: "LU" },
      MO: { label: "Macao", value: "MO" },
      MK: { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
      MG: { label: "Madagascar", value: "MG" },
      MW: { label: "Malawi", value: "MW" },
      MY: { label: "Malaysia", value: "MY" },
      MV: { label: "Maldives", value: "MV" },
      ML: { label: "Mali", value: "ML" },
      MT: { label: "Malta", value: "MT" },
      MH: { label: "Marshall Islands", value: "MH" },
      MQ: { label: "Martinique", value: "MQ" },
      MR: { label: "Mauritania", value: "MR" },
      MU: { label: "Mauritius", value: "MU" },
      YT: { label: "Mayotte", value: "YT" },
      MX: { label: "Mexico", value: "MX" },
      FM: { label: "Micronesia, Federated States of", value: "FM" },
      MD: { label: "Moldova, Republic of", value: "MD" },
      MC: { label: "Monaco", value: "MC" },
      MN: { label: "Mongolia", value: "MN" },
      MS: { label: "Montserrat", value: "MS" },
      MA: { label: "Morocco", value: "MA" },
      MZ: { label: "Mozambique", value: "MZ" },
      MM: { label: "Myanmar", value: "MM" },
      NA: { label: "Namibia", value: "NA" },
      NR: { label: "Nauru", value: "NR" },
      NP: { label: "Nepal", value: "NP" },
      NL: { label: "Netherlands", value: "NL" },
      AN: { label: "Netherlands Antilles", value: "AN" },
      NC: { label: "New Caledonia", value: "NC" },
      NZ: { label: "New Zealand", value: "NZ" },
      NI: { label: "Nicaragua", value: "NI" },
      NE: { label: "Niger", value: "NE" },
      NG: { label: "Nigeria", value: "NG" },
      NU: { label: "Niue", value: "NU" },
      NF: { label: "Norfolk Island", value: "NF" },
      MP: { label: "Northern Mariana Islands", value: "MP" },
      NO: { label: "Norway", value: "NO" },
      OM: { label: "Oman", value: "OM" },
      PK: { label: "Pakistan", value: "PK" },
      PW: { label: "Palau", value: "PW" },
      PS: { label: "Palestinian Territory, Occupied", value: "PS" },
      PA: { label: "Panama", value: "PA" },
      PG: { label: "Papua New Guinea", value: "PG" },
      PY: { label: "Paraguay", value: "PY" },
      PE: { label: "Peru", value: "PE" },
      PH: { label: "Philippines", value: "PH" },
      PN: { label: "Pitcairn", value: "PN" },
      PL: { label: "Poland", value: "PL" },
      PT: { label: "Portugal", value: "PT" },
      PR: { label: "Puerto Rico", value: "PR" },
      QA: { label: "Qatar", value: "QA" },
      RE: { label: "Reunion", value: "RE" },
      RO: { label: "Romania", value: "RO" },
      RU: { label: "Russian Federation", value: "RU" },
      RW: { label: "RWANDA", value: "RW" },
      SH: { label: "Saint Helena", value: "SH" },
      KN: { label: "Saint Kitts and Nevis", value: "KN" },
      LC: { label: "Saint Lucia", value: "LC" },
      PM: { label: "Saint Pierre and Miquelon", value: "PM" },
      VC: { label: "Saint Vincent and the Grenadines", value: "VC" },
      WS: { label: "Samoa", value: "WS" },
      SM: { label: "San Marino", value: "SM" },
      ST: { label: "Sao Tome and Principe", value: "ST" },
      SA: { label: "Saudi Arabia", value: "SA" },
      SN: { label: "Senegal", value: "SN" },
      CS: { label: "Serbia and Montenegro", value: "CS" },
      SC: { label: "Seychelles", value: "SC" },
      SL: { label: "Sierra Leone", value: "SL" },
      SG: { label: "Singapore", value: "SG" },
      SK: { label: "Slovakia", value: "SK" },
      SI: { label: "Slovenia", value: "SI" },
      SB: { label: "Solomon Islands", value: "SB" },
      SO: { label: "Somalia", value: "SO" },
      ZA: { label: "South Africa", value: "ZA" },
      GS: {
        label: "South Georgia and the South Sandwich Islands",
        value: "GS",
      },
      ES: { label: "Spain", value: "ES" },
      LK: { label: "Sri Lanka", value: "LK" },
      SD: { label: "Sudan", value: "SD" },
      SR: { label: "Suriname", value: "SR" },
      SJ: { label: "Svalbard and Jan Mayen", value: "SJ" },
      SZ: { label: "Swaziland", value: "SZ" },
      SE: { label: "Sweden", value: "SE" },
      CH: { label: "Switzerland", value: "CH" },
      SY: { label: "Syrian Arab Republic", value: "SY" },
      TW: { label: "Taiwan, Province of China", value: "TW" },
      TJ: { label: "Tajikistan", value: "TJ" },
      TZ: { label: "Tanzania, United Republic of", value: "TZ" },
      TH: { label: "Thailand", value: "TH" },
      TL: { label: "Timor-Leste", value: "TL" },
      TG: { label: "Togo", value: "TG" },
      TK: { label: "Tokelau", value: "TK" },
      TO: { label: "Tonga", value: "TO" },
      TT: { label: "Trinidad and Tobago", value: "TT" },
      TN: { label: "Tunisia", value: "TN" },
      TR: { label: "Türkiye", value: "TR" },
      TM: { label: "Turkmenistan", value: "TM" },
      TC: { label: "Turks and Caicos Islands", value: "TC" },
      TV: { label: "Tuvalu", value: "TV" },
      UG: { label: "Uganda", value: "UG" },
      UA: { label: "Ukraine", value: "UA" },
      AE: { label: "United Arab Emirates", value: "AE" },
      GB: { label: "United Kingdom", value: "GB" },
      US: { label: "United States", value: "US" },
      UM: { label: "United States Minor Outlying Islands", value: "UM" },
      UY: { label: "Uruguay", value: "UY" },
      UZ: { label: "Uzbekistan", value: "UZ" },
      VU: { label: "Vanuatu", value: "VU" },
      VE: { label: "Venezuela", value: "VE" },
      VN: { label: "Viet Nam", value: "VN" },
      VG: { label: "Virgin Islands, British", value: "VG" },
      VI: { label: "Virgin Islands, U.S.", value: "VI" },
      WF: { label: "Wallis and Futuna", value: "WF" },
      EH: { label: "Western Sahara", value: "EH" },
      YE: { label: "Yemen", value: "YE" },
      ZM: { label: "Zambia", value: "ZM" },
      ZW: { label: "Zimbabwe", value: "ZW" },
    };

    return countryList[code]["label"];
  }

  async function saveContactDetail() {
    setEditContactDetailSaveButtonLoading(true);
    if (orderDetailContactDetailIsCustomerProfileUpdating) {
      const result = await fetcher({
        endpoint: "editOrderContactDetails.php",
        method: "POST",
        body: JSON.stringify({
          secure: location.pathname.replace("/orders/drafts/", ""),
          customerSecure: orderDetail.customerHash,
          email: "noneableKKKey",
          phone: "noneableKKKey",
          newEmail: orderDetail.email,
          newPhone: orderDetail.phone,
          customerProfileUpdate:
            orderDetailContactDetailIsCustomerProfileUpdating,
        }),
      });
      if (!result.fetchError) {
        setEditContactDetailSaveButtonLoading(false);
        setEditContactDetailModalActive(false);
      } else {
        setEditContactDetailSaveButtonLoading(false);
      }
    } else {
      setEditContactDetailSaveButtonLoading(false);
      setEditContactDetailModalActive(false);
    }
  }

  async function addNewCustomer() {
    setNewCustomerSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "addNewQuickCustomer.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/orders/drafts/", ""),
        customerDetail: orderDetailNewCustomerDetails,
      }),
    });
    if (!result.fetchError) {
      setNewCustomerSaveButtonLoading(false);
      setNewCustomerModalActive(false);
    } else {
      setNewCustomerSaveButtonLoading(false);
    }
  }

  if (staff.length === 0) {
    (async () => {
      const result = await fetcher({
        endpoint: "getStaff.php",
        method: "GET",
      });
      if (!result.fetchError) {
        setStaff(result);
      }
    })();
  }

  async function getCustomers() {
    setOrderAddCustomerLoading(true);
    if (customers.length === 0) {
      const result = await fetcher({
        endpoint: "getCustomers.php",
        method: "GET",
      });
      if (!result.fetchError) {
        setCustomers(result);
        setOrderAddCustomerLoading(false);
      }
    } else {
      setOrderAddCustomerLoading(false);
    }
  }

  async function getCustomerDetailAndAssignIt(secure) {
    const result = await fetcher({
      endpoint: "getCustomerDetail.php",
      method: "POST",
      body: JSON.stringify({
        secure: secure,
      }),
    });
    if (!result.fetchError) {
      setOrderDetail({
        ...orderDetail,
        shippingAddress: result.primary_address,
        invoiceAddress: result.primary_address,
        customer: result.name + " " + result.surname,
        customerHash: result.secure_hash,
        email: result.email,
        phone: result.phone,
      });
    }
  }

  function deleteCustomerFromOrder() {
    setOrderDetail({ ...orderDetail, customerHash: "", customer: "" });
  }

  return actualPageMarkup;
}

export default NewDraftOrder;
