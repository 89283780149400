import { useMain } from "src/contexts/Main";
import NumberFormat from "./general";

function ProductPriceCalculator(gram, type, rates) {
  const foundRate = rates.find(({ uuid }) => (uuid = type));
  if (!foundRate) return NumberFormat(0) + "₺";

  return (
    NumberFormat(
      parseFloat(foundRate.prices.sell.float) *
        gram *
        (gram > 20 ? 1.23 : gram > 15 ? 1.27 : gram > 10 ? 1.35 : 1.42)
    ) + "₺"
  );
}

export default ProductPriceCalculator;
