import {
  LegacyCard,
  IndexTable,
  useIndexResourceState,
  Badge,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  Page,
  Button,
  Layout,
  Link,
  LegacyStack,
  Tag,
  EmptyState,
  VerticalStack,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import LoadingMarkup from "src/components/LoadingMarkup";
import { fetcher } from "src/helpers/fetcher";

function Orders() {
  // start: Page Variables
  const [orders, setOrders] = useState(false);
  // end: Page Variables

  // start: Tabs Variables
  const [selectedTab, setSelectedTab] = useState(0);
  // end: Tabs Variables

  // start: Index Filters Variables
  const filterSortOptions = [
    {
      label: "Sipariş numarası",
      value: "number asc",
      directionLabel: "Artan",
    },
    {
      label: "Sipariş numarası",
      value: "number desc",
      directionLabel: "Azalan",
    },
    {
      label: "Tarih",
      value: "date asc",
      directionLabel: "Eskiden Yeniye",
    },
    {
      label: "Tarih",
      value: "date desc",
      directionLabel: "Yeniden Eskiye",
    },
    {
      label: "Müşteri adı",
      value: "cname asc",
      directionLabel: "A-Z",
    },
    {
      label: "Müşteri adı",
      value: "cname desc",
      directionLabel: "Z-A",
    },
    {
      label: "Ödeme durumu",
      value: "payment_status asc",
      directionLabel: "Ödenmedi",
    },
    {
      label: "Ödeme durumu",
      value: "payment_status desc",
      directionLabel: "Ödendi",
    },
    {
      label: "Gönderim durumu",
      value: "shipping_status asc",
      directionLabel: "Gönderilmedi",
    },
    {
      label: "Gönderim durumu",
      value: "shipping_status desc",
      directionLabel: "Gönderildi",
    },
  ];
  const [filterSortSelectedOption, setFilterSortSelectedOption] = useState([
    "date desc",
  ]);
  const filtersMode = useSetIndexFiltersMode();
  const [filtersQueryValue, setFiltersQueryValue] = useState("");
  const [filtersLoading, setFiltersLoading] = useState(false);
  const checkFilterChanges = () => {
    var defaultFilters = {
      selectedTab: 0,
      query: "",
    };
    return defaultFilters.selectedTab === selectedTab &&
      defaultFilters.query === filtersQueryValue &&
      orders.length == 0
      ? false
      : true;
  };
  // end: Index Filters Variables

  // start: Index Table Variables
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);
  // end: Index Table Variables

  // start: Page Functions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        handleSelectionChange([]);
        setFiltersLoading(true);
        var orderSort = filterSortSelectedOption[0].split(" ");
        const result = await fetcher({
          endpoint:
            "getOrders.php?status=" +
            (selectedTab === 0
              ? "All"
              : selectedTab === 1
              ? "notShipped"
              : selectedTab === 2
              ? "notPaid"
              : selectedTab === 3
              ? "Open"
              : "Closed") +
            (filtersQueryValue !== 0 && filtersQueryValue !== ""
              ? "&query=" + filtersQueryValue
              : "") +
            (orderSort[1] !== null
              ? "&orderSort=" + orderSort[1]
              : "&orderSort=DESC") +
            (orderSort[0] !== null
              ? "&sortType=" + orderSort[0]
              : "&sortType=date"),
          method: "GET",
        });
        if (!result.fetchError) {
          setOrders(result);
          setFiltersLoading(false);
        } else {
          setFiltersLoading(false);
        }
      })();
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersQueryValue, selectedTab, filterSortSelectedOption]);
  // end: Page Functions

  const tableRowMarkup =
    orders &&
    orders.map(
      (
        {
          id,
          secure,
          number,
          date,
          customer,
          payments,
          archived,
          paymentStatus,
          total,
          refunded,
          shippingStatus,
          shippingCarrier,
          items,
          tags,
        },
        index
      ) => (
        <IndexTable.Row
          id={id.toString()}
          key={id.toString()}
          selected={selectedResources.includes(id.toString())}
          position={index}
          status={archived === 1 && "subdued"}
        >
          <IndexTable.Cell>
            <Link url={"/orders/" + secure} monochrome removeUnderline>
              <Text
                as="span"
                color={archived === 1 ? "subdued" : null}
                fontWeight={archived === 1 ? "regular" : "semibold"}
              >
                {refunded === 1 ? (
                  <span style={{ textDecoration: "line-through" }}>
                    #{number}
                  </span>
                ) : (
                  "#" + number
                )}
              </Text>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell></IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" color={archived === 1 ? "subdued" : null}>
              {refunded === 1 ? (
                <span style={{ textDecoration: "line-through" }}>{date}</span>
              ) : (
                date
              )}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" color={archived === 1 ? "subdued" : null}>
              {refunded === 1 ? (
                <span style={{ textDecoration: "line-through" }}>
                  {customer}
                </span>
              ) : (
                customer
              )}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" color={archived === 1 ? "subdued" : null}>
              {refunded === 1 ? (
                <span style={{ textDecoration: "line-through" }}>{total}₺</span>
              ) : (
                total + "₺"
              )}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {paymentStatus === 1 && refunded === 0 ? (
              <Badge status="success" progress="complete">
                Ödendi
              </Badge>
            ) : refunded === 1 ? (
              <Badge status="attention" progress="complete">
                Ödeme iade edildi
              </Badge>
            ) : (
              <Badge status="warning" progress="partiallyComplete">
                Ödeme bekleniyor
              </Badge>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {refunded === 1 ? (
              <Badge status="critical" progress="complete">
                İade edildi
              </Badge>
            ) : (
              "-"
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {shippingStatus === 1 ? (
              <Badge status="success" progress="complete">
                Gönderildi
              </Badge>
            ) : shippingStatus === 2 ? (
              <Badge status="warning" progress="incomplete">
                İade bekleniyor
              </Badge>
            ) : shippingStatus === 3 ? (
              <Badge status="warning" progress="complete">
                İade edildi
              </Badge>
            ) : (
              <Badge status="attention" progress="incomplete">
                Gönderilmedi
              </Badge>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" color={archived === 1 ? "subdued" : null}>
              {items.length > 0 ? items.length + " ürün" : "-"}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" color={archived === 1 ? "subdued" : null}>
              {shippingCarrier}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <LegacyStack spacing="tight">
              {tags.length > 0
                ? tags.map((object, index) => <Tag key={index}>{object}</Tag>)
                : "-"}
            </LegacyStack>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  const pageMarkup = (
    <Page
      fullWidth
      title="Siparişler"
      primaryAction={
        checkFilterChanges() ? (
          <Button url="/orders/drafts/new/" primary>
            Sipariş oluştur
          </Button>
        ) : (
          ""
        )
      }
      secondaryActions={
        checkFilterChanges() ? [{ content: "Dışa aktar", disabled: true }] : ""
      }
    >
      <Layout sectioned>
        {checkFilterChanges() ? (
          <LegacyCard>
            <IndexFilters
              sortOptions={filterSortOptions}
              sortSelected={filterSortSelectedOption}
              queryValue={filtersQueryValue}
              queryPlaceholder="Siparişlerin içerisinde ara"
              onQueryChange={(value) => {
                setFiltersQueryValue(value);
              }}
              onQueryClear={() => {
                setFiltersQueryValue("");
              }}
              onSort={(value) => {
                setFilterSortSelectedOption(value);
              }}
              filters={[]}
              appliedFilters={[]}
              hideFilters
              cancelAction={{
                onAction: () => {
                  setFiltersQueryValue("");
                },
                disabled: false,
                loading: false,
              }}
              tabs={[
                {
                  id: "All",
                  content: "Tümü",
                  panelID: "All",
                },
                {
                  id: "notShipped",
                  content: "Gönderilmedi",
                  panelID: "notShipped",
                },
                {
                  id: "notPaid",
                  content: "Ödenmedi",
                  panelID: "notPaid",
                },
                {
                  id: "Open",
                  content: "Açık",
                  panelID: "Open",
                },
                {
                  id: "Closed",
                  content: "Tamamlandı",
                  panelID: "Closed",
                },
              ]}
              selected={selectedTab}
              onSelect={setSelectedTab}
              canCreateNewView={false}
              onClearAll={() => setFiltersQueryValue("")}
              mode={filtersMode.mode}
              setMode={filtersMode.setMode}
              loading={filtersLoading}
            />
            <IndexTable
              resourceName={{
                singular: "Sipariş",
                plural: "Siparişler",
              }}
              itemCount={orders.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[
                { title: "Sipariş" },
                { title: "Badges", hidden: true },
                { title: "Tarih" },
                { title: "Müşteri" },
                { title: "Toplam" },
                { title: "Ödeme durumu" },
                { title: "İade durumu" },
                { title: "Gönderim durumu" },
                { title: "Ürünler" },
                { title: "Teslimat yöntemi" },
                { title: "Etiketler" },
              ]}
              bulkActions={[
                {
                  content: "Sevk irsaliyelerini yazdır",
                  onAction: () =>
                    console.log("Todo: implement payment capture"),
                },
                {
                  content: "Siparişleri arşivle",
                  onAction: () => {
                    selectedResources.map((value) =>
                      updateArchiveStatus(value, 1)
                    );
                  },
                },
                {
                  content: "Siparişleri arşivden çıkar",
                  onAction: () => {
                    selectedResources.map((value) =>
                      updateArchiveStatus(value, 0)
                    );
                  },
                },
              ]}
              promotedBulkActions={[
                {
                  content: "Gönderildi olarak işaretle",
                  onAction: () => {
                    selectedResources.map((value) => shipOrder(value));
                  },
                },
                {
                  content: "Ödemeleri kaydet",
                  onAction: () => {
                    selectedResources.map((value) =>
                      updatePaymentOrder(value, 1)
                    );
                  },
                },
              ]}
            >
              {tableRowMarkup}
            </IndexTable>
          </LegacyCard>
        ) : (
          <LegacyCard sectioned>
            <EmptyState
              action={{
                content: "Sipariş oluştur",
                url: "/orders/drafts/new/",
              }}
              image={
                "https://cdn.shopify.com/shopifycloud/web/assets/v1/0ecc04f85cc74cb401e71254d8a8b83dc03bdade26037cdda6a034831d940dcd.svg"
              }
            >
              <VerticalStack gap={2}>
                <Text variant="headingLg">
                  Siparişleriniz bu bölümde görünür
                </Text>
                <p>
                  Siparişleri göndermek, ödemeleri tahsil etmek ve siparişin
                  ilerleme durumunu kontrol etmek için bu bölümden
                  yararlanabilirsiniz.
                </p>
              </VerticalStack>
            </EmptyState>
          </LegacyCard>
        )}
      </Layout>
    </Page>
  );

  return orders ? pageMarkup : <LoadingMarkup />;

  async function shipOrder(value) {
    await fetcher({
      endpoint: "shipOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: value,
        shippingCarrier: null,
        trackingCode: null,
        customerContact: null,
      }),
    });
    handleSelectionChange([]);
  }

  async function updatePaymentOrder(value, status) {
    await fetcher({
      endpoint: "updatePaymentOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: value,
        status: status,
      }),
    });
    handleSelectionChange([]);
  }

  async function updateArchiveStatus(value, status) {
    const result = await fetcher({
      endpoint: "updateArchiveStatusOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: value,
        status: status,
      }),
    });
    handleSelectionChange([]);
  }
}

export default Orders;
