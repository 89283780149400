import {
  Page,
  Layout,
  LegacyCard,
  TextField,
  SkeletonBodyText,
  SkeletonPage,
  PageActions,
  Loading,
  LegacyStack,
  Toast,
  DropZone,
  Grid,
  FormLayout,
  Checkbox,
  Icon,
  Button,
  Autocomplete,
  Popover,
  ColorPicker,
  rgbToHsb,
  Select,
  hsbToRgb,
  Tag,
  Combobox,
  Listbox,
  ContextualSaveBar,
  Modal,
  Text,
  VerticalStack,
  HorizontalStack,
} from "@shopify/polaris";
import {
  ArchiveMinor,
  CancelSmallMinor,
  CirclePlusMinor,
  DeleteMinor,
  PlusMinor,
  SearchMinor,
  ViewMinor,
} from "@shopify/polaris-icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import ProductPriceCalculator from "../../helpers/ProductPriceCalculator";
import NumberFormat from "../../helpers/general";
import _ from "lodash";
import { fetcher } from "../../helpers/fetcher";
import { useMain } from "src/contexts/Main";

function ProductDetail(props) {
  const { id } = useParams();
  const { rates } = useMain();
  let navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [categoryListSearchValue, setCategoryListSearchValue] = useState("");
  const [tagsTextFieldValue, setTagsTextFieldValue] = useState("");
  const [contextualSaveBarActive, setContextualSaveBarActive] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const [productUpdateToastActive, setProductUpdateToastActive] =
    useState(false);

  const productUpdateActionToastToggle = useCallback(
    () => setProductUpdateToastActive((active) => !active),
    []
  );

  const [options, setOptions] = useState(
    rates.map(({ uuid, name }) => ({ value: uuid, label: name }))
  );
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [productImageDeleteDetails, setProductImageDeleteDetails] = useState({
    modalOpened: false,
    imageID: null,
    isBlob: false,
  });

  const [
    productVariantsColorPopoversActive,
    setProductVariantsColorPopoversActive,
  ] = useState([]);

  const [newlyUploadedImages, setNewlyUploadedImages] = useState([]);

  const [categoryList, setCategoryList] = useState({
    deselected: [],
    search: [],
  });

  const [featureList, setFeatureList] = useState([]);

  const [productDetail, setProductDetail] = useState({
    original: {
      id: 0,
      secure_hash: "",
      product_title: "",
      product_description: "",
      product_status: 1,
      product_archived: 0,
      product_images: [],
      product_brand: "",
      product_categories: [],
      product_tags: [],
      product_price: 0,
      product_labour: 0,
      product_type: {},
      product_sku: "",
      product_barcode: "",
      product_quantity: 0,
      product_digital: 0,
      product_weight: 0,
      product_variations: [{ variations: [] }],
      product_features: [{ features: [] }],
      product_customization_fields: [],
      product_seo_title: "",
      product_seo_description: "",
      product_url: "",
    },
    editable: {
      id: 0,
      secure_hash: "",
      product_title: "",
      product_description: "",
      product_status: 1,
      product_archived: 0,
      product_images: [],
      product_brand: "",
      product_categories: [],
      product_tags: [],
      product_price: 0,
      product_labour: 0,
      product_type: {},
      product_sku: "",
      product_barcode: "",
      product_quantity: 0,
      product_digital: 0,
      product_weight: 0,
      product_variations: [{ variations: [] }],
      product_features: [{ features: [] }],
      product_customization_fields: [],
      product_seo_title: "",
      product_seo_description: "",
      product_url: "",
    },
  });

  function giveSecureHash(length) {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    if (!_.isEqual(productDetail.editable, productDetail.original)) {
      setContextualSaveBarActive(true);
    } else {
      setContextualSaveBarActive(false);
    }
  }, [productDetail]);

  const dropzoneArea = (
    <DropZone
      overlay
      overlayText="Yüklenecek medyayı sürükleyin"
      type="image"
      onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) => {
        setNewlyUploadedImages((files) => [...files, ...acceptedFiles]);
        var array = [];
        acceptedFiles.forEach((e) => {
          array.push(window.URL.createObjectURL(e));
        });
        setProductDetail({
          ...productDetail,
          editable: {
            ...productDetail.editable,
            product_images: [
              ...productDetail.editable.product_images,
              ...array,
            ],
          },
        });
      }}
    >
      <DropZone.FileUpload />
    </DropZone>
  );

  const setProductDetailCallBack = useCallback(
    (newProductDetail) => {
      console.log({
        ...productDetail,
        editable: { ...productDetail.editable, ...newProductDetail },
      });
      setProductDetail({
        ...productDetail,
        editable: { ...productDetail.editable, ...newProductDetail },
      });
    },
    [productDetail, setProductDetail]
  );

  const foundRate = useMemo(
    () =>
      rates.find(
        (rate) => rate.uuid === productDetail.editable.product_type.value
      ),
    [productDetail.editable.product_type.value]
  );

  const loadingMarkup = (
    <SkeletonPage>
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              disabled: true,
            }}
          />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actualPageMarkup = (
    <Page
      backAction={{ content: "Ürünler", url: "/products/" }}
      secondaryActions={[
        {
          content: "Görüntüle",
          icon: ViewMinor,
        },
      ]}
      title="Ürün detayı"
    >
      {contextualSaveBarActive && (
        <ContextualSaveBar
          message="Kaydedilmemiş değişiklikler"
          saveAction={{
            onAction: () => saveProductDetail(),
            loading: saveButtonLoading,
          }}
          discardAction={{
            onAction: () => {
              setProductDetail({
                ...productDetail,
                original: productDetail.editable,
              });
            },
          }}
        />
      )}
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="loose">
              <TextField
                label="Başlık"
                value={productDetail.editable.product_title}
                onChange={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_title: v,
                    },
                  });
                }}
                placeholder="Kısa kollu tişört"
              />
              <TextField
                label="Açıklama"
                value={productDetail.editable.product_description}
                onChange={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_description: v,
                    },
                  });
                }}
                multiline={5}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard title="Medya" sectioned>
            {productDetail.editable.product_images.length > 0 ? (
              <Grid
                gap={{
                  xl: "0.5rem",
                  lg: "0.5rem",
                  md: "0.5rem",
                  sm: "0.5rem",
                  xs: "0.5rem",
                }}
                columns={{ xl: 4, lg: 4, md: 4, sm: 4, xs: 3 }}
              >
                {productDetail.editable.product_images.map((item, index) => (
                  <Grid.Cell
                    key={index}
                    columnSpan={
                      index === 0
                        ? { xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }
                        : { xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }
                    }
                    row={
                      index === 0
                        ? {
                            xl: "1/span 2",
                            lg: "1/span 2",
                            md: "1/span 2",
                            sm: "1/span 2",
                            xs: "1/span 2",
                          }
                        : null
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0.25rem",
                        border: "1px solid #c9cccf",
                        background: "white",
                        position: "relative",
                      }}
                      className="productImageBlock"
                    >
                      <div className="productImageOverlay">
                        <Button
                          plain
                          monochrome
                          onClick={() => {
                            setProductImageDeleteDetails({
                              modalOpened: true,
                              imageID: item.includes("blob")
                                ? item
                                : item.replace(
                                    "//altinoranonline.com.tr/cdn/p/" +
                                      productDetail.editable.secure_hash +
                                      "_",
                                    ""
                                  ),
                              isBlob: item.includes("blob"),
                            });
                          }}
                        >
                          <Icon source={CancelSmallMinor} color="subdued" />
                          <p style={{ color: "white" }}>Fotoğrafı Sil</p>
                        </Button>
                      </div>
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "content-box",
                        }}
                      >
                        <img
                          src={
                            item.includes("blob")
                              ? item
                              : item + "?t=" + Math.random() * 10
                          }
                          width="100%"
                          alt="product_image"
                        />
                      </div>
                    </div>
                  </Grid.Cell>
                ))}
                <Grid.Cell>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "0.25rem",
                    }}
                    className="productImageUploadHeight"
                  >
                    {dropzoneArea}
                  </div>
                </Grid.Cell>
              </Grid>
            ) : (
              dropzoneArea
            )}
          </LegacyCard>
          <LegacyCard title="Fiyatlandırma">
            <LegacyCard.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Autocomplete
                    options={options}
                    selected={selectedOptions}
                    onSelect={(selected) => {
                      const selectedValue = selected.map(
                        (selectedItem, index) => {
                          const matchedOption = options.find((option) => {
                            return option.value.match(selectedItem);
                          });
                          return {
                            key: index,
                            label: matchedOption.label,
                            value: matchedOption.value,
                          };
                        }
                      );

                      setSelectedOptions(selected);
                      if (
                        selectedValue[0] !== productDetail.editable.product_type
                      ) {
                        setProductDetail({
                          ...productDetail,
                          editable: {
                            ...productDetail.editable,
                            product_type: selectedValue[0],
                          },
                        });
                      }
                    }}
                    textField={
                      <Autocomplete.TextField
                        onChange={(value) => {
                          setProductDetail({
                            ...productDetail,
                            editable: {
                              ...productDetail.editable,
                              product_type: {
                                ...productDetail.product_type,
                                label: value,
                              },
                            },
                          });
                          if (value === "") {
                            setOptions(
                              rates.map(({ name, uuid }) => ({
                                value: uuid,
                                label: name,
                              }))
                            );
                            return;
                          }

                          const filterRegex = new RegExp(value, "i");
                          const resultOptions = rates
                            .map(({ name, uuid }) => ({
                              value: uuid,
                              label: name,
                            }))
                            .filter((option) =>
                              option.label.match(filterRegex)
                            );
                          setOptions(resultOptions);
                        }}
                        label="Ürün Türü"
                        value={productDetail?.editable?.product_type?.label}
                        placeholder="Ürün türünü aratarak seçin"
                        autoComplete="off"
                      />
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    label="Ürün gramı"
                    value={productDetail.editable.product_price}
                    onChange={(v) => {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_price: v,
                        },
                      });
                    }}
                    type="number"
                    inputMode="numeric"
                    placeholder="0.00"
                    prefix="g"
                    step={0.01}
                    autoComplete="off"
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    label="Ürün işçiliği"
                    value={productDetail.editable.product_labour}
                    onChange={(v) => {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_labour: v,
                        },
                      });
                    }}
                    type="number"
                    inputMode="numeric"
                    placeholder="0.00"
                    prefix="g"
                    step={0.01}
                    autoComplete="off"
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <LegacyStack distribution="fill" spacing="extraLoose" vertical>
                <LegacyStack.Item fill>
                  <LegacyStack distribution="fill">
                    <LegacyStack
                      spacing="tight"
                      distribution="center"
                      alignment="center"
                      vertical
                    >
                      <Text variant="headingMd">Ürünün Alış Fiyatı</Text>
                      <Text>
                        {foundRate &&
                        productDetail.editable.product_type?.value &&
                        productDetail.editable.product_type?.value.length
                          ? NumberFormat(
                              parseFloat(foundRate.prices.buy.float) *
                                productDetail.editable.product_price
                            ) + "₺"
                          : NumberFormat(0) + "₺"}
                      </Text>
                    </LegacyStack>
                    <LegacyStack
                      spacing="tight"
                      distribution="center"
                      alignment="center"
                      vertical
                    >
                      <Text variant="headingMd">Ürünün Satış Fiyatı</Text>
                      <Text>
                        {foundRate &&
                        productDetail.editable.product_type?.value &&
                        productDetail.editable.product_type?.value.length
                          ? NumberFormat(
                              parseFloat(foundRate.prices.sell.float) *
                                productDetail.editable.product_price
                            ) + "₺"
                          : NumberFormat(0) + "₺"}
                      </Text>
                    </LegacyStack>
                  </LegacyStack>
                </LegacyStack.Item>
                <LegacyStack.Item fill>
                  <LegacyStack
                    spacing="tight"
                    distribution="center"
                    alignment="center"
                    vertical
                  >
                    <Text variant="headingMd">
                      Ürünün Websitesindeki Satış Fiyatı
                    </Text>
                    <Text>
                      {ProductPriceCalculator(
                        productDetail.editable?.product_price,
                        productDetail.editable?.product_type?.value,
                        rates
                      )}
                    </Text>
                  </LegacyStack>
                </LegacyStack.Item>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Envanter">
            <LegacyCard.Section>
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField
                    label="SKU (Stok Bulundurma Birimi)"
                    value={productDetail.editable.product_sku}
                    onChange={(v) => {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_sku: v,
                        },
                      });
                    }}
                    inputMode="text"
                  />
                  <TextField
                    label="Barkod (ISBN, UPC, GTIN vb.)"
                    value={productDetail.editable.product_barcode}
                    onChange={(v) => {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_barcode: v,
                        },
                      });
                    }}
                    inputMode="text"
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField
                    label="Adet"
                    value={productDetail.editable.product_quantity}
                    onChange={(v) => {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_quantity: v.replace(/\D+/g, ""),
                        },
                      });
                    }}
                    type="number"
                    inputMode="numeric"
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Kargo">
            <LegacyCard.Section>
              <Checkbox
                checked={
                  productDetail.editable.product_digital === 0 ? true : false
                }
                onChange={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_digital: v ? 0 : 1,
                    },
                  });
                }}
                label="Bu fiziksel bir ürün"
              />
            </LegacyCard.Section>
            {productDetail.editable.product_digital === 0 ? (
              <LegacyCard.Section title="AĞIRLIK" subdued>
                <LegacyStack spacing="tight" vertical>
                  <Text as="span" color="subdued">
                    Ödeme sırasında kargo ücretlerinin ve gönderim sırasında
                    etiket fiyatlarının hesaplanması için kullanılır.
                  </Text>
                  <div style={{ maxWidth: "13.4375rem" }}>
                    <TextField
                      label="Ağırlık"
                      value={productDetail.editable.product_weight}
                      onChange={(v) => {
                        setProductDetail({
                          ...productDetail,
                          editable: {
                            ...productDetail.editable,
                            product_weight: v,
                          },
                        });
                      }}
                      type="number"
                      inputMode="numeric"
                      suffix="g"
                    />
                  </div>
                </LegacyStack>
              </LegacyCard.Section>
            ) : (
              <LegacyCard.Section subdued>
                <Text as="span" color="subdued">
                  Müşteriler bu ürünü satın alırken kargo adreslerini girmez ve
                  kargo yöntemi seçmez.
                </Text>
              </LegacyCard.Section>
            )}
          </LegacyCard>
          <LegacyCard title="Ürün Özellikleri">
            <LegacyCard.Section>
              <Checkbox
                label="Bu ürüne ek özellik listesi gerekiyor"
                checked={
                  productDetail.editable.product_features.length > 0
                    ? true
                    : false
                }
                onChange={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_features: v
                        ? [
                            {
                              featureGroupIdentifier: "",
                              featureGroupName: "",
                              features: [],
                            },
                          ]
                        : [],
                    },
                  });
                }}
              />
            </LegacyCard.Section>
            {productDetail.editable.product_features.length > 0 ? (
              <>
                {productDetail.editable.product_features.map(
                  (
                    { featureGroupIdentifier, featureGroupName, features },
                    index
                  ) => (
                    <LegacyCard.Section key={index}>
                      <FormLayout>
                        <Autocomplete
                          options={featureTypes()}
                          selected={[featureGroupIdentifier]}
                          onSelect={(selected) => {
                            var featuresList =
                              productDetail.editable.product_features;
                            featuresList[index]["featureGroupIdentifier"] =
                              selected[0];
                            const matchedOption = featureTypes().find(
                              (option) => {
                                return option.value.match(selected[0]);
                              }
                            );
                            const matchedItem = featureList.find((option) => {
                              return option.identifier.match(selected[0]);
                            });
                            featuresList[index]["featureGroupName"] =
                              matchedOption.label;
                            featuresList[index]["features"] =
                              matchedItem.features;

                            setProductDetailCallBack({
                              product_features: featureList,
                            });
                          }}
                          textField={
                            <Autocomplete.TextField
                              label="Özellik grubu"
                              placeholder="Özellik grubu seçin"
                              value={featureGroupName}
                              autoComplete="off"
                              connectedRight={
                                <Button
                                  icon={DeleteMinor}
                                  onClick={() => {
                                    var spliceArray =
                                      productDetail.editable.product_features;
                                    spliceArray.splice(index, 1);

                                    setProductDetail({
                                      ...productDetail,
                                      editable: {
                                        ...productDetail.editable,
                                        product_features: spliceArray,
                                      },
                                    });
                                  }}
                                ></Button>
                              }
                            />
                          }
                        />
                        {features.length > 0 && (
                          <VerticalStack gap={2}>
                            <label>Seçenek değerleri</label>
                            {features.map(
                              ({ name, value }, indexTwo) =>
                                name !== "" && (
                                  <FormLayout key={indexTwo}>
                                    <FormLayout.Group>
                                      <TextField
                                        placeholder="Özellik adı"
                                        autoComplete="off"
                                        value={name}
                                        readOnly
                                      />
                                      <TextField
                                        placeholder="Özellik değeri"
                                        autoComplete="off"
                                        value={value || ""}
                                        onChange={(v) => {
                                          var detailProuct = {
                                            ...productDetail,
                                          };
                                          detailProuct.editable.product_features[
                                            index
                                          ].features[indexTwo].value = v;

                                          setProductDetail({
                                            ...productDetail,
                                            editable: detailProuct,
                                          });
                                        }}
                                      />
                                    </FormLayout.Group>
                                  </FormLayout>
                                )
                            )}
                          </VerticalStack>
                        )}
                      </FormLayout>
                    </LegacyCard.Section>
                  )
                )}
                <LegacyCard.Section
                  children={
                    <Button
                      textAlign="left"
                      fullWidth
                      plain
                      removeUnderline
                      onClick={() => {
                        var x =
                          productDetail.editable.product_features[
                            productDetail.editable.product_features.length - 1
                          ];
                        if (
                          x.variantType !== "" &&
                          x.featureGroupName !== "" &&
                          x.features[0].name !== ""
                        ) {
                          setProductDetail({
                            ...productDetail,
                            editable: {
                              ...productDetail.editable,
                              product_features: [
                                ...productDetail.editable.product_features,
                                {
                                  featureType: "",
                                  featureGroupName: "",
                                  features: [],
                                },
                              ],
                            },
                          });
                        }
                      }}
                    >
                      <LegacyStack spacing="loose" alignment="center">
                        <Icon source={PlusMinor} />
                        <span>Başka bir özellik ekle</span>
                      </LegacyStack>
                    </Button>
                  }
                />
              </>
            ) : null}
          </LegacyCard>
          <LegacyCard title="Seçenekler">
            <LegacyCard.Section>
              <Checkbox
                label="Bu ürünün boyut veya renk gibi seçenekleri var"
                checked={
                  productDetail.editable.product_variations.length > 0
                    ? true
                    : false
                }
                onChange={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_variations: v
                        ? [
                            {
                              variationType: "",
                              variationGroupName: "",
                              variations: [
                                { id: giveSecureHash(10), variationName: "" },
                              ],
                            },
                          ]
                        : [],
                    },
                  });
                }}
              />
            </LegacyCard.Section>
            {productDetail.editable.product_variations.length > 0 ? (
              <>
                {productDetail.editable.product_variations.map(
                  (
                    { variationType, variationGroupName, variations },
                    index
                  ) => (
                    <LegacyCard.Section key={index}>
                      <FormLayout>
                        <Autocomplete
                          options={variantTypes(index)}
                          selected={[variationType]}
                          onSelect={(selected) => {
                            var variationsList =
                              productDetail.editable.product_variations;
                            variationsList[index]["variationType"] =
                              selected[0];
                            const matchedOption = variantTypes(index).find(
                              (option) => {
                                return option.value.match(selected[0]);
                              }
                            );
                            variationsList[index]["variationGroupName"] =
                              matchedOption.label;
                            if (selected[0] === "color") {
                              variationsList[index].variations[0].hex = {
                                red: 0,
                                green: 0,
                                blue: 0,
                                alpha: 1,
                              };
                            }
                            setProductDetail({
                              ...productDetail,
                              editable: {
                                ...productDetail.editable,
                                product_variations: variationsList,
                              },
                            });
                          }}
                          textField={
                            <Autocomplete.TextField
                              label="Seçenek adı"
                              placeholder="Renk"
                              value={variationGroupName}
                              autoComplete="off"
                              connectedRight={
                                <Button
                                  icon={DeleteMinor}
                                  onClick={() => {
                                    var spliceArray =
                                      productDetail.editable.product_variations;
                                    spliceArray.splice(index, 1);
                                    setProductDetail({
                                      ...productDetail,
                                      editable: {
                                        ...productDetail.editable,
                                        product_variations: spliceArray,
                                      },
                                    });
                                  }}
                                ></Button>
                              }
                            />
                          }
                        />
                        <LegacyStack spacing="extraTight" vertical>
                          <label>Seçenek değerleri</label>
                          {variations.map(({ variationName }, indexTwo) => (
                            <LegacyStack spacing="extraTight" key={indexTwo}>
                              <LegacyStack.Item fill>
                                <TextField
                                  placeholder="Seçenek ekle"
                                  autoComplete="off"
                                  value={variationName}
                                  onChange={(v) => {
                                    var gotVariants =
                                      productDetail.editable.product_variations;
                                    if (
                                      (v === "" ||
                                        v === undefined ||
                                        v === null) &&
                                      gotVariants[index].variations[
                                        gotVariants[index].variations.length - 1
                                      ].variationName === ""
                                    ) {
                                      gotVariants[index].variations.splice(
                                        gotVariants[index].variations.length -
                                          1,
                                        1
                                      );
                                    } else if (
                                      v === "" ||
                                      v === undefined ||
                                      (v === null &&
                                        gotVariants[index].variations[
                                          gotVariants[index].variations.length -
                                            1
                                        ].variationName !== "")
                                    ) {
                                    }
                                    gotVariants[index].variations[
                                      indexTwo
                                    ].variationName = v;
                                    if (
                                      v !== "" &&
                                      v !== undefined &&
                                      v !== null &&
                                      gotVariants[index].variations[
                                        gotVariants[index].variations.length - 1
                                      ].variationName !== ""
                                    ) {
                                      gotVariants[index].variations.push(
                                        variationType === "color"
                                          ? {
                                              id: giveSecureHash(10),
                                              variationName: "",
                                              hex: {
                                                red: 0,
                                                green: 0,
                                                blue: 0,
                                                alpha: 1,
                                              },
                                            }
                                          : {
                                              id: giveSecureHash(10),
                                              variationName: "",
                                            }
                                      );
                                    }
                                    setProductDetail({
                                      ...productDetail,
                                      editable: {
                                        ...productDetail.editable,
                                        product_variations: gotVariants,
                                      },
                                    });
                                  }}
                                />
                              </LegacyStack.Item>
                              {variationType === "color" && (
                                <LegacyStack.Item>
                                  <Popover
                                    active={
                                      productVariantsColorPopoversActive[
                                        indexTwo
                                      ]
                                    }
                                    activator={
                                      <Button
                                        onClick={() => {
                                          var activates = [
                                            ...productVariantsColorPopoversActive,
                                          ];
                                          for (
                                            let i = 0;
                                            i < activates.length;
                                            i++
                                          ) {
                                            if (i !== indexTwo) {
                                              activates[i] = false;
                                            }
                                          }
                                          activates[indexTwo] =
                                            !activates[indexTwo];
                                          setProductVariantsColorPopoversActive(
                                            activates
                                          );
                                        }}
                                      >
                                        Renk Seçin
                                      </Button>
                                    }
                                    onClose={() => {
                                      var activates = [
                                        ...productVariantsColorPopoversActive,
                                      ];
                                      for (
                                        let i = 0;
                                        i < activates.length;
                                        i++
                                      ) {
                                        if (i !== indexTwo) {
                                          activates[i] = false;
                                        }
                                      }
                                      activates[indexTwo] =
                                        !activates[indexTwo];
                                      setProductVariantsColorPopoversActive(
                                        activates
                                      );
                                    }}
                                    sectioned
                                  >
                                    <ColorPicker
                                      onChange={(v) => {
                                        var gotVariants =
                                          productDetail.editable
                                            .product_variations;
                                        gotVariants[index].variations[
                                          indexTwo
                                        ].hex = hsbToRgb(v);
                                        setProductDetail({
                                          ...productDetail,
                                          editable: {
                                            ...productDetail.editable,
                                            product_variations: gotVariants,
                                          },
                                        });
                                      }}
                                      color={rgbToHsb(
                                        productDetail.editable
                                          .product_variations[index].variations[
                                          indexTwo
                                        ].hex
                                      )}
                                    />
                                  </Popover>
                                </LegacyStack.Item>
                              )}
                            </LegacyStack>
                          ))}
                        </LegacyStack>
                      </FormLayout>
                    </LegacyCard.Section>
                  )
                )}
                <LegacyCard.Section
                  children={
                    <Button
                      textAlign="left"
                      fullWidth
                      plain
                      removeUnderline
                      onClick={() => {
                        var x =
                          productDetail.editable.product_variations[
                            productDetail.editable.product_variations.length - 1
                          ];
                        if (
                          x.variantType !== "" &&
                          x.variationGroupName !== "" &&
                          x.variations[0].variationName !== ""
                        ) {
                          setProductDetail({
                            ...productDetail,
                            editable: {
                              ...productDetail.editable,
                              product_variations: [
                                ...productDetail.editable.product_variations,
                                {
                                  variationType: "",
                                  variationGroupName: "",
                                  variations: [
                                    {
                                      id: giveSecureHash(10),
                                      variationName: "",
                                      hex: {
                                        red: 0,
                                        green: 0,
                                        blue: 0,
                                        alpha: 1,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          });
                        }
                      }}
                    >
                      <LegacyStack spacing="loose" alignment="center">
                        <Icon source={PlusMinor} />
                        <span>Başka bir seçenek ekle</span>
                      </LegacyStack>
                    </Button>
                  }
                />
              </>
            ) : null}
          </LegacyCard>
          <LegacyCard title="Kişiselleştirilebilir alanlar">
            <LegacyCard.Section>
              <Checkbox
                label="Bu üründe kişiselleştirilebilir alanlar var"
                checked={
                  productDetail.editable.product_customization_fields.length > 0
                    ? true
                    : false
                }
                onChange={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_customization_fields: v
                        ? [
                            {
                              label: "",
                              length: "",
                            },
                          ]
                        : [],
                    },
                  });
                }}
              />
            </LegacyCard.Section>
            {productDetail.editable.product_customization_fields.length > 0 ? (
              <>
                {productDetail.editable.product_customization_fields.map(
                  ({ label, length }, index) => (
                    <LegacyCard.Section key={index}>
                      <FormLayout>
                        <LegacyStack spacing="extraTight" vertical>
                          <LegacyStack spacing="extraTight">
                            <LegacyStack.Item fill>
                              <TextField
                                placeholder="İsim"
                                autoComplete="off"
                                label="Kişiselleştirilebilir alan ismi"
                                value={label}
                                onChange={(v) => {
                                  var gotVariants =
                                    productDetail.editable
                                      .product_customization_fields;
                                  if (
                                    (v === "" ||
                                      v === undefined ||
                                      v === null) &&
                                    gotVariants[gotVariants.length - 1]
                                      .label === ""
                                  ) {
                                    gotVariants.splice(
                                      gotVariants.length - 1,
                                      1
                                    );
                                  } else if (
                                    v === "" ||
                                    v === undefined ||
                                    (v === null &&
                                      gotVariants[gotVariants.length - 1]
                                        .label !== "")
                                  ) {
                                  }
                                  gotVariants[index].label = v;
                                  if (
                                    v !== "" &&
                                    v !== undefined &&
                                    v !== null &&
                                    gotVariants[gotVariants.length - 1]
                                      .label !== ""
                                  ) {
                                    gotVariants.push({
                                      label: "",
                                      length: "",
                                    });
                                  }
                                  setProductDetail({
                                    ...productDetail,
                                    editable: {
                                      ...productDetail.editable,
                                      product_customization_fields: gotVariants,
                                    },
                                  });
                                }}
                              />
                            </LegacyStack.Item>
                            <LegacyStack.Item>
                              <TextField
                                label="Yazı uzunluğu"
                                value={length}
                                type="number"
                                inputMode="numeric"
                                onChange={(v) => {
                                  var gotVariants =
                                    productDetail.editable
                                      .product_customization_fields;
                                  gotVariants[index].length = v;
                                  setProductDetail({
                                    ...productDetail,
                                    editable: {
                                      ...productDetail.editable,
                                      product_customization_fields: gotVariants,
                                    },
                                  });
                                }}
                              />
                            </LegacyStack.Item>
                          </LegacyStack>
                        </LegacyStack>
                      </FormLayout>
                    </LegacyCard.Section>
                  )
                )}
              </>
            ) : (
              false
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Ürün durumu">
            <LegacyCard.Section>
              {productDetail.editable.product_archived === 1 ? (
                <LegacyCard subdued sectioned>
                  <LegacyStack vertical>
                    <LegacyStack spacing="loose" alignment="center">
                      <Icon source={ArchiveMinor} backdrop color="base" />
                      <Text variant="headingMd" as="h2">
                        Arşivlendi
                      </Text>
                    </LegacyStack>
                    <p>
                      Ürünü arşivlediniz. Ürün satış yaptığınız yerlerde satışa
                      durdurulmuş.
                    </p>
                    <Button onClick={() => toggleDraftStatus()} fullWidth>
                      Ürünü arşivden çıkarın
                    </Button>
                  </LegacyStack>
                </LegacyCard>
              ) : (
                <>
                  <Select
                    options={[
                      { label: "Etkin", value: "active" },
                      { label: "Taslak", value: "draft" },
                    ]}
                    onChange={(v) => {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_status: v === "active" ? 1 : 0,
                        },
                      });
                    }}
                    value={
                      productDetail.editable.product_status === 1
                        ? "active"
                        : "draft"
                    }
                  />
                  {productDetail.editable.product_status === 0 && (
                    <Text as="span" color="subdued">
                      Bu ürün tüm satış kanallarından gizlenecektir.
                    </Text>
                  )}
                </>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Satış kanalları ve uygulamalar" subdued>
              <hr
                style={{
                  border: "none",
                  borderBottom: "0.0625rem solid #c9cccf",
                  marginBottom: "1rem",
                }}
              />
              <LegacyStack alignment="center" spacing="tight">
                <div
                  style={{
                    border: "0.125rem solid currentcolor",
                    borderRadius: "50%",
                    height: "0.625rem",
                    width: "0.625rem",
                    color:
                      productDetail.editable.product_status === 1
                        ? "#108043"
                        : "#919eab",
                    background:
                      productDetail.editable.product_status === 1
                        ? "currentColor"
                        : "transparent",
                  }}
                />
                <Text as="span">Online Mağaza</Text>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard title="Ürün organizasyonu" sectioned>
            <LegacyStack vertical>
              <Autocomplete
                allowMultiple
                options={categoryList.search}
                selected={productDetail.editable.product_categories}
                textField={
                  <Autocomplete.TextField
                    onChange={(value) => {
                      setCategoryListSearchValue(value);
                      if (value === "") {
                        setCategoryList({
                          ...categoryList,
                          search: categoryList.deselected,
                        });
                        return;
                      }

                      const filterRegex = new RegExp(value, "i");
                      const resultOptions = categoryList.deselected.filter(
                        (option) => option.label.match(filterRegex)
                      );
                      setCategoryList({
                        ...categoryList,
                        search: resultOptions,
                      });
                    }}
                    value={categoryListSearchValue}
                    label="Koleksiyonlar"
                    placeholder="Koleksiyonları bulun veya oluşturun"
                  />
                }
                onSelect={(v) => {
                  setProductDetail({
                    ...productDetail,
                    editable: {
                      ...productDetail.editable,
                      product_categories: v,
                    },
                  });
                }}
                listTitle="Koleksiyonlar"
              />
              <Combobox
                activator={
                  <Combobox.TextField
                    autoComplete="off"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(value) => {
                      setTagsTextFieldValue(value);
                    }}
                    value={tagsTextFieldValue}
                    label="Etiketler"
                    placeholder="Etiket bulun veya oluşturun"
                  />
                }
              >
                <Listbox
                  onSelect={() => {
                    if (
                      !productDetail.editable.product_tags.includes(
                        tagsTextFieldValue
                      ) &&
                      tagsTextFieldValue !== null &&
                      tagsTextFieldValue !== ""
                    ) {
                      setProductDetail({
                        ...productDetail,
                        editable: {
                          ...productDetail.editable,
                          product_tags: [
                            ...productDetail.editable.product_tags,
                            tagsTextFieldValue,
                          ],
                        },
                      });
                      setTagsTextFieldValue("");
                    }
                  }}
                >
                  <Listbox.Action key={"addNew"} value={"addNew"}>
                    <LegacyStack spacing="tight">
                      <Icon source={CirclePlusMinor} color="base" />
                      <div>Etiketi ekle</div>
                    </LegacyStack>
                  </Listbox.Action>
                </Listbox>
              </Combobox>
              <LegacyStack spacing="tight">
                {productDetail.editable.product_tags.map((v, index) => (
                  <Tag
                    key={index}
                    onRemove={() => {
                      var array = [...productDetail.editable.product_tags];
                      var index = array.indexOf(v);
                      if (index !== -1) {
                        array.splice(index, 1);
                        setProductDetail({
                          ...productDetail,
                          editable: {
                            ...productDetail.editable,
                            product_tags: array,
                          },
                        });
                      }
                    }}
                  >
                    {v}
                  </Tag>
                ))}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            secondaryActions={[
              {
                content:
                  productDetail.editable.product_archived === 1
                    ? "Ürünü arşivden çıkar"
                    : "Ürünü arşivle",
                onAction: () => toggleDraftStatus(),
              },
              {
                content: "Ürünü sil",
                onAction: () => deleteProduct(),
                destructive: true,
                outline: true,
              },
            ]}
            primaryAction={{
              content: "Kaydet",
              onAction: () => saveProductDetail(),
              disabled: !contextualSaveBarActive,
              loading: saveButtonLoading,
            }}
          />
        </Layout.Section>
      </Layout>
      <Modal
        open={productImageDeleteDetails.modalOpened}
        onClose={() =>
          setProductImageDeleteDetails({
            modalOpened: false,
            imageID: null,
            isBlob: false,
          })
        }
        title="Dosya silinsin mi?"
        primaryAction={{
          content: "Dosyayı sil",
          destructive: true,
          onAction: () => deleteProductImage(),
        }}
        secondaryActions={[
          {
            content: "İptal",
            onAction: () =>
              setProductImageDeleteDetails({
                modalOpened: false,
                imageID: null,
                isBlob: false,
              }),
          },
        ]}
      >
        <Modal.Section>Bu işlem geri alınamaz.</Modal.Section>
      </Modal>
      {productUpdateToastActive ? (
        <Toast
          onDismiss={productUpdateActionToastToggle}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  function featureTypes() {
    var pushArray = [];
    featureList.forEach((e) => {
      pushArray.push({
        key: window.crypto.randomUUID(),
        value: e.identifier,
        label: e.name,
      });
    });
    return pushArray;
  }

  function variantTypes(item) {
    if (item !== undefined) {
      var array = [
        { value: "size", label: "Beden" },
        { value: "color", label: "Renk" },
        { value: "length", label: "Uzunluk" },
        { value: "chainLength", label: "Zincir Uzunluğu" },
        { value: "material", label: "Malzeme" },
        { value: "style", label: "Stil" },
      ];
      var parser = [];
      var returnArray = [];
      productDetail.editable.product_variations.forEach((e) => {
        parser.push(e.variationType);
      });
      var itemCode =
        productDetail.editable.product_variations[item].variationType;
      if (itemCode === "size") {
        returnArray.push(array[0]);
      } else if (itemCode === "color") {
        returnArray.push(array[1]);
      } else if (itemCode === "length") {
        returnArray.push(array[2]);
      } else if (itemCode === "chainLength") {
        returnArray.push(array[3]);
      } else if (itemCode === "material") {
        returnArray.push(array[4]);
      } else if (itemCode === "style") {
        returnArray.push(array[5]);
      }

      if (!parser.includes("size")) {
        returnArray.push(array[0]);
      }
      if (!parser.includes("color")) {
        returnArray.push(array[1]);
      }
      if (!parser.includes("length")) {
        returnArray.push(array[2]);
      }
      if (!parser.includes("chainLength")) {
        returnArray.push(array[3]);
      }
      if (!parser.includes("material")) {
        returnArray.push(array[4]);
      }
      if (!parser.includes("style")) {
        returnArray.push(array[5]);
      }
      return returnArray;
    }
  }

  async function toggleDraftStatus() {
    const result = await fetcher({
      endpoint: "updateArchiveStatusProduct.php",
      method: "POST",
      body: JSON.stringify({
        secure: id,
        status: productDetail.editable.product_archived === 1 ? 0 : 1,
      }),
    });
    if (!result.fetchError) {
      setProductDetail({
        ...productDetail,
        editable: {
          ...productDetail.editable,
          product_archived: productDetail.product_archived === 1 ? 0 : 1,
        },
      });
    }
  }

  async function saveProductDetail() {
    setSaveButtonLoading(true);
    var formdata = new FormData();
    if (productDetail.editable.product_images.length > 0) {
      var count =
        productDetail.editable.product_images.length -
        newlyUploadedImages.length;
    } else {
      count = 0;
    }
    newlyUploadedImages.forEach((efile) => {
      formdata.append(count, efile);
      count = count + 1;
    });
    const result = await fetcher({
      endpoint: "updateProductDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: id,
        productDetail: productDetail.editable,
      }),
    });
    if (!result.fetchError) {
      const result = await fetcher({
        endpoint:
          "uploadProductImages.php?secureHash=" +
          productDetail.editable.secure_hash +
          "&count=" +
          (productDetail.editable.product_images.length -
            newlyUploadedImages.length),
        method: "POST",
        body: formdata,
      });
      if (!result.fetchError) {
        setSaveButtonLoading(false);
        setProductUpdateToastActive(true);
        setProductDetail({
          ...productDetail,
          original: productDetail.editable,
        });
      } else {
        setSaveButtonLoading(false);
      }
    }
  }

  async function deleteProductImage() {
    if (!productImageDeleteDetails.isBlob) {
      const result = await fetcher({
        endpoint: "deleteProductImage.php",
        method: "POST",
        body: JSON.stringify({
          secure: id,
          id: productImageDeleteDetails.imageID,
          imageCount: productDetail.editable.product_images.length,
        }),
      });
      if (!result.fetchError) {
        setProductImageDeleteDetails({
          modalOpened: false,
          imageID: null,
          isBlob: false,
        });
      }
    } else {
      var array = [...productDetail.editable.product_images];
      var secondArray = [...newlyUploadedImages];
      var index = array.indexOf(productImageDeleteDetails.imageID);
      if (index !== -1) {
        array.splice(index, 1);
        secondArray.splice(index, 1);
        setProductDetail({
          ...productDetail,
          editable: {
            ...productDetail.editable,
            product_images: array,
          },
        });
        setNewlyUploadedImages(secondArray);
        setProductImageDeleteDetails({
          modalOpened: false,
          imageID: null,
          isBlob: false,
        });
      }
    }
  }

  async function deleteProduct() {
    const result = await fetcher({
      endpoint: "deleteProduct.php",
      method: "POST",
      body: JSON.stringify({
        secure: id,
      }),
    });
    if (!result.fetchError) {
      navigate("/products/", { replace: true });
    }
  }

  useEffect(() => {
    (async () => {
      fetcher({
        endpoint: "getProductDetails.php",
        method: "POST",
        body: JSON.stringify({
          secure: id,
        }),
      })
        .then((data) => {
          if (data.status === "failure") throw new Error("");
          fetcher({
            endpoint: "getFeatures.php",
            method: "GET",
          }).then((e) => setFeatureList(e));

          fetcher({
            endpoint: "getCategories.php",
            method: "GET",
          }).then((e) => {
            var pushArray = [];
            e.forEach((e) => {
              pushArray.push({ value: e.id, label: e.category_title });
            });
            setCategoryList({ deselected: pushArray, search: pushArray });
          });

          setProductDetail({
            original: data,
            editable: data,
          });
          setLoading(false);
          setIsLoaded(true);
        })
        .catch(() => navigate("/products/", { replace: true }));
    })();
  }, []);

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default ProductDetail;
