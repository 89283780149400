import {
  Page,
  Layout,
  LegacyCard,
  PageActions,
  ContextualSaveBar,
  LegacyStack,
  TextField,
  Select,
  Text,
  FormLayout,
} from "@shopify/polaris";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { fetcher } from "src/helpers/fetcher";

function NewFeature() {
  let navigate = useNavigate();

  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const [featureDetail, setFeatureDetail] = useState({
    id: "",
    secure_hash: "",
    identifier: "",
    name: "",
    description: "",
    features: [{ name: "" }],
    date: "",
    status: 1,
  });

  const saveButtonDisabled = () => {
    return !(
      featureDetail.name !== "" &&
      featureDetail.features.length > 0 &&
      featureDetail.features[0].name !== ""
    );
  };

  const actualPageMarkup = (
    <Page
      title="Özellik oluştur"
      backAction={{ content: "Özellik oluştur", url: "/features/" }}
    >
      <ContextualSaveBar
        message="Kaydedilmemiş özellik"
        saveAction={{
          onAction: createFeature,
          loading: saveButtonLoading,
          disabled: saveButtonDisabled(),
        }}
        discardAction={{
          onAction: () => {
            navigate("/features/", { replace: false });
          },
        }}
      />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="loose">
              <TextField
                label="Başlık"
                value={featureDetail.name}
                onChange={(v) => {
                  setFeatureDetail({ ...featureDetail, name: v });
                }}
                placeholder="Pırlanta Taşı"
              />
              <TextField
                label="Açıklama"
                value={featureDetail.description}
                onChange={(v) => {
                  setFeatureDetail({
                    ...featureDetail,
                    description: v,
                  });
                }}
                multiline={3}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard title="Özellik Listesi">
            <LegacyCard.Section>
              <LegacyStack spacing="baseTight" vertical>
                {featureDetail.features.map(({ name }, index) => (
                  <FormLayout key={index}>
                    <TextField
                      placeholder="İsim"
                      autoComplete="off"
                      label="Özellik adı"
                      value={name}
                      onChange={(v) => {
                        var lastItem =
                          featureDetail.features[
                            featureDetail.features.length - 1
                          ];
                        var currentList = featureDetail.features;
                        currentList[index].name = v;
                        if (v !== "" && lastItem.name !== "") {
                          currentList.push({ name: "" });
                        } else if (v === "" && lastItem.name === "") {
                          currentList.splice(index, 1);
                        }
                        setFeatureDetail({
                          ...featureDetail,
                          features: currentList,
                        });
                      }}
                    />
                  </FormLayout>
                ))}
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Özellik durumu">
            <LegacyCard.Section>
              <Select
                options={[
                  { label: "Etkin", value: "1" },
                  { label: "Devre dışı", value: "0" },
                ]}
                onChange={(v) => {
                  setFeatureDetail({
                    ...featureDetail,
                    status: parseInt(v),
                  });
                }}
                value={featureDetail.status.toString()}
              />
              {!featureDetail.status && (
                <Text as="span" color="subdued">
                  Bu ürün tüm satış kanallarından gizlenecektir.
                </Text>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Satış kanalları ve uygulamalar" subdued>
              <hr
                style={{
                  border: "none",
                  borderBottom: "0.0625rem solid #c9cccf",
                  marginBottom: "1rem",
                }}
              />
              <LegacyStack alignment="center" spacing="tight">
                <div
                  style={{
                    border: "0.125rem solid currentcolor",
                    borderRadius: "50%",
                    height: "0.625rem",
                    width: "0.625rem",
                    color: featureDetail.status ? "#108043" : "#919eab",
                    background: featureDetail.status
                      ? "currentColor"
                      : "transparent",
                  }}
                />
                <Text as="span">Online Mağaza</Text>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              loading: saveButtonLoading,
              disabled: saveButtonDisabled(),
              onAction: createFeature,
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );

  async function createFeature() {
    setSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "createFeature.php",
      method: "POST",
      body: JSON.stringify({
        featureDetails: featureDetail,
      }),
    });
    if (!result.fetchError) {
      navigate("/features/" + result.createdFeatureSecure, { replace: true });
    } else {
      setSaveButtonLoading(false);
    }
  }

  return actualPageMarkup;
}

export default NewFeature;
