import {
  LegacyCard,
  IndexTable,
  useIndexResourceState,
  Badge,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  Page,
  Button,
  Layout,
  Link,
  LegacyStack,
  Tag,
  EmptyState,
  VerticalStack,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import LoadingMarkup from "src/components/LoadingMarkup";
import { fetcher } from "src/helpers/fetcher";

function DraftOrders() {
  // start: Page Variables
  const [orders, setOrders] = useState(false);
  // end: Page Variables

  // start: Tabs Variables
  const [selectedTab, setSelectedTab] = useState(0);
  // end: Tabs Variables

  // start: Index Filters Variables
  const filterSortOptions = [
    {
      label: "Sipariş numarası",
      value: "number asc",
      directionLabel: "Artan",
    },
    {
      label: "Sipariş numarası",
      value: "number desc",
      directionLabel: "Azalan",
    },
    {
      label: "Tarih",
      value: "date asc",
      directionLabel: "Eskiden Yeniye",
    },
    {
      label: "Tarih",
      value: "date desc",
      directionLabel: "Yeniden Eskiye",
    },
    {
      label: "Müşteri adı",
      value: "cname asc",
      directionLabel: "A-Z",
    },
    {
      label: "Müşteri adı",
      value: "cname desc",
      directionLabel: "Z-A",
    },
    {
      label: "Ödeme durumu",
      value: "payment_status asc",
      directionLabel: "Ödenmedi",
    },
    {
      label: "Ödeme durumu",
      value: "payment_status desc",
      directionLabel: "Ödendi",
    },
    {
      label: "Gönderim durumu",
      value: "shipping_status asc",
      directionLabel: "Gönderilmedi",
    },
    {
      label: "Gönderim durumu",
      value: "shipping_status desc",
      directionLabel: "Gönderildi",
    },
  ];
  const [filterSortSelectedOption, setFilterSortSelectedOption] = useState([
    "date desc",
  ]);
  const filtersMode = useSetIndexFiltersMode();
  const [filtersQueryValue, setFiltersQueryValue] = useState("");
  const [filtersLoading, setFiltersLoading] = useState(false);
  const checkFilterChanges = () => {
    var defaultFilters = {
      selectedTab: 0,
      query: "",
    };
    return defaultFilters.selectedTab === selectedTab &&
      defaultFilters.query === filtersQueryValue &&
      orders.length == 0
      ? false
      : true;
  };
  // end: Index Filters Variables

  // start: Index Table Variables
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);
  // end: Index Table Variables

  // start: Page Functions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        handleSelectionChange([]);
        setFiltersLoading(true);
        var orderSort = filterSortSelectedOption[0].split(" ");
        const result = await fetcher({
          endpoint:
            "getDraftOrders.php?status=" +
            (selectedTab === 0
              ? "All"
              : selectedTab === 1
              ? "Open"
              : selectedTab === 2 && "InvoiceSent") +
            (filtersQueryValue !== 0 && filtersQueryValue !== ""
              ? "&query=" + filtersQueryValue
              : "") +
            (orderSort[1] !== null
              ? "&orderSort=" + orderSort[1]
              : "&orderSort=DESC") +
            (orderSort[0] !== null
              ? "&sortType=" + orderSort[0]
              : "&sortType=date"),
          method: "GET",
        });
        if (!result.fetchError) {
          setOrders(result);
          setFiltersLoading(false);
        } else {
          setFiltersLoading(false);
        }
      })();
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersQueryValue, selectedTab, filterSortSelectedOption]);
  // end: Page Functions

  const tableRowMarkup =
    orders &&
    orders.map(
      (
        {
          id,
          secure,
          number,
          lastUpdated,
          customer,
          completed,
          waitingPayment,
          total,
        },
        index
      ) => (
        <IndexTable.Row
          id={id.toString()}
          key={id.toString()}
          selected={selectedResources.includes(id.toString())}
          position={index}
          status={completed === 1 && waitingPayment === 0 ? "subdued" : null}
        >
          <IndexTable.Cell>
            <Link url={"/orders/drafts/" + secure} monochrome removeUnderline>
              <Text
                as="span"
                color={
                  completed === 1 && waitingPayment === 0 ? "subdued" : "invert"
                }
                fontWeight={
                  completed === 1 && waitingPayment === 0
                    ? "regular"
                    : "semibold"
                }
              >
                #{number}
              </Text>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text
              as="span"
              color={completed === 1 && waitingPayment === 0 ? "subdued" : null}
            >
              {lastUpdated}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text
              as="span"
              color={completed === 1 && waitingPayment === 0 ? "subdued" : null}
            >
              {customer === " " ? "—" : customer}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge status={completed === 1 ? "success" : "info"}>
              {completed === 0 && waitingPayment === 0
                ? "Açık"
                : completed === 0 && waitingPayment === 1
                ? "Fatura gönderildi"
                : "Tamamlanmış"}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text
              as="span"
              color={completed === 1 && waitingPayment === 0 ? "subdued" : null}
            >
              {total}₺
            </Text>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  const pageMarkup = (
    <Page
      fullWidth
      title="Taslaklar"
      primaryAction={
        checkFilterChanges() ? (
          <Button url="/orders/drafts/new/" primary>
            Sipariş oluştur
          </Button>
        ) : (
          ""
        )
      }
      secondaryActions={
        checkFilterChanges() ? [{ content: "Dışa aktar", disabled: true }] : ""
      }
    >
      <Layout sectioned>
        {checkFilterChanges() ? (
          <LegacyCard>
            <IndexFilters
              sortOptions={filterSortOptions}
              sortSelected={filterSortSelectedOption}
              queryValue={filtersQueryValue}
              queryPlaceholder="Siparişlerin içerisinde ara"
              onQueryChange={(value) => {
                setFiltersQueryValue(value);
              }}
              onQueryClear={() => {
                setFiltersQueryValue("");
              }}
              onSort={(value) => {
                setFilterSortSelectedOption(value);
              }}
              filters={[]}
              appliedFilters={[]}
              hideFilters
              cancelAction={{
                onAction: () => {
                  setFiltersQueryValue("");
                },
                disabled: false,
                loading: false,
              }}
              tabs={[
                {
                  id: "All",
                  content: "Tümü",
                  panelID: "All",
                },
                {
                  id: "Open",
                  content: "Açık",
                  panelID: "Open",
                },
                {
                  id: "InvoiceSent",
                  content: "Fatura gönderildi",
                  panelID: "InvoiceSent",
                },
              ]}
              selected={selectedTab}
              onSelect={setSelectedTab}
              canCreateNewView={false}
              onClearAll={() => setFiltersQueryValue("")}
              mode={filtersMode.mode}
              setMode={filtersMode.setMode}
              loading={filtersLoading}
            />
            <IndexTable
              resourceName={{
                singular: "Sipariş",
                plural: "Siparişler",
              }}
              itemCount={orders.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[
                { title: "Taslak Sipariş" },
                { title: "Tarih" },
                { title: "Müşteri" },
                { title: "Durum" },
                { title: "Toplam" },
              ]}
              promotedBulkActions={[
                {
                  content: "Taslak siparişleri sil",
                  onAction: () => {
                    selectedResources.map((value) => deleteDraftOrders(value));
                  },
                },
              ]}
            >
              {tableRowMarkup}
            </IndexTable>
          </LegacyCard>
        ) : (
          <LegacyCard sectioned>
            <EmptyState
              action={{
                content: "Taslak sipariş oluştur",
                url: "/orders/drafts/new/",
              }}
              image={
                "https://cdn.shopify.com/shopifycloud/web/assets/v1/117e80a71ada1c3bad0a6e00c85c4df8881a1a33cf8d1d638b486dca7aa19ac5.svg"
              }
            >
              <VerticalStack gap={2}>
                <Text variant="headingLg" size="small">
                  Sipariş ve faturaları manuel olarak oluşturun
                </Text>
                <p>
                  Siparişleri telefon üzerinden almak, faturayı müşterilere
                  e-postayla göndermek ve ödemeleri tahsil etmek için
                  kullanabileceğiniz taslak siparişler oluşturun.
                </p>
              </VerticalStack>
            </EmptyState>
          </LegacyCard>
        )}
      </Layout>
    </Page>
  );

  return orders ? pageMarkup : <LoadingMarkup />;

  async function deleteDraftOrders(value) {
    await fetcher({
      endpoint: "deleteDraftOrder.php",
      method: "POST",
      body: JSON.stringify({
        secure: value,
      }),
    });
    handleSelectionChange([]);
  }
}

export default DraftOrders;
