import {
  LegacyCard,
  IndexTable,
  useIndexResourceState,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  Page,
  Button,
  Layout,
  Link,
  EmptyState,
  VerticalStack,
  Modal,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import LoadingMarkup from "src/components/LoadingMarkup";
import { fetcher } from "src/helpers/fetcher";

function Collections() {
  // start: Page Variables
  const [collections, setCollections] = useState(false);
  // end: Page Variables

  // start: Modal Variables
  const [deleteCategoryModalActive, setDeleteCategoryModalActive] =
    useState(false);
  const [
    deleteCategoryModalButtonLoading,
    setDeleteCategoryModalButtonLoading,
  ] = useState(false);
  // end: Modal Variables

  // start: Tabs Variables
  const [selectedTab, setSelectedTab] = useState(0);
  // end: Tabs Variables

  // start: Index Filters Variables
  const filterSortOptions = [
    {
      label: "Koleksiyon başlığı",
      value: "category_title asc",
      directionLabel: "A-Z",
    },
    {
      label: "Koleksiyon başlığı",
      value: "category_title desc",
      directionLabel: "Z-A",
    },
    {
      label: "Güncelleme",
      value: "last_updated asc",
      directionLabel: "Eskiden Yeniye",
    },
    {
      label: "Güncelleme",
      value: "last_updated desc",
      directionLabel: "Yeniden Eskiye",
    },
  ];
  const [filterSortSelectedOption, setFilterSortSelectedOption] = useState([
    "last_updated desc",
  ]);
  const filtersMode = useSetIndexFiltersMode();
  const [filtersQueryValue, setFiltersQueryValue] = useState("");
  const [filtersLoading, setFiltersLoading] = useState(false);
  const checkFilterChanges = () => {
    var defaultFilters = {
      selectedTab: 0,
      query: "",
    };
    return defaultFilters.selectedTab === selectedTab &&
      defaultFilters.query === filtersQueryValue &&
      collections.length == 0
      ? false
      : true;
  };
  // end: Index Filters Variables

  // start: Index Table Variables
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(collections);
  // end: Index Table Variables

  // start: Page Functions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        handleSelectionChange([]);
        setFiltersLoading(true);
        var collectionSort = filterSortSelectedOption[0].split(" ");
        const result = await fetcher({
          endpoint:
            "getCategories.php?status=" +
            (selectedTab === 0
              ? "All"
              : selectedTab === 1
              ? "Active"
              : selectedTab === 2
              ? "Draft"
              : selectedTab === 3
              ? "Archived"
              : "All") +
            (filtersQueryValue !== 0 && filtersQueryValue !== ""
              ? "&query=" + filtersQueryValue
              : "") +
            (collectionSort[1] !== null
              ? "&categorySort=" + collectionSort[1]
              : "&categorySort=DESC") +
            (collectionSort[0] !== null
              ? "&sortType=" + collectionSort[0]
              : "&sortType=date"),
          method: "GET",
        });
        if (!result.fetchError) {
          setCollections(result);
          setFiltersLoading(false);
        } else {
          setFiltersLoading(false);
        }
      })();
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersQueryValue, selectedTab, filterSortSelectedOption]);
  // end: Page Functions

  const tableRowMarkup =
    collections &&
    collections.map(({ id, category_title, category_product_count }, index) => (
      <IndexTable.Row
        id={id.toString()}
        key={id.toString()}
        selected={selectedResources.includes(id.toString())}
        position={index}
      >
        <IndexTable.Cell>
          <Link url={"/collections/" + id} monochrome removeUnderline>
            <Text fontWeight="semibold">{category_title}</Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{category_product_count} adet</IndexTable.Cell>
      </IndexTable.Row>
    ));

  const pageMarkup = (
    <Page
      fullWidth
      title="Koleksiyonlar"
      primaryAction={
        checkFilterChanges() ? (
          <Button url="/collections/new/" primary>
            Koleksiyon oluştur
          </Button>
        ) : (
          ""
        )
      }
      secondaryActions={
        checkFilterChanges()
          ? [
              { content: "Dışa aktar", disabled: true },
              { content: "İçe aktar", disabled: true },
            ]
          : [{ content: "İçe aktar", disabled: true }]
      }
    >
      <Layout sectioned>
        {checkFilterChanges() ? (
          <LegacyCard>
            <IndexFilters
              sortOptions={filterSortOptions}
              sortSelected={filterSortSelectedOption}
              queryValue={filtersQueryValue}
              queryPlaceholder="Ürünlerin içerisinde ara"
              onQueryChange={(value) => {
                setFiltersQueryValue(value);
              }}
              onQueryClear={() => {
                setFiltersQueryValue("");
              }}
              onSort={(value) => {
                setFilterSortSelectedOption(value);
              }}
              filters={[]}
              appliedFilters={[]}
              hideFilters
              cancelAction={{
                onAction: () => {
                  setFiltersQueryValue("");
                },
                disabled: false,
                loading: false,
              }}
              tabs={[
                {
                  id: "All",
                  content: "Tümü",
                  panelID: "All",
                },
              ]}
              selected={selectedTab}
              onSelect={setSelectedTab}
              canCreateNewView={false}
              onClearAll={() => setFiltersQueryValue("")}
              mode={filtersMode.mode}
              setMode={filtersMode.setMode}
              loading={filtersLoading}
            />
            <IndexTable
              resourceName={{
                singular: "Koleksiyon",
                plural: "Koleksiyonlar",
              }}
              itemCount={collections.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[{ title: "Başlık" }, { title: "Ürünler" }]}
              bulkActions={[
                {
                  items: [
                    {
                      content: "Etkin olarak ayarla",
                      onAction: () =>
                        selectedResources.map((value) =>
                          changeCategoryDetail(value, {
                            category_status: 1,
                            category_archived: 0,
                          })
                        ),
                    },
                    {
                      content: "Taslak olarak ayarla",
                      onAction: () => {
                        selectedResources.map((value) =>
                          changeCategoryDetail(value, {
                            category_status: 0,
                            category_archived: 0,
                          })
                        );
                      },
                    },
                    {
                      content: "Koleksiyonları arşivle",
                      onAction: () => {
                        selectedResources.map((value) =>
                          changeCategoryDetail(value, {
                            category_status: 0,
                            category_archived: 1,
                          })
                        );
                      },
                    },
                    {
                      content: "Koleksiyonları sil",
                      onAction: () => setDeleteCategoryModalActive(true),
                    },
                  ],
                },
              ]}
            >
              {tableRowMarkup}
            </IndexTable>
            <Modal
              open={deleteCategoryModalActive}
              onClose={() => {
                setDeleteCategoryModalActive(false);
              }}
              title={"Emin misiniz?"}
              primaryAction={{
                content: "Sil",
                loading: deleteCategoryModalButtonLoading,
                onAction: deleteCategories,
                destructive: true,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setDeleteCategoryModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <Text as="span">Bu işlem geri alınamaz.</Text>
              </Modal.Section>
            </Modal>
          </LegacyCard>
        ) : (
          <LegacyCard sectioned>
            <EmptyState
              action={{
                content: "Koleksiyon ekleyin",
                url: "/collections/new/",
              }}
              image={
                "https://cdn.shopify.com/shopifycloud/web/assets/v1/0c8a43219c5c1a08963055a7d32352213ea52e716004c76dff2cd1fefbf8b75f.svg"
              }
            >
              <VerticalStack gap={4}>
                <Text variant="headingLg" color="subdued" size="small">
                  Ürünlerinizi kategorilere göre gruplandırın
                </Text>
                <p>
                  Online mağazanızda, ürünlerinizi kategorilere ve galerilere
                  göre gruplandırmak için koleksiyonları kullanın.
                </p>
              </VerticalStack>
            </EmptyState>
          </LegacyCard>
        )}
      </Layout>
    </Page>
  );

  return collections ? pageMarkup : <LoadingMarkup />;

  async function changeCategoryDetail(secure, changedArray) {
    var combineArray = [];
    collections.forEach((e) => {
      if (e.secure_hash === secure) {
        combineArray.push(e);
      }
    });
    combineArray[0] = { ...combineArray[0], ...changedArray };
    await fetcher({
      endpoint: "updateCategoryDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: secure,
        newCategoryDetail: combineArray[0],
      }),
    });
    handleSelectionChange([]);
  }

  async function deleteCategories(value) {
    setDeleteCategoryModalButtonLoading(true);
    await Promise.all(
      selectedResources.map((e) =>
        fetcher({
          endpoint: "deleteCategory.php",
          method: "POST",
          body: JSON.stringify({
            secure: e,
          }),
        })
      )
    );
    handleSelectionChange([]);
  }
}

export default Collections;
