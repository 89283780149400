import {
  LegacyCard,
  IndexTable,
  useIndexResourceState,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  Page,
  Button,
  Layout,
  Link,
  EmptyState,
  VerticalStack,
  Badge,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import LoadingMarkup from "src/components/LoadingMarkup";
import { fetcher } from "src/helpers/fetcher";

function Customers() {
  // start: Page Variables
  const [customers, setCustomers] = useState(false);
  // end: Page Variables

  // start: Tabs Variables
  const [selectedTab, setSelectedTab] = useState(0);
  // end: Tabs Variables

  // start: Index Filters Variables
  const filterSortOptions = [
    {
      label: "Müşteri Adı",
      value: "name asc",
      directionLabel: "A-Z",
    },
    {
      label: "Müşteri Adı",
      value: "name desc",
      directionLabel: "Z-A",
    },
    {
      label: "Müşteri olduğu tarih",
      value: "date asc",
      directionLabel: "Eskiden Yeniye",
    },
    {
      label: "Müşteri olduğu tarih",
      value: "date desc",
      directionLabel: "Yeniden Eskiye",
    },
    {
      label: "Harcanan tutar",
      value: "all_paid_total asc",
      directionLabel: "Artan",
    },
    {
      label: "Harcanan tutar",
      value: "all_paid_total desc",
      directionLabel: "Azalan",
    },
    {
      label: "Toplam sipariş",
      value: "all_order_count asc",
      directionLabel: "Artan",
    },
    {
      label: "Toplam sipariş",
      value: "all_order_count desc",
      directionLabel: "Azalan",
    },
  ];
  const [filterSortSelectedOption, setFilterSortSelectedOption] = useState([
    "date desc",
  ]);
  const filtersMode = useSetIndexFiltersMode();
  const [filtersQueryValue, setFiltersQueryValue] = useState("");
  const [filtersLoading, setFiltersLoading] = useState(false);
  const checkFilterChanges = () => {
    var defaultFilters = {
      selectedTab: 0,
      query: "",
    };
    return defaultFilters.selectedTab === selectedTab &&
      defaultFilters.query === filtersQueryValue &&
      customers.length === 0
      ? false
      : true;
  };
  // end: Index Filters Variables

  // start: Index Table Variables
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(customers);
  // end: Index Table Variables

  // start: Page Functions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        handleSelectionChange([]);
        setFiltersLoading(true);
        var customerSort = filterSortSelectedOption[0].split(" ");
        const result = await fetcher({
          endpoint:
            "getCustomers.php?status=All" +
            (filtersQueryValue !== 0 && filtersQueryValue !== ""
              ? "&query=" + filtersQueryValue
              : "") +
            (customerSort[1] !== null
              ? "&customerSort=" + customerSort[1]
              : "&customerSort=DESC") +
            (customerSort[0] !== null
              ? "&sortType=" + customerSort[0]
              : "&sortType=date"),
          method: "GET",
        });
        if (!result.fetchError) {
          setCustomers(result);
          setFiltersLoading(false);
        } else {
          setFiltersLoading(false);
        }
      })();
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersQueryValue, selectedTab, filterSortSelectedOption]);
  // end: Page Functions

  const tableRowMarkup =
    customers &&
    customers.map(
      (
        {
          id,
          secure_hash,
          name,
          surname,
          primary_address,
          email_subscription,
          all_order_count,
          all_paid_total,
        },
        index
      ) => (
        <IndexTable.Row
          id={id.toString()}
          key={id.toString()}
          selected={selectedResources.includes(id.toString())}
          position={index}
        >
          <IndexTable.Cell>
            <Link url={"/customers/" + secure_hash} monochrome removeUnderline>
              <Text as="span" fontWeight="semibold">
                {name + " " + surname}
              </Text>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge status={email_subscription === 1 ? "success" : "attention"}>
              {email_subscription === 1 ? "Abone olundu" : "Reddedildi"}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {primary_address
              ? primary_address.city + ", " + primary_address.country
              : "—"}
          </IndexTable.Cell>
          <IndexTable.Cell>{all_order_count + " sipariş"}</IndexTable.Cell>
          <IndexTable.Cell>{"₺" + all_paid_total.toFixed(2)}</IndexTable.Cell>
        </IndexTable.Row>
      )
    );

  const pageMarkup = (
    <Page
      fullWidth
      title="Müşteriler"
      primaryAction={
        checkFilterChanges() ? (
          <Button url="/customers/new/" primary>
            Müşteri ekle
          </Button>
        ) : (
          ""
        )
      }
      secondaryActions={
        checkFilterChanges()
          ? [
              { content: "Dışa aktar", disabled: true },
              { content: "İçe aktar", disabled: true },
            ]
          : [{ content: "İçe aktar", disabled: true }]
      }
    >
      <Layout sectioned>
        {checkFilterChanges() ? (
          <LegacyCard>
            <IndexFilters
              sortOptions={filterSortOptions}
              sortSelected={filterSortSelectedOption}
              queryValue={filtersQueryValue}
              queryPlaceholder="Ürünlerin içerisinde ara"
              onQueryChange={(value) => {
                setFiltersQueryValue(value);
              }}
              onQueryClear={() => {
                setFiltersQueryValue("");
              }}
              onSort={(value) => {
                setFilterSortSelectedOption(value);
              }}
              filters={[]}
              appliedFilters={[]}
              hideFilters
              cancelAction={{
                onAction: () => {
                  setFiltersQueryValue("");
                },
                disabled: false,
                loading: false,
              }}
              tabs={[
                {
                  id: "All",
                  content: "Tümü",
                  panelID: "All",
                },
              ]}
              selected={selectedTab}
              onSelect={setSelectedTab}
              canCreateNewView={false}
              onClearAll={() => setFiltersQueryValue("")}
              mode={filtersMode.mode}
              setMode={filtersMode.setMode}
              loading={filtersLoading}
            />
            <IndexTable
              resourceName={{
                singular: "Müşteri",
                plural: "Müşteriler",
              }}
              itemCount={customers.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[
                { title: "Müşteri adı" },
                { title: "E-posta aboneliği" },
                { title: "Konum" },
                { title: "Siparişler" },
                { title: "Harcanan" },
              ]}
              promotedBulkActions={[
                {
                  content: "Müşterileri düzenle",
                  disabled: true,
                },
                {
                  content: "Müşterileri sil",
                  onAction: deleteCustomers,
                },
              ]}
            >
              {tableRowMarkup}
            </IndexTable>
          </LegacyCard>
        ) : (
          <LegacyCard sectioned>
            <EmptyState
              action={{ content: "Müşteri ekle", url: "/customers/new/" }}
              secondaryAction={{
                content: "Müşterileri içe aktar",
                disabled: true,
              }}
              image={
                "https://cdn.shopify.com/shopifycloud/web/assets/v1/93a30c07e111eac4342bdc13ff393a4fc1b51f3aaf062eca4cfe027c589fe036.svg"
              }
              fullWidth
            >
              <VerticalStack gap={4}>
                <Text variant="headingLg">
                  Müşterilerle ilgili her şey tek bir yerde
                </Text>
                <p>
                  Müşteri ekledikten sonra müşteri ayrıntılarını
                  güncelleyebilir, sipariş geçmişi özetlerini edinebilir,
                  satışları artıran kişiselleştirilmiş iletişimler göndermek
                  için segmentler oluşturabilir ve daha fazlasını
                  yapabilirsiniz.
                </p>
              </VerticalStack>
            </EmptyState>
          </LegacyCard>
        )}
      </Layout>
    </Page>
  );

  return customers ? pageMarkup : <LoadingMarkup />;

  async function deleteCustomers(value) {
    await Promise.all(
      selectedResources.map((e) =>
        fetcher({
          endpoint: "deleteCustomer.php",
          method: "POST",
          body: JSON.stringify({
            secure: e,
          }),
        })
      )
    );
    handleSelectionChange([]);
  }
}

export default Customers;
