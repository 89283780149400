import {
  Page,
  Layout,
  LegacyCard,
  Toast,
  ContextualSaveBar,
  FormLayout,
  TextField,
  Select,
  OptionList,
  Popover,
  Button,
  Checkbox,
  LegacyStack,
  Combobox,
  Icon,
  Listbox,
  Tag,
  Text,
} from "@shopify/polaris";
import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { CirclePlusMinor, SearchMinor } from "@shopify/polaris-icons";

import { fetcher } from "src/helpers/fetcher";

function NewCustomer() {
  let navigate = useNavigate();

  const [customerUpdateToastActive, setCustomerUpdateToastActive] =
    useState(false);

  const [createButtonLoading, setCreateButtonLoading] = useState(false);

  const [tagsAddInputValue, setTagsAddInputValue] = useState("");

  const [
    editCustomerPhoneCountryPopoverActive,
    setEditCustomerPhoneCountryPopoverActive,
  ] = useState(false);

  const [
    primaryAddressPhoneCountryPopoverActive,
    setPrimaryAddressPhoneCountryPopoverActive,
  ] = useState(false);

  const countryList = useRef([]);

  const [customerDetail, setCustomerDetail] = useState({
    id: 0,
    secure_hash: "",
    name: "",
    surname: "",
    email: "",
    phonePrefix: "+90",
    phone: "",
    language: 0,
    primary_address: {
      name: "",
      surname: "",
      addressLine: "",
      secondAddressLine: "",
      countryCode: "TR",
      country: "Türkiye",
      city: "",
      province: "",
      zip: "",
      phonePrefix: "+90",
      phone: "",
    },
    note: "",
    tags: [],
    email_subscription: true,
    account_approved: 0,
  });

  useEffect(() => {
    fetcher({
      endpoint: "geo/countries.php",
    }).then((e) => (countryList.current = e.content));
  }, []);

  const actualPageMarkup = (
    <Page
      title="Yeni müşteri"
      backAction={{ content: "Müşteriler", url: "/customers/" }}
    >
      <ContextualSaveBar
        message="Kaydedilmemiş değişiklikler"
        saveAction={{
          onAction: createCustomer,
          disabled: !(
            customerDetail.customer_title !== "" &&
            customerDetail.customer_title !== null
          ),
          loading: createButtonLoading,
        }}
        discardAction={{
          onAction: () => navigate("/customers/", { replace: false }),
        }}
      />
      <Layout sectioned>
        <Layout.Section></Layout.Section>
        <Layout.AnnotatedSection title="Müşteriye genel bakış">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  label="Ad"
                  value={customerDetail.name}
                  onChange={(val) =>
                    setCustomerDetail({ ...customerDetail, name: val })
                  }
                  type="text"
                  inputMode="text"
                />
                <TextField
                  label="Soyad"
                  value={customerDetail.surname}
                  onChange={(val) =>
                    setCustomerDetail({ ...customerDetail, surname: val })
                  }
                  type="text"
                  inputMode="text"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label="Dil"
                  options={[
                    { label: "Türkçe", value: "0" },
                    { label: "İngilizce", value: "1" },
                  ]}
                  value={String(customerDetail.language)}
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      language: parseInt(val),
                    })
                  }
                  helpText="Bu müşteri bildirimleri seçilen dilde alır."
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="E-posta"
                  value={customerDetail.email}
                  onChange={(val) =>
                    setCustomerDetail({ ...customerDetail, email: val })
                  }
                  type="email"
                  inputMode="email"
                  autoComplete="email"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  type="tel"
                  inputMode="tel"
                  autoComplete="tel"
                  placeholder="Telefon"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      phone: val,
                    })
                  }
                  prefix={
                    <Text as="span" fontWeight="semibold">
                      {customerDetail.phonePrefix}
                    </Text>
                  }
                  connectedRight={
                    <Popover
                      active={editCustomerPhoneCountryPopoverActive}
                      activator={
                        <Button
                          onClick={() =>
                            setEditCustomerPhoneCountryPopoverActive(
                              (editCustomerPhoneCountryPopoverActive) =>
                                !editCustomerPhoneCountryPopoverActive
                            )
                          }
                          disclosure
                        >
                          Ülke
                        </Button>
                      }
                    >
                      <OptionList
                        title="Ülke"
                        options={countryList.current.map(
                          ({ iso, name, phone_prefix }) => ({
                            label: name,
                            value: phone_prefix,
                            phoneCountry: iso,
                          })
                        )}
                        onChange={(va) => {
                          setCustomerDetail({
                            ...customerDetail,
                            phonePrefix: va[0],
                          });
                          setEditCustomerPhoneCountryPopoverActive(false);
                        }}
                        selected={customerDetail.phonePrefix}
                      />
                    </Popover>
                  }
                  value={customerDetail.phone}
                  label="Telefon"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <LegacyStack spacing="tight" vertical>
                  <Checkbox
                    label="Müşteri, pazarlama e-postaları almayı kabul etti."
                    checked={Boolean(customerDetail.email_subscription)}
                    onChange={(val) =>
                      setCustomerDetail({
                        ...customerDetail,
                        email_subscription: +val,
                      })
                    }
                  />
                  <Checkbox
                    label="Müşteri, SMS ile pazarlama kısa mesajları almayı kabul etti."
                    disabled
                  />
                </LegacyStack>
              </FormLayout.Group>
              <FormLayout.Group>
                <Text as="span" color="subdued">
                  Müşterileri pazarlama e-postalarınıza veya SMS'lerinize abone
                  yapmadan önce izinlerini istemelisiniz.
                </Text>
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Adres"
          description="Bu müşterinin birincil adresi"
        >
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label="Ülke/Bölge"
                  options={countryList.current.map(({ iso, name }) => ({
                    value: iso,
                    label: name,
                  }))}
                  onChange={(val) => {
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        countryCode: val,
                        country: getCountryNameByCode(val),
                      },
                    });
                  }}
                  value={customerDetail.primary_address.countryCode}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  placeholder="Ad"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        name: val,
                      },
                    })
                  }
                  value={customerDetail.primary_address.name}
                  label="Ad"
                />
                <TextField
                  placeholder="Soyad"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        surname: val,
                      },
                    })
                  }
                  value={customerDetail.primary_address.surname}
                  label="Soyad"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  placeholder="Adres"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        addressLine: val,
                      },
                    })
                  }
                  value={customerDetail.primary_address.addressLine}
                  label="Adres"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  placeholder="Apartman, daire vb."
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        secondAddressLine: val,
                      },
                    })
                  }
                  value={customerDetail.primary_address.secondAddressLine}
                  label="Apartman, daire vb."
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  placeholder="Posta kodu"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        zip: val,
                      },
                    })
                  }
                  value={customerDetail.primary_address.zip}
                  label="Posta kodu"
                />
                <TextField
                  placeholder="Şehir"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        city: val,
                      },
                    })
                  }
                  value={customerDetail.primary_address.city}
                  label="Şehir"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  type="tel"
                  inputMode="tel"
                  placeholder="Telefon"
                  onChange={(val) =>
                    setCustomerDetail({
                      ...customerDetail,
                      primary_address: {
                        ...customerDetail.primary_address,
                        phone: val,
                      },
                    })
                  }
                  prefix={
                    <Text as="span" fontWeight="semibold">
                      {customerDetail.primary_address.phonePrefix}
                    </Text>
                  }
                  connectedRight={
                    <Popover
                      active={primaryAddressPhoneCountryPopoverActive}
                      activator={
                        <Button
                          onClick={() =>
                            setPrimaryAddressPhoneCountryPopoverActive(
                              (primaryAddressPhoneCountryPopoverActive) =>
                                !primaryAddressPhoneCountryPopoverActive
                            )
                          }
                          disclosure
                        >
                          Ülke
                        </Button>
                      }
                    >
                      <OptionList
                        title="Ülke"
                        options={countryList.current.map(
                          ({ iso, name, phone_prefix }) => ({
                            label: name,
                            value: phone_prefix,
                            phoneCountry: iso,
                          })
                        )}
                        onChange={(va) => {
                          setCustomerDetail({
                            ...customerDetail,
                            primary_address: {
                              ...customerDetail.primary_address,
                              phonePrefix: va,
                            },
                          });
                          setPrimaryAddressPhoneCountryPopoverActive(false);
                        }}
                        selected={customerDetail.primary_address.phonePrefix}
                      />
                    </Popover>
                  }
                  value={customerDetail.primary_address.phone}
                  label="Telefon"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Notlar"
          description="Müşteriniz hakkında notlar ekleyin"
        >
          <LegacyCard sectioned>
            <TextField
              label="Not"
              value={customerDetail.note}
              onChange={(val) =>
                setCustomerDetail({ ...customerDetail, note: val })
              }
              multiline
            />
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Etiketler"
          description="Etiketler müşterileri gruplar halinde kategorilendirmek için kullanılabilir."
        >
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="tight">
              <Combobox
                activator={
                  <Combobox.TextField
                    autoComplete="off"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(value) => {
                      setTagsAddInputValue(value);
                    }}
                    value={tagsAddInputValue}
                    placeholder="Etiket bulun veya oluşturun"
                  />
                }
              >
                <Listbox
                  onSelect={() => {
                    if (
                      !customerDetail.tags.includes(tagsAddInputValue) &&
                      tagsAddInputValue !== null &&
                      tagsAddInputValue !== ""
                    ) {
                      setCustomerDetail({
                        ...customerDetail,
                        tags: [...customerDetail.tags, tagsAddInputValue],
                      });
                      setTagsAddInputValue("");
                    }
                  }}
                >
                  <Listbox.Action key={"addNew"} value={"addNew"}>
                    <LegacyStack spacing="tight">
                      <Icon source={CirclePlusMinor} color="base" />
                      <div>Etiketi ekle</div>
                    </LegacyStack>
                  </Listbox.Action>
                </Listbox>
              </Combobox>
              <LegacyStack spacing="tight">
                {customerDetail.tags.length > 0 &&
                  customerDetail.tags.map((option) => (
                    <Tag
                      key={`${option}`}
                      onRemove={() => {
                        var array = [...customerDetail.tags];
                        var index = array.indexOf(option);
                        if (index !== -1) {
                          array.splice(index, 1);
                          setCustomerDetail({
                            ...customerDetail,
                            tags: array,
                          });
                        }
                      }}
                    >
                      {option}
                    </Tag>
                  ))}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
      {customerUpdateToastActive ? (
        <Toast
          onDismiss={() => setCustomerUpdateToastActive(false)}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  function getCountryNameByCode(code) {
    return countryList.current.find(({ iso }) => iso === code).name;
  }

  async function createCustomer() {
    setCreateButtonLoading(true);
    const result = await fetcher({
      endpoint: "createCustomer.php",
      method: "POST",
      body: JSON.stringify({
        customerDetail: customerDetail,
      }),
    });
    if (!result.fetchError) {
      navigate("/customers/" + result.createdCustomerSecure, {
        replace: false,
      });
    } else {
      setCreateButtonLoading(false);
    }
  }

  return actualPageMarkup;
}

export default NewCustomer;
