import {
  Page,
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonPage,
  PageActions,
  Loading,
  Toast,
  ContextualSaveBar,
  LegacyStack,
  TextField,
  Text,
  Select,
  Icon,
  ResourceList,
  ResourceItem,
  Spinner,
  Thumbnail,
  Button,
  FormLayout,
  Modal,
  Badge,
  DataTable,
  TextContainer,
} from "@shopify/polaris";
import {
  CancelSmallMinor,
  SearchMinor,
  ViewMinor,
} from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { fetcher } from "src/helpers/fetcher";
import ProductPriceCalculator from "src/helpers/ProductPriceCalculator";
import { useMain } from "src/contexts/Main";

function CollectionDetail(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const { rates } = useMain();

  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [contextualSaveBarActive, setContextualSaveBarActive] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [deleteCategoryModalActive, setDeleteCategoryModalActive] =
    useState(false);

  const [categoryUpdateToastActive, setCategoryUpdateToastActive] =
    useState(false);

  const categoryUpdateActionToastToggle = useCallback(
    () => setCategoryUpdateToastActive((active) => !active),
    []
  );

  const [categoryDetail, setCategoryDetail] = useState({
    id: 0,
    secure_hash: "",
    last_updated: 0,
    category_title: "",
    category_description: "",
    category_products: [],
    category_status: 0,
    category_seo_title: "",
    category_seo_description: "",
    category_url: "",
  });

  const [
    categoryDetailSelectedProductItems,
    setCategoryDetailSelectedProductItems,
  ] = useState([]);
  const [addNewProductsModalActive, setAddNewProductsModalActive] =
    useState(false);
  const [addNewProductModalSearchValue, setAddNewProductModalSearchValue] =
    useState("");
  const [addNewProductsSaveButtonLoading, setAddNewProductsSaveButtonLoading] =
    useState(false);
  const [addNewProductsSearchLoading, setAddNewProductsSearchLoading] =
    useState(false);
  const [addNewProductsSearchedProducts, setAddNewProductsSearchedProducts] =
    useState([]);

  function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return "" + Math.round(n * k) / k;
      };
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  }

  const productsRows =
    categoryDetail.category_products.length > 0 &&
    categoryDetail.category_products.map(
      ({ secure, name, status, product_image }, index) => [
        <div style={{ width: "100%" }} key={index}>
          <LegacyStack alignment="center">
            <Thumbnail source={product_image} size="small" />
            <LegacyStack spacing="extraTight" vertical>
              <Text as="span">{name}</Text>
            </LegacyStack>
          </LegacyStack>
        </div>,
        <Badge status={status === 0 ? "info" : "success"} key={index}>
          {status === 0 ? "Taslak" : "Aktif"}
        </Badge>,
        <Button
          plain
          removeUnderline
          onClick={() => {
            deleteProductFromCategory(secure);
          }}
          key={index}
        >
          <div style={{ color: "black", fill: "black" }}>
            <Icon source={CancelSmallMinor} />
          </div>
        </Button>,
      ]
    );

  const loadingMarkup = (
    <SkeletonPage>
      <Loading />
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
          <LegacyCard sectioned>
            <SkeletonBodyText lines={3} />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Kaydet",
              disabled: true,
            }}
          />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const actualPageMarkup = (
    <Page
      title={categoryDetail.category_title}
      backAction={{ content: "Koleksiyonlar", url: "/collections/" }}
      secondaryActions={[
        {
          content: "Görüntüle",
          icon: ViewMinor,
          onAction: () => {
            window.open(
              "//altinoranonline.com.tr/c/" +
                categoryDetail.category_title
                  .toLocaleLowerCase("tr")
                  .replaceAll("Ğ", "g")
                  .replaceAll("g", "g")
                  .replaceAll("ü", "u")
                  .replaceAll("ş", "s")
                  .replaceAll("ı", "i")
                  .replaceAll("i", "i")
                  .replaceAll("o", "o")
                  .replaceAll("ç", "c")
                  .replaceAll(" ", "-"),
              "_blank"
            );
          },
        },
      ]}
    >
      {contextualSaveBarActive && (
        <ContextualSaveBar
          message="Kaydedilmemiş değişiklikler"
          saveAction={{
            onAction: () => saveCategoryDetail(categoryDetail),
            loading: saveButtonLoading,
          }}
          discardAction={{
            onAction: () => {
              setContextualSaveBarActive(false);
            },
          }}
        />
      )}
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <LegacyStack vertical spacing="loose">
              <TextField
                label="Başlık"
                value={categoryDetail.category_title}
                onChange={(v) => {
                  if (v !== categoryDetail.category_title) {
                    setContextualSaveBarActive(true);
                  }
                  setCategoryDetail({ ...categoryDetail, category_title: v });
                }}
                placeholder="Kısa kollu tişört"
              />
              <TextField
                label="Açıklama"
                value={categoryDetail.category_description}
                onChange={(v) => {
                  if (v !== categoryDetail.category_description) {
                    setContextualSaveBarActive(true);
                  }
                  setCategoryDetail({
                    ...categoryDetail,
                    category_description: v,
                  });
                }}
                multiline={5}
              />
            </LegacyStack>
          </LegacyCard>
          <LegacyCard title="Ürünler">
            <LegacyCard.Section>
              <LegacyStack spacing="extraTight">
                <LegacyStack.Item fill>
                  <TextField
                    type="text"
                    autoComplete="off"
                    placeholder="Ürünleri arayın"
                    prefix={<Icon source={SearchMinor} />}
                    onChange={(val) => {
                      setAddNewProductsModalActive(true);
                      setAddNewProductModalSearchValue(val);
                    }}
                  />
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <Button
                    onClick={() => {
                      setAddNewProductsModalActive(true);
                      setCategoryDetailSelectedProductItems([]);
                      searchProducts("");
                    }}
                  >
                    Gözat
                  </Button>
                </LegacyStack.Item>
              </LegacyStack>
              <Modal
                open={addNewProductsModalActive}
                onClose={() => {
                  setAddNewProductsModalActive(false);
                  setAddNewProductModalSearchValue("");
                  setCategoryDetailSelectedProductItems([]);
                  searchProducts("");
                }}
                title="Tüm ürünler"
                primaryAction={{
                  content: "Bitti",
                  loading: addNewProductsSaveButtonLoading,
                  onAction: addProducts,
                }}
                secondaryActions={[
                  {
                    content: "İptal",
                    onAction: () => {
                      setAddNewProductsModalActive(false);
                      setAddNewProductModalSearchValue("");
                      setCategoryDetailSelectedProductItems([]);
                      searchProducts("");
                    },
                  },
                ]}
              >
                <Modal.Section>
                  <TextField
                    type="text"
                    autoComplete="off"
                    placeholder="Ürünleri arayın"
                    prefix={<Icon source={SearchMinor} />}
                    value={addNewProductModalSearchValue}
                    onChange={(val) => {
                      searchProducts(val);
                      setAddNewProductModalSearchValue(val);
                    }}
                    autoFocus
                  />
                </Modal.Section>
                {addNewProductsSearchLoading && (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner size="large" />
                  </LegacyStack>
                )}
                {addNewProductsSearchedProducts.length === 0 &&
                !addNewProductsSearchLoading ? (
                  <Modal.Section>
                    <div>
                      <Text as="span">"{addNewProductModalSearchValue}"</Text>
                      <Text as="span" color="subdued">
                        {" "}
                        için sonuç bulunamadı
                      </Text>
                    </div>
                  </Modal.Section>
                ) : (
                  !addNewProductsSearchLoading && (
                    <ResourceList
                      items={addNewProductsSearchedProducts}
                      renderItem={({
                        id,
                        product_title,
                        product_price,
                        product_type,
                        product_quantity,
                        product_image,
                      }) => {
                        return (
                          <ResourceItem
                            id={id}
                            media={
                              <Thumbnail size="small" source={product_image} />
                            }
                          >
                            <LegacyStack distribution="equalSpacing">
                              <LegacyStack
                                vertical
                                spacing="extraTight"
                                distribution="center"
                              >
                                <Text as="span">{product_title}</Text>
                                <Text as="span" color="subdued">
                                  {product_quantity} adet kaldı
                                </Text>
                              </LegacyStack>
                              <Text as="span" fontWeight="semibold">
                                {rates && rates.length > 0 && product_type.value
                                  ? ProductPriceCalculator(
                                      product_price,
                                      product_type.value,
                                      rates
                                    )
                                  : "0.00₺"}
                              </Text>
                            </LegacyStack>
                          </ResourceItem>
                        );
                      }}
                      selectedItems={categoryDetailSelectedProductItems}
                      onSelectionChange={setCategoryDetailSelectedProductItems}
                      selectable
                    />
                  )
                )}
              </Modal>
            </LegacyCard.Section>
            {categoryDetail.category_products.length > 0 && (
              <LegacyCard.Section>
                <DataTable
                  columnContentTypes={["text", "text", "numeric"]}
                  headings={[
                    <Text as="span" fontWeight="semibold">
                      Ürün
                    </Text>,
                    <Text as="span" fontWeight="semibold">
                      Durum
                    </Text>,
                    <Text as="span" fontWeight="semibold"></Text>,
                  ]}
                  rows={productsRows}
                />
              </LegacyCard.Section>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section secondary>
          <LegacyCard title="Koleksiyon durumu">
            <LegacyCard.Section>
              <Select
                options={[
                  { label: "Etkin", value: "active" },
                  { label: "Devre dışı", value: "deactivated" },
                ]}
                onChange={(v) => {
                  if (
                    (v === "active" ? 1 : 0) !== categoryDetail.category_status
                  ) {
                    setContextualSaveBarActive(true);
                  }
                  setCategoryDetail({
                    ...categoryDetail,
                    category_status: v === "active" ? 1 : 0,
                  });
                }}
                value={
                  categoryDetail.category_status === 1
                    ? "active"
                    : "deactivated"
                }
              />
              {categoryDetail.category_status === 0 && (
                <Text color="subdued">
                  Bu ürün tüm satış kanallarından gizlenecektir.
                </Text>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Satış kanalları ve uygulamalar" subdued>
              <hr
                style={{
                  border: "none",
                  borderBottom: "0.0625rem solid #c9cccf",
                  marginBottom: "1rem",
                }}
              />
              <LegacyStack alignment="center" spacing="tight">
                <div
                  style={{
                    border: "0.125rem solid currentcolor",
                    borderRadius: "50%",
                    height: "0.625rem",
                    width: "0.625rem",
                    color:
                      categoryDetail.category_status === 1
                        ? "#108043"
                        : "#919eab",
                    background:
                      categoryDetail.category_status === 1
                        ? "currentColor"
                        : "transparent",
                  }}
                />
                <Text as="span">Online Mağaza</Text>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            secondaryActions={[
              {
                content: "Koleksiyonu sil",
                onAction: () => setDeleteCategoryModalActive(true),
                destructive: true,
                outline: true,
              },
            ]}
            primaryAction={{
              content: "Kaydet",
              disabled: !contextualSaveBarActive,
              loading: saveButtonLoading,
            }}
          />
        </Layout.Section>
        <Modal
          open={deleteCategoryModalActive}
          onClose={() => {
            setDeleteCategoryModalActive(false);
          }}
          title={categoryDetail.category_title + " silinsin mi?"}
          primaryAction={{
            content: "Bitti",
            loading: saveButtonLoading,
            onAction: deleteCategory,
            destructive: true,
            outline: true,
          }}
          secondaryActions={[
            {
              content: "İptal",
              onAction: () => {
                setDeleteCategoryModalActive(false);
              },
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>Bu işlem geri alınamaz.</TextContainer>
          </Modal.Section>
        </Modal>
      </Layout>
      {categoryUpdateToastActive ? (
        <Toast
          onDismiss={categoryUpdateActionToastToggle}
          content="İşlem başarılı"
        />
      ) : null}
    </Page>
  );

  async function searchProducts(q) {
    if (!addNewProductsSearchLoading) {
      setAddNewProductsSearchLoading(true);
    }
    setAddNewProductsSearchedProducts([]);
    const result = await fetcher({
      endpoint: "getProducts.php?status=All&query=" + q,
      method: "GET",
    });
    if (!result.fetchError) {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts(result);
    } else {
      setAddNewProductsSearchLoading(false);
      setAddNewProductsSearchedProducts([]);
    }
  }

  async function addProducts() {
    setSaveButtonLoading(true);
    setAddNewProductsSaveButtonLoading(true);
    await Promise.all(
      categoryDetailSelectedProductItems.map((e) =>
        fetcher({
          endpoint: "addProductsFromCategory.php",
          method: "POST",
          body: JSON.stringify({
            secure: location.pathname.replace("/collections/", ""),
            productSecure: e,
          }),
        })
      )
    );
    updateCategoryDetail();
    setSaveButtonLoading(false);
    setAddNewProductsSaveButtonLoading(false);
    setAddNewProductsModalActive(false);
  }

  async function deleteCategory() {
    setSaveButtonLoading(true);
    await fetcher({
      endpoint: "deleteCategory.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/collections/", ""),
      }),
    });
    navigate("/collections/", { replace: false });
  }

  async function updateCategoryDetail() {
    const result = await fetcher({
      endpoint: "getCategoryDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/collections/", ""),
      }),
    });
    if (!result.fetchError) {
      setCategoryDetail(result);
      setSaveButtonLoading(false);
      setCategoryUpdateToastActive(true);
      setContextualSaveBarActive(false);
    } else {
      navigate("/collections/", { replace: true });
    }
  }

  async function saveCategoryDetail(newArray) {
    setSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "updateCategoryDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/collections/", ""),
        newCategoryDetail: newArray,
      }),
    });
    if (!result.fetchError) {
      updateCategoryDetail();
    } else {
      setSaveButtonLoading(false);
    }
  }

  async function deleteProductFromCategory(product) {
    setSaveButtonLoading(true);
    const result = await fetcher({
      endpoint: "deleteProductsFromCategory.php",
      method: "POST",
      body: JSON.stringify({
        secure: location.pathname.replace("/collections/", ""),
        productSecure: product,
      }),
    });
    if (!result.fetchError) {
      updateCategoryDetail();
    } else {
      setSaveButtonLoading(false);
    }
  }

  if (isLoading && !isLoaded) {
    (async () => {
      const result = await fetcher({
        endpoint: "getCategoryDetails.php",
        method: "POST",
        body: JSON.stringify({
          secure: location.pathname.replace("/collections/", ""),
        }),
      });
      if (!result.fetchError) {
        setCategoryDetail(result);
        setLoading(false);
        setIsLoaded(true);
      } else {
        navigate("/collections/", { replace: true });
      }
    })();
  }

  const pageMarkup = isLoading && !isLoaded ? loadingMarkup : actualPageMarkup;

  return pageMarkup;
}

export default CollectionDetail;
