import { Text } from "@shopify/polaris";
import { useMediaPredicate } from "react-media-hook";
import { useClock } from "react-use-clock";

function Clock() {
  const clock = useClock();
  const isDesktop = useMediaPredicate("(min-width: 1045px)");

  return isDesktop ? (
    <Text variant={isDesktop ? "headingLg" : "headingSm"}>
      {clock.hours.toString().padStart(2, "0")}:
      {clock.minutes.toString().padStart(2, "0")}:
      {clock.seconds.toString().padStart(2, "0")}
    </Text>
  ) : (
    <></>
  );
}

export default Clock;
