import {
  LegacyCard,
  IndexTable,
  useIndexResourceState,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  Page,
  Button,
  Layout,
  Link,
  EmptyState,
  VerticalStack,
  Modal,
  Badge,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import LoadingMarkup from "src/components/LoadingMarkup";
import { fetcher } from "src/helpers/fetcher";

function Features() {
  // start: Page Variables
  const [features, setFeatures] = useState(false);
  // end: Page Variables

  // start: Modal Variables
  const [deleteFeatureModalActive, setDeleteFeatureModalActive] =
    useState(false);
  const [deleteFeatureModalButtonLoading, setDeleteFeatureModalButtonLoading] =
    useState(false);
  // end: Modal Variables

  // start: Tabs Variables
  const [selectedTab, setSelectedTab] = useState(0);
  // end: Tabs Variables

  // start: Index Filters Variables
  const filterSortOptions = [
    {
      label: "Koleksiyon başlığı",
      value: "feature_title asc",
      directionLabel: "A-Z",
    },
    {
      label: "Koleksiyon başlığı",
      value: "feature_title desc",
      directionLabel: "Z-A",
    },
    {
      label: "Güncelleme",
      value: "last_updated asc",
      directionLabel: "Eskiden Yeniye",
    },
    {
      label: "Güncelleme",
      value: "last_updated desc",
      directionLabel: "Yeniden Eskiye",
    },
  ];
  const [filterSortSelectedOption, setFilterSortSelectedOption] = useState([
    "last_updated desc",
  ]);
  const filtersMode = useSetIndexFiltersMode();
  const [filtersQueryValue, setFiltersQueryValue] = useState("");
  const [filtersLoading, setFiltersLoading] = useState(false);
  const checkFilterChanges = () => {
    var defaultFilters = {
      selectedTab: 0,
      query: "",
    };
    return defaultFilters.selectedTab === selectedTab &&
      defaultFilters.query === filtersQueryValue &&
      features.length == 0
      ? false
      : true;
  };
  // end: Index Filters Variables

  // start: Index Table Variables
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(features);
  // end: Index Table Variables

  // start: Page Functions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        handleSelectionChange([]);
        setFiltersLoading(true);
        var featureSort = filterSortSelectedOption[0].split(" ");
        const result = await fetcher({
          endpoint:
            "getFeatures.php?status=" +
            (selectedTab === 0
              ? "All"
              : selectedTab === 1
              ? "Active"
              : selectedTab === 2
              ? "Draft"
              : selectedTab === 3
              ? "Archived"
              : "All") +
            (filtersQueryValue !== 0 && filtersQueryValue !== ""
              ? "&query=" + filtersQueryValue
              : "") +
            (featureSort[1] !== null
              ? "&sortDirection=" + featureSort[1]
              : "&sortDirection=DESC") +
            (featureSort[0] !== null
              ? "&sortType=" + featureSort[0]
              : "&sortType=date"),
          method: "GET",
        });
        if (!result.fetchError) {
          setFeatures(result);
          setFiltersLoading(false);
        } else {
          setFiltersLoading(false);
        }
      })();
    }, 600);
    return () => clearTimeout(delayDebounceFn);
  }, [filtersQueryValue, selectedTab, filterSortSelectedOption]);
  // end: Page Functions

  const tableRowMarkup =
    features &&
    features.map(({ id, secure_hash, name, status }, index) => (
      <IndexTable.Row
        id={id.toString()}
        key={id.toString()}
        selected={selectedResources.includes(id.toString())}
        position={index}
      >
        <IndexTable.Cell>
          <Link url={"/features/" + secure_hash} monochrome removeUnderline>
            <Text fontWeight="semibold">{name}</Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge status={status ? "success" : "critical"}>
            {status ? "Etkin" : "Devre dışı"}
          </Badge>
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const pageMarkup = (
    <Page
      fullWidth
      title="Koleksiyonlar"
      primaryAction={
        checkFilterChanges() ? (
          <Button url="/features/new/" primary>
            Koleksiyon oluştur
          </Button>
        ) : (
          ""
        )
      }
      secondaryActions={
        checkFilterChanges()
          ? [
              { content: "Dışa aktar", disabled: true },
              { content: "İçe aktar", disabled: true },
            ]
          : [{ content: "İçe aktar", disabled: true }]
      }
    >
      <Layout sectioned>
        {checkFilterChanges() ? (
          <LegacyCard>
            <IndexFilters
              sortOptions={filterSortOptions}
              sortSelected={filterSortSelectedOption}
              queryValue={filtersQueryValue}
              queryPlaceholder="Ürünlerin içerisinde ara"
              onQueryChange={(value) => {
                setFiltersQueryValue(value);
              }}
              onQueryClear={() => {
                setFiltersQueryValue("");
              }}
              onSort={(value) => {
                setFilterSortSelectedOption(value);
              }}
              filters={[]}
              appliedFilters={[]}
              hideFilters
              cancelAction={{
                onAction: () => {
                  setFiltersQueryValue("");
                },
                disabled: false,
                loading: false,
              }}
              tabs={[
                {
                  id: "All",
                  content: "Tümü",
                  panelID: "All",
                },
              ]}
              selected={selectedTab}
              onSelect={setSelectedTab}
              canCreateNewView={false}
              onClearAll={() => setFiltersQueryValue("")}
              mode={filtersMode.mode}
              setMode={filtersMode.setMode}
              loading={filtersLoading}
            />
            <IndexTable
              resourceName={{
                singular: "Koleksiyon",
                plural: "Koleksiyonlar",
              }}
              itemCount={features.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[{ title: "Başlık" }, { title: "Durum" }]}
              bulkActions={[
                {
                  items: [
                    {
                      content: "Etkin olarak ayarla",
                      onAction: () =>
                        selectedResources.map((value) =>
                          changeFeatureDetail(value, {
                            feature_status: 1,
                            feature_archived: 0,
                          })
                        ),
                    },
                    {
                      content: "Taslak olarak ayarla",
                      onAction: () => {
                        selectedResources.map((value) =>
                          changeFeatureDetail(value, {
                            feature_status: 0,
                            feature_archived: 0,
                          })
                        );
                      },
                    },
                    {
                      content: "Koleksiyonları arşivle",
                      onAction: () => {
                        selectedResources.map((value) =>
                          changeFeatureDetail(value, {
                            feature_status: 0,
                            feature_archived: 1,
                          })
                        );
                      },
                    },
                    {
                      content: "Koleksiyonları sil",
                      onAction: () => setDeleteFeatureModalActive(true),
                    },
                  ],
                },
              ]}
            >
              {tableRowMarkup}
            </IndexTable>
            <Modal
              open={deleteFeatureModalActive}
              onClose={() => {
                setDeleteFeatureModalActive(false);
              }}
              title={"Emin misiniz?"}
              primaryAction={{
                content: "Sil",
                loading: deleteFeatureModalButtonLoading,
                onAction: deleteFeatures,
                destructive: true,
              }}
              secondaryActions={[
                {
                  content: "İptal",
                  onAction: () => {
                    setDeleteFeatureModalActive(false);
                  },
                },
              ]}
            >
              <Modal.Section>
                <Text as="span">Bu işlem geri alınamaz.</Text>
              </Modal.Section>
            </Modal>
          </LegacyCard>
        ) : (
          <LegacyCard sectioned>
            <EmptyState
              action={{
                content: "Özellik ekleyin",
                url: "/features/new/",
              }}
              image={
                "https://cdn.shopify.com/shopifycloud/web/assets/v1/0c8a43219c5c1a08963055a7d32352213ea52e716004c76dff2cd1fefbf8b75f.svg"
              }
            >
              <VerticalStack gap={4}>
                <Text variant="headingLg" color="subdued" size="small">
                  Ürünlerinize özellik ekleyin
                </Text>
                <p>
                  Ürünlerinize ek özellikler oluşturmak için burayı kullanın,
                  oluşturduğunuz özellikler ürün sayfasında görünür
                </p>
              </VerticalStack>
            </EmptyState>
          </LegacyCard>
        )}
      </Layout>
    </Page>
  );

  return features ? pageMarkup : <LoadingMarkup />;

  async function changeFeatureDetail(secure, changedArray) {
    var combineArray = [];
    features.forEach((e) => {
      if (e.secure_hash === secure) {
        combineArray.push(e);
      }
    });
    combineArray[0] = { ...combineArray[0], ...changedArray };
    const result = await fetcher({
      endpoint: "updateFeatureDetails.php",
      method: "POST",
      body: JSON.stringify({
        secure: secure,
        newFeatureDetail: combineArray[0],
      }),
    });
    if (!result.fetchError) {
      handleSelectionChange([]);
    }
  }

  async function deleteFeatures(value) {
    setDeleteFeatureModalButtonLoading(true);
    await Promise.all(
      selectedResources.map((e) =>
        fetcher({
          endpoint: "deleteFeature.php",
          method: "POST",
          body: JSON.stringify({
            secure: e,
          }),
        })
      )
    );
    handleSelectionChange([]);
  }
}

export default Features;
