import { Icon, Spinner, LegacyStack, Text } from "@shopify/polaris";
import { useState } from "react";
import { useEffect } from "react";
import { CircleTickOutlineMinor } from "@shopify/polaris-icons";
import { useMediaPredicate } from "react-media-hook";
import { fetcher } from "../helpers/fetcher";
import { useMain } from "src/contexts/Main";

function LivePricingTable() {
  const isDesktop = useMediaPredicate("(min-width: 1045px)");
  const { rates, loadState } = useMain();

  return (
    <LegacyStack
      spacing={isDesktop ? "loose" : "extraTight"}
      wrap={isDesktop ? false : true}
      distribution={isDesktop ? null : "center"}
    >
      {loadState === 0 ? (
        <LegacyStack
          spacing="extraTight"
          distribution="center"
          alignment="center"
        >
          <Text variant="headingMd">Bağlantı sağlanıyor</Text>
          <Spinner size="small" />
        </LegacyStack>
      ) : loadState === 1 ? (
        <LegacyStack
          spacing="extraTight"
          distribution="center"
          alignment="center"
        >
          <Text variant="headingMd">Bağlantı kuruldu</Text>
          <Icon source={CircleTickOutlineMinor} color="success" />
        </LegacyStack>
      ) : rates.length > 0 ? (
        rates.slice(0, 6).map(({ uuid, name, prices }, index) => (
          <Text variant={isDesktop ? "headingMd" : "headingSm"} key={index}>
            {name}: {prices.sell.int}
          </Text>
        ))
      ) : null}
    </LegacyStack>
  );
}

export default LivePricingTable;
