"use client";
import { createContext, useContext, useEffect, useState } from "react";
import { MainContextProps, MainProviderProps } from "./interface";
import { fetcher } from "src/helpers/fetcher";

const MainContext = createContext<MainContextProps | undefined>(undefined);

export function useMain() {
  const context = useContext(MainContext);
  if (context === undefined) {
    throw new Error("useMain must be used within a MainProvider");
  }
  return context;
}

export function MainProvider(mainP: MainProviderProps) {
  const [rates, setRates] = useState([]);
  const [loadState, setLoadState] = useState(0);

  useEffect(() => {
    fetcher({
      endpoint: "https://api.altinorankuyumculuk.com.tr/api/rates/",
      customEndpoint: true,
      method: "GET",
    }).then((data) => {
      if (loadState === 0) {
        setLoadState(1);
        setTimeout(() => {
          setLoadState(2);
          setRates(data.content);
          setInterval(() => {
            fetcher({
              endpoint: "https://api.altinorankuyumculuk.com.tr/api/rates/",
              customEndpoint: true,
              method: "GET",
            }).then((data) => {
              setRates(data.content);
            });
          }, 30000);
        }, 2000);
      }
    });
  }, []);

  const value: MainContextProps = {
    rates,
    loadState,
  };

  return (
    <MainContext.Provider value={value}>{mainP.children}</MainContext.Provider>
  );
}
