export const NumberFormat = (
  number: number | string,
  decimals: number = 2,
  dec_point: string = ",",
  thousands_sep: string = "."
): string => {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = thousands_sep;
  const dec = dec_point;
  let s: string[] = [];

  const toFixedFix = (n: number, prec: number): string => {
    const k = Math.pow(10, prec);
    return (Math.round(n * k) / k).toFixed(prec);
  };

  s = (prec ? toFixedFix(n, prec) : Math.round(n).toString()).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

export default NumberFormat;
